import  ProfileInstitute from "../modules/coaching/profile/profile";
import Branches from "../modules/coaching/Branches";
import Review from "../modules/coaching/review";
import News from "../modules/coaching/news";
import RolesNUser from "../modules/coaching/rolesnuser/index";
import Settings from "../modules/coaching/setting";
import MainHome from "../modules/HomeCoaching/home";
import Dashboard from "../modules/dashboardCoaching/dashboard";
import Lead from "../modules/coaching/Leads";
import OnlineAddmission from "../modules/coaching/onlineAddmission/onlineAdmission";




const coachingRoutes = [
   {path: '/coaching/home/', exact: true, strict:true, component:  MainHome},
   {path: '/coaching/dashboard/', exact: true, strict:true, component:  Dashboard},
   {path: '/coaching/profile/', exact: true, strict:true, component:  ProfileInstitute},
   {path:'/coaching/branches/',exact:true,strict:true,component:Branches},
   {path:'/coaching/review/',exact:true,strict:true,component:Review},
   {path:'/coaching/news/' ,exact:true,strict:true,component:News},
   {path:'/coaching/rolesnuser/' ,exact:true,strict:true,component:RolesNUser},
   {path:'/coaching/setting/' ,exact:true,strict:true,component:Settings},
   {path:'/coaching/leads/' ,exact:true,strict:true,component:Lead},
   {path:'/coaching/onlineAdmission/' ,exact:true,strict:true,component:OnlineAddmission}
   ]



export {coachingRoutes };