import React, { useState } from 'react'
import '../../css/superAdmin/SuperAdmincss.css'
import {Link } from 'react-router'
import dwld from '../../assets/home/download_icon.png'


function NavBar(props){
   

    
    console.log(props.selectedTab)
   
    return(
      
      
        <>
        
        <div className="sa-LeadsNav">
           <div className="sa-leadsHead">
           
            <span className= {props.selectedTab=="SCHOOL"?"sa-leadsSchool-active":"sa-leadsSchool"} onClick={()=>props.setSelectedTab("SCHOOL")} >School</span>


           
            <span className= {props.selectedTab=="COACHING"?"sa-leadsSchool-active":"sa-leadsSchool"}  onClick={()=>props.setSelectedTab("COACHING")} >Coachings</span>
           
           
            <span className= {props.selectedTab=="EDUCATOR"?"sa-leadsSchool-active":"sa-leadsSchool"}  onClick={()=>props.setSelectedTab("EDUCATOR")}  >Educator</span>
        
            </div>
        
            {/* <div className="sa-leads">
               <button style={{border:"0",background:"none",cursor:"pointer"}}> <img src={dwld} style={{height:"20px",width:"20px",marginRight:"5px"}}/></button>
               <span >Export List</span>
            </div> */}
        </div>
        
        </>
    )
}
export default NavBar