import React, { useState, useEffect } from "react";
import api from '../../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../../utils";
import Loader from '../../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';


function Category(props) {
  const [category, setCategory] = useState("")
  const [position, setPosition] = useState("")
  const [loading, setLoading] = useState(false)
  const [etitle, seteTitle] = useState("")


  useEffect(() => {
    if (props.selected != 0) {
      seteTitle(props.mdata.name)
      setPosition(props.mdata.position)
    }
    return category
  }, [props.selected])



  const savedetails = () => {
    if(validate()){
    setLoading(true)
    let request = {
      url: `faq/catg/add`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
        'Content-Type': "application/json"
      },
      data: {
        name: category,
        position: position*1,
        i_p_id:props.prof_id
      }
    }
    api.postCustom(request).then(data => {
      setLoading(false)
      toast.success("Category Added successfully")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }}


  const updateValue = () => {
     props.setLoading(true)
    let request = {
        url: `faq/catg/${props.mdata.id}/update`,
        headers: {
            'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          name: etitle,
        position: position*1,
        i_p_id: props.prof_id
        }
    }
    api.putOther(request).then(data => {
         props.setLoading(false)
         toast.success("Category Updated successfully")
         props.setselected(0); props.setForm(false);
         props.fetchData()
    }).catch((err) => {
         props.setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
    })
  }


  const validate = () => {
    if (category === '') {
        toast.error("Please Enter Category")
        return false
    }
    if (position === '') {
        toast.error("Please Enter Position")
        return false
    }
   
    return true
}



  return (<>
    {loading && <Loader />}
    <div class="form-section ">
      <div>
        {/* <p class="heading">Add Category</p> */}
        {props.selected ?
          <p class="heading">Edit Category</p> : <p class="heading">Add Category</p>}
        <p class="line_purple"></p>
        <label>Category <span>*</span></label>

        {props.selected ?
          <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
          <input class="expandale-input w-450" placeholder="Enter" value={category} onChange={(e) => setCategory(e.target.value)}></input>
        }
      </div>
      <div>
        <label>Enter Position <span>*</span></label>

        <input contenteditable="true" class="expandale-input w-450" type="number" placeholder="Enter" value={position} min={0} onChange={(e) => setPosition(e.target.value)} />
        <p class="data-info">Only Numeric values allowed.</p>

      </div>

      < div class="button-section">
        <div></div>
        <div>
          <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
          {
            props.selected ?
              <button class="blue_button" onClick={updateValue}>Update</button> :
              <button class="blue_button" onClick={()=>{
                if(props.prof_id==""){
                  toast.error("Please Add Basic Details First.")
                }else{
                  savedetails()
                }
                }}>Save</button>
          }
        </div>
      </div>

    </div>


  </>)
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(Category)
