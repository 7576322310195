import React,{useState,useEffect} from "react";
import DeletePopup from "../../popups/delete";
import Loader from '../../../../../components/loader'
import api from '../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../utils";
import { ToastContainer, toast } from 'react-toastify';
import PlacementsForms from './placements_form';
import  PlacementsBlock from  './placements_blocks';
import { select } from "@redux-saga/core/effects";

function Placemnets(props){
    let [mdata, setMdata] = useState({})
    const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([]);
  
  
    
    useEffect(() => {
      fetchData();
      props.setselected(0);
    }, [])
  

    const fetchData = () => {
      if(props.prof_id==""){
         toast.error("Please Add Basic Details First.")
         setLoading(false)
         }else{
      setLoading(true)
      let request = {
          url: `placement/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
          headers: {
             'Authorization': `Bearer ${props.loginToken}`,
          },
       }
       api.getCustom(request).then(data => {
          setLoading(false)
          setRdata(data.data.result.response)
          // setRdata([{id:1,name:"Name Amet minim mollit non deseruntullamco",time:"11-9-2021 04:40 PM "},{id:2,name:"Name Amet minim mollit non deseruntullamco",time:"11-9-2021 04:40 PM "}])
       }).catch((err) => {
          setLoading(false)
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
       })}
  }
  
    return(<>
      {loading && <Loader />}
        {
            props.form == false ?
            <PlacementsBlock {...props} setLoading={setLoading} setMdata={setMdata} rdata={rdata}  setRdata={setRdata}/>:
            <PlacementsForms {...props} setLoading={setLoading} mdata={mdata} rdata={rdata} fetchData={fetchData}/>

        }
    </>)

}

const mapStateToProps = (state) => ({loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(Placemnets)