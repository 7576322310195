import React, { useState, useEffect } from 'react'
import Loader from '../../../components/loader'
import Data from './data'
import Operate from './operate'
import { connect } from 'react-redux'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from '../../../utils'


function Department(props) {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `department/all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)
        }).catch((err) => {
            console.log(err,"errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }


    return (
        <>
            {loading && <Loader />}
            <div className="Sa-Profile">
                <Operate fetchData={fetchData} setLoading={setLoading} selected={selected} setSelected={setSelected} 
                data={rdata.find(data => data.entity_id == selected)} 
                />
                <Data data={rdata} setSelected={setSelected} search={search} />
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Department)
