import React, { useState, useEffect } from "react";
import './admission.css'
import close from '../../../assets/img/close.svg'
import CustomiseForm from "./customiseform";
import ApplicationRecieved from "./ApplicationRecieved";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

function OnlineAddmission(props) {
    const [form, setForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rdata, setRdata] = useState([]);
    const [standard, setStandard] = useState([])
    const [course,setCourse] = useState([])
    const [date, setDate] = useState('')
    const [data, setData] = useState('')
    const [fees, setFees] = useState('')
    const [noFees,setNofees] =useState(false)
    const [dataDeadline,setDataDeadline] = useState([])
    const [accepted, setAccepted] = useState(false)

  





    useEffect(() => {
        fetchData();
        getStandard()
        Course()
    }, [])




    const fetchData = () => {
        if (props.prof_id == null) {
            toast.error("Please add Basic Details first ")
        } else {
            setLoading(true)
            let request = {
                url: `website/institute/admission-setting/${props.prof_id}/get?uid=${props.up_id}`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
            }
            api.getCustom(request).then(data => {
                setLoading(false)
                setRdata(data.data.result)
                setAccepted(data.data.result.is_application_accepted)
                setDataDeadline(data.data.result.deadline_dto)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const getStandard = () => {
        setLoading(true)
        let request = {
            url: `master-stand/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setStandard(data.data.result)
        }).catch((err) => {
            setLoading(false)

        })
    }

    const Course = () => {
        setLoading(true)
        let request = {
          url: `course/all`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.getCustom(request).then(data => {
          setLoading(false)
          setCourse(data.data.result)
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          };
        })
      }

    const updateSettings = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/${rdata.admission_setting_id}/application-accept/${!accepted}/update?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success(data.data.result)
            fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const AddDeadline = (date) => {
        setLoading(true)
            
        let data2 = props.p_type == 1 ? {
            course_id: data,
            last_date: date
        } : {
            standard_id: data,
            last_date: data
        }
        let request = {
            url: `website/institute/admission-setting/application-deadline/${props.prof_id}/add?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
            data:data2
        }
        api.postAuth(request).then(data => {
            setLoading(false)
            toast.success(data.data.result)
            fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const AddFees = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/${rdata.admission_setting_id}/application-form-fee/add?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
            data: {
                "form_fee": fees,
                "is_form_free": noFees
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success(data.data.result)
            fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    const deleteTag = (id, ) => {
        setLoading(true)
        let request = {
          url: `website/institute/admission-setting/application-deadline/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.delete(request).then(data => {
          setLoading(false)
          toast.success("Deleted successfully")
          fetchData()
        }).catch((err) => {
           setLoading(false)
            if(err.response.status == 401){
            props.dispatch({ type: 'LOGOUT' })
          }else{
            toast.error(errorMsg(err))
          } 
    
        })
      }

    return (
        <>
            {loading && <Loader />}
            {
                form == false ?
                    <div style={{ marginLeft: '20px' }}><section class="middle-top bulk-header">
                        <h1 className="Heading-v">Admission Applications </h1>
                    </section>

                        <div class="p-16">
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link active show" data-toggle="tab" href="#one">Setup</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#two">Application Received</a>
                                </li>
                            </ul>


                            <div class="tab-content">
                                <div id="one" class="tab-pane active">
                                    <div>
                                        <div>
                                            <label class="bold_settings">Accept Applications </label>
                                            <p class="black_settings-v">This will allow students to send applications to your institute.
                                                When you don't want to accept the applications you can turn of this option
                                            </p>
                                        </div>
                                        <div>
                                            <label class="switch" title="If razorpay integration is not done then this will not be activated. It will give message as Please Complete the razorpay integration first in order to Start Accepting applications">
                                                <input type="checkbox" checked={accepted} onChange={() => updateSettings()} /><span class="slider round"></span>

                                            </label>


                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label class="bold_settings">Application Deadlines
                                            </label>
                                            <p class="black_settings-v">Please select course application deadline date. Students will not able to submit
                                                application after deadline
                                            </p>
                                            <div class="tags">
                                            {dataDeadline?.map((item, key) => {
                                                return (
                                                    
                                                 props.p_type==1? <span>{item.course_name}-{item.last_date}<img src={close} onClick={()=>deleteTag(item.deadline_id)}/></span>:
                                                 <span>{item.standard_name}-{item.last_date}<img src={close} onClick={()=>deleteTag(item.deadline_id)}/></span>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div>
                                            <select class="admission-input-v" onChange={(e) => setData(e.target.value)}>
                                                <option>Select Course Standard</option>
                                                {
                                                    props.p_type==1?
                                                    course.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) }):
                                                    standard.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })
                                                }
                                              
                                            </select>
                                            <input type="date" class="admission-input-v ml-16-v" onChange={(e) => AddDeadline(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label class="bold_settings">Application Form Fee </label>
                                            <p class="black_settings-v">Set the application form fee which Students need to pay while applying
                                                Please complete RazorPay Integration first in order to receive Application form fee
                                            </p>
                                            <a class="admission-link">Please check this video tutorial for Payment Gateway Setup</a>
                                        </div>
                                        <div class="block">
                                            {
                                                noFees ==false && 
                                                <input type="number" class="admission-input-v" placeholder="Application Form Fee " onChange={(e) => setFees(e.target.value)} />
                                            }
                                           
                                            <div>
                                                <label class="checkbox-v"><span class="checkbox__input"><input type="checkbox" name="checked" checked={noFees}  onClick={()=>setNofees(!noFees)}/><span
                                                    class="checkbox__control"><svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                                        <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                                                    </svg></span></span></label>
                                                <p class="black_settings-v">No Fees</p>
                                            </div>
                                            <div> <button class="blue_button-v ml-16-v" onClick={()=>AddFees()}>Save</button></div>
                                           
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label class="bold_settings">Application Deadlines
                                            </label>
                                            <p class="black_settings-v">Please select course application deadline date. Students will not able to submit
                                                application after deadline
                                            </p>
                                        </div>
                                        <div>
                                            <button class="blue-button-v" onClick={() => setForm(true)}>Customise Form</button>
                                        </div>
                                    </div>
                                </div>
                                <ApplicationRecieved />
                            </div>
                        </div>
                    </div>
                    : <CustomiseForm setForm={setForm} settingid={rdata.admission_setting_id}  formDataSet={rdata.is_admission_procedure_set}/>
            }
            <ToastContainer/>
        </>




    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id,p_type: state.auth.p_type })

export default connect(mapStateToProps)(OnlineAddmission)
