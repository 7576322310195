import React, { useState } from "react";
import cross_icon from '../../../assets/home/cross_icon.svg'
import searchh from '../../../assets/home/search_icon.png'

function LeadsData(props) {
    const [check, setCheck] = useState("")


    return (<>
        <div>
            <div className="sa-popupEnquiry">
                <div className="sa-popup">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ padding: "0", marginLeft: "15px" }}>
                            <div className="sa-searchhh">
                                <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                                <input className="Super-admin-Search_Input" type="text"
                                    placeholder="Search" style={{ outline: "0" }} />
                            </div>
                        </div>
                        </div>
                        <div>
                            <img src={cross_icon} onClick={() => props.setClicked(false)} style={{ cursor: "pointer" }} />
                        </div>

                    </div>
                    <div className="sa-tableLeads">
                        <table id="super-admin">
                            <tr>
                                <th>
                                    <input type="checkbox" style={{ marginRight: "10px" }}
                                        checked={check && "on"}
                                        onClick={() => setCheck(!check)}
                                    />

                                    Select All</th>
                                <th>SR No</th>
                                <th>Name</th>
                                <th>Phone No</th>
                                <th>Location</th>
                            </tr>
                            <tr>

                                <td>
                                    {check == true ?
                                        <input type="checkbox" checked="on" />
                                        : <input type="checkbox" />
                                    }</td>
                                <td>105</td>
                                <td >Raj</td>
                                <td >123654789</td>
                                <td >Delhi</td>

                                {/* <td><button onClick={() => props.setSelected(data.id)}>EDIT</button></td> */}
                            </tr>




                        </table>

                    </div>
                </div>

            </div>
        </div>
    </>)
}

export default LeadsData