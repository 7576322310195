import React, { useState, useEffect, useRef } from "react";
import unexpad from '../../../../assets/img/un-expand.svg'
import expand from '../../../../assets/img/expand.svg'
import circular from '../../../../assets/img/circular.png'
import close from '../../../../assets/img/close.svg'
import { connect } from "react-redux";
import api from '../../../../api/api'
import { errorMsg } from "../../../../utils";
import Moment from 'moment'
import Loader from '../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';




export function Achievement(props) {
    const [image, setimage] = useState([])
    const [acheivemnet, setAcheivement] = useState([{ title: "", yr: "", desc: "", awarded_by: "", file: [], id: "", open: true }])
    const [loading, setLoading] = useState(false)
    const [achiev, setAchieve] = useState(false)
    const [initialTime, setInitialTime] = useState(true)


    useEffect(() => {
        if (props.dataId != "") {
            getAchievemnt()
        }
    }, [props.dataId])

    let directorRef = useRef(null);
    useEffect(() => {
        if (initialTime == false) {
            directorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }, [acheivemnet])


    const getAchievemnt = () => {
        props.setLoading(true)
        let request = {
            url: `educator/achv/all?educator_id=${props.dataId}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setAcheivement(data.data.result.response.map((data) => ({ ...data, file: data?.files ?? [], open: false })))
            if (data.data.result.response.length > 0) {
                setAchieve(true)
            }
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const validate = (data) => {
        if (data.title == "") {
            toast.error("Please Enter Achievement Title.")
            return false
        } if (data.yr == "") {
            toast.error("Please select year.")
            return false
        }
        return true
    }


    const savedetails = (index) => {
        let data = { ...acheivemnet[index] };
        let id = data.id;
        let file = data.file
        delete data.file
        delete data.id;
        delete data.open;
        if (validate(data)) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify(data));
            for (let index = 0; index < file.length; index++) {
                fd.append('file', file[index]);
            }

            let request = {
                url: `educator/${props.dataId}/achv/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            if (id == "") {
                api.postCustom(request).then(data => {
                    props.setLoading(false)
                    toast.success("Achievement Added successfully.")
                    setInitialTime(true)
                    setAcheivement(acheivemnet.map((d, i) => i == index ? { ...d, open: false, id: data.data.result } : d))
                }).catch((err) => {
                    props.setLoading(false)
                    if (err.response.status == 401) {
                        props.dispatch({ type: 'LOGOUT' })
                    } else {
                        toast.error(errorMsg(err))
                    }
                })

            } else {
                request.url = `educator/achv/${id}/update`
                api.putOther(request).then(data => {
                    props.setLoading(false)
                    toast.success("Achievement updated successfully.")
                    setInitialTime(true)
                    setAcheivement(acheivemnet.map((d, i) => i == index ? { ...d, open: false } : d))
                }).catch((err) => {
                    props.setLoading(false)
                    if (err.response.status == 401) {
                        props.dispatch({ type: 'LOGOUT' })
                    } else {
                        toast.error(errorMsg(err))
                    }
                })
            }
        }

    }

    const openBox = (index) => {
        setAcheivement(acheivemnet.map((d, i) => i == index ? { ...d, open: true } : d))
    }

    const deleteAch = (index) => {
        props.setLoading(true)
        let data = { ...acheivemnet[index] };
        let id = data.id;
        if (id == "") {
            setAcheivement(acheivemnet.filter((data3, index3) => index3 != index))
            props.setLoading(false)
        }
        else {
            let request = {
                url: `educator/achv/${id}/delete`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.delete(request).then(data => {
                props.setLoading(false)
                toast.success("Quallification Deleted successfully")
                setAcheivement(acheivemnet.filter((data3, index3) => index3 != index))
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const validateImage = (e) => {
        let img = e.target.files[0].size / 1024
        if (img > 500) {
            toast.error("Please upload image of size 500kb only")
            return false
        }
        else return true
    }


    const select_MediaContent = (e, index) => {
        if (validateImage(e)) {
            let data = { ...acheivemnet[index] }
            if (e.target.files.length > 0) {
                let imgs = acheivemnet[index]?.file ?? [];
                for (const [key, value] of Object.entries(e.target.files)) {
                    imgs.push(value)
                }
                setimage(imgs)
                setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, file: imgs } : data2))
            }
        }

    }


    return (
        <>
            {/* {loading && <Loader/>} */}
            <div style={{ background: "#00ffd00d" }}>
                <div class="flex-a pointer">
                    <img src={expand} class="mr16" onClick={() => { 
                        if( props.dataId == ""){
                            toast.error("Please Add Educator")
                        }else{
                            setInitialTime(false);
                             setAchieve(true); 
                            setAcheivement([...acheivemnet, { title: "", yr: "", desc: "", awarded_by: "", file: [], id: "", open: true }]) }}
                        }
                       /><label class="mb-0">Achievements </label>
                </div>
            </div>

            {achiev == true && acheivemnet.map((data, index) => {
                return (<> {data.open ? <div ref={directorRef}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img src={unexpad} style={{ cursor: "pointer" }} class="mr16" onClick={() => { setInitialTime(true); deleteAch(index) }} />
                        
                    </div>
                    <div class="mt-32">
                        <label>Enter Achievement Title<span>*</span></label>

                        <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.title} onChange={(e) => setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, title: e.target.value } : data2))} />
                    </div>

                    <div class="mt-16 flex mob-v">
                        <div class="mr-70">
                            <label>Year<span>*</span></label>
                            <input contenteditable="true" class="expandale-input w-300" onKeyDown={(e) => e.preventDefault()} type="date" value={data.yr} onChange={(e) => setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, yr: e.target.value } : data2))} />
                        </div>
                        <div class="mob-top">
                            <label>Awarded By </label>

                            <input contenteditable="true" class="expandale-input w-300" placeholder="Enter" value={data.awarded_by} onChange={(e) => setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, awarded_by: e.target.value } : data2))} />
                        </div>
                    </div>


                    <div class="mt-16">
                        <label>Add Photo</label>
                        <p class="data-info">Submit your achievement related picture such as trophy, certificate etc .</p>
                        <div class="flex-p1">
                            <input class="expandale-input w-450 choose" type="file" id="myFile" multiple disabled={image.length === 5} accept="image/*" placeholder="Choose File" onChange={(e) => select_MediaContent(e, index)} /><a href="#"></a>
                        </div>
                        <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
                        <div class="tags-img">
                            {image.map((item,key) => {
                                 
                                return (
                                    <div key={key}>
                                        <img src={URL.createObjectURL(item)} />
                                        <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>
                    <div class="mt-16">
                        <label>Achievement Details</label>

                        <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.desc} onChange={(e) => setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, desc: e.target.value } : data2))} />
                    </div>
                    <div class="display mob-top">
                        <div></div>
                        <div>
                            <button class="white_button mr16" onClick={() => { data.id == "" ? setAcheivement(acheivemnet.filter((data3, index3) => index3 != index)) : setAcheivement(acheivemnet.map((data2, index2) => index == index2 ? { ...data2, open: false } : data2)); setInitialTime(true) }}>Cancel</button>
                            <button class="blue_button" onClick={() => { props.dataId == "" ? toast.error("Please Add Educator") : savedetails(index) }}>Save</button>
                        </div>
                    </div>
                </div> : <div class="view-detail-short">
                    <div class="display align-s">
                        <div class="flex">
                            <img src={circular} />
                            <div>
                                <p>
                                    {data.title}
                                </p>
                                <p>
                                    {data.desc}
                                </p>
                                <p>
                                    {Moment(data.yr).format('DD-MM-YYYY')}
                                
                                </p>
                            </div>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '12%' }}>
                            <button class="editbutton" onClick={() => { openBox(index) }}> Edit</button>
                            <button class="deletebutton" onClick={() => { deleteAch(index) }}> Delete</button>
                        </div>


                    </div>
                    <p></p>
                </div>}</>)
            })}




        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Achievement)