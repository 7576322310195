import React, { useState, useEffect } from 'react';
import api from '../../api/api'
import { connect } from "react-redux";
import Loader from '../../components/loader'
import { errorMsg } from '../../utils'
import { ToastContainer, toast } from 'react-toastify';
import DataInsideReport from './dataInsideReport';

function ReportsInside(props) {
    const [dataReport, setDataReport] = useState([])
    const [initial, setInitial] = useState('report')
    const [loading, setLoading] = useState(false)


    const Url = (type, id) => {
        if (type == " > State Wise") {
            return `report/institute/state-wise/${id}?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > City Wise") {
            return `report/institute/city-wise/${id}?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Category/course Wise profiles") {
            return `report/institute/by-mst-course/${id}?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        }if (type == " > Education Board") {
            return `report/institute/edu-board-wise/${id}?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        }
    }


    const Urls = (type, id) => {
        if (type == " > State Wise") {
            return `report/educator/state-wise/${id}?uid=${props.up_id}`
        } if (type == " > City Wise") {
            return `report/educator/city-wise/${id}?uid=${props.up_id}`
        }
    }

    

    const getTableData = (type, id) => {
        setLoading(true)
        let request = {
            url: props.Show=="edu"?Urls(type, id):Url(type, id),
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(res => {
            setLoading(false)
            setDataReport(Array.isArray(res.data.result) ? res.data.result : res.data.result.response)
            setInitial("Data")
            // setKycData(res.data.result.response ?? [])
        }).catch((err) => {
            console.log(err, "errorrr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }


    return (<>

        {
            initial == 'report' ?
                <div className="sa_reports_cards" style={{ height: "auto" }}>

                    <div className="sa_reports_cards_inner" style={{ height: "auto", flexWrap: 'wrap', justifyContent: "space-evenly" }}>

                        {props.data.map((item, key) => {
                            return (
                                <div className="reports_cards" style={{ marginBottom: "20px" }} key={key} onClick={() => { getTableData(props.tag,item.id) }}>
                                    <center>
                                        {item.name}
                                        <p>
                                            {item.occurence}
                                        </p>
                                    </center>
                                </div>
                            )
                        })
                        }
                    </div>

                </div> :
                <DataInsideReport dataReport={dataReport} />
        }
    </>


    )
}


const mapStateToProps = state => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })
export default connect(mapStateToProps)(ReportsInside)