import React from "react"
import "./sa_dashboard.css"
import { Bar } from 'react-chartjs-2';
import mapp from '../../assets/home/map_img.png'
import dropdown_icon from '../../assets/home/dropdown_icon.png'
import 'react-dropdown/style.css';
import { ToastContainer, toast } from "react-toastify";
import BackDropTransparent from '../../components/backdrop_transparent/backdrop_transparent'
import api from '../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../utils'
import Loader from '../../components/loader'


class Dash extends React.Component {
    constructor(props) {
        super(props)
        this.state =
        {
            loader: false,
            month_picker_clicked: false,
            date_picker_on: false,
            order1_data: {},
            order2_data: [],
            order3_data: [],
            maps_data: [],
            maps_data_temp: [],
            maps_data_total_sum: "",
            graph_data: [],
            graph_data_value: [],
            graph_profile_type: "",
            graph_selected_year: "2022",
            graph_data_length: 0,
            city: ["Pune", "Mumbai", "Delhi", "Nagpur", "Bangluru"],
            date_picker_month_name_and_month_digit_combined: ""
            , date_picker_month_and_year_combined: (Number(new Date().getMonth()) + 1) + "/" + Number(new Date().getFullYear()),
            month_year: (Number(new Date().getMonth()) + 1) + "/" + Number(new Date().getFullYear()),
            month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            graph_data1: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [{
                    label: 'Value',
                    data: [],

                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }],
            },
        }
    }

    TotalInstutuesCoachingsApi1() {
        this.setState({ loader: true })
        let request = {
            url: `dashboard/all-counts?uid=${this.props.up_id}`,
            headers: {
                'Authorization': `Bearer ${this.props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            this.setState({ loader: false })
            // setRdata(data.data.result)
            this.setState({ order1_data: data.data.result })

        }).catch((err) => {
            this.setState({ loader: false })
            if (err.response.status == 401) {
                this.props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })

    }



    MapDataApi3() {
        if (this.state.date_picker_month_name_and_month_digit_combined == "") {
            this.setState({
                date_picker_month_name_and_month_digit_combined:
                    (this.state.month_names_short[(Number(new Date().getMonth())).toString()] + ((Number(new Date().getMonth()) + 1)).toString())
            })
        }
        var data =
        {
            maps_data: [12, 20, 80, 30, 200],

        }

        var new_maps_data = data.maps_data.map
            (el =>

                Math.floor(
                    (el * Number(this.state.date_picker_month_and_year_combined.split("/")[0])) +
                    (1.006 ** Number(this.state.date_picker_month_and_year_combined.split("/")[1])
                        - (1.259 ** (Number(new Date().getFullYear()) - Number(this.state.date_picker_month_and_year_combined.split("/")[1])))
                    )
                )
            )
        this.setState({ maps_data: new_maps_data })
        var new_maps_data_sum = new_maps_data.reduce(toAdd, 0)

        function toAdd(sum_value_after_each_iteration, single_value_to_add_after_each_iteration) {
            sum_value_after_each_iteration = sum_value_after_each_iteration + single_value_to_add_after_each_iteration
            return sum_value_after_each_iteration
        }
        this.setState({ maps_data_total_sum: new_maps_data_sum })
    }



    async GraphDataApi2() {
        this.setState({ loader: true })
        let request = {
            url: `dashboard/${this.props.up_id}/${this.state.graph_profile_type == "Educator" ? "edu-performance" : "inst-performance"}/${this.state.graph_selected_year}`,
            headers: {
                'Authorization': `Bearer ${this.props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            this.setState({ loader: false })
            // setRdata(data.data.result)
            this.setState({ graph_data: Object.keys(data.data.result) })
            this.setState({ graph_data_value: Object.values(data.data.result) })

            var temp = { ...this.state.graph_data1 }
            if (data && data.data && data.data.result) {
                temp.labels = Object.keys(data.data.result)
                temp.data = Object.values(data.data.result)
            }
            this.setState({ graph_data1: temp })
        }).catch((err) => {
            this.setState({ loader: false })
            if (err.response.status == 401) {
                this.props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })


    }






    componentDidMount() {
        this.TotalInstutuesCoachingsApi1()
        this.MapDataApi3()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.date_picker_month_and_year_combined != this.state.date_picker_month_and_year_combined) {
            this.MapDataApi3()
        }
        if ((prevState.graph_selected_year != this.state.graph_selected_year)) {
            this.GraphDataApi2()
        }

        if ((prevState.graph_profile_type != this.state.graph_profile_type)) {
            this.GraphDataApi2()
        }
    }



    YearDropDown() {
        var present_year = new Date().getFullYear()
        var end_year = 1970
        var year_range_descending_array = []
        for (var r = present_year; r >= end_year; r--) {
            year_range_descending_array.push(r)
        }
        return year_range_descending_array
    }
    HandleMapDatePickerBackDrop = () => {
        this.setState({ date_picker_on: false })
    }
    render() {

        return (
            <>
                {this.state.date_picker_on && <BackDropTransparent FromChildBackDrop={this.HandleMapDatePickerBackDrop} />}
                <ToastContainer />
                {
                    this.state.loader &&
                    <Loader />
                }
                <div className="entire_dash_container">
                    <div className="sa_dash_inner_container">
                        <div className="labels_tree_distribution_section">
                            <div className="labels_left_section">
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Total Count
                                            <p>
                                                {this.state.order1_data?.institutes ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="labels_left_section_div_middle">

                                    <div>
                                        <center>
                                            School
                                            <p>
                                                {this.state.order1_data?.schools ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                    <div>
                                        <center>
                                            Coaching
                                            <p>
                                                {this.state.order1_data?.coachings ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Enquries
                                            <p>
                                                {this.state.order1_data?.enquiries ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                            <div className="labels_middle_section">
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Educators
                                            <p>
                                                {this.state.order1_data?.educators ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Leads Generated
                                            <p>
                                                {this.state.order1_data?.leads ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Advertisements
                                            <p>
                                                {this.state.order1_data?.advertisement ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                            <div className="labels_right_section">
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Students
                                            <p>
                                                {this.state.order1_data?.students ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="labels_div">
                                </div>
                                <div className="labels_div">
                                    <div>
                                        <center>
                                            Reviews/Ratings
                                            <p>
                                                {this.state.order1_data?.reviews ?? "-"}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="dash_graph_and_map_section">
                            <div className="sa_dash_graph_section">
                                <div className="dash_graph_margin">
                                </div>
                                <div className="graph_filters">
                                    <div className="dashboard_graph_heading">
                                        Check Instituts/Educators Registrations
                                    </div>
                                    <div className="dash_graph_two_filters">
                                        <div style={{ cursor: "pointer", border: "1px solid black", borderRadius: "4px", padding: "1px", display: "flex", }}>
                                            <select
                                                onChange={(el) => {
                                                    this.setState({ graph_profile_type: el.target.value })
                                                }
                                                }
                                                style={{
                                                    border: "0", minWidth: "80px", cursor: "pointer", outline: "0",
                                                    padding: "1px",
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                }}>
                                                <option value="" style={{ color: "gray" }}>Select Profile Type</option>
                                                <option value='Educator'>Educator</option>
                                                <option value='Institute'>Institute</option>
                                                <option value='Student'>Student</option>
                                            </select>
                                            <font style={{ paddingRight: "10px", fontWeight: "bold" }}><img src={dropdown_icon} height="18px" /></font>
                                        </div>
                                        {this.state.graph_profile_type &&
                                            <div style={{ cursor: "pointer", border: "1px solid black", borderRadius: "4px", padding: "1px", display: "flex", }}>
                                                {
                                                    this.state.graph_profile_type &&
                                                    <select
                                                        onChange={(el) => { this.setState({ graph_selected_year: el.target.value }) }}
                                                        style={{
                                                            border: "0", minWidth: "80px", cursor: "pointer",
                                                            outline: "0",
                                                            padding: "2px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                        }}>
                                                        {
                                                            this.state.graph_profile_type &&
                                                            <option value="" style={{ color: "gray" }}>Select Year</option>
                                                        }
                                                        {
                                                            this.state.graph_profile_type &&
                                                            (this.YearDropDown().map((el) =>
                                                                <option>{el}</option>
                                                            ))
                                                        }
                                                    </select>
                                                }
                                                {this.state.graph_profile_type &&
                                                    <font style={{ paddingRight: "10px", fontWeight: "bold" }}><img src={dropdown_icon} height="18px" /></font>}
                                            </div>}
                                    </div>
                                </div>
                                <div className="dash_graph" >
                                    <div style={{
                                        padding: "20px", display: "flex", alignItems: "center", height: "99%",
                                        width: "100%",
                                        minWidth: "900px",
                                    }}>



                                        <Bar data={this.state.graph_data1} height="90%" />
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="sa_dash_map" >
                                <div className="dash_map_inner">
                                    <div className="map_div_left_section">
                                        Track Students Form Their Cities
                                        <p style={{ paddingTop: "15px" }}>
                                            <img src={mapp} height="180px" style={{ borderRadius: "10px" }} />
                                        </p>
                                    </div>
                                    <div className="map_div_right_section">
                                        <div className="maps_dropdown" style={this.state.date_picker_on ? { width: "75%" } : { width: "100%" }}>
                                            <div style={
                                                !this.state.date_picker_on
                                                    ?
                                                    {
                                                        cursor: "pointer", border: "1px solid black",
                                                        borderRadius: "4px", padding: "1px", display: "flex", position: "relative"
                                                    }
                                                    :
                                                    {
                                                        borderRadius: "4px", padding: "1px", display: "flex", position: "relative"
                                                    }
                                            }>
                                                {
                                                    <div style={{
                                                        border: "0", minWidth: "80px", right: "0",
                                                        cursor: "text",
                                                        outline: "0",
                                                        padding: "5px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                    }}
                                                    >
                                                        <input
                                                            style={this.state.month_picker_clicked ? { display: "none" } : { outline: 0, border: 0, width: "80px" }}
                                                            value={
                                                                this.state.date_picker_month_name_and_month_digit_combined.substr(0, 3)
                                                                + "/" + this.state.date_picker_month_and_year_combined.split("/")[1]
                                                            }
                                                            onClick={() => { this.setState({ month_picker_clicked: true }) }}
                                                        />
                                                        <input
                                                            style={!this.state.month_picker_clicked ? { display: "none" } : { outline: 0, border: 0 }}
                                                            type="month"
                                                            onChange={(c) => { this.setState({ month_year: c.target.value }) }}
                                                        />
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="maps_information_data_section">
                                            <div>
                                                <center>
                                                    Total Students
                                                    <p>

                                                        {this.state.maps_data_total_sum}

                                                    </p>
                                                </center>
                                            </div>
                                            <div className="maps_city_wise_data">
                                                <div className="individual_city_data">
                                                    <center>
                                                        {this.state.city[0]}
                                                        <p>

                                                            {this.state.maps_data[0]}

                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="individual_city_data">
                                                    <center>
                                                        {this.state.city[1]}
                                                        <p>

                                                            {this.state.maps_data[1]}

                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="individual_city_data">
                                                    <center>
                                                        {this.state.city[2]}
                                                        <p>

                                                            {this.state.maps_data[2]}

                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="individual_city_data">
                                                    <center>
                                                        {this.state.city[3]}
                                                        <p>

                                                            {this.state.maps_data[3]}

                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="individual_city_data">
                                                    <center>
                                                        {this.state.city[4]}
                                                        <p>

                                                            {this.state.maps_data[4]}

                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })
export default connect(mapStateToProps)(Dash)
