import React, { useState, useEffect } from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { connect } from "react-redux";
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';




function ExperienceForm(props) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [designation, setDesignation] = useState("")
  const [degree, setDegree] = useState("")
  const [eduType, setEduType] = useState("")
  const [from, setForm] = useState("")
  const [to, setTo] = useState("")
  const [etitle, seteTitle] = useState("")
  const [value, setValue] = useState('');
  const [isg, setIsg] = useState(false)



  useEffect(() => {
    if (props.selected != 0) {
      console.log(props.data,"data")
      seteTitle(props.data.inst_name)
      setName(props.data.inst_name)
      setDegree(props.data.d_name)
      setEduType(props.data.e_type)
      setTo(props.data.t_date)
      setForm(props.data.f_date)
      setValue(props.data.desc)
      setDesignation(props.data.designation)
    }
    return name
  }, [props.selected])

  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      let request = {
        url: `educator/${props.eduId}/exp/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          inst_name: name,
          designation: designation,
          e_type: eduType,
          f_date: from,
          t_date: to,
          desc: value,
          is_current: isg,

        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Experience Added successfully");
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const updateValue = () => {
    if (validate()) {
      setLoading(true)
      let request = {
        url: `educator/exp/${props.data.id}/update`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          inst_name: etitle,
          designation: designation,
          e_type: eduType,
          f_date: from,
          t_date: to,
          desc: value,
          is_current: true,
        }
      }
      api.putOther(request).then(data => {
        setLoading(false)
        toast.success("Experience Updated successfully.")
        props.setSelected(0);  props.setshowform(false);;
        props.fetchData()
      }).catch((err) => {
        console.log(err,"err")
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }


  const validate = () => {
        if (name === '') {
            toast.error("Please Enter School/Institute Name")
            return false
        }
        if (designation === '') {
            toast.error("Please Enter designation")
            return false
        }if(from===""){
          toast.error("Please select From Date")
          return false
        }
       
        return true
    }

  return (<>
    {loading && <Loader />}
    <div class="form-section ">
      <div>
        {props.selected ? <p class="heading">Edit Experience</p> : <p class="heading">Add Experience</p>}
        <p class="line_purple"></p>
        <div>
          <label>Enter School/Institute Name <span>*</span></label>
          {props.selected ?
            <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
            <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
          }
        </div>
      </div>
      <div class="flex mob-v mt-16">
        <div class="mr-70">
          <label>Designation Name <span>*</span></label>

          <input contenteditable="true" class="expandale-input w-300" placeholder="Enter" value={designation} onChange={(e) => setDesignation(e.target.value)} />
        </div>
        <div class="mob-top">
          <label>Employment Type</label>

          <div class="select-wrapper ">
            <select class="expandale-input" value={eduType} onChange={(e) => setEduType(e.target.value)}>
              <option>Select</option>
              <option>Full Time</option>
              <option>Part Time</option>
              <option>Visiting</option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex-a mt-16 mob-res">
        <div class="mr-50">
          <label>From Date <span>*</span></label>
          <input type="date" class="expandale-input" value={from} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setForm(e.target.value)} />
        </div>
        { 
          isg != true &&
          <div class="mob-top">
          <label>To Date </label>
          <input type="date" class="expandale-input" value={to} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setTo(e.target.value)} />
        </div>
        } 
        <div class="flex-inline mt-36">
          <label class="checkbox">
            <span class="checkbox__input">
              <input type="checkbox" name="checked" checked={isg} onChange={() => setIsg(!isg)} />
              <span class="checkbox__control">
                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                  <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                </svg>
              </span>
            </span>
          </label>
          <span>Currently Working</span>
        </div>

      </div>
      <div>
        <label>Tell us more about your experience</label>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>
      <div class="button-section">
        <div></div>
        <div>
          <button class="white_button mr16" onClick={() => { props.setSelected(0); props.setshowform(false); }} >Cancel</button>
          {
            props.selected ?
              <button class="blue_button" onClick={updateValue}>Update</button> :
              <button class="blue_button" onClick={()=>
                {
                  if(props.eduId==""){
                    toast.error("Please add Basic Details first.")
                  }else{
                    savedetails()
                  }
                }
                }>Save</button>
          }
        </div>
      </div>
    </div>
    <ToastContainer />
  </>)
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(ExperienceForm)