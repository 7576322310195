import React, {useState, useEffect}from 'react'
import arrow from '../../../assets/login/signin/arrow.png'
import india from '../../../assets/login/signin/india.png'
import loader2 from '../../../assets/login/signin/loader/loader2.gif'
import  api from '../../../api/api'
import style from '../../../css/style.css'
import website from '../../../css/website.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Signin(props) {
    let [loading, setLoading] = useState(false)
    let [number, setNumber] = useState("");
    useEffect(() => {
        console.log(localStorage.getItem('login'));
        if(localStorage.getItem('newuserdata')){
            props.setSelected('SigninNext')
        } else if(localStorage.getItem('login')){
            props.setSelected('Otp')
        }
    }, [])
    const onSubmit = () => {
        if(number.length != 10){
            toast.error("Please provide valid number.");
        } else {
            setLoading(true);
            api.post({url: 'user/login', data: { "phone": number,  "user_type": 4 }}).then((data) => {
                let adata = data.data.result;
                adata.number = number
                adata.user_type = 4
                localStorage.setItem("login", JSON.stringify(adata))
                props.setSelected('Otp')
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                toast.error("Some error occured while logging in.");
            })
        }
    }
    return (
        <div className="signin">
            <h2>Sign In</h2>
            <h4>to your account</h4>
            <p className="loginp">Take the first step towards brighter Career </p>
            <div className="flex-row">
                <img className="country" src={india} />
                <select className="ccode"><option>+91</option></select>
                <input type="number" value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Enter your phone number" />
            </div>
            {
                loading ? 
                <div className="loginProgress"><img src={loader2} /></div>
                :
                <button onClick={onSubmit} className="flex-row">Continue <img src={arrow} /></button>
            }
            
            
            <h4 className="inst"><a onClick={() => props.setSelected('Signin')}>Are you an Institute ? <span>Click Here</span></a></h4>
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}
