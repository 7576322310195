import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { errorMsg } from "../../../utils";
import Loader from '../../../components/loader'
import api from '../../../api/api'


function ProfileHeader(props) {
  let type = window.location.href.split("?")[1].split("=")[1]
  const history = useHistory()

  const [checkStan, setCheckstan] = useState("")
  const [checkEdu, setCheckEdu] = useState("")


  useEffect(() => {
    if (props.prof_id != "") {
      CheckStandard(props.count)
    }
  }, [props.prof_id])

  const CheckStandard = (num) => {
    return new Promise((resolve, reject) => {
      let request = {
        url: `inst/${props.prof_id}/check-std-crs-edu-present`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        localStorage.setItem("stnadard", data.data.result.is_std_crs_present);
        localStorage.setItem("educator", data.data.result.is_edu_present);
        resolve(true)
        // if (num == 3 && data.data.result.is_std_crs_present) {
        //   resolve(true);
        // } else if (num == 3 && data.data.result.is_std_crs_present == false) {
        //   toast.error(type == 'school' ? "Please Add Standard" : "Please Add Course")
        //   resolve(false)
        // } else if ((num == 4 || num == 5 || num == 6) && data.data.result.is_std_crs_present && data.data.result.is_edu_present) {
        //   resolve(true);
        // } else if ((num == 4 || num == 5 || num == 6) && data.data.result.is_std_crs_present == false && data.data.result.is_edu_present) {
        //   toast.error(type == 'school' ? "Please Add Standard" : "Please Add Course")
        //   resolve(false)
        // } else if ((num == 4 || num == 5 || num == 6) && data.data.result.is_std_crs_present && data.data.result.is_edu_present == false) {
        //   toast.error("Please Add Educator")
        //   resolve(false)
        // } else if ((num == 4 || num == 5 || num == 6) && data.data.result.is_std_crs_present == false && data.data.result.is_edu_present == false) {
        //   toast.error("Please Add Standard and Educator")
        //   resolve(false)
        // } else {
        //   resolve(true)
        // }
      }).catch((err) => {
        
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          // toast.error(errorMsg(err))
        }
        resolve(false)
      })
    })


  }



  return (
    <>
      <div style={{ margin: "15px 0px 0px 25px" }}>
        <div class="display progress_bar-outter">
          <div class="scroll-solve">
            <div class="progress_bar">
              <div className={props.count >= 1 ? "selected" : "bullet"} onClick={() => { props.setCount(1); props.setButton(""); props.setEcount(true) }}>
                <span style={{ cursor: "pointer" }}>Basic Details</span>
                <div>
                  <button>

                  </button>
                  <div></div>
                </div>
              </div>
              {type == 'school' ?
                <div className={props.count >= 2 ? "selected" : "bullet"} onClick={() => { props.setCount(2); props.setButton(""); props.setEcount(true) }}>
                  <span style={{ cursor: "pointer" }}>Standard</span>
                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                </div>
                : <div className={props.count >= 2 ? "selected" : "bullet"} onClick={() => { { props.setCount(2); props.setButton(""); props.setEcount(true) } }}>
                  <span style={{ cursor: "pointer" }}>Courses</span>
                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                </div>
              }
              <div className={props.count >= 3 ? "selected" : "bullet"}

                onClick={async () => {
                  if (props.prof_id != "") {
                    let sts = await CheckStandard(3)
                    if (sts) {
                      props.setCount(3); props.setButton(""); props.setEcount(true); props.setEdit([])
                    }
                  } else {
                    props.setCount(3); props.setButton(""); props.setEcount(true); props.setEdit([])
                  }

                }}

              >
                <span style={{ cursor: "pointer" }}>Educators</span>
                <div>
                  <button>

                  </button>
                  <div></div>
                </div>
              </div>
              <div className={props.count >= 4 ? "selected" : "bullet"}
                onClick={async () => {

                  if (props.prof_id != "") {
                    let sts = await CheckStandard(3)
                    if (sts) {
                      props.setCount(4); props.setButton(""); props.setEcount(true); props.setEdit([])
                    }
                  } else {
                    props.setCount(4); props.setButton(""); props.setEcount(true); props.setEdit([])
                  }
                }}
              >
                <span style={{ cursor: "pointer" }}>Alumni</span>
                <div>
                  <button>

                  </button>
                  <div></div>
                </div>
              </div>
              <div className={props.count >= 5 ? "selected" : "bullet"}
                onClick={async () => {

                  if (props.prof_id != "") {
                    let sts = await CheckStandard(3)
                    if (sts) {
                      props.setCount(5); props.setButton(""); props.setEcount(true); props.setEdit([])
                    }
                  } else {
                    props.setCount(5); props.setButton(""); props.setEcount(true); props.setEdit([])
                  }
                }}

              >
                <span style={{ cursor: "pointer" }}>Management</span>
                <div>
                  <button>

                  </button>
                  <div></div>
                </div>
              </div>
              <div className={props.count >= 6 ? "selected" : "bullet"}


                onClick={async () => {

                  if (props.prof_id != "") {
                    let sts = await CheckStandard(3)
                    if (sts) {
                      props.setCount(6); props.setButton(""); props.setEcount(true)
                    }
                  } else {
                    props.setCount(6); props.setButton(""); props.setEcount(true)
                  }
                }}
              >
                <span style={{ cursor: "pointer" }}>Misc</span>
                <div>
                  <button>

                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <!-- Save button has functionality of back for my use, it can be changed by removing id --> */}

            {
              props.showform == false &&
              (props.count == 1 ?
                <><button class="white_button mr16" id="previousBtn" onClick={() => { props.savedetails(); }}>Save</button>
                  <button class="blue_button" id="nextBtn"
                    onClick={() => {
                      CheckStandard();
                      if (props.prof_id == "") {
                        toast.error("Please add Basic Details First")
                      } else {
                        props.savedetails()
                        setTimeout(() => {
                          props.count >= 6 ? props.setCount(1) : props.setCount(props.count + 1);
                        }, 2000);
                      }
                    }}>Next</button></>
                :
                <><button class="white_button mr16" id="previousBtn" onClick={() => { props.prof_id == "" ? toast.error("Please add Basic Details First") : toast.success("Details saved successfully"); }}>Save</button>

                  {
                    props.count == 6 ?
                      <button class="blue_button" id="nextBtn" onClick={() => {
                        if (props.p_type == 6) {
                          props.setCount(1)
                        } else {
                          toast.success("Profile Created successfully.")
                          setTimeout(() => {
                            history.push(`/coaching/dashboard/`)
                          }, 2000);
                        }
                      }}>Finish</button> :
                      <button class="blue_button" id="nextBtn" onClick={() => {CheckStandard();  props.count >= 6 ? props.setCount(1) : props.setCount(props.count + 1); }}>Next</button>
                  }



                </>
              )

            }

            {
              props.next == true &&
              <button class="blue_button" id="nextBtn"
                onClick={() => {
                  CheckStandard()
                  if (props.prof_id == "") {
                    toast.error("Please add Basic Details First")
                  } else {
          
                    setTimeout(() => {
                      props.count >= 6 ? props.setCount(1) : props.setCount(props.count + 1);
                    }, 2000);
                  }
                }}>Next</button>
            }




            <p>Last Updated on {new Date().toLocaleString().replace(',', '')}</p>
          </div>
        </div>
      </div>


    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(ProfileHeader)

