import React from 'react'


function Data(props) {
    return (
        <>


            <div style={{ border: '1px solid white' }}>
                <table id="super-admin">
                    <tr>
                        <th>SR NO</th>
                        {/* <th>Position</th> */}
                        <th>Extra Curricular Activities</th>
                        <th>Icon</th>
                        <th>Action</th>
                    </tr>
                    {
                        props.data.map((data, key) => (data.name.toLowerCase().indexOf(props.search.toLowerCase()) != -1) && <tr key={key}>
                            <td>{key + 1}</td>
                            {/* <td>{data.position}</td> */}
                            <td>{data.name}</td>
                            <td><img src={data.file_url} style={{ height: "20px", width: "20px" }} /></td>
                            <td><button onClick={() => { props.setSelected(data.entity_id); window.scrollTo(0, 0) }} className="Super-admin-edit-button">EDIT</button>
                                <label class="switch1" style={{ marginLeft: "15px" }}>
                                    <input type="checkbox" checked={data.active}
                                        onClick={() => props.ShowonProfile(data.entity_id, data.active)}
                                    />
                                    <span class="slider1 round"></span>

                                </label>
                            </td>
                        </tr>)
                    }



                </table>


            </div>





        </>
    );
}

export default Data;