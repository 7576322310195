import React, { useState } from "react";
import Educatorheader from "./educator_header";
import BasicDetail from "./basicdetails";
import QualificationBlock from "./qualification/qualification_block"
import ExperienceBlock from "./experience/experience_block";
import AcheivemnetBlock from "./acheivements/acheivemnet_block";
import KYCBlock from "./kyc/kyc_block";
import LinkBlock from "./Link/link_block";


function EducatorProfile() {

    const [count, setCount] = useState(1);
    const [button, setButton] = useState("")
    const [ecount, setEcount] = useState(true)
    const [eduId,setId]=useState(0)


    return (<>
        {/* <Educatorheader
            count={count} setCount={setCount}
            setButton={setButton}
            setEcount={setEcount} /> */}


        {count == 1 &&
            <BasicDetail  count={count} setCount={setCount} eduId={eduId} setId={setId}  setButton={setButton}
            setEcount={setEcount} />
        }
        {count == 2 &&
            <QualificationBlock  count={count} setCount={setCount} button={button} setButton={setButton} ecount={ecount} setEcount={setEcount}  eduId={eduId} setId={setId}/>
        }

        {count == 3 &&
            <ExperienceBlock  count={count} setCount={setCount} button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} eduId={eduId} setId={setId} />
        }

        {count == 4 &&
            <AcheivemnetBlock   count={count} setCount={setCount} button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} eduId={eduId} setId={setId}/>
        }

        {count == 5 &&
            <KYCBlock    count={count} setCount={setCount} button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} eduId={eduId} setId={setId} />
        }
        {count == 6 &&
            <LinkBlock    count={count} setCount={setCount} button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} eduId={eduId} setId={setId} />
        }
    </>)
}

export default EducatorProfile