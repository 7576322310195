import React, { useState, useEffect } from "react";
import searchh from '../../../assets/home/search_icon.png'
import eye from '../../../assets/img/header/eye.svg'
import '../../../css/vAdmin/admin.css'
import api from '../../../api/api'
import Loader from '../../../components/loader'
import coming from '../../../assets/img/Group.png'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

function Lead(props) {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [rdata, setRdata] = useState([])
    const [search, setsearch] = useState('')

    useEffect(() => {
        getLead()
    }, [])

    const getLead = (active) => {
        let prof_id = localStorage.getItem("prof_id")
        if (props.p_type == 3 ? prof_id == 'null' : props.prof_id == null) {
            toast.error("Please add Basic details first")
        } else {
            setLoading(true)
            let request = {
                url: `enquiry/all?${props.p_type == 3 ? "edu_id" : "inst_id"}=${props.p_type == 3 ? prof_id : props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.getCustom(request).then(data => {
                setLoading(false)
                setRdata(data.data.result.response)
            }).catch((err) => {
                console.log(err, "errrr")
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const Apply = () => {
        let prof_id = localStorage.getItem("prof_id")
        setLoading(true)
        let request = {
            url: `enquiry/lead?${props.p_type == 3 ? "edu_id" : "inst_id"}=${props.p_type == 3 ? prof_id : props.prof_id}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.enquiry_lead_response)
            // setTotal(data.data.result.total_elements)
            toast.success("Filter applied successfully")
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const UpdateStatus = (stat, id) => {
        setLoading(true)
        let request = {
            url: `enquiry/${id}/update-status/${stat}?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            getLead()
            toast.success("Roles updated successfully.")
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    return (
        <>
            {loading && <Loader />}
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginTop: '10px', borderBottom: '1px solid #dee2e6' }}>
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#role" role="tab" aria-selected="true">Leads</a>
                    </li>

                </ul>
                <div className="sa-searchhh" style={{ marginRight: '40px' }}>
                    <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                    <input className="Super-admin-Search_Input" type="text" onChange={(e) => setsearch(e.target.value)}
                        placeholder=" Search by name or mobile No" style={{ outline: "0", width: '200px' }} />
                </div>
            </div>

            <div className="sa-leadsSchool2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px', backgroundColor: 'white' }}>
                <div className="Sa-dropdown2" style={{ marginLeft: '15px' }}>
                    <section className="sa-wrapper" style={{ width: "7rem" }}>
                        <select name="Master Category" id="Master Category" onChange={(e) => setStatus(e.target.value)}
                            className="Sa-input3" style={{ fontSize: "13px", width: '200px', marginTop: '17px' }} >
                            <option>Select Status</option>
                            <option value='PENDING'>Open</option>
                            <option value='INPROGRESS'>Inprogress</option>
                            <option value='ADMITTED'>Student Admitted</option>
                            <option value='REJECTED'>Closed</option>
                        </select>
                    </section >
                </div>


                <div className="Sa-dropdown3">
                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>From date:</div>
                    <input type="date" id="Start date" className="Sa-input3" onChange={(e) => setFromDate(e.target.value)} name="Start Date" style={{ fontSize: "12px" }} />
                </div>

                <div className="Sa-dropdown3">
                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" onChange={(e) => setToDate(e.target.value)} style={{ fontSize: "12px" }} />
                </div >
                <div className="sa-SeachButton">
                    <button className="Super-admin-save-button" onClick={Apply}>Go</button>
                </div>


            </div>

            {
                rdata.length == 0 ?
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '50px' }}>

                        <div style={{ marginBottom: '20px' }}>COMING SOON...</div>
                        <div><img src={coming} /></div>


                    </div> :
                    <div className="sa-tableLeads">
                        <table id="super-admin">
                            <tr >
                                <th >Sr.no</th>
                                <th>Enquiry Recieved</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile No</th>
                                <th>Message</th>
                                <th>Status</th>
                                <th>Last Updated On</th>
                                <th>Last Updated By</th>
                                <th>Action</th>
                            </tr>

                            {
                                rdata.map((data, key) => (data.enq_nm.toLowerCase().indexOf(search.toLowerCase()) != -1 || data.mob_no.toLowerCase().indexOf(search.toLowerCase()) != -1) && <tr key={key} >
                                    <td >{key + 1}</td>
                                    <td>{data.c_date.split(" ", 1)}</td>
                                    <td>{data.enq_nm}</td>
                                    <td>{data.eml_id}</td>
                                    <td>{data.mob_no}</td>
                                    <td> <img src={eye} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}></img></td>
                                    <td>{data.status}</td>
                                    <td>{data.u_date.split(" ", 1)}</td>
                                    <td>Proctur</td>
                                    <td>
                                        <select className="Sa-input2" style={{ marginTop: '5px' }} value={data.status} onChange={(e) => UpdateStatus(e.target.value, data.id)}>
                                            <option>Select</option>
                                            <option value='PENDING'>Open</option>
                                            <option value='INPROGRESS'>Inprogress</option>
                                            <option value='ADMITTED'>Student Admitted</option>
                                            <option value='REJECTED'>Closed</option>
                                        </select>
                                    </td>
                                </tr>)
                            }
                        </table>

                    </div>
            }




            <ToastContainer />
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type, up_id: state.auth.up_id })

export default connect(mapStateToProps)(Lead)
