import React, { useState, useEffect } from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { connect } from "react-redux";
import close from '../../../assets/img/close.svg'
import pdf from '../../../assets/img/pdf.png'
import xls from '../../../assets/img/xls.png'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';

export function KYCForm(props) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [image, setimage] = useState([])
  const [oimage, setOimage] = useState([])



  useEffect(() => {
    if (props.selected != 0) {
      setLoading(true)
      let request = {
        url: `educator/kyc/${props.data.id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setName(data.data.result.d_type)
        setOimage(data.data.result.files ?? [])
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }

  }, [props.selected])

  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        d_type: name,

      }));
      for (let index = 0; index < image.length; index++) {
        fd.append('files', image[index]);
      }

      let request = {
        url: `educator/${props.eduId}/kyc/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("KYC Added successfully");
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        console.log(err, "error")
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const updateValue = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        d_type: name,

      }));
      for (let index = 0; index < image.length; index++) {
        fd.append('files', image[index]);
      }

      let request = {
        url: `educator/kyc/${props.data.id}/update`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.putOther(request).then(data => {
        setLoading(false)
        toast.success(" KYC Updated successfully.")
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const validate = () => {
    if (name === '') {
      toast.error("Please Enter Name")
      return false
    }
    if (image === '') {
      toast.error("Please Upload file")
      return false
    }
    return true
  }


  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      if ([...oimage, ...image].length > 2) {
        toast.error("You are only allowed to upload a maximum of 3 files")
      } else {
        if (e.target.files.length >= 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          setimage([...image, ...imgs])
          getExtension(image.name)
        }
      }
    }
  }

  const validateImage = (e) => {
    let img = e.target.files[0].size / 1024
    if (img > 500) {
      toast.error("Please upload image of size 500kb only.")
      return false
    }
    else return true
  }

  const getExtension = (name) => {
    if (name === undefined || name === null) {
      return '';
    }
    let str = name.split('.');
    return str[str.length - 1];
  }

  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3, index) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }

    })

  }

  return (<>
    {loading && <Loader />}
    <div class="form-section ">
      <div>
        {props.selected ? <p class="heading">Edit Document </p> : <p class="heading">Add Document </p>}
        <p class="line_purple"></p>
        <div class="mob-top">
          <label>Document Type<span>*</span></label>
          <div class="select-wrapper ">
            <select class="expandale-input" value={name} onChange={(e) => setName(e.target.value)}>
              <option >Select</option>
              <option value='GOVERNMENT'>Government Issued ID Proof</option>
              <option value='IDCARD'>ID card of School / Institute</option>
              <option value='OTHER'>Other</option>
            </select>
          </div>
        </div>
        <div>

        </div>
      </div>
      <div>

        <label>Upload Document<span>*</span></label>

        <input class="expandale-input w-450 mt-16" type="file" onChange={select_MediaContent} />
        <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img , pdf ,doc .</p>
        <div class="tags-img">
          {oimage.map((item, key) => {
            { key = key }
            return (
              <div >
                {
                  item.f_disp.split(".")[1] == "pdf" ?
                    <img src={pdf} /> :
                    item.f_disp.split(".")[1] == "xls" ?
                      <img src={xls} /> :
                      <img src={item.f_url} />
                }
                <img src={close} onClick={() => { deleteImage(item.id) }} />
              </div>
            )
          })
          }
          {image.map((item, key) => {
            { key = key }
            return (
              <div >
                {
                  item.name.split(".")[1] == "pdf" ?
                    <img src={pdf} /> :
                    item.name.split(".")[1] == "xls" ?
                      <img src={xls} /> :
                      <img src={URL.createObjectURL(item)} />
                }

                <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
              </div>
            )
          })
          }</div>
      </div>
      <div class="button-section">
        <div></div>
        <div>
          <button class="white_button mr16" onClick={() => { props.setSelected(0); props.setshowform(false); }} >Cancel</button>
          {
            props.selected ?
              <button class="blue_button" onClick={updateValue}>Update</button> :
              <button class="blue_button" onClick={() =>
                {
                  if(props.eduId == ""){
                    toast.error("Please add Basic Details first.")
                  }else{
                    savedetails()
                  }
                }
                  }>Save</button>
          }
        </div>

      </div>
    </div>
    <ToastContainer />

  </>)

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(KYCForm)