import React from "react";


export default function ReviewPopup(props){
    return(
        <>
           <div className="sa-popupEnquiry">
        <div class="modal-dialog">
            <div class="modal-content ">
                <div class="modal-body ">

                    <div class="news-box">
                        <p><b>When to report a review ?</b></p>
                        <p class="mb-0">1. If anyone uses any abusive or vulgar language. <br/>
                            2. If any student gives negative review who doesn't belong to your Institute/School or has
                            not taken tuitions from you.</p>
                        <br/>
                        <p><b> How this works ? </b></p>
                        <p class="mb-0">1. For Each Review received , there is option "Report Review" in "Action"
                            Column.<br/>
                            2. Click on the "Report Review" , then there will be pop-up window to confirm the same.<br/>
                            3. Our Team will investigate on your case and will take necessary action against it and will
                            notify you the same</p>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="white_button mr16" data-dismiss="modal" onClick={()=>props.setpopup(false)}>Close</button>
                    <button type="button" class="blue_button" onClick={()=>props.Report()}>Report</button>
                </div>
            </div>

        </div>
    </div>
        </>
    )
}