import './backdrop.css'

function BackDrop(props)
{
  return(
      <div className="backdrop_at_all" onClick={props.FromChildBackDrop}>

      </div>
  )
  
}

export default BackDrop