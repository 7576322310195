import React, { useState, useEffect } from "react";
import close from '../../../../assets/img/close.svg'
import add from '../../../../assets/img/add.svg'
import { connect } from "react-redux";
import pic from '../../../../assets/img/pic.svg'
import { errorMsg } from "../../../../utils";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/loader";
import api from "../../../../api/api";
import circular from '../../../../assets/img/circular.png'

export function ManagementForm(props) {
  const [loading, setLoading] = useState(false)
  const [name, setname] = useState("")
  const [link, setlink] = useState("")
  const [videolink, setvideolink] = useState([])
  const [designation, setdesignation] = useState("")
  const [msg, setmsg] = useState("")
  const [picture, setPicture] = useState("")
  const [title, setTitle] = useState("")
  const [etitle, seteTitle] = useState("")
  const [image, setImage] = useState("")
  const [getvideolink, setGetvideolink] = useState([])


  useEffect(() => {
    if (props.selected != 0) {
      console.log(props.data, "datatat")
      setname(props.data.name)
      setTitle(props.data.title)
      setmsg(props.data.m_msg)
      setImage(props.data.ph)
      setGetvideolink(props.data.vdo_link ?? [])
      setPicture(props.data.ph)
      setImage(props.data.ph)
      setdesignation(props.data.designation)
    }
    return name
  }, [props.selected])

  console.log(getvideolink, "getvideolink")

  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        title: title,
        name: name,
        designation: designation,
        vdo_link: videolink.map(data => data.link),
        m_msg: msg,
        uid: props.up_id,
        i_p_id: props.prof_id
      }));
      fd.append('file', picture);
      let request = {
        url: `mgmnt-msg/add `,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Form Management Added successfully");
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const updateValue = () => {
    if (validate()) {
      setLoading(true)
      let link = [...getvideolink, ...videolink.map(data => data.link)]
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        title: title,
        name: name,
        designation: designation,
        vdo_link: link,
        m_msg: msg,
        ph: image,
        uid: props.up_id,
        i_p_id: props.prof_id
      }));
      fd.append('file', picture);
      let request = {
        url: `mgmnt-msg/${props.data.id}/update`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
          'Content-Type': "application/json"

        },
        data: fd
      }
      api.putOther(request).then(data => {
        setLoading(false)
        toast.success("Form Management Updated successfully.")
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const validateImage = (e) => {
    console.log(e.target.files[0].size, "kjngnghg")
    let img = e.target.files[0].size / 1024
    console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }

  const onChange = (e) => {
    if (validateImage(e)) {
      setPicture(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  const validate = () => {
    if (title === '') {
      toast.error("Please select Title")
      return false
    }
    if (name === '') {
      toast.error("Please Enter name")
      return false
    }
    if (designation === '') {
      toast.error("Please Enter designation")
      return false
    } if (picture === '') {
      toast.error("Please Upload Image")
      return false
    }
    if (msg === '') {
      toast.error("Please Enter message")
      return false
    }
    return true
  }



  const getImageThumbnail = () => {
    if (link.indexOf("http") == -1) {
      toast.error("Please Add Video Link.")
    } else if ([...getvideolink, ...videolink].length > 2) {
      toast.error("You are only allowed to upload a maximum of 3 video link.")
    } else {
      let videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        setvideolink([...videolink,
        {
          preview: `https://img.youtube.com/vi/${videoid[1]}/0.jpg`,
          link
        }])
      } else {
        setvideolink([...videolink,
        {
          preview: circular,
          link
        }])
      }
      setlink("")
    }
  }

  const validateText = (name) => {
    var reg = /^[A-Z a-z]*$/
    if (!reg.test(name)) {
      return false;
    }
    return true;
  }

  const YouTubeGetID = (url) => {
    var ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    return ID;
  }




  return (
    <>
      {loading && <Loader />}
      <div class="form-section ">
        <div>
          <p class="heading">Add Message</p>
          <p class="line_purple"></p>
          <p class="data-info mt10">You can convey management teams message to
            students, to be displayed on your proctur profile.</p>
          <div class="flex f-wrap">
            <div>
              <label>Title<span>*</span></label>

              <div class="select-wrapper w-20">
                <select class="expandale-input w-20" value={title} onChange={(e) => setTitle(e.target.value)}>
                  <option>Select</option>
                  {["Mr", "Mrs", "Ms", "Dr", "Hon", "Prof", "Jr"].map((item,key) => {
                     {key=key}
                    return (<option value={item}>{item}</option>)
                  })}
                </select>
              </div>
            </div>
            <div>
              <label>Full Name<span>*</span></label>
              <input contenteditable="true" class="expandale-input w-450 mob-20" placeholder="Enter" value={name} onChange={(e) => { if (validateText(e.target.value)) { setname(e.target.value) } }} />
              {/* {props.selected ?
                <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => { if (validateText(e.target.value)) { seteTitle(e.target.value) } }}></input> :
               
              } */}
            </div>
            <div class="w-14">
              <label>Designation<span>*</span></label>
              <input contenteditable="true" class="expandale-input" placeholder="Enter" value={designation} onChange={(e) => setdesignation(e.target.value)} />
            </div>
          </div>
        </div>
        <div>
          <label>Video Link </label>
          <p class="data-info">You can enter YouTube, Vimeo or any other video links</p>
          <div class="flex-p1">
            <input class="expandale-input w-450 choose" type="text" placeholder="Add Link" value={link} onChange={(e) => setlink(e.target.value)}></input>
            <img src={add} style={{ cursor: "pointer" }} onClick={getImageThumbnail} />
          </div>
          <div class="tags-img">
            {getvideolink.map((item, index) => {
              return (
                <div key={index} >
                  <img src={`https://img.youtube.com/vi/${YouTubeGetID(item)}/0.jpg`} onClick={() => { window.open(item) }} />
                  <img src={close} onClick={(index) => setGetvideolink(getvideolink.filter(item2 => item2 != item))} />
                </div>
              )
            })
            }
            {videolink.map((item,key) => {
              return (
                <div  key={key}>
                  <img src={item.preview} onClick={() => { window.open(item.link) }} />
                  <img src={close} onClick={(index) => setvideolink(videolink.filter(item2 => item2.preview + item2.lastModified != item.preview + item.lastModified))} />
                </div>
              )
            })
            }
          </div>
        </div>
        <div>
          <label>Profile Photo<span>*</span></label>
          <label htmlFor="photo-upload" >
            <div   >{picture == "" ?
              <img for="photo-upload" class="pro-pic" src={pic} /> :
              <img for="photo-upload" class="pro-pic" src={image} />
            }
            </div>
            <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
              display: "none",
              visibility: "hidden"
            }} />
          </label>

          <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
        </div>

        <div>
          <label>Member Message<span>*</span></label>
          <p class="data-info">Max 250 characters</p>
          <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={msg} onChange={(e) => setmsg(e.target.value)} />
        </div>
        <div class="button-section">
          <div></div>
          <div>
            <button class="white_button mr16" onClick={() => props.setshowform(false)}>Cancel</button>
            {
              props.selected ?
                <button class="blue_button" onClick={updateValue}>Update</button> :
                <button class="blue_button" onClick={() => {
                  if (props.prof_id == "") {
                    toast.error("Please Add Basic Details First.")
                  }else if(props.standard=='false' && props.educator=='false'){
                    toast.error("Please Add Standard and Educator.")
                  }else if(props.educator=='false'){
                    toast.error("Please Add Educator")
                  }else{
                    savedetails()
                  }
                   }}>Save</button>
            }
          </div>
        </div>
      </div>


    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(ManagementForm)