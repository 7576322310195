import React, { useState } from 'react'
import eye from '../../assets/img/header/eye.svg'
import cross from '../../assets/home/cross.png'
import down from '../../assets/img/down.png'
import api from '../../api/api'
import Loader from '../../components/loader'
import { errorMsg } from '../../utils'
import { connect } from "react-redux";
import Moment from 'moment';
import axios from 'axios'
import fileDownload from 'js-file-download'
import { ToastContainer, toast } from 'react-toastify';





function KYCData(props) {

    const [Flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [image, setImage] = useState([])

    console.log(Flag, "flag")

    const UpdateStatusInsti = (stat, id) => {
        setLoading(true)
        let request = {
            url: `inst/kyc/${id}/update-status/${stat}?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("KYC status updated successfully.")
            props.getTableData(props.tag)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const UpdateStatussducator = (stat, id) => {
        setLoading(true)
        let request = {
            url: `educator/${id}/kyc/update-status/${stat}?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("KYC status updated successfully.")
            props.getTableData(props.tag)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    const handleClick = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }

    return (
        <>
            {loading && <Loader />}
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>KYC Status</th>
                        <th>View Document</th>
                        <th>Last Updated </th>
                        <th>Last Updated By</th>
                    </tr>
                    {
                        props.data.map((data, key) => <tr key={key} >
                            <td >{data.kyc_id ?? data.entity_id}</td>
                            <td>{data.name}</td>
                            <td>{data.location}</td>
                            <td>{data.email}</td>
                            <td>{data.mobile}</td>
                            <td><select className="Sa-input2" style={{ marginTop: '5px' }} value={data.status} onChange={(e) => {
                                if (props.type == 'educator') {
                                    UpdateStatussducator(e.target.value, data.entity_id)
                                } else {
                                    UpdateStatusInsti(e.target.value, data.kyc_id)
                                }
                            }
                            }>
                                <option>KYC Status</option>
                                <option value="VERIFIED">Verified</option>
                                <option value="NOTVERIFIED">Not Verified</option>
                                {/* <option value="PENDING">Pending</option> */}
                            </select></td>
                            <td><img src={eye} onClick={() => { setImage(data.upload_detail ?? data.kyc_detail); setFlag(true) }}
                                style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}></img></td>
                            <td>{Moment(data.updated_date).format('DD-MM-YYYY')}</td>
                            <td>{data.updated_by}</td>
                        </tr>)
                    }
                </table>


            </div>
           

            {
                Flag == true &&
                <div className="sa-popupEnquiry">
                    <div style={{ width: '50%', background: 'white', alignItems: 'stretch' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                            <div>View Documnets</div>
                            <img src={cross} style={{ width: '30px', height: '30px', marginTop: '5px', cursor: 'pointer' }} onClick={() => setFlag(false)} />
                        </div>
                        {props.type != 'educator' ?
                            image.map((item,key) => {
                              {key=key}
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                                        <div>{item.fileName}</div>
                                        <img src={down} style={{ width: '20px', height: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={() => handleClick(item.fileUrl, item.fileName)} />
                                    </div>
                                )
                            }) :

                            image.map((item) => item.upload_detail.map((items) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                                        <div>{items.fileName}</div>
                                        <img src={down} style={{ width: '20px', height: '20px', marginTop: '5px', cursor: 'pointer' }} onClick={() => handleClick(items.fileUrl, item.fileName)} />
                                    </div>
                                )
                            })
                            )
                        }
                    </div>
                </div>
            }



        </>
    );
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type, up_id: state.auth.up_id })

export default connect(mapStateToProps)(KYCData)

