import React, { useState, useEffect } from 'react'
import api from '../../api/api'
import { errorMsg } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';



function Operate(props) {
    const [change, setChange] = useState("")
    const [echange, seteChange] = useState("")
    const [date, setDate] = useState("")
    const [edate, seteDate] = useState("")




    const searchButton = () => {

        props.setLoading(true)
        let request = {
            url: `/todos/1`,
            headers: {
                'Authorization': ""
            },
            data: {
                change: props.subject,
                echange: props.lead,
                date: date,
                edate: edate

            }
        }

        api.postCustom(request).then(data => {

            props.setLoading(false)
            seteChange("");
            setChange("");
            props.fetchData();


        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })


    }
    return (
        <>
            <div className="sa-leadsSchool2">



                {props.selectedTab == "School" &&
                    <div className="Sa-dropdown2" style={{ marginLeft: "13px" }}>
                        <section className="sa-wrapper">
                            <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px" }} >
                                <option value="">Select School</option>
                                <option value="Competetion">Competetion</option>
                                <option value="Government">Government</option>
                            </select>
                        </section>
                    </div>
                }

                {props.selectedTab == "Coaching" &&
                    <div className="Sa-dropdown2" style={{ marginLeft: "13px" }}>
                        <section className="sa-wrapper">
                            <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px" }} >
                                <option value="">Select Coaching</option>
                                <option value="Competetion">Competetion</option>
                                <option value="Government">Government</option>
                            </select>
                        </section>
                    </div>
                }

                {props.selectedTab == "Educator" &&
                    <div className="Sa-dropdown2" style={{ marginLeft: "13px" }}>
                        <section className="sa-wrapper">
                            <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px" }} >
                                <option value="">Select Educator</option>
                                <option value="Competetion">Competetion</option>
                                <option value="Government">Government</option>
                            </select>
                        </section>
                    </div>
                }
                <div className="Sa-dropdown3">

                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>From date:</div>
                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" onChange={(e) => setDate(e.target.value)} style={{ fontSize: "12px" }} />


                </div>
                <div className="Sa-dropdown3">
                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" onChange={(e) => seteDate(e.target.value)} style={{ fontSize: "12px" }} />
                </div>


                <div className="Sa-dropdown2">

                    <section className="sa-wrapper">
                        <select name="Master Category" id="Master Category" className="Sa-input2" value={props.lead} onChange={(e) => props.setLead(e.target.value)} style={{ fontSize: "13px" }} >
                            <option value="">Lead Count</option>
                            <option value="0 to 25">0 to 25</option>
                            <option value="26 to 99">26 to 99</option>
                            <option value="100 to 499">100 to 499</option>
                            <option value="500 Above">500 Above</option>
                        </select>
                    </section >
                </div>
                <div className="Sa-dropdown2">
                    <section className="sa-wrapper">
                        <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px" }} >
                            <option value="">Priority</option>
                            <option value="Competetion">Higest</option>
                            <option value="Government">Lowest</option>
                        </select>
                    </section>
                </div>

                <div className="sa-SeachButton">
                    <button className="Super-admin-save-button" onClick={searchButton} >Apply</button>
                </div>


            </div>
            <ToastContainer />
        </>
    )
}
export default Operate