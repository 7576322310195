import React, { useState,useEffect } from "react";
import NewsBlock from "./news_blocks";
import NewsForm from "./news_form";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';



 function News(props) {
    const [form, setForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rdata, setRdata] = useState([]);
    const [selected, setselected]=useState(0)
    let [mdata, setMdata] = useState({})



    useEffect(() => {
        fetchData();
         setselected(0);
      }, [])
    


      const fetchData = () => {
        if(props.prof_id==null){
            toast.error("Please add Basic Details first.")
        }else{
        setLoading(true)
        let request = {
            url: `news/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
         }
         api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)
         }).catch((err) => {
            setLoading(false)
              if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
         })}
    }
     


    return (
        <>
        {loading && <Loader />}
            {
                form == false ?
                    <NewsBlock  setForm={setForm} fetchData={fetchData} rdata={rdata}  setRdata={setRdata} setselected={setselected} setMdata={setMdata} /> :
                    <NewsForm  setForm={setForm} selected={selected} fetchData={fetchData} mdata={mdata} setselected={setselected}/>

            }

        </>
    )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id  ,p_type:state.auth.p_type})

export default connect(mapStateToProps)(News)
