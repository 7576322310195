import React, { useEffect, useState } from "react";



function Operate(props) {
   
    return (<>
        <div className="sa-leadsSchool2">            
        <div className="Sa-dropdown2" style={{marginLeft:'20px'}}>
                <section className="sa-wrapper" style={{width:"7rem"}}>
                    <select name="Master Category" id="Master Category" 
                    onChange={(e)=>props.setOperate({...props.operate,added:e.target.value})} value={props.operate.added}
                    className="Sa-input2" style={{ fontSize: "13px",width:"100px"  }} >
                        <option value="Added By"> Added By</option>
                        <option value="Self"> Self</option>
                        <option value="Proctur"> Proctur</option>
                    </select>
                </section >
            </div>

            <div className="Sa-dropdown2">
                <section className="sa-wrapper" style={{width:"7rem"}}>
                    <select name="Master Category" id="Master Category" 
                    onChange={(e)=>props.setOperate({...props.operate,location:e.target.value})} value={props.operate.location}
                    className="Sa-input2" style={{ fontSize: "13px",width:"100px" }} >
                        <option value="">Location</option>
                        {props.country.map((item)=> <option value={item.id}> {item.ct_nm}</option>)}
                    </select>
                </section >
            </div>
            <div className="Sa-dropdown3">
                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>From date:</div>
                <input type="date" id="Start date" className="Sa-input3" name="Start Date" value={props.operate.fromdate} style={{ fontSize: "12px" }} onChange={(e)=>props.setOperate({...props.operate,fromdate:e.target.value})} />
            </div>
            <div className="Sa-dropdown3">
                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} value={props.operate.todate} onChange={(e)=>props.setOperate({...props.operate,todate:e.target.value})}/>
            </div>


            <div className="Sa-dropdown2" >
                <section className="sa-wrapper" style={{width:"7rem"}}>
                    <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px",width:"100px"  }} value={props.operate.statuss} onChange={(e)=>props.setOperate({...props.operate,statuss:e.target.value})} >
                        <option value="Profile Status">Profile Status</option>
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                       
                    </select>
                </section >
            </div>

            <div className="Sa-dropdown2">
                <section className="sa-wrapper" style={{width:"7rem"}}>
                    <select name="Master Category" id="Master Category" className="Sa-input2" value={props.operate.kyc} style={{ fontSize: "13px",width:"100px"  }} onChange={(e)=>props.setOperate({...props.operate,kyc:e.target.value})} >
                        <option value="KYC Status"> KYC Status</option>
                        <option value="Pending">Non-Verify</option>
                        <option value="Verify">Verify</option>
                    </select>
                </section >
            </div>

            <div className="sa-SeachButton">
            <button className="Super-admin-save-button" onClick={props.Apply}>Apply</button>
            <button className="Super-admin-save-button" onClick={props.reset} >Reset</button>    
            </div>


        </div>
    </>)
}

export default Operate