import React, { useState, useEffect } from 'react'
import Operate from './operate';
import Data from './school';
import Loader from '../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { errorMsg } from '../../utils';
import api from '../../api/api'


function SchoolData(props) {

    const [loading, setLoading] = useState(false)
    const [rdata, setRdata] = useState([]);
    const [active, setActive] = useState(1)
    const [total, setTotal] = useState("")
    const [operate, setOperate] = useState({ search: "", fromdate: "", todate: "" })


    useEffect(() => {
        fetchData(active);
    }, [])

    const fetchData = (active) => {
        setLoading(true)
        let request = {
            url: `stud/all?pageOffset=${active}&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.postCustom(request).then(data => {
            setRdata(data.data.result.response)
            setLoading(false)
            setTotal(data.data.result.total_elements)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const Apply = () => {
        if (operate.fromdate == "" && operate.todate == "" && operate.search == "") {
            toast.error("Please apply any filter.")
        } else {
            setLoading(true)
            let request = {
                url: `stud/all?pageOffset=${active}&pageSize=10&isAll=false`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: {

                    f_date: operate.fromdate,
                    t_date: operate.todate,
                    sr_txt: operate.search

                }
            }
            api.postCustom(request).then(data => {
                setLoading(false)
                setRdata(data.data.result.response)
                setTotal(data.data.result.total_elements)
                toast.success("Filter applied successfully.")
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const reset = () => {
        setOperate({ ...operate, fromdate: "", todate: "", search: " " })
        fetchData(active)
    }

    return (
        <>
            {loading && <Loader />}
            <Operate setOperate={setOperate} operate={operate} Apply={Apply} reset={reset} />
            <Data
                setRdata={setRdata} rdata={rdata}
            />
            <ToastContainer />
        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken })

export default connect(mapStateToProps)(SchoolData)

