import React, { useState, useEffect } from "react";
import pic from '../../../../assets/img/pic.svg'
import close from '../../../../assets/img/close.svg'
import api from '../../../../api/api'
import { connect } from "react-redux";
import Loader from '../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from "../../../../utils";
import Qualifications from "./education_qualification";
import Experience from "./experience";
import Achievement from "./acheivemnet";


export function EducatorForm(props) {
  let type = window.location.href.split("?")[1].split("=")[1]
  const [loading, setLoading] = useState(false)
  const [Master, setMaster] = useState({
    standard: [],
    subjects: [],
    category: [],
    courseSub: []
  })
  const [image, setImage] = useState("")
  const [profilePhoto, setProfile] = useState("")
  const [title, setTitle] = useState("")
  const [name, setName] = useState("")
  const [heading, setHeading] = useState("")
  const [standard, setStandard] = useState([])
  const [category, setCategory] = useState([])
  const [subject, setSubject] = useState([])
  const [subjectcat, setSubjectCat] = useState([])
  const [nos, setNos] = useState("")
  const [dataId, setDataId] = useState("")
  let [detail, setDetail] = useState([])
  let [courseDeatil, setcourseDetail] = useState([])






  useEffect(() => {
    if (props.selected != 0) {
      let id = props.data.id
      let request = {
        url: `educator/${id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        let dataa = data.data.result
        setDataId(dataa.id)
        setName(dataa.e_name)
        setHeading(dataa.p_headline)
        setTitle(dataa.title)
        setProfile(dataa.ph_url)
        setImage(dataa.ph_url)
        setNos(dataa.n_of_s)
        setProfile(dataa.ph_url)
        setDetail(dataa.std_sub_li??[])
        setcourseDetail(dataa.crs_sub_li??[])
      }).catch((err) => {
        toast.error("Error");
      })
    }


    getStandard();
    getSubject();
    Category();


  }, [props.selected])

  const getStandard = () => {
    setLoading(true)
    let request = {
      url: `master-stand/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, standard: data.data.result }))

    }).catch((err) => {
      setLoading(false)

    })
  }

  const getSubject = () => {
    setLoading(true)
    let request = {
      url: `master-sub/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, subjects: data.data.result.all_std_subject }))
      setMaster(Master => ({ ...Master, courseSub: data.data.result.all_course_subject }))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }





  const Category = () => {
    setLoading(true)
    let request = {
      url: `course/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, category: data.data.result }))

    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }


  let addSubject = (cat_id, sub_id) => {
    let ind = detail.findIndex(data => data.std_id == cat_id);
    if (ind == -1) {
      setDetail([...detail, { std_id: cat_id, sub_det: [sub_id] }])
    } else {
      let element = detail[ind].sub_det;
      if (element.indexOf(sub_id) == -1) {
        element.push(sub_id);
        detail[ind].sub_det = element;
        setDetail([...detail])
      }

    }
  }

  let removeSubject = (cat_id, sub_id) => {
    let ind = detail.findIndex(data => data.std_id == cat_id);
    let element = detail[ind]?.sub_det ?? []
    if (element.length == 1) {
      setDetail(detail.filter(data => data.std_id != cat_id))

    } else {
      element = element.filter(data => data != sub_id);
      detail[ind].sub_det = element;
      setDetail([...detail])
    }
  }


  let addSubjectCourses = (cat_id, sub_id) => {
    let ind = courseDeatil.findIndex(data => data.crs_id == cat_id);
    if (ind == -1) {
      setcourseDetail([...courseDeatil, { crs_id: cat_id, sub_det: [sub_id] }])
    } else {
      let element = courseDeatil[ind].sub_det;
      if (element.indexOf(sub_id) == -1) {
        element.push(sub_id);
        courseDeatil[ind].sub_det = element;
        setcourseDetail([...courseDeatil])
      }

    }
  }

  let removeSubjectcourses = (cat_id, sub_id) => {
    let ind = courseDeatil.findIndex(data => data.crs_id == cat_id);
    let element = courseDeatil[ind]?.sub_det ?? []
    if (element.length == 1) {
      setcourseDetail(courseDeatil.filter(data => data.crs_id != cat_id))

    } else {
      element = element.filter(data => data != sub_id);
      courseDeatil[ind].sub_det = element;
      setcourseDetail([...courseDeatil])
    }
  }


  let removeStandard = (cat_id) => {
    setDetail(detail.filter(data => data.std_id != cat_id))

  }

  const onChange = (e) => {
    if (validateImage(e)) {
      setProfile((e.target.files[0]))
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  }


  const validateImage = (e) => {
    let img = e.target.files[0].size / 1024
    if (img > 500) {
      toast.error("Please upload image of size 500kb only.")
      return false
    }
    else return true
  }

  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      let tempdata = {
        title: title,
        e_name: name,
        n_of_s: nos * 1,
        p_headline: heading,
        // ph_url: image,
        is_internal: true,
        i_p_id: props.prof_id,
        uid: props.up_id,
        ss_det: detail,
        cs_det: courseDeatil,
      }
      fd.append("u_data", JSON.stringify(tempdata));
      fd.append('file', profilePhoto);
      let request = {
        url: `educator/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      if (dataId == "") {
        api.postCustom(request).then(data => {
          setLoading(false)
          toast.success("Educator Added successfully.")
          setDataId(data.data.result)
          props.setNext(true)
          // props.setform(false); props.setSelected(0)
          // props.fetchData()
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      } else {
        updatedetails();
      }
    }
  }


  const updatedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      let tempdata = {
        title: title,
        e_name: name,
        n_of_s: nos * 1,
        p_headline: heading,
        ph_url: image,
        is_internal: true,
        i_p_id: props.prof_id,
        uid: props.up_id,
        ss_det: detail,
        cs_det: courseDeatil,
      }
      fd.append("u_data", JSON.stringify(tempdata));
      fd.append('file', profilePhoto);
      let request = {
        url: 'educator/' + dataId + '/update',
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.putOther(request).then(data => {
        toast.success("Educator Updated successfully.")
        setLoading(false)
        // props.setform(false); props.setSelected(0)
        // props.fetchData()
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }



  const validate = () => {
    if (title === '') {
      toast.error("Please Enter Title")
      return false
    }
    if (name === '') {
      toast.error("Please Enter Name")
      return false
    } if (heading === '') {
      toast.error("Please Enter Profile Heading")
      return false
    }
    if ( type == "school" && detail == "") {
      toast.error("Please select Standard and Subject")
      return false
    }
    return true
  }


  const validateText = (name) => {
    var reg = /^[A-Z a-z]*$/
    if (!reg.test(name)) {
      return false;
    }
    return true;
  }

  return (
    <>
      {loading && <Loader />}
      <div class="form-section ">
        <div>
          <p class="heading">Add Educator</p>
          <p class="line_purple"></p>
          <p class="data-info mt10">You can add your educators, they will be shown your proctur profile.</p>
          <div class="flex f-wrap">
            <div>
              <label>Title<span>*</span></label>
              <div class="select-wrapper w-20">
                <select class="expandale-input w-20" value={title} onChange={(e) => setTitle(e.target.value)} >
                  <option>Select</option>
                  {["Mr", "Mrs", "Ms", "Dr", "Hon", "Prof", "Jr"].map((item,key) => { {key=key} return (<option value={item}>{item}</option>) })}
                </select>
              </div>
            </div>
            <div>
              <label>Full Name<span>*</span></label>

              <input contenteditable="true" class="expandale-input w-450 mob-20" placeholder="Enter" value={name} onChange={(e) => { if (validateText(e.target.value)) { setName(e.target.value) } }} />
            </div>
          </div>

        </div>
        <div>
          <label>Profile Headline<span>*</span></label>
          <p class="data-info mt10">Brief about your teacher's educational and teaching experience.</p>
          <input contenteditable="true" class="expandale-input w-450 mob-20" placeholder="Enter" value={heading} onChange={(e) => setHeading(e.target.value)} />
        </div>
        <div>
          <label>Profile Photo</label>
          <label htmlFor="photo-upload" >
            <div   >{profilePhoto == "" ?
              <img for="photo-upload" class="pro-pic" src={pic} /> :
              <img for="photo-upload" class="pro-pic" src={image} />
            }
            </div>
            <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
              display: "none",
              visibility: "hidden"
            }} />
          </label>
        </div>
        <div>
          <div class="flex mob-v">
            <div>
              <label>Standard  {type == "school" && <span>*</span>}</label>
              {type != "school" &&
                <p class="data-info mt10">Please add subjects for Standards and Courses separately</p>
              }

              <div class="select-wrapper">
                <select class="expandale-input w-200" onChange={(e) => { setStandard(e.target.value) }}>
                  <option style={{ fontWeight: 'bold' }}>Standard</option>
                  {Master.standard.map((item,key) => <option value={item.entity_id}>{item.name}</option>)}
                </select>
              </div>
              <div class="tags">
                {detail.map((item,key) => <><button> {[...Master.standard, ...Master.category].find(data => data.entity_id == item.std_id)?.name ?? ''} - {item.sub_det.map((data) => <>{[...Master.courseSub, ...Master.subjects].find(data2 => data == data2.entity_id)?.name ?? ''}<img src={close} onClick={() => removeSubject(item.std_id, data)} /></>)}</button></>)}
              </div>
            </div>
            <div class="mob-top">
              <label>Subjects {type == "school" && <span>*</span>}</label>
              {type != "school" &&
                <p class="data-info mt10">.</p>
              }
              <div class="select-wrapper">
                <select class="expandale-input w-200" onChange={(e) => { setSubject(e.target.value); addSubject(standard, e.target.value) }}>
                  <option>Select</option>
                  {Master.courseSub.map((item,key) => item.mast_course_id == standard && <option value={item.entity_id}>{item.name}</option>)}
                  {Master.subjects.map((item,key) => item.master_standard_id == standard && <option value={item.entity_id}>{item.name}</option>)}
                </select>
              </div>
            </div>

            <div class="mob-top">
              <label>Number of Students Taught </label>
              <p class="data-info mt10">Students taught till date .</p>
              <input contenteditable="true" class="expandale-input " placeholder="Enter" value={nos} type="number" min={0} onChange={(e) => setNos(e.target.value)} />
            </div>
          </div>
          {
            type != "school" &&
            <div class="flex mob-v" style={{ marginTop: "40px" }}>
              <div>
                <label>Category </label>
                <div class="select-wrapper">
                  <select class="expandale-input w-200" onChange={(e) => { setCategory(e.target.value) }}>
                    <option style={{ fontWeight: 'bold' }}>Category</option>
                    {Master.category.map((item,key) => <option value={item.entity_id}>{item.name}</option>)}
                  </select>
                </div>
                <div class="tags">
                  {courseDeatil.map((item,key) => <><button> {[...Master.category].find(data => data.entity_id == item.crs_id)?.name ?? ''} - {item.sub_det.map((data) => <>{[...Master.courseSub, ...Master.subjects].find(data2 => data == data2.entity_id)?.name ?? ''}<img src={close} onClick={() => removeSubjectcourses(item.crs_id, data)} /></>)}</button></>)}
                </div>
              </div>
              <div class="mob-top">
                <label>Subjects </label>
                <p class="data-info mt10"></p>
                <div class="select-wrapper">
                  <select class="expandale-input w-200" onChange={(e) => { setSubjectCat(e.target.value); addSubjectCourses(category, e.target.value) }}>
                    <option>Select</option>
                    {Master.courseSub.map((item,key) => item.mast_course_id == category && <option value={item.entity_id}>{item.name}</option>)}
                  </select>
                </div>
              </div>

            </div>
          }


        </div>



        <div class="button-section">
          <div></div>
          <div>
            <button class="white_button mr16" onClick={() => { props.setform(false); props.setSelected(0) }}>Cancel</button>
            <button class="blue_button" onClick={() => { 
              if(props.prof_id == ""){
                toast.error("Please Add Basic Details First.")
              }else if(props.standard=="false"){
                toast.error("Please Add Standard.")
              }else{
                savedetails()
              } }}>Save</button>
          </div>
        </div>


        <Qualifications rdata={props.rdata} dataId={dataId} loading={loading} setLoading={setLoading} />
        <Experience dataId={dataId} loading={loading} setLoading={setLoading} />
        <Achievement dataId={dataId} loading={loading} setLoading={setLoading} />


      </div>

    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(EducatorForm)