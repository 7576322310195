import React, { useState, useEffect } from 'react'
import help from '../../../assets/login/signin/help.png'
import arrow from '../../../assets/login/signin/arrow.png'
import india from '../../../assets/login/signin/india.png'
import loader2 from '../../../assets/login/signin/loader/loader2.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import style from '../../../css/style.css'
import website from '../../../css/website.css'

export default function Signin(props) {

    let [loading, setLoading] = useState(false)
    let [type, setType] = useState(2)
    let [number, setNumber] = useState("")



    const onSubmit = () => {
        if (number.length != 10) {
            toast.error("Please provide valid number.");
        } else {
            setLoading(true);
            api.post({ url: 'user/login', data: { "phone": number, "user_type": type } }).then((data) => {
                let adata = data.data.result;
                adata.number = number
                adata.user_type = type
                localStorage.setItem("login", JSON.stringify(adata))
                props.setSelected('Otp')
                // if(adata.is_new_user==true){
                //     props.setSelected('SigninNext')
                // }else{
                //     props.setSelected('Otp') 
                // }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const validateMobileNumber = (number) => {
        var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        if (!reg.test(number)) {
            toast.error("Please enter a valid 10 digit Number");
        }
    }

    return (
        <div className="signin">
            <h2>Sign In</h2>
            <h4>to your account</h4>
            <div className="flex-row signtype">
                <div onClick={() => setType(2)} className={type == 2 ? "active" : ""}>School</div>
                <div onClick={() => setType(1)} className={type == 1 ? "active" : ""}>Coaching</div>
                <div onClick={() => setType(3)} className={type == 3 ? "active" : ""}>Educator <img title="If you are an individual teacher." src={help} style={{ marginLeft: '10px', width: '18px', height: '18px' }} /></div>
            </div>

            <div className="flex-row">
                <img className="country" src={india} />
                <select className="ccode"><option>+91</option></select>
                <input type="text" contenteditable="true" placeholder="Enter your phone number" maxlength="10" value={number}
                    onKeyPress={(e) => {
                        let regex = /^\d+$/;
                        if (((e.target.value === '' || regex.test(e.target.value)))) {
                            setNumber(e.target.value)
                        }
                    }}
                    onBlur={(e) => validateMobileNumber(e.target.value)}
                    onChange={(e) => {
                        let regex = /^\d+$/;
                        if (((e.target.value === '' || regex.test(e.target.value)))) {
                            setNumber(e.target.value)
                        }
                    }}
                />
            </div>
            {
                loading ?
                    <div className="loginProgress"><img src={loader2} /></div>
                    :
                    <button onClick={onSubmit} className="flex-row">Confirm <img src={arrow} /></button>
            }

            {/* <h4 className="inst" style={{margin: '10px 0 20px 0'}}><a onClick={() => props.setSelected('Login')}>Not an Institute ? <span>Go Back</span></a></h4> */}
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}
