import React, { useState, useEffect } from 'react'
import Loader from '../../../components/loader'
import Data from './data'
import Operate from './operate'
import api from '../../../api/api'
import { connect } from 'react-redux'
import config from '../../../config'
import { errorMsg } from '../../../utils'
import masters from '../../../masters'
import { ToastContainer, toast } from 'react-toastify';

const mdata = masters[config.env]
const entity_id = mdata.find(data => data.path == window.location.pathname)?.entity_id;


function Ownership(props) {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `master/${entity_id}/sub-master/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)
        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
            setLoading(false)
        })
    }


    return (
        <>
            {loading && <Loader />}
            <div className="Sa-Profile">
                <Operate fetchData={fetchData} entity_id={entity_id} setLoading={setLoading} selected={selected} setSelected={setSelected} data={rdata.find(data => data.entity_id == selected)} />
                {/* <MasterSearch placeholder=" Global Search" value={search} onChange={setSearch} /> */}
                <Data data={rdata} setSelected={setSelected} search={search} />

            </div>




        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Ownership)
