import React, { useState } from 'react'
import '../../css/vAdmin/admin.css'
import icon from '../../assets/img/header/icon.svg'
import Group from '../../assets/img/header/Group.png'
import noti from '../../assets/img/header/notidot.png'
import bell from '../../assets/img/header/bell.svg'
import bellDot from '../../assets/img/header/belldot.png'
import Notification from './notification'
import { connect } from "react-redux";



function Header(props) {
  const [toggleShow, setToggleShow] = useState(false)
  const [notification, setNotification] = useState(false)

  const openNav=()=> {
    document.getElementById("sidebar").style.width = "250px";
  }

  console.log(toggleShow)
  return (
    <>
      <header>
        <div>
          <div class="header">
            <div class="header-left"  >
              <button class="sidebar-toggle-button" style={{ textDecorationLine: 'line-through' }} onClick={openNav}><i class="fa fa-bars"
                aria-hidden="true"></i></button>
              {/* <p>Profile</p> */}
              {/* <!--  <input type="text" class="header-input" name="" placeholder="Student/Enquiry/Contact"> --> */}
            </div>
            <div class="header-right">
              {
                props.p_type==6 &&
                <button onClick={() => { props.dispatch({ type: 'LOGOUT' });localStorage.clear() }} class="blue_button">LOGOUT</button>
              }
              {/* <button class="blue_button" style={{marginLeft:'10px'}}><img src={icon} />&nbsp;&nbsp;Download App</button> */}
              {
                props.p_type!=6 &&
                <><span><img src={noti} class="m20 head_img" onClick={() => setNotification(true)} /></span>
                <span><img src={bellDot} class="m20 head_img" onClick={() => setNotification(true)} /></span></>
              }
             
            </div>
          </div>
        </div>

      </header>
      {
        notification == true &&
        <Notification setNotification={setNotification} />
      }



    </>

  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, p_type: state.auth.p_type })

export default connect(mapStateToProps)(Header)
