import React, { useState } from 'react'
import '../../css/superAdmin/SuperAdmincss.css'
import { Link } from 'react-router'
import dwld from '../../assets/home/download_icon.png'
import searchh from '../../assets/home/search_icon.png'


function LeadNavBar(props) {

    return (


        <>

            <div className="sa-LeadsNav">
                <div className="sa-leadsHead">

                    <span className={props.selectedTab == "School" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => props.setSelectedTab("School")} >School</span>



                    <span className={props.selectedTab == "Coaching" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => props.setSelectedTab("Coaching")} >Coachings</span>


                    <span className={props.selectedTab == "Educator" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => props.setSelectedTab("Educator")}  >Educator</span>

                </div>


                <div className="sa-leads">
                    <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ padding: "0", marginRight: "10px" }}>
                        <div className="sa-searchhh">
                            <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                            <input className="Super-admin-Search_Input" type="text"
                                placeholder="Search" style={{ outline: "0" }} />
                        </div>
                    </div>
                    </div>
                    <button style={{ border: "0", background: "none", cursor: "pointer" }}> <img src={dwld} style={{ height: "20px", width: "20px", marginRight: "5px" }} /></button>
                    <span >Export List</span>
                </div>
            </div>

        </>
    )
}
export default LeadNavBar