import React, { useEffect, useState } from "react";
import Delete from '../../../components/delete'
import api from '../../../api/api'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from '../../../utils'
import Moment from 'moment';
import Loader from '../../../components/loader'
import { useHistory } from 'react-router-dom';

function BranchesBox(props) {
    const history = useHistory()
    const [deletepopup, setdelete] = useState(false)
    const [id, setid] = useState("")
    const [MainBranch, setMainBranch] = useState("")
    const [loading, setLoading] = useState(false)
    const [sop, setSOP] = useState(false)

    const deleteInst = () => {
        if (MainBranch == null) {
            toast.error("This action cannot be performed kindly contact support team.")
            setdelete(false)
        } else {
            setLoading(true)
            let request = {
                url: `inst/${id}/delete?uid=${props.up_id}`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
            }
            api.delete(request).then(data => {
                setLoading(false)
                toast.success("Deleted successfully")
                props.dispatch({ type: 'BASIC_DETAILS', prof_id: MainBranch })
                setdelete(false)
                // props.fetchData()
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const ShowonProfile = (id, status) => {
        setSOP(sop => (!sop));
        setLoading(true)
        let request = {
            url: `inst/setSop`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                id: id,
                module: "institute",
                is_sop: status == true ? false : true
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Show On Profile Updated successfully")
            props.fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const setAsMainBranch = (id) => {
        setLoading(true)
        let request = {
            url: `inst/set-main-branch/${id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Set as main branch successfully.")
            props.fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    return (
        <>
            {loading && <Loader />}
            <div class="box-section1">
                <div class="flex">
                    <div class="branch-box">
                        <p>Total Branches</p>
                        <span>{props.rdata.length}</span>
                    </div>
                    <div class="branch-box">
                        <p>Active Branches</p>
                        <span>{props.rdata.length}</span>
                    </div>
                </div>
                <div><button class="blue_button" onClick={() => props.setForm(true)}>Add Branch</button></div>
            </div>
            <div class="branch-details">
                <p> 1. You can manage multiple Branches for your Institute</p>
                <p> 2. All the Branches will be listed as Individual Profiles on Proctur</p>
                <p> 3. You can Deactivate Any Branch by Using the Toggle Option available.</p>
                <p> After Deactivation that Branch will not visible on Proctur.</p>
            </div>
            <div class="box-educator branches">

                {
                    props.rdata.map((item,key) => {
                        
                        return (
                            <div key={key}><div class="display mb-8">
                                <div class="flex w-100-a">
                                    <div class="name-div">
                                    <p>{item.br_nm}</p>
                                        <span>Created Date:{Moment(item.u_date).format('DD-MM-YYYY')}</span>
                                    </div>
                                    <div class="option-div">

                                        <p>Show on Profile</p>
                                        <label class="switch1">
                                            <input type="checkbox" checked={item.is_show_prof == true ? true : false}
                                                onClick={() => ShowonProfile(item.br_id, item.is_show_prof)}
                                            />
                                            <span class="slider1 round"></span>
                                        </label>

                                    </div>
                                </div>
                            </div><div class="line-v-grey">
                                </div><div class="flex align-s">
                                    <div class="flex-a">
                                        <div class="flex-inline">
                                            <label class="checkbox">
                                                <span class="checkbox__input">
                                                    <input type="checkbox" name="checked" checked={item.is_main_br} onClick={() => setAsMainBranch(item.br_id)} />
                                                    <span class="checkbox__control">
                                                        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                                            <path fill="none" stroke="currentColor"
                                                                d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </label>
                                            <span class="branch">Set as main branch</span>
                                        </div>

                                    </div>
                                    <div class="ml-a">
                                        <button class="editbutton" onClick={() => {history.push(`/institute/profile/?type=${props.p_type==2?"school":"institute"}?id=${item.br_id}`,{state:'',new:false ,edit:true})}}> Edit</button>
                                        <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.br_id); setMainBranch(item.main_br_id) }}>Delete</button>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }

            </div>

            {deletepopup == true &&
                <Delete delete={deleteInst} setdelete={setdelete} />
            }

        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(BranchesBox)