import React, { useEffect  , useState} from "react";

const RoleSetting=(props)=>{
useEffect(()=>{


},[])

const setitem = (item) => {
    if (props.settings.indexOf(item.f_u_id) == -1) {
        props.setSettings([...props.settings, item.f_u_id])
      } else {
        props.setSettings(props.settings.filter(data => data != item.f_u_id))
      }    
}


return(<>
{
    props.item.f_li&& props.item.f_li.map((data , key)=>(
        <>
       
        <div key={key} class="flex-inline">
                     <label class="checkbox">
                        <span class="checkbox__input">
                            <input type="checkbox"  checked={props.settings.indexOf(data.f_u_id) == -1 ? false : true}  onClick={() => setitem(data)} />
                            <span class="checkbox__control">
                                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                    <path fill="none" stroke="currentColor"
                                        d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                                </svg>
                            </span>
                        </span>
                    </label>
                    <span>{data.name}</span>
                </div>
        </>
    ))
}
</>)
}
export default RoleSetting;


