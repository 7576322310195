import React from "react";

function FilterPopup(props) {
  return (
    <>
      <div class="modal-backdrop show">
      </div>
      <div class="modal" id="filterModal" style={{ paddingRight: '4px', display: 'block' }}>
        <div class="modal-dialog">
          <div class="modal-content">

            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <div>
                <h4 class="modal-title">Filter</h4>
                <p class="line_purple"></p>
              </div>
              <button type="button" class="close"onClick={()=>props.setFilter(false)}>&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div class="form-section">
                <div>
                  <label>Manage By</label>
                  <div class="select-wrapper">
                    <select class="expandale-input">
                      <option>Select</option>

                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            <div class="modal-footer">
              <button type="button" class="white_button mr16" data-dismiss="modal" onClick={()=>props.setFilter(false)}>Close</button>
              <button type="button" class="blue_button">Confirm</button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default FilterPopup