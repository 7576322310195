import React, { useState, useEffect } from "react";
import pic from '../../../assets/img/pic.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from '../../../api/api'
import { connect } from 'react-redux';
import { errorMsg } from "../../../utils";
import close from '../../../assets/img/close.svg'
import Loader from '../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';


function UserForm(props) {
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false)
    const [mobno, setMobno] = useState("")
    const [email, setEmail] = useState("")
    const [picture, setPicture] = useState("")
    const [image, setImage] = useState("")
    const [value, setValue] = useState("")
    const [roles, setRoles] = useState([])



    const onChange = (e) => {
        if (validateImage(e)) {
            setPicture(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]));
        }

    }

    const validateImage = (e) => {
        let img = e.target.files[0].size / 1024
        if (img > 500) {
            toast.error("Please upload image of size 500kb only")
            return false
        }
        else return true
    }

    useEffect(() => {
        if (props.selected != 0) {
            setLoading(true)
            let request = {
                url: `user/${props.mdata.id}/get`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.getCustom(request).then(data => {
                setLoading(false)
                let rdata = data.data.result;
                setName(rdata.name)
                setEmail(rdata.email)
                setMobno(rdata.phone)
                setPicture(rdata.p_url)
                setImage(rdata.p_url)
                setValue(rdata.desc)
                setRoles(rdata.roles ?? [])

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
        return name
    }, [props.selected])


    const savedetails = () => {
        if (validate()) {
            setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({
                name: name,
                phone: mobno,
                email: email,
                desc: value,
                roles: roles,
            }));
            fd.append('file', picture);
            let request = {
                url: `user/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
                data: fd

            }
            api.postCustom(request).then(data => {
                setLoading(false)
                toast.success("User Added successfully.")
                setTimeout(() => {
                    props.setselected(0);
                    props.setForm(false);
                    props.fetchData()
                }, [3000]);

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }


    const updateValue = () => {
        if (validate()) {
            setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({
                name: name,
                phone: mobno,
                email: email,
                desc: value,
                roles: roles,
                p_url: image
            }));
            fd.append('file', picture);

            let request = {
                url: `user/${props.mdata.id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
                data: fd
            }
            api.putOther(request).then(data => {
                setLoading(false)
                toast.success("User updated successfully.")
                setTimeout(() => {
                    props.setselected(0);
                    props.setForm(false);
                    props.fetchData()
                }, [3000]);
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }
    const validateEmail = (email) => {
        var reg =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!reg.test(email))
            toast.error("Please enter a valid email address");
    }

    const validateMobileNumber = (number) => {
        var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        if (!reg.test(number)) {
            toast.error("Please enter a valid 10 digit Number");
        }
    }
    const validateText = (name) => {
        var reg = /^[A-Z a-z]*$/
        if (!reg.test(name)) {
            return false;
        }
        return true;
    }

    const validate = () => {
        if (name == "") {
            toast.error("Please enter Full Name.")
            return false
        } if (mobno == "") {
            toast.error("Please enter Mobile Number.")
            return false
        } if (email == "") {
            toast.error("Please enter Email.")
            return false
        } if (picture == "") {
            toast.error("Please upload Profile Photo.")
            return false
        } if (roles == []) {
            toast.error("Please select Roles.")
            return false
        }
        else return true
    }


    return (
        <>
            {loading && <Loader />}
            <div class="form-section roles">
                <div>
                    {props.selected ?
                        <p class="heading">Edit User</p> : <p class="heading">Add User</p>}
                    <p class="line_purple"></p>
                    <label>Full Name<span>*</span></label>
                    <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => { if (validateText(e.target.value)) { setName(e.target.value) } }}></input>
                </div>
                {/* <!-- Multiple role can be assigned --> */}
                <div>

                    <label>Mobile Number<span>*</span></label>
                    <input contenteditable="true" class="expandale-input w-450" maxlength="10" placeholder="Enter" value={mobno}
                        onKeyPress={(e) => {
                            let regex = /^\d+$/;
                            if (((e.target.value === '' || regex.test(e.target.value)))) {
                                setMobno(e.target.value)
                            }
                        }}
                        onBlur={(e) => validateMobileNumber(e.target.value)}
                        onChange={(e) => {
                            let regex = /^\d+$/;
                            if (((e.target.value === '' || regex.test(e.target.value)))) {
                                setMobno(e.target.value)
                            }
                        }}

                    />

                </div>
                <div>
                    <label>Email<span>*</span></label>
                    <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" onBlur={(e) => validateEmail(e.target.value)} value={email} onChange={(e) => setEmail(e.target.value)} />

                </div>
                <div>
                    <label>Profile Photo<span>*</span></label>
                    <label htmlFor="photo-upload" >
                        <div   >{picture == "" ?
                            <img for="photo-upload" class="pro-pic" src={pic} /> :
                            <img for="photo-upload" class="pro-pic" src={image} />
                        }
                        </div>
                        <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
                            display: "none",
                            visibility: "hidden"
                        }} />
                    </label>

                    <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
                </div>
                <div>
                    <label>Roles</label>

                    <div class="select-wrapper w-450">
                        <select class="expandale-input w-450" value={roles} onChange={(e) => setRoles([...roles, e.target.value])}>
                            <option>Select</option>
                            {props.roles.map((item, key) => { { key = key } return (<option value={item.id}>  {item.name}</option>) })}
                        </select>
                    </div>

                    <div class="tags">
                        {props.roles.map((item, key) => roles.indexOf(item.id) !== -1 && <button>{item.name}<img src={close} onClick={() => setRoles(roles.filter((data) => data != item.id))} /></button>)}
                    </div>
                </div>
                <div>

                    <label>Description </label>

                    <ReactQuill theme="snow" value={value} onChange={setValue} />

                </div>
                <div class="button-section">
                    <div></div>
                    <div>
                        <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
                        {
                            props.selected ?
                                <button class="blue_button" onClick={updateValue}>Update</button> :
                                <button class="blue_button" onClick={savedetails}>Save</button>
                        }
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(UserForm)