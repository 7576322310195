import React, { useState, useEffect } from "react";
import Review from "./review";
import ReviewDetails from "./review_detais";
import Loader from '../../../components/loader'
import { connect } from "react-redux";
import { errorMsg } from "../../../utils";
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import ReviewPopup from "./reportPopup";

function Reviewmain(props) {
    const [form, setForm] = useState(false)
    const [popup, setpopup] = useState(false)
    const [rdata, setRdata] = useState([]);
    const [selected, setselected] = useState(0)
    const [loading, setLoading] = useState(true)
    let [mdata, setMdata] = useState({})
    const [operate, setOperate] = useState({
        search_text: "", ratings: "0", f_date: "2022-06-06", t_date: "2022-12-31"
    })



    useEffect(() => {
        fetchData();

    }, [])


    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "institute"}/review/all?${props.p_type == 3 ? "edu_id" : "inst_id"}=${props.prof_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.postCustom(request).then(data => {
            setLoading(false)
            console.log(data.data.result, "data.data.result.response")
            setRdata(data.data.result == "No Review Found" ? [] : data.data.result.response)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                // props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const Apply = () => {
        if (operate.search_text == "" && operate.ratings == "" && operate.f_date == "" && operate.t_date == "") {
            toast.error("Please apply any filter.")
        } else {
            setLoading(true)
            let request = {
                url: `${props.p_type == 3 ? "educator" : "institute"}/review/all?${props.p_type == 3 ? "edu_id" : "inst_id"}=${props.prof_id}`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: operate
            }
            api.postCustom(request).then(data => {
                setRdata(data.data.result?.response ?? [])
                setLoading(false)
                // setTotal(data.data.result.total_elements)
                toast.success("Filter applied successfully")
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })

        }
    }

    const getReviewbyId = (id) => {
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "institute"}/review/${id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setForm(true)
            setMdata(data.data.result)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const Report = () => {
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "institute"}/review/reported/${selected}?is_reported=true`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Reported successfully.")
            setpopup(false)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                // props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }



    return (
        <>
            {form == false ?
                <Review setForm={setForm} setpopup={setpopup} popup={popup} setselected={setselected} rdata={rdata} setMdata={setMdata}
                    getReviewbyId={getReviewbyId}
                    setReviewData={mdata} setOperate={setOperate} Apply={Apply} operate={operate}
                /> :
                <ReviewDetails setForm={setForm} setpopup={setpopup} setMdata={setMdata} mdata={mdata} setselected={setselected} />
            }



            {popup == true &&
                <ReviewPopup setpopup={setpopup} Report={Report} setselected={setselected} />
            }


            {loading && <Loader />}

            <ToastContainer />
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(Reviewmain)