import React, { useState } from "react";
import Header from './header'
import Content from './content'
import ProfileHeader from "../profile_header";



function Missl(props) {
  const [tab, setTab] = useState("Addmission")
  // const [form, setForm] = useState(false)
  const [selected, setselected] = useState(0)
  const [showform, setshowform] = useState(false)
  let type = window.location.href.split("?")[1].split("=")[1]
  return (<>
  <ProfileHeader  count={props.count} setCount={props.setCount}
                    setButton={props.setButton}
                    setEcount={props.setEcount}
                    setEdit={props.setEdit}
                    showform={showform}
                    />

                    
    <Header type={type} tab={tab} setTab={setTab} setForm={setshowform} setselected={setselected} />
    <Content type={type} tab={tab} form={showform} setForm={setshowform} selected={selected} setselected={setselected} />
  </>)


}

export default Missl