import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { connect } from 'react-redux'
import close from '../../../assets/img/close.svg'
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from '../../../utils'


function Operate(props) {
    const [title, setTitle] = useState("")
    const [etitle, seteTitle] = useState("")
    const [change, setChange] = useState("")
    const [cchange, setcChange] = useState("")
    const [image, setimage] = useState("")
    const [photo, setphoto] = useState("")
    const [subject, setSubject] = useState("Standard")
    const [position, setPosition] = useState("")
    const [eposition, setePosition] = useState("")
    const [dropdown, setDropdown] = useState([])
    const [dropdown2, setDropdown2] = useState([])
    const [isStand, setIsStand] = useState(true)


    useEffect(() => {
        getCourse();
        getStandard();
        if (props.selected != 0) {

            if (props.tab == "S") {
                setSubject("Standard")
                setTitle(props.data.name)
                // setePosition(props.data.position)
                setChange(props.data.master_standard_id)
                setimage(props.data.file_url)
                setphoto(props.data.file_url)
            } else {
                setSubject("Category/Courses")
                setcChange(props.data2.mast_course_id)
                setTitle(props.data2.name)
                setePosition(props.data2.position)
                setimage(props.data2.file_url)
                setphoto(props.data2.file_url)
            }


        }
    }, [props.selected])



    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, stnd_id: change, is_stnd: subject == 'Standard' ? true : false, pos: eposition, c_id: cchange }));
            fd.append('file', photo);
            let request = {
                url: `master-sub/${subject == 'Standard' ? props.data.entity_id : props.data2.entity_id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Subject updated successfully")
                setTitle("");
                seteTitle("");
                setimage("");
                setPosition("")
                setChange("");
                setcChange("");
                props.setSelected(0);
                props.fetchData();

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, stnd_id: change, is_stnd: subject == 'Standard' ? true : false, pos: position, c_id: cchange }));
            fd.append('file', photo);

            let request = {
                url: `master-sub/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                toast.success("Subject added successfully")
                props.setLoading(false)
                setTitle("");
                seteTitle("");
                setChange("");
                setPosition("")
                setimage("");
                setcChange("")
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const onCancel = () => {
        setTitle("");
        seteTitle("");
        setChange("");
        setPosition("")
        setimage("");
        setcChange("")

    }

    const getCourse = () => {
        let request = {
            url: `course/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setDropdown(data.data.result)
        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const getStandard = () => {
        let request = {
            url: `master-stand/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setDropdown2(data.data.result)
        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Subject")
            return false
        }
        if (photo === '') {
            toast.error("Please Upload Icon")
            return false
        }
        return true
    }

    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }



    return (
        <>
            <div className="Sa-AddProfile">
                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Subject</p> : <p style={{ fontSize: "15px" }}>Add Subject</p>
                }
                <div className="Super-admin-line_purple"></div>
                <div className="Sa-radiobutoon" style={{ display: "flex", flexDirection: "row" }}>
                    {[{ type: "Standard", id: 1 }, { type: "Category/Courses", id: 2 }].map((choice, index, selected) => {
                        return (<div>
                            <input type="radio" class="form-radio" style={{ marginLeft: "10px" }}
                                name="vote"
                                checked={subject === choice.type}
                                value={choice.type}
                                key={index}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                    choice.type == "Standard" ?
                                        setIsStand(true) :
                                        setIsStand(false)
                                }
                                }
                            />

                            <span>{choice.type}</span>
                        </div>
                        )
                    })
                    }
                </div>
                {subject == "Standard" &&
                    <div className="Sa-dropdown">
                        <section className="sa-wrapper2">
                            <select name="Master Category" id="Master Category" className="Sa-input" value={change} onChange={(e) => setChange(e.target.value)} style={{ fontSize: "13px" }} >
                                <option value="">Select Standard</option>
                                {dropdown2.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                            </select>
                        </section>
                    </div>
                }
                {
                    subject == "Category/Courses" &&
                    <div className="Sa-dropdown">
                        <section className="sa-wrapper2">
                            <select name="Master Category" id="Master Category" className="Sa-input" value={cchange} onChange={(e) => setcChange(e.target.value)} style={{ fontSize: "13px" }} >
                                <option value="">Select Category</option>
                                {dropdown.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                            </select>
                        </section>
                    </div>

                }

                <div className="Sa-Inputbox">
                    <div>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Subject " style={{ fontSize: "13px" }} />
                    </div>
                    <div >
                        <input type="file" contenteditable="true" placeholder='Upload Icon*' onChange={select_file} style={{ fontSize: "13px", borderBottom: "1px solid #939393", width: '70%' }} />
                        {
                            image != "" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close} onClick={() => { setimage("") }} />
                                </div>
                            </div>

                        }
                    </div>
                </div>


                <div >

                </div>


                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); onCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { onCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }
                <ToastContainer />

            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)