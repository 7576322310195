import Home from '../modules/home/home'
import Dashboard from '../modules/dashboard/dash'
import Profile from '../modules/superAdmin_Master/profile_Type/'
import Ownership from '../modules/superAdmin_Master/ownership_type/'
import EducationBoard from '../modules/superAdmin_Master/education_Board/'
import MasterCategory from '../modules/superAdmin_Master/master_category/'
import Standard from '../modules/superAdmin_Master/standard/'
import ModeOfTeaching from '../modules/superAdmin_Master/mode_of_teaching/'
import Facilities from '../modules/superAdmin_Master/facilities/'
import ExtraCurricular from '../modules/superAdmin_Master/extra_curricular_acti/'
import CoEducation from '../modules/superAdmin_Master/co-education'
import MediumOfTeaching from '../modules/superAdmin_Master/medium_of_teaching/'
import EducationLevel from '../modules/superAdmin_Master/education_level/'
import Gender from '../modules/superAdmin_Master/gender/'
import Title from '../modules/superAdmin_Master/title/'
import FeeType from '../modules/superAdmin_Master/fee_type/'
import SchoolType from '../modules/superAdmin_Master/school_type/'
import Category from '../modules/superAdmin_Master/category_course/'
import Subject from '../modules/superAdmin_Master/subject/'
import Leads from '../modules/leads/index'
import MasterSidebar from "../components/sidebar/masterSidebar"
import LeadSchool from '../modules/leads/school'
import Reports from '../modules/reports/reports'
import RolesUser from '../modules/rolesnuser/index'
import CustomNotification from '../modules/customNotification/custm_noti'
import Enquires from '../modules/enquires/index'
import Institute from '../modules/institutes'
import ProfileInstitute from "../modules/coaching/profile/profile";
import Educator from '../modules/educator'
import ProfileSidebar from '../components/sidebar/profileMsidebar'
import EducatorProfile from '../modules/addEducators/profile'
import WhatsNew from '../modules/whatsNew/whats_new'
import SubProfile from '../modules/superAdmin_Master/sub_Profiletype'
import SchoolData from '../modules/student'
import CampusType from '../modules/superAdmin_Master/campus_type'
import login from '../modules/login/login'
import ReviewRating from '../modules/reviewRating.js'
import Departmnet from '../modules/superAdmin_Master/department'
import Stream from '../modules/superAdmin_Master/stream'
import university from '../modules/superAdmin_Master/university'
import CollegeType from '../modules/superAdmin_Master/college_type'


const superAdminRoutes = [
    { path: '/home/', exact: true, strict: true, component: Home },
    { path: '/dashboard/', exact: true, strict: true, component: Dashboard },
    { path: '/profilemanagement/profile/', exact: true, strict: true, component: Profile },
    { path: '/profilemanagement/subprofile/', exact: true, strict: true, component: SubProfile },
    { path: '/superadmin/masters/ownership/', exact: true, strict: true, component: Ownership },
    { path: '/superadmin/masters/educationboard/', exact: true, strict: true, component: EducationBoard },
    { path: '/profilemanagement/mastercategory/', exact: true, strict: true, component: MasterCategory },
    { path: '/profilemanagement/standard/', exact: true, strict: true, component: Standard },
    { path: '/superadmin/masters/modeofteaching/', exact: true, strict: true, component: ModeOfTeaching },
    { path: '/superadmin/masters/facilities/', exact: true, strict: true, component: Facilities },
    { path: '/superadmin/masters/extracurricular/', exact: true, strict: true, component: ExtraCurricular },
    { path: '/superadmin/masters/coeducation/', exact: true, strict: true, component: CoEducation },
    { path: '/superadmin/masters/mediumofinstructions/', exact: true, strict: true, component: MediumOfTeaching },
    { path: '/superadmin/masters/educationlevel/', exact: true, strict: true, component: EducationLevel },
    { path: '/superadmin/masters/gender/', exact: true, strict: true, component: Gender },
    { path: '/superadmin/masters/title/', exact: true, strict: true, component: Title },
    { path: '/superadmin/masters/feetype/', exact: true, strict: true, component: FeeType },
    { path: '/superadmin/masters/schooltype/', exact: true, strict: true, component: SchoolType },
    { path: '/superadmin/masters/campustype/', exact: true, strict: true, component: CampusType },
    { path: '/superadmin/masters/university/', exact: true, strict: true, component: university },
    { path: '/superadmin/masters/collegetype/', exact: true, strict: true, component: CollegeType },
    { path: '/profilemanagement/category/', exact: true, strict: true, component: Category },
    { path: '/profilemanagement/subject/', exact: true, strict: true, component: Subject },
    { path: '/profilemanagement/department/', exact: true, strict: true, component: Departmnet },
    { path: '/profilemanagement/stream/', exact: true, strict: true, component: Stream },
    { path: '/leads/', exact: true, strict: true, component: Leads },
    { path: '/superadmin/leads/school/', exact: true, strict: true, component: LeadSchool },
    { path: '/master/', exact: true, strict: true, component: MasterSidebar },
    { path: '/profileManagement/', exact: true, strict: true, component: ProfileSidebar },
    { path: '/school/', exact: true, strict: true, component: SchoolData },
    { path: '/reports/', exact: true, strict: true, component: Reports },
    { path: '/roles/', exact: true, strict: true, component: RolesUser },
    { path: '/customNotification/', exact: true, strict: true, component: CustomNotification },
    { path: '/enquires/', exact: true, strict: true, component: Enquires },
    { path: '/institute/', exact: true, strict: true, component: Institute },
    { path: '/institute/profile/', exact: true, strict: true, component: ProfileInstitute },
    { path: '/educator/', exact: true, strict: true, component: Educator },
    { path: '/educator/profile/', exact: true, strict: true, component: EducatorProfile },
    { path: '/whatsnew/', exact: true, strict: true, component: WhatsNew },
    { path: '/reviewRating/', exact: true, strict: true, component: ReviewRating }

]



export { superAdminRoutes };