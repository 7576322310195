import React, { useState } from "react";
import add from '../../../assets/img/add.svg'
import close from '../../../assets/img/close.svg'
import circular from '../../../assets/img/circular.png'
import globe from '../../../assets/img/basic/globe.png'
import yt from '../../../assets/img/yt.svg'
import linkk from '../../../assets/img/linkedin.svg'
import fb from '../../../assets/img/fb.svg'
import insta from '../../../assets/img/basic/insta.png'
import tw from '../../../assets/img/tw.svg'
import { ToastContainer, toast } from 'react-toastify';


export default function Media(props) {
  const [link, setlink] = useState("")



  const getImageThumbnail = () => {
    if (link.indexOf("http") == -1) {
      alert("Invalid")
    } else if ([...props.getvideolink, ...props.media.videoLink].length > 2) {
      toast.error("You are only allowed to upload a maximum of 3 video link.")
    }
    else {
      let videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        props.setMedia({
          ...props.media, videoLink: [...props.media.videoLink, {
            preview: `https://img.youtube.com/vi/${videoid[1]}/0.jpg`,
            link
          }]
        })
      } else {
        props.setMedia({
          ...props.media, videoLink: [...props.media.videoLink, {
            preview: circular,
            link
          }]
        })
      }
      setlink("")
    }
  }

  const YouTubeGetID = (url) => {
    var ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    return ID;
  }


  return (

    <><div>
      <div>
        <label>Video Link </label>
        <p class="data-info">You can enter YouTube, Vimeo or any other video links</p>
        <div class="flex-p1">
          <input class="expandale-input w-450 choose" contenteditable="true" placeholder="Add Link" value={link} onChange={(e) => setlink(e.target.value)} />
          <img src={add} style={{ cursor: "pointer" }} onClick={getImageThumbnail} />
        </div>
        <div class="tags-img">
          {props.getvideolink.map((item, key) => {
           
            return (
              <div key = {key}>
                <img src={`https://img.youtube.com/vi/${YouTubeGetID(item)}/0.jpg`} onClick={() => { window.open(item) }} />
                <img src={close} onClick={(index) => props.setGetvideolink(props.getvideolink.filter(item2 => item2 != item))} />
              </div>
            )
          })
          }
          {props.media.videoLink.map((item, key) => {
          
            return (
              <div key = {key}>
                <img src={item.preview} onClick={() => { window.open(item.link); }} />
                <img src={close} onClick={(index) => props.setMedia({ ...props.media, videoLink: (props.media.videoLink.filter(item2 => item2.preview + item2.lastModified != item.preview + item.lastModified)) })} />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div style={{ marginTop: "20px" }}></div>
        <label>Personal Social Media Links</label>
        <p class="line_purple"></p>
        <div class="flex-p1 m-24">
          <img src={globe} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Website Link" value={props.media.website} onChange={(e) => props.setMedia({ ...props.media, website: e.target.value })} />
        </div>
        <div class="flex-p1 m-24">
          <img src={yt} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Youtube Link" value={props.media.yt} onChange={(e) => props.setMedia({ ...props.media, yt: e.target.value })} />
        </div>

        <div class="flex-p1 m-24">
          <img src={linkk} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Linkedin Link" value={props.media.li} onChange={(e) => props.setMedia({ ...props.media, li: e.target.value })} />
        </div>
        <div class="flex-p1 m-24">
          <img src={fb} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Facebook Link" value={props.media.fb} onChange={(e) => props.setMedia({ ...props.media, fb: e.target.value })} />
        </div>

        <div class="flex-p1 m-24">
          <img src={insta} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Instagram Link" value={props.media.insta} onChange={(e) => props.setMedia({ ...props.media, insta: e.target.value })} />

        </div>
        <div class="flex-p1">
          <img src={tw} style={{ height: "25px", width: "25px" }} />
          <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Twitter Link" value={props.media.tw} onChange={(e) => props.setMedia({ ...props.media, tw: e.target.value })} />
        </div>
      </div>

    </div><div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end' }}>
        <button class="white_button mr16" onClick={()=>props.Cancel()}>Cancel</button>
        <button class="blue_button" onClick={() => props.savedetails()}>Save</button>
      </div></>


  )

}