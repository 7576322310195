import React, { useEffect, useState } from "react";
import searchh from '../../assets/home/search_icon.png'


function Operate(props) {
   
    return (<>
        <div className="sa-leadsSchool2">
          
            <div className="Sa-dropdown3">
                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" ,marginLeft: "20px" }}>From date:</div>
                <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} onChange={(e)=>props.setOperate({...props.operate,fromdate:e.target.value})}  />
            </div>
            <div className="Sa-dropdown3">
                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} onChange={(e)=>props.setOperate({...props.operate,todate:e.target.value})} />
            </div>
           

            <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{padding:"0",marginRight:"10px",marginTop:"12px"}}>
                <div className="sa-searchhh">
                    <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                    <input className="Super-admin-Search_Input" type="text"
                        placeholder="Search" style={{ outline: "0" }} value={props.operate.search} onChange={(e)=>props.setOperate({...props.operate, search:e.target.value})} />
                </div>
            </div>
            </div>


            <div className="sa-SeachButton">
            <button className="Super-admin-save-button" onClick={props.Apply}>Apply</button>
            <button className="Super-admin-save-button" onClick={props.reset} >Reset</button>
            </div>


        </div>
    </>)
}

export default Operate