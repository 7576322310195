import React, { useState, useEffect } from "react";
import BranchesBox from "./branches_box";
import BranchesForm from "./branches_form";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';


function Branches(props) {
   const [form, setForm] = useState(false)
   const [loading, setLoading] = useState(false)
   const [rdata, setRdata] = useState([]);
   const [selected, setselected] = useState(0)
   const [mdata, setMdata] = useState({})
   const [data, setData] = useState("")
   const [id, setid] = useState("")




   useEffect(() => {
      fetchData();
   }, [])




   const fetchData = () => {
      if (props.prof_id == null) {
         toast.error("Please add Basic Details first ")
      } else {
         setLoading(true)
         let request = {
            url: `inst/${props.prof_id}/all-branches`,
            headers: {
               'Authorization': `Bearer ${props.loginToken}`
            },
         }
         api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)

         }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
               props.dispatch({ type: 'LOGOUT' })
            } else {
               toast.error(errorMsg(err))
            }
         })
      }
   }



   return (
      <>
         {loading && <Loader />}
         {form == false ?
            <BranchesBox setForm={setForm} setid={setid} id={id} setData={setData} fetchData={fetchData} data={data} rdata={rdata} setselected={setselected} setMdata={setMdata} /> :
            <BranchesForm setForm={setForm} setid={setid} id={id} selected={selected} fetchData={fetchData} setData={setData} data={data} p_type={props.p_type}
               mdata={mdata} rdata={rdata} />
         }
         <ToastContainer />

      </>
   )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(Branches)

