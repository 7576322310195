import React from "react";
import unlink from '../assets/img/unlink.svg'

function DeletePopup(props) {




    return (
        <>
            <div class="modal-backdrop show">
                
            </div>
            <div className="modal show" id="deleteModal" style={{paddingRight: '4px', display: 'block'}}>
                    <div class="modal-dialog">
                        <div class="modal-content ">
                            <div class="modal-body center-align">
                                <embed src={unlink} />
                                <p class="red-para">Are you sure you want to delete?</p>
                            </div>

                            <div class="modal-footer">
                                <button type="button" onClick={() => {props.setdelete(false)}} class="white_button mr16">Close</button>
                                <button type="button" onClick={() => {props.delete()}} class="blue_button">Delete</button>
                            </div>
                        </div>

                    </div>
                </div>
        </>
    )
}

export default DeletePopup