import React, { useState, useEffect } from "react";
import Delete from '../../../../components/delete'
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import StandardForm from './standard_forms'
import { connect } from "react-redux";
import config from '../../../../config'
import { errorMsg } from '../../../../utils'
import masters from "../../../../masters";
import ProfileHeader from "../profile_header";
import StandardPopup from "../popups/standardpopup";
import duplicate from '../../../../assets/img/duplicate.png'


const mdata = masters[config.env]

function StandardBlocks(props) {
  let type = window.location.href.split("?")[1].split("=")[1]
  const [deletepopup, setdelete] = useState(false)
  const [loading, setLoading] = useState(true)
  const [rdata, setRdata] = useState([]);
  const [cdata, setCdata] = useState([]);
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [id, setid] = useState("")
  const [popup, setpopup] = useState(false)



  useEffect(() => {
    if (type == "school") {
      fetchData();
    } else {
      fetchCources();
    }


  }, [])


  const fetchData = () => {
    if (props.prof_id == "") {
      toast.error("Please Add Basic Details First.")
      setLoading(false)
    } else {
      setLoading(true)
      let request = {
        url: `inst-standard/all?inst_id=${props.prof_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  // console.log(props.prof_id ,"prof_iddkfk")


  const fetchCources = () => {
    if (props.prof_id == "") {
      toast.error("Please Add Basic Details First.")
      setLoading(false)
    } else {
      setLoading(true)
      let request = {
        url: `inst-course/all?inst_id=${props.prof_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setCdata(data.data.result)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  // console.log(sop, "sop")

  const deleteStandatrd = () => {
    setdelete(false)
    setLoading(true)
    if (type == "school") {
      let request = {
        url: `inst-standard/${id}/delete`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.delete(request).then(data => {
        toast.success("Standards Deleted successfully")
        // setRdata(props.rdata.filter((item3) => item3.entity_id != id))
        fetchData()
        setid("")
      }).catch((err) => {
        setid("")
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
    else {
      let request = {
        url: `inst-course/${id}/delete`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.delete(request).then(data => {
        toast.success("Courses Deleted successfully")
        //  setCdata(props.rdata.filter((item3) => item3.id != id))
        fetchCources()
        setid("")
      }).catch((err) => {
        setid("")
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }


  const ShowonProfile = (id, status, module) => {
    setLoading(true)
    let request = {
      url: `inst/setSop`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: {
        id: id,
        module: module,
        "is_sop": status == true ? false : true
      }
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success("Show On Profile Updated successfully")
      fetchData()
      fetchCources()
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })

  }

  return (
    <>
      {loading && <Loader />}

      <ProfileHeader count={props.count} setCount={props.setCount}
        setButton={props.setButton}
        setEcount={props.setEcount}
        setEdit={props.setEdit}
        showform={showform}
      />
      {
        showform == false && type == "school" ?
          <div>
            <div class="box-section1">
              <div>Standards</div>
              <div><button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add Standard</button></div>
            </div>
            <div class="box-section">
              {rdata.map((item) => {
                return (
                  <div>
                    <div class="display">
                      <div>
                        <label>Standard</label>
                        <p style={{width:'auto'}}>{item.mast_std_name}</p>
                      </div>
                      <div>
                        <label>Total&nbsp;Seats</label>
                        <p>{item.total_seats}</p>
                      </div>
                    </div>
                    <div class="line-sep"></div>
                    <div class="display">
                      <div class="flex-a">
                        <span>Show on Profile</span>
                        <label class="switch1">
                          <input type="checkbox" checked={item.show_on_profile == true ? true : false}
                            onClick={() => ShowonProfile(item.entity_id, item.show_on_profile, "standard")}
                          />
                          <span class="slider1 round"></span>
                        </label>
                      </div>
                      <div class="flex">
                        <button class="editbutton" onClick={() => {setpopup(true);setSelected(item.entity_id) }} ><img src={duplicate} title='Duplicate' /></button>
                        <button class="editbutton" onClick={() => { setSelected(item.entity_id); setshowform(true); }} > Edit</button>
                        <button class="deletebutton" onClick={() => { setdelete(true); setid(item.entity_id) }}> Delete</button>
                      </div>
                    </div>

                  </div>
                )
              })
              }
            </div>
          </div>
          : showform == false && <div>
            <div class="box-section1">
              <div>Course</div>
              <div><button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add Course</button></div>
            </div>
            <div class="box-section">

              {cdata.map((item) => {
                return (
                  <div>
                    <div class="display">
                      <div>
                        <label>Courses</label>
                        <p>{item.course_name}</p>
                      </div>
                      <div>
                        <label>Total&nbsp;Seats</label>
                        <p>{item.total_seats}</p>
                      </div>
                    </div>
                    <div class="line-sep"></div>
                    <div class="display">
                      <div class="flex-a">
                        <span>Show on Profile</span>

                        <label class="switch1">
                          <input type="checkbox" checked={item.show_on_profile == true ? true : false}
                            onClick={() => ShowonProfile(item.entity_id, item.show_on_profile, "courses")}
                          />
                          <span class="slider1 round"></span>
                        </label>
                      </div>
                      <div class="flex">
                      <button class="editbutton" onClick={() => {setpopup(true);setSelected(item.entity_id) }} ><img src={duplicate} title='Duplicate' /></button>
                        <button class="editbutton" onClick={() => { setSelected(item.entity_id); setshowform(true); }} >Edit</button>
                        <button class="deletebutton" onClick={() => { setdelete(true); setid(item.entity_id) }}> Delete</button>
                      </div>
                    </div>

                  </div>
                )
              })
              }
            </div>
          </div>
      }

      {deletepopup == true &&
        <Delete delete={deleteStandatrd} setdelete={setdelete} />
      }


      {
        showform == true &&
        <StandardForm setSelected={setSelected} selected={selected} data={rdata.find(data => data.entity_id == selected)}
          showform={showform} setshowform={setshowform} rdata={rdata} cdata={cdata.find(data => data.entity_id == selected)}
          fetchData={fetchData} fetchCources={fetchCources}
        />
      }
      {popup == true &&
        <StandardPopup setpopup={setpopup} setSelected={setSelected} selected={selected} fetchData={fetchData} fetchCources={fetchCources}/>
      }
    </>
  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(StandardBlocks)

