import React from "react";
import '../../css/superAdmin/mastersidebar.css'
import List from "./list";



function ProfileSidebar() {

  const list = [
    {
      link: '/profilemanagement/profile/',
      text: 'Profile Type',

    },
    {
      link: '/profilemanagement/subprofile/',
      text: 'Sub Profile Type',

    },
    {
      link: '/profilemanagement/mastercategory/',
      text: 'Master Category',

    },{
      link: '/profilemanagement/department/',
      text: 'Department',
    },
    {
      link: '/profilemanagement/stream/',
      text: 'Stream',
    },
    {
      link: '/profilemanagement/category/',
      text: 'Category/Course',

    },
    {
      link: '/profilemanagement/standard/',
      text: 'Standard',

    },
    {
      link: '/profilemanagement/subject/',
      text: 'Subjects',
    },
  ]
  return (
    <>
       <div className="sa-mastersidebar">
        <ul>
            {list.map((objLink, i) => <List name={objLink.text} url={objLink.link} />)}
            </ul>
          </div>
    </>
  )
}

export default ProfileSidebar
