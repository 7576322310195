import React, { useState, useEffect } from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from "react-redux";
import config from '../../../config'
import { errorMsg } from '../../../utils'
import masters from "../../../masters";


const mdata = masters[config.env]



export function LinkForm(props) {
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [Ownership, setOwnership] = useState([])
  const [EduBoard, setEduBoard] = useState([])
  const [Institute, setInstitute] = useState([])
  const [value, setValue] = useState('');



  const [Details, setDetails] = useState({ ownershipTyp: "", eduBoard: "", institute: "", state: "", city: "", country: "" })

  useEffect(() => {
    getCountry();
    profiletype();
    if (props.selected != 0) {
      // seteTitle(props.data.Document)
    }
    return
  }, [props.selected])

  const profiletype = () => {
    let ownershipTyp = mdata.find(data => data.name == "Ownership Type")?.entity_id;
    let eduBoard = mdata.find(data => data.name == "Education Board")?.entity_id;
    let request = {
      url: `master/all/sub-master/?mast_id_list=${ownershipTyp},${eduBoard}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setOwnership(data.data.result[ownershipTyp])
      setEduBoard(data.data.result[eduBoard])
    }).catch((err) => {
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }

  const getCountry = () => {
    setLoading(true)
    let request = {
      url: `country/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setCountry(data.data.result)

    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }



  const getState = (val) => {
    setDetails(details => ({ ...details, country: val }));
    setLoading(true)
    let request = {
      url: `country/${val}/stCtAr/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setState(data.data.result)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }


  const getCity = (val) => {
    setDetails({ ...Details, state: val });
    let ct = state.find(data => data.st_id == val);
    setCity(ct.ct_map)
  }



  const savedetails = () => {
    if (Details.institute == "") {
      toast.error("Please select Institute to link.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/link/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          e_id: props.eduId,
          i_p_id: Details.institute,
          msg: value

        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        props.setshowform(false)
        props.setSelected(0)
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }



  const GetInstitute = (eduaction) => {
    if (validate()) {
      setLoading(true)
      let request = {
        url: `inst/namesByCriteria`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          own_type: Details.ownershipTyp,
          edu_board: Details.eduBoard,
          st_id: Details.state,
          ct_id: Details.city,
          c_id: Details.country,

        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        setInstitute(data.data.result ?? [])
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const validate = () => {
    if (Details.country == "") {
      toast.error("Please select Country.")
      return false
    } if (Details.state == "") {
      toast.error("Please select State.")
      return false
    } if (Details.city == "") {
      toast.error("Please select City.")
      return false
    }
    return true
  }

  const cancel = () => {
    setDetails({ ...Details, ownershipTyp: "", eduBoard: "", institute: "", state: "", city: "", country: "", value: "" })

  }


  return (<>
    {loading && <Loader />}
    <div class="form-section ">
      <div>
        <p class="heading">How linking the profile works?</p>
        <p class="line_purple"></p>
        <label>1. If you want to display your profile in Institute or School as well then please select your
          Institute or School and Submit the Linking Request.
        </label>
        <label> 2 After approval of the concerned authority from Institute or School your profile will be
          added in their Profile.
        </label>
        <label>
          3 All your requests and their status can be viewed below
        </label>
      </div>

      <div>
        <div class="display">
          <div>
            <p class="heading">Please select Institute to link your profile</p>
            <p class="line_purple"></p>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "20%" }}>
            <button class="blue_button" onClick={GetInstitute}>Apply</button>
            <button class="red_button" onClick={cancel}>Clear All</button>
          </div>
        </div>

        <div class="display f-wrap mt-16">
          <div>
            <label>Country<span>*</span></label>

            <div class="select-wrapper">
              <select class="expandale-input w-200" value={Details.country} onChange={(e) => { getState(e.target.value); }}>
                <option value="" disabled="" selected="" hidden="">Select </option>
                {country.map((item,key) => { {key=key} return (<> <option value={item.id}>{item.c_nm}</option> </>) })}
              </select>
            </div>
          </div>
          <div>
            <label>State<span>*</span></label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={Details.state} onChange={(e) => { getCity(e.target.value) }}>
                <option value="">Select</option>
                {state.map((item,key) => {  {key=key} return (<option value={item.st_id}>{item.st_nm}</option>) })}
              </select>
            </div>
          </div>
          <div>
            <label>City<span>*</span> </label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={Details.city} onChange={(e) => setDetails({ ...Details, city: e.target.value })}>
                <option value="">Select</option>
                {city.map((item,key) => { {key=key} return (<option value={item.ct_id}>{item.ct_nm}</option>) })}
              </select>
            </div>
          </div>
          <div>
            <label>Ownership Type</label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={Details.ownershipTyp} onChange={(e) => setDetails({ ...Details, ownershipTyp: e.target.value })}>
                <option value="">Select</option>
                {Ownership.map((item,key) => { {key=key} return (<option value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>

          <div>
            <label>Education Board</label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={Details.eduBoard} onChange={(e) => { setDetails({ ...Details, eduBoard: e.target.value }) }}>
                <option value="">Select</option>
                {EduBoard.map((item,key) => {return (<option key = {key} value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div>
          <label>Select Name of Institute or ID <span>*</span></label>
          <div class="select-wrapper w-450">
            <select class="expandale-input w-450" value={Details.institute} onChange={(e) => setDetails({ ...Details, institute: e.target.value })}>
              <option value="">Select</option>
              {Institute.map((item,key) => { return (<option key = {key} value={item.id}>{item.inst_name}</option>) })}
            </select>
          </div>
        </div>
        <div class="mt-16">
          <label>Your Message to Institute (optional)</label>
          <ReactQuill theme="snow" value={value} onChange={setValue} />
        </div>
      </div>
      <div class="button-section">
        <div></div>
        <div>
          <button class="white_button mr16" onClick={() => props.setshowform(false)}>Cancel</button>
          <button class="blue_button" onClick={() =>
            {
              if(props.eduId == "" ){
                toast.error("Please add Basic Details first.")
              }else{
                savedetails()
              }
            }
            }>Submit Request</button>
        </div>
      </div>
    </div>
    <ToastContainer />
  </>)
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(LinkForm)