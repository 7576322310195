import React, {useState, useEffect} from 'react'
import MasterSearch from '../../../components/masterSearch'
import Loader from '../../../components/loader'
import Data from './data'
import Operate from '../../enquires/operate'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';


function EnquiresCoaching(props) {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)

   
    return (
        <>
            {/* {loading && <Loader />} */}
            <div className="Sa-Profile2">
               
 
                <Data data={props.rdata} setSelected={setSelected}  />
        
            </div>




        </>
    );
}

export default EnquiresCoaching;