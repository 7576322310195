import React from 'react'


function Data(props) {
    return (
        <>
            <div style={{ border: '1px solid white' }}>
                <table id="super-admin">
                    <tr>
                        <th>SR NO</th>
                        {/* <th>Position</th> */}
                        <th>Profile Type</th>
                        <th>Icon</th>
                        <th>Action</th>
                    </tr>
                    {
                        props.data.map((data, key) => (data.name.toLowerCase().indexOf(props.search.toLowerCase()) != -1) && <tr key={key}>
                            <td>{key + 1}</td>
                            {/* <td>{data.position}</td> */}
                            <td>{data.name}</td>
                            <td> <img src={data.file_url} style={{ width: "20px", height: "20px" }} /></td>
                            <td><button onClick={() => { props.setSelected(data.entity_id); window.scrollTo(0, 0) }} className="Super-admin-edit-button">EDIT</button></td>
                        </tr>)
                    }



                </table>


            </div>





        </>
    );
}

export default Data;