import React, { useState } from 'react'
import help from '../../../assets/login/signin/help.png'
import arrow from '../../../assets/login/signin/arrow.png'
import india from '../../../assets/login/signin/india.png'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from 'react-redux'
import loader2 from '../../../assets/login/signin/loader/loader2.gif'
import style from '../../../css/style.css'
import website from '../../../css/website.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let typeMaster = [{ id: 9, name: 'K1-5' }, { id: 10, name: 'K5-6' }, { id: 11, name: 'K6-8' }, { id: 12, name: 'K9-10' },
{ id: 13, name: 'Management' }, { id: 14, name: 'Competitive/NEET' }, { id: 15, name: 'CA/CS/Accounts' },
{ id: 16, name: 'Banking' }, { id: 17, name: 'Others' }]

function Signin(props) {
    let [name, setName] = useState("")
    let [institiute, setInstitute] = useState("")
    let [email, setEmail] = useState("")
    let [itype, setItype] = useState(0)
    let [type, setType] = useState([11, 12]);
    let [terms, setTerms] = useState(false)
    let [loading, setLoading] = useState(false)


    const onSubmit = () => {
        if (institiute.length == "") {
            toast.error("Please provide valid institute name.");
        } else if (email.length == "") {
            toast.error("Please provide valid email.");
        } else if (terms == false) {
            toast.error("Please agree to terms & conditions.");
        } else {
            setLoading(true);
            const ldata = JSON.parse(localStorage.getItem('login'));
            api.post({
                url: 'user/register',
                data: { "name": institiute, "phone": ldata.number, "email": email, "user_type": ldata.user_type }
            }).then((data) => {
                localStorage.removeItem("login")
                localStorage.removeItem("newuserdata")
                localStorage.setItem("userdata", JSON.stringify(data.data.result));
                localStorage.setItem("token", (data.data.result.token));
                localStorage.setItem("name", (data.data.result.name));
                localStorage.setItem("email", (data.data.result.email));
                localStorage.setItem("phone", (data.data.result.phone));
                localStorage.setItem("id", (data.data.result.up_id));
                localStorage.setItem("p_type", (data.data.result.p_type));
                localStorage.setItem("prof_id", data.data.result.c_id);
                toast.success("Registration successful");
                props.dispatch({
                    type: 'LOGIN_SUCCESS',
                    datalogin: data.data.result,
                    usertype: data.data.result.p_type,
                    userid: data.data.result.p_type,
                    loginToken: data.data.result.token,
                    up_id: data.data.result.up_id,
                    p_type: data.data.result.p_type
                });
                props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result.c_id });
                setTimeout(() => {window.location = '/coaching/home/'}, 500);
                setLoading(false);
            }).catch((err) => {
                console.log(err)
                setLoading(false);
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const validateEmail = (email) => {
        var reg =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!reg.test(email))
            toast.error("Please enter a valid email address");

    }


    return (
        <div className="signin">
            <h2 style={{ fontSize: '24px' }}>Set up your account</h2>
            <div className="separator"></div>
            {/* <input style={{margin: '4% 0 0 0'}} type="text" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)} /> */}
            <input style={{ margin: '4% 0 0 0' }} type="text" placeholder="Institute Name *" value={institiute} onChange={e => setInstitute(e.target.value)} />
            <input style={{ margin: '4% 0 0 0' }} type="text" placeholder="Enter your Email ID *" value={email} onBlur={(e) => validateEmail(e.target.value)} onChange={e => setEmail(e.target.value)} />

            {/* <select value={itype} onChange={e => setItype(e.target.value)}
            style={{margin: '4% 0 0 0', color: '#75787A'}} className="institiute"><option value="0">Select your Institute Type*</option><option value="1">Coaching</option></select>
             */}
            {/* <div className="flex-row inst-type-div" style={{margin: '4% 0 0 0', flexWrap: 'wrap'}}>
                {
                    typeMaster.map(data => <div key={data.id} className={type.indexOf(data.id)!=-1?"active":""}
                    onClick={() => { type.indexOf(data.id)==-1?setType([...type, data.id]): setType(type.filter(dd => dd != data.id)) }}
                    >
                        {data.name}
                    </div>)
                }
            </div> */}


            <div className="flex-row" style={{ margin: '4% 0' }}>
                <input checked={terms} onChange={e => setTerms(!terms)} type="checkbox" /> <p style={{ marginLeft: '10px' }}>I agree to the <a href="#">Terms &amp; Conditions</a></p>
            </div>

            {
                loading ?
                    <div className="loginProgress"><img src={loader2} /></div>
                    :
                    <button onClick={onSubmit} className="flex-row">Submit <img src={arrow} /></button>
            }


            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}

const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(Signin)