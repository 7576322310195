import React, { useState, useEffect } from "react";
import circular from '../../../../../assets/img/circular.png'
import close from '../../../../../assets/img/close.svg'
import add from '../../../../../assets/img/add.svg'
import api from '../../../../../api/api'
import { connect } from "react-redux";
import pic from '../../../../../assets/img/pic.svg'
import { errorMsg } from "../../../../../utils";
import Loader from '../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';

function PlacementsForms(props) {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState([])
  const [website, setWebsite] = useState("")
  const [etitle, seteTitle] = useState("")
  const [oimage, setOimage] = useState([])
  const [picture, setPicture] = useState("")
  // const [image, setImage] = useState("")


  useEffect(() => {
    if (props.selected != 0) {
      console.log(props.mdata)
      seteTitle(props.mdata.c_name)
      setImage(props.mdata.c_l_url)
      setPicture(props.mdata.c_l_url)
    }
    return website
  }, [props.selected])



  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        c_name: website,
        i_p_id: props.prof_id,
        uid: props.up_id,
      }));
      fd.append('file', picture);
      // for (let index = 0; index < image.length; index++) {
      //   fd.append('file', image[index]);
      // }

      let request = {
        url: `placement/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Placement Added successfully")
        props.setselected(0); props.setForm(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const validateImage = (e) => {
    console.log(e.target.files[0].size , "kjngnghg")
    let img = e.target.files[0].size/ 1024
     console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only.")
      return false
    }
    else return true
  }

  const onChange = (e) => {
    if(validateImage(e)){
      setPicture(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    } 
  }


  const updateValue = () => {
    props.setLoading(true)
    var fd = new FormData();
    fd.append("u_data", JSON.stringify({
      c_name: etitle,
      i_p_id:props.prof_id,
      uid: props.up_id,
      c_l_url: image,
    }));
    fd.append('file', picture);
    // for (let index = 0; index < image.length; index++) {
    //   fd.append('file', image[index]);
    // }
    let request = {
      url: `placement/${props.mdata.id}/update`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: fd
    }
    api.putOther(request).then(data => {
      props.setLoading(false)
      toast.success("Placement Updated successfully")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }


  const select_MediaContent = (e) => {
    if (e.target.files.length > 0) {
      let imgs = [];
      for (const [key, value] of Object.entries(e.target.files)) {
        imgs.push(value)
      }
      setImage(imgs)
      // props.setSocial({ ...props.Social, gallary: imgs })
    }
  }

  const validate = () => {
    if (website === '') {
      toast.error("Please Add Website Link")
      return false
    }
    if (image.length == 0) {
      toast.error("Please Upload Image")
      return false
    }
    return true
  }

 
  return (

    <>
      {loading && <Loader />}
      <div>
        <div class="form-section">
          <div>
            {/* <label>Add Placement</label> */}
            {props.selected ?
              <p class="heading">Edit Placement</p> : <p class="heading">Add Placement</p>}
            <p class="line_purple"></p>
            <p class="data-info mt10">
              You can display the companies who hired your students to be displayed on your profile .
            </p>
            <label>Hiring Company Name/Website Link <span>*</span></label>
            {/* <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={website} onChange={(e)=>setWebsite(e.target.value)}/> */}
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
              <input class="expandale-input w-450" placeholder="Enter" value={website} onChange={(e) => setWebsite(e.target.value)}></input>
            }
          </div>

        <div>
          <label>Upload Company Logo<span>*</span></label>
          <label htmlFor="photo-upload" >
            <div   >{picture == "" ?
              <img for="photo-upload" class="pro-pic" src={pic} /> :
              <img for="photo-upload" class="pro-pic" src={image} />
            }
            </div>
            <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
              display: "none",
              visibility: "hidden"
            }} />
          </label>

          <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
        </div>

          {/* <div>
            <label>Upload Company Logo<span>*</span></label>

            <div class="flex-p1">
              <input class="expandale-input w-450 choose" type="file" id="myFile" accept="application/pdf,application/vnd.ms-excel,image/*" multiple onChange={select_MediaContent} disabled={image.length === 5} placeholder="Choose File" /><a href="#"></a>
            </div>
            <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img, pdf, word</p>

            <div class="tags-img">
            {oimage.map((item) => {
                return (
                  <div >
                    <img src={item} />
                    <img src={close} onClick={() => {setOimage(oimage.filter(item3 => item3 != item)) }} />
                  </div>
                )
              })
              }
              {image.map((item) => {
                return (
                  <div >
                    <img src={URL.createObjectURL(item)} />
                    <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                  </div>
                )
              })
              }
            </div>
          </div> */}
          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
              {
                props.selected ?
                  <button class="blue_button" onClick={updateValue}>Update</button> :
                  <button class="blue_button" onClick={()=>{
                    if(props.prof_id==""){
                      toast.error("Please Add Basic Details First.")
                    }else{
                      savedetails()
                    }
                   }}>Save</button>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(PlacementsForms)