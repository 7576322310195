import React, { useState } from 'react'
import Moment from 'moment';



function DailyReportData(props) {


    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>Added On</th>
                        <th>Added By</th>
                    </tr>
                    {
                        props.schoolData.map((data, key) => <tr key={key} >
                                <td>{data.display_id}</td>
                                <td>{data.name}</td>
                                <td>{data.location}</td>
                                <td>{data.email}</td>
                                <td>{data.mobile_no}</td>
                                <td>{Moment(data.added_on).format('DD-MM-YYYY')}</td>
                                <td>{data.added_by}</td>
                            </tr>
                        )
                    }
                </table>


            </div>




        </>
    );
}

export default DailyReportData
