import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux'
import close from '../../../assets/img/close.svg'
import 'react-toastify/dist/ReactToastify.css';
import {errorMsg} from '../../../utils'


function Operate(props) {
    const [title, setTitle] = useState("")
    const [etitle, seteTitle] = useState("")
    const [photo, setphoto] = useState("")
    const [image, setimage] = useState("")
    const [position, setPosition] = useState("")
    const [eposition, setePosition] = useState("")

   

    useEffect(() => {
        if (props.selected != 0) {
            setTitle(props.data.name)
            // setePosition(props.data.position)
            setimage(props.data.file_url)
            setphoto(props.data.file_url)
        }
    }, [props.selected])

    const updateValue = () => {
        if(validate()){
        props.setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({ name: title, pos: eposition * 1 }));
        fd.append('file', photo);
        let request = {
            url: `master/${props.entity_id}/sub-master/update/${props.data.entity_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data:fd
        }
        api.putOther(request).then(data => {
            props.setLoading(false)
            toast.success("Facilities updated Successfully  ")
            setTitle("");
            seteTitle("");
            setPosition("")
            setimage("")
            props.setSelected(0);
            props.fetchData();

        }).catch((err) => {
            props.setLoading(false)
              if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
        })
    }}

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, pos: position * 1 }));
            fd.append('file', photo);
            let request = {
                url: `master/${props.entity_id}/sub-master/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Facilities added Successfully  ")
                setTitle("");
                seteTitle("");
                setPosition("")
                setimage("")
                props.fetchData();


            }).catch((err) => {
                props.setLoading(false)
                  if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
            })
        }
    }
    const isNumber = (num) => {
        return /^[0-9\b]+$/.test(num)
    }

    const validate = () => {
        if (title === '') {
            toast.error("Please enter Facility.")
            return false
        }  
        if (photo === '') {
            toast.error("Please upload Icon")
            return false
        }
        return true
    }

    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>

            <div className="Sa-AddProfile">
                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Facility</p> : <p style={{ fontSize: "15px" }}>Add Facility</p>
                }
                <div className="Super-admin-line_purple"></div>


                <div className="Sa-Inputbox">
                    <div>
                    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Facility " style={{ fontSize: "13px" }} />
                    </div>
                    <div>

                        <input type="file" accept="image/*" contenteditable="true"  placeholder='Upload Icon*' className="Sa-input" onChange={select_file} style={{ fontSize: "13px" ,width:'70%'}} />
                        {
                            image !="" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close}  onClick={()=>{setimage("")}} />
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() =>{ props.setSelected(0);setTitle("");setPosition("");setimage("")}}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => {setTitle("");setPosition("");setimage("")}}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }
                <ToastContainer />

            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(Operate)
 