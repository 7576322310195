import React, { useState, useEffect } from "react";
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import ChangePopup from "../popups/change";
import { useHistory } from 'react-router-dom';
import YourProfile from "./yourprofile";
import MoreDetails from "./more_details";
import { connect } from "react-redux";
import Media from "./media";
import config from '../../../../config'
import { errorMsg } from '../../../../utils'
import masters from "../../../../masters";
import Loader from '../../../../components/loader'
import ProfileHeader from "../profile_header";


const mdata = masters[config.env]
function BasicDetailForm(props) {
  const history = useHistory()



  let type = window.location.href.split("?")[1].split("=")[1]
  const [change, setChange] = useState(false)
  const [changeMob, setChangeMob] = useState("Email")
  const [loading, setLoading] = useState(false)
  const [dataId, setDataId] = useState("")
  const [showform, setshowform] = useState(false)
  const [getCover, setGetCover] = useState([])
  const [getGallary, setGetGallaryx] = useState([])
  const [getvideolink, setGetvideolink] = useState([])

  const [Profile, setProfile] = useState({
    instName: "",
    mail: "",
    name: "",
    appNo: "",
    teleNo: "",
    contactPerson: "",
    // overview: "",
    // tagline: "",
    profilePhoto: '',
    image: ''
  })

  const [overview, setOverview] = useState("")
  const [tagline, setTagline] = useState("")

  const [director, setDirector] = useState([{ d_nm: "", d_msg: "", ph_url: "", mode: 'add', id: "" }])
  const [dirImage, setdirImage] = useState([])

  const [Details, setDetails] = useState({
    ownershipTyp: "", eduBoard: [], established: "", studentNo: "", facultiesNo: " ", ratio: "", schooltype: "", campusSize: "", campusType: "", modeofTeaching: "", eductionType: "", mediumofinst: "", educationLevel: "", profileType: "", university: "", department: "", stream: "", collegeType: ""
  })

  const [address, setaddress] = useState({
    address: "",
    country: "",
    mobNo: "",
    pin: "",
    area: '',
    state: "",
    city: "",
    lat: "",
    long: "",
  })

  const [Master, setMaster] = useState({
    ownershipTyp: [],
    eduBoard: [],
    schooltype: [],
    campusType: [],
    campusSize: [],
    modeofTeaching: [],
    eductionType: [],
    mediumofinst: [],
    extracurricular: [],
    facilities: [],
    educationLevel: [],
    university: [],
    Department: [],
    Stream: [],
    ProfileType: [],
    collegeType: []

  })

  const [Social, setSocial] = useState({
    facilities: [],
    extracurricular: [],
    gallary: [],
    videolink: [],
    coverImage: [],
    educationLevel: [],
    ProfileType: [],
    Department: [],
    Stream: [],
    website: "",
    yt: "",
    li: "",
    fb: "",
    insta: "",
    twitter: ""
  })

  useEffect(() => {
    profiletype();
    if (window.location.href.split("?").length > 2) {
      SelectedInst();
    }
    getProfileType();
    getDepartment();

  }, [])


  useEffect(() => {
    if (history.location.state?.name ?? '') {
      setProfile({ ...Profile, instName: history.location.state.name })
    } else if (props.p_type != 6) {
      let name = localStorage.getItem("name")
      let phone = localStorage.getItem("phone")
      let email = localStorage.getItem("email")
      setProfile({ ...Profile, instName: name, mail: email ?? '', mobNo: phone })
    }
  }, [])

  useEffect(() => {
    if (history.location.state?.state ?? '' == 'branch') {
      setDataId("");
    }
  }, [])

  const getProfileType = () => {

    let request = {
      url: `master/3b6df447-d9af-4958-bc0a-224273cae73f/sub-master/all`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setMaster(prevState => ({ ...prevState, ProfileType: data.data.result.filter((data) => data.sub_profile_id == (config.env == 'sit' ? "ed878005-16fa-4567-a4c1-02d8dee4b19b" : "0dbd7c11-13c2-41fb-b71b-7001c713a781")) }))
    }).catch((err) => {
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };

    })
  }

  const SelectedInst = () => {
    setLoading(true)
    let type1 = window.location.href.split("?")[2].split("=")[1]
    let request = {
      url: `inst/${type1}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      let rdata = data.data.result;
      setDataId(history.location.state?.state ?? '' == 'branch' ? "" : rdata.id)
      setGetGallaryx(rdata.gallery_img_li ?? [])
      setGetCover(rdata.cover_img_li ?? [])
      setGetvideolink(rdata.vdo_link ?? [])
      setProfile({ ...Profile, instName: rdata.inst_name, mail: rdata.email, name: rdata.d_name, mobNo: rdata.phone, pin: rdata.p_code, appNo: rdata.w_number, teleNo: rdata.t_number, profilePhoto: rdata.ph_url, image: rdata.ph_url, contactPerson: rdata.cp_name })
      setaddress({ ...address, address: rdata.loc_det.address, pin: rdata.loc_det.p_code, state: rdata.loc_det.s_nm, city: rdata.loc_det.ct_nm, area: rdata.loc_det.ar_nm, })
      setSocial({
        ...Social, fb: rdata.sm_link?.fb ?? '', website: rdata.sm_link?.website ?? '',
        yt: rdata.sm_link?.yt ?? '',
        li: rdata.sm_link?.li ?? '',
        insta: rdata.sm_link?.insta ?? '',
        twitter: rdata.sm_link?.twitter ?? '',
        facilities: rdata?.facilities ?? [],
        extracurricular: rdata?.ext_c_activities ?? [],
        educationLevel: rdata?.e_level ?? [],
        ProfileType: rdata?.sub_prof_types ?? [],
        Stream: rdata?.streams ?? [],
        Department: rdata?.dept_ids ?? []



        // videolink: rdata.vdo_link??[],
      })
      setDirector(rdata.dir_li.length == 0 ? director : (rdata.dir_li.map((data) => ({ d_nm: data.d_nm, d_msg: data.d_msg, ph_url: data.ph_url, mode: 'edit', id: data.id }))))
      if (type == "school") {
        setDetails({ ...Details, ownershipTyp: rdata.own_type, eduBoard: rdata.edu_boards??[], established: rdata.estb_in, studentNo: rdata.n_of_stud, facultiesNo: rdata.n_o_fac, ratio: rdata.s_to_f_ratio, schooltype: rdata.sch_type == null ? "" : rdata.sch_type.toString(), campusSize: rdata.camp_size, campusType: rdata.camp_type, modeofTeaching: rdata.mot == null ? "" : rdata.mot.toString(), eductionType: rdata.e_type == null ? "" : rdata.e_type.toString(), mediumofinst: rdata.m_of_instruct == null ? "" : rdata.m_of_instruct.toString(), collegeType: rdata.coll_type == null ? [] : rdata.coll_type.toString() })
      } else {
        setDetails({ ...Details, ownershipTyp: rdata.own_type, eduBoard: rdata.edu_boards??[], established: rdata.estb_in, studentNo: rdata.n_of_stud, facultiesNo: rdata.n_o_fac, ratio: rdata.s_to_f_ratio, campusSize: rdata.camp_size, campusType: rdata.camp_type, modeofTeaching: rdata.mot == null ? "" : rdata.mot.toString(), eductionType: rdata.e_type == null ? "" : rdata.e_type.toString(), mediumofinst: rdata.m_of_instruct == null ? "" : rdata.m_of_instruct.toString(), collegeType: rdata.coll_type == null ? [] : [rdata.coll_type.toString()], university: rdata.university })
      }
      setOverview(rdata.overview)
      setTagline(rdata.tagline)

    }).catch((err) => {
      // toast.error("Some Error Occured")
      //  if(err.response.status == 401){
      //   props.dispatch({ type: 'LOGOUT' })
      // }else{
      //   toast.error(errorMsg(err))
      // } 
      setLoading(false)
    })

  }

  const getDepartment = () => {
    // setLoading(true)
    let request = {
      url: `stream/dept-wise-stream`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      // setLoading(false)
      setMaster(prevState => ({ ...prevState, Department: data.data.result }))
    }).catch((err) => {
      // setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }
  const getStream = (id) => {
    let streamArrayFiltered = Master.Department.filter((item) => item.id == id)[0]?.sub_mst_li
    setMaster({ ...Master, Stream: streamArrayFiltered ?? [] })

  }



  const SetDirectorMsg = () => {
    let arr = director.map(el => {
      return { ph_url: isObject(el.ph_url) ? "" : el.ph_url, d_nm: el.d_nm, d_msg: el.d_msg, id: el.id }
    })
    return arr;
  }




  function isObject(val) {
    return val instanceof Object;
  }

  const SetDirectorUpdate = () => {
    let arr = director.map(el => {
      return { ph_url: isObject(el.ph_url) ? "" : el.ph_url, d_nm: el.d_nm, d_msg: el.d_msg, id: el.id }
    })
    return arr
  }

  const ProfileType = () => {
    if (type == 'institute') {
      return 'COACHING'
    } else if (type == 'school') {
      return 'SCHOOL'
    } else {
      return 'COLLEGE'
    }
  }

  console.log(Details.eduBoard,"board")

  const savedetails = () => {
    if (validate()) {
      let detailarray = SetDirectorMsg()
      let newarr = detailarray.filter((item) => (item.d_nm != "" && item.d_msg != ""))
      setLoading(true)
      var fd = new FormData();
      let tempdata = {
        inst_name: Profile.instName,
        phone: Profile.mobNo,
        d_name: Profile.name,
        email: Profile.mail,
        w_number: Profile.appNo,
        t_number: Profile.teleNo,
        cp_name: Profile.contactPerson,
        overview: overview,
        tagline: tagline,
        // d_msgs: [Profile.ownerMess.join(",")],
        sm_link: ({ fb: Social.fb, li: Social.li, website: Social.website, yt: Social.yt, insta: Social.insta, twitter: Social.twitter }),
        vdo_link: Social.videolink.map(data => data.link),
        facilities: Social.facilities,
        ext_c_activities: Social.extracurricular,
        estb_in: Details.established,
        n_of_stud: Details.studentNo,
        camp_size: Details.campusSize,
        camp_type: Details.campusType,
        dir_li: newarr,
        ph_url: history.location.state?.state ?? '' == 'branch' ? isObject(Profile.profilePhoto) ? null : Profile.profilePhoto : null,
        s_to_f_ratio: Details.ratio,
        mot: [Details.modeofTeaching],
        e_type: [Details.eductionType],
        m_of_instruct: [Details.mediumofinst],
        own_type: Details.ownershipTyp,
        edu_boards: Details.eduBoard,
        n_o_fac: Details.facultiesNo,
        sch_type: [Details.schooltype],
        university: Details.university,
        e_level: Social.educationLevel,
        coll_type: [Details.collegeType],
        sub_prof_types: Social.ProfileType,
        streams: Social.Stream,
        dept_ids: Social.Department,
        uid: props.up_id,
        is_from_branch: history.location.state?.state ?? '' == 'branch' ? true : false,
        cp_br_id: history.location.state?.state ?? '' == 'branch' ? history.location.state?.new ?? null == true ? props.prof_id : window.location.href.split("?")[2].split("=")[1] : null,
        is_new_branch: history.location.state?.new ?? false,
        loc_det: {
          c_nm: "India",
          s_nm: address.state,
          ct_nm: address.city,
          ar_nm: address.area,
          p_code: address.pin,
          address: address.address,
          lat: address.lat,
          long: address.long
        },
        is_by_proc: props.p_type == 6 ? true : false,
        inst_type: ProfileType()
      }

      fd.append("u_data", JSON.stringify(tempdata));

      fd.append('prof_ph', Profile.profilePhoto);

      for (let index = 0; index < director.length; index++) {
        fd.append('d_imgs', director[index].ph_url);
      }
      for (let index = 0; index < Social.gallary.length; index++) {
        fd.append('g_imgs', Social.gallary[index]);
      }
      for (let index = 0; index < Social.coverImage.length; index++) {
        fd.append('c_imgs', Social.coverImage[index]);
      }
      let request = {
        url: `inst/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      if (dataId.length == 0) {
        api.postCustom(request).then(data => {
          toast.success("Basic Details Added successfully.")
          setLoading(false)
          setDataId(data.data.result)
          if (type == "school") {
            setTimeout(() => {
              props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result });
              history.push(`/institute/profile/?type=school?id=${data.data.result}`)
            }, 2000);
          } else if (type == "institute") {
            setTimeout(() => {
              props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result });
              history.push(`/institute/profile/?type=institute?id=${data.data.result}`)
            }, 2000);
          } else {
            setTimeout(() => {
              props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result });
              history.push(`/institute/profile/?type=college?id=${data.data.result}`)
            }, 2000);
          }
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      } else {
        update()
      }
    }
  }



  const update = () => {
    if (validate()) {
      setLoading(true)
      let detailarray = SetDirectorUpdate()
      let newarr = detailarray.filter((item) => (item.d_nm != "" && item.d_msg != ""))
      var fd = new FormData();
      let tempdata = {
        inst_name: Profile.instName,
        phone: Profile.mobNo,
        // d_name: Profile.name,
        email: Profile.mail,
        w_number: Profile.appNo,
        t_number: Profile.teleNo,
        cp_name: Profile.contactPerson,
        overview: overview,
        tagline: tagline,
        // d_msgs: [Profile.ownerMess.join(",")],
        sm_link: ({ fb: Social.fb, li: Social.li, website: Social.website, yt: Social.yt, insta: Social.insta, twitter: Social.twitter }),
        vdo_link: Social.videolink.map(data => data.link),
        facilities: Social.facilities,
        ext_c_activities: Social.extracurricular,
        estb_in: Details.established,
        n_of_stud: Details.studentNo,
        camp_size: Details.campusSize,
        dir_li: newarr,
        camp_type: Details.campusType,
        s_to_f_ratio: Details.ratio,
        mot: [Details.modeofTeaching],
        e_type: [Details.eductionType],
        m_of_instruct: [Details.mediumofinst],
        own_type: Details.ownershipTyp,
        edu_boards: Details.eduBoard,
        n_o_fac: Details.facultiesNo,
        sch_type: [Details.schooltype],
        university: Details.university,
        e_level: Social.educationLevel,
        coll_type: Details.collegeType,
        streams: Social.Stream,
        sub_prof_types: Social.ProfileType,
        dept_ids: Social.Department,
        ph_url: history.location.state?.state ?? '' == 'branch' ? Profile.profilePhoto : null,
        uid: props.up_id,
        is_from_branch: history.location.state?.state ?? '' == 'branch' ? true : false,
        cp_br_id: history.location.state?.new ?? null == true ? props.prof_id : window.location.href.split("?")[2].split("=")[1] ?? null,
        is_new_branch: history.location.state?.new ?? false,
        loc_det: {
          c_nm: "India",
          s_nm: address.state,
          ct_nm: address.city,
          ar_nm: address.area,
          p_code: address.pin,
          address: address.address,
          lat: address.lat,
          long: address.long
        },
        is_by_proc: props.p_type == 6 ? true : false,
        inst_type: ProfileType()
      }

      fd.append("u_data", JSON.stringify(tempdata));

      fd.append('prof_ph', Profile.profilePhoto);

      for (let index = 0; index < director.length; index++) {
        fd.append('d_imgs', director[index].ph_url);
      }

      for (let index = 0; index < Social.gallary.length; index++) {
        fd.append('g_imgs', Social.gallary[index]);
      }

      for (let index = 0; index < Social.coverImage.length; index++) {
        fd.append('c_imgs', Social.coverImage[index]);
      }

      let request = {
        url: 'inst/' + dataId + '/update',
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.putOther(request).then(data => {
        toast.success("Basic Details Updated successfully.")
        setLoading(false)

      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }

      })
    }
  }



  const validate = () => {
    if (Profile.instName == '') {
      toast.error("Please Enter institute name.")
      return false
    } if (address.address == '') {
      toast.error("Please Enter Address.")
      return false
    } if (Profile.mail == '') {
      toast.error("Please Enter Email.")
      return false
    } if (Profile.mobNo == undefined || Profile.mobNo == '') {
      toast.error("Please Enter Mobile Number.")
      return false
    } if (Profile.profilePhoto == "") {
      toast.error("Please Upload Logo.")
      return false
    } if (overview == '') {
      toast.error("Please Enter Overview.")
      return false
    } if (Social.educationLevel.length == 0) {
      toast.error("Please select Education level.")
      return false
    } if (type == "institute" && Social.ProfileType == []) {
      toast.error("Please select Profile Type.")
      return false
    } if (type == "college" && Details.university == '') {
      toast.error("Please select University.")
      return false
    } if (type == "college" && Social.Department.length == 0) {
      toast.error("Please select Department .")
      return false
    } if (type == "college" && Social.Stream.length == 0) {
      toast.error("Please select stream.")
      return false
    } if (type == "college" && Details.collegeType == '') {
      toast.error("Please select College Type.")
      return false
    } if (Details.modeofTeaching == '') {
      toast.error("Please select Mode of Teaching.")
      return false
    }
    return true
  }



  const profiletype = () => {
    let ownerShip_eid = mdata.find(data => data.name == "Ownership Type")?.entity_id;
    let education_eid = mdata.find(data => data.name == "Education Board")?.entity_id;
    let school_typ = mdata.find(data => data.name == "School Type")?.entity_id;
    let campus_typ = mdata.find(data => data.name == "Campus Type")?.entity_id;
    let mode = mdata.find(data => data.name == "Mode of Teaching")?.entity_id;
    let education_type = mdata.find(data => data.name == "Co-Education")?.entity_id;
    let mediumofinst = mdata.find(data => data.name == "Medium of Instruction")?.entity_id;
    let facilities = mdata.find(data => data.name == "Facilities")?.entity_id;
    let extra = mdata.find(data => data.name == "Extra Curricular Activities")?.entity_id;
    let university = mdata.find(data => data.name == "University")?.entity_id;
    let collegeType = mdata.find(data => data.name == "College Type")?.entity_id;
    let educationLevel = mdata.find(data => data.name == "Education Level")?.entity_id;
    let request = {
      url: `master/all/sub-master/?mast_id_list=${ownerShip_eid},${education_eid},${school_typ},${mode},${education_type},${mediumofinst},${facilities},${extra},${campus_typ},${university},${collegeType},${educationLevel}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setMaster(prevState => ({
        ...prevState,
        ownershipTyp: data.data.result[ownerShip_eid],
        eduBoard: data.data.result[education_eid],
        schooltype: data.data.result[school_typ],
        modeofTeaching: data.data.result[mode],
        eductionType: data.data.result[education_type],
        mediumofinst: data.data.result[mediumofinst],
        facilities: data.data.result[facilities],
        extracurricular: data.data.result[extra],
        campusType: data.data.result[campus_typ],
        university: data.data.result[university],
        collegeType: data.data.result[collegeType],
        educationLevel: data.data.result[educationLevel]
      }))
      //setMaster(prevState => ({...prevState ,  extracurricular :data.data.result[extra]}))
    }).catch((err) => {
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }

  const clearDetails = () => {

    setProfile({ ...Profile, instName: "", mail: "", name: "", mobNo: "", pin: "", overview: " ", appNo: "", teleNo: "", profilePhoto: "", image: "", contactPerson: "", tagline: "", ownerMess: [] })
    setaddress({ ...Profile, address: "", pin: "", state: "", city: "", area: "" })
    setDirector([{ d_nm: "", d_msg: "", ph_url: "", mode: 'add', id: "" }])
    setdirImage([])
    setDetails({ ...Details, ownershipTyp: "", eduBoard: "", established: "", studentNo: "", facultiesNo: "", ratio: "", schooltype: "", campusSize: "", campusType: "", modeofTeaching: "", eductionType: "", mediumofinst: "" })
    setSocial({
      ...Social, facilities: [],
      extracurricular: [],
      gallary: [],
      videolink: [],
      coverImage: [],
      website: "",
      yt: "",
      li: "",
      fb: "",
      insta: "",
      twitter: ""
    })
    setOverview("")
    setTagline("")
    setGetGallaryx([])
    setGetCover([])
    setGetvideolink([])
    window.scrollTo(0, 0)

  }



  return (
    <>
      {change == true &&
        <ChangePopup setChange={setChange} setChangeMob={setChangeMob} changeMob={changeMob} loading={loading} setLoading={setLoading} />
      }

      {loading && <Loader />}


      <ProfileHeader count={props.count} setCount={props.setCount}
        setButton={props.setButton}
        setEcount={props.setEcount}
        setEdit={props.setEdit}
        savedetails={savedetails}
        showform={showform}
      />
      <div>
        <div class="form-section ">

          {<YourProfile
            Profile={Profile} setProfile={setProfile}
            change={change} setChange={setChange}
            setChangeMob={setChangeMob} changeMob={changeMob}
            address={address} setaddress={setaddress}
            setDirector={setDirector} director={director}
            // Image={props.Profile.image}
            setOverview={setOverview} overview={overview}
            setTagline={setTagline} tagline={tagline}

          />}
          <MoreDetails
            Details={Details} setDetails={setDetails}
            Master={Master}
            setMaster={setMaster} Social={Social}
            setSocial={setSocial} getStream={getStream}
          />
          <Media
            loading={loading} setLoading={setLoading}
            savedetails={savedetails}
            Social={Social}
            setSocial={setSocial}
            Master={Master}
            clearDetails={clearDetails}
            setMaster={setMaster}
            getCover={getCover}
            setGetCover={setGetCover}
            getGallary={getGallary} setGetGallaryx={setGetGallaryx}
            setGetvideolink={setGetvideolink} getvideolink={getvideolink}
            setdirImage={setdirImage} dirImage={dirImage}
          />
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(BasicDetailForm)

