import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../css/superAdmin/SuperAdmincss.css'
import config from '../../../config'
import close from '../../../assets/img/close.svg'
import masters from '../../../masters'
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'

const mdata = masters[config.env]

function Operate(props) {
    const [title, setTitle] = useState("")
    const [image, setimage] = useState("")
    const [change, setChange] = useState("")
    const [photo, setphoto] = useState("")
    const [position, setPosition] = useState("")
    const [check, setcheck] = useState(false)
    const [eposition, setePosition] = useState("")
    const [master, setmaster] = useState([])
    const [department, setDepartment] = useState([])
    const [departmentValue, setDepartmentValue] = useState([])
    const [stream, setstream] = useState([])
    const [streamValue,setStreamValue] = useState("")


    useEffect(() => {
        profiletype()
        getDepartment()
        if (props.selected != 0) {
            setTitle(props.data.name)
            setDepartmentValue(props.data.department_id)

            setStreamValue(props.data.stream_id)
            setChange(props.data.mast_cat_id)
            setcheck(props.data.show_on_website)
            setimage(props.data.file_url)
            setphoto(props.data.file_url)
        }
    }, [props.selected])




    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, mc_id: change, s_web: check, pos: eposition ,stream_id:streamValue}));
            fd.append('file', photo);
            let request = {
                url: `course/${props.data.entity_id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Category Course updated Successfully  ")
                setTitle("");
                setChange("");
                setimage("")
                props.setSelected(0);
                props.fetchData();

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, mc_id: change, s_web: check, pos: position ,stream_id:streamValue}));
            fd.append('file', photo);
            let request = {
                url: `course/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Category Course added Successfully  ")
                setTitle("");
                setChange("");
                setimage("")
                setPosition("");
                setcheck("")
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const OnCancel = () => {
        setTitle("");
        setChange("");
        setimage("")
        setPosition("");
        setcheck("")

    }


    const profiletype = () => {

        let request = {
            url: `master_catg/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setmaster(data.data.result)

        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const getDepartment = () => {
        props.setLoading(true)
        let request = {
            url: `department/all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setDepartment(data.data.result.response)
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }
    const getStream = (id) => {
        props.setLoading(true)
        let request = {
            url: `stream/all?isAll=true&dept_id=${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setstream(data.data.result.response)
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }


    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Category/Course")
            return false
        }
        if (change === '') {
            toast.error("Please Select Master Category")
            return false
        } if (photo === '') {
            toast.error("Please Upload Image")
            return false
        }
        return true
    }

    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>

            <div className="Sa-AddProfile">

                <div>

                    {
                        props.selected ? <p style={{ fontSize: "15px" }}>Edit Master Category</p> : <p style={{ fontSize: "15px" }}>Select Master Category</p>
                    }
                    <div className="Super-admin-line_purple"></div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="Sa-dropdown">
                            <section className="sa-wrapper2">
                                <select name="Master Category" id="Master Category" className="Sa-input" value={change} onChange={(e) => setChange(e.target.value)} style={{ fontSize: "13px" }} >
                                    <option value="">Select Master Category</option>
                                    {master.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                                </select>
                            </section>

                        </div>
                        <div style={{ marginLeft: "100px", marginTop: "10px" }}>
                            <input type="checkbox" style={{ marginRight: "10px" }} checked={check} onChange={() => setcheck(!check)} />Show on App
                        </div>
                    </div>
                </div>

                {  change == (config.env=='sit'? 'eaf9b677-01c1-4286-871c-3271ff6a7872':'d6b0fe42-0167-4bc2-a312-f9a7856d189f') &&
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="Sa-dropdown" >
                            <section className="sa-wrapper2">
                                <select className="Sa-input" style={{ fontSize: "13px" }} value={departmentValue} onChange={(e)=>{setDepartmentValue(e.target.value);getStream(e.target.value)}}>
                                    <option value="">Select Department</option>
                                    {department.map((item) => <option value={item.entity_id}>{item.name}</option>)}
                                </select>
                            </section>
                        </div>
                        <div className="Sa-dropdown" style={{ marginLeft: '100px' }}>
                            <section className="sa-wrapper2">
                                <select name="Master Category" id="Master Category" className="Sa-input" value={streamValue} onChange={(e) => setStreamValue(e.target.value)} style={{ fontSize: "13px" }} >
                                    <option value="">Select Stream</option>
                                    {stream.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                                </select>
                            </section>

                        </div>
                    </div>
                }


                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Category Courses</p> : <p style={{ fontSize: "15px" }}>Add Category/Course</p>
                }
                <div className="Super-admin-line_purple"></div>
                <div className="Sa-Inputbox">
                    <div>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Category/Course " style={{ fontSize: "13px" }} />
                    </div>

                    <div>

                        <input type="file" accept="image/*" contenteditable="true" placeholder='Upload Icon*' onChange={select_file} style={{ fontSize: "13px", borderBottom: "1px solid #939393", width: "70%" }} />
                        {
                            image != "" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close} onClick={() => { setimage("") }} />
                                </div>
                            </div>

                        }
                    </div>

                </div>
                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }

                <ToastContainer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)

