import React, { useState, useEffect } from "react";
import add from '../../assets/img/add.svg'
import Data from "./data";
import { errorMsg } from "../../utils";
import { connect } from 'react-redux';
import Loader from '../../components/loader'
import api from '../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import circular from '../../assets/img/circular.png'
import close from '../../assets/img/close.svg'
import Delete from '../../components/delete'



function WhatsNew(props) {
    const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([]);
    const [title, setTitle] = useState("")
    const [link, setlink] = useState("")
    const [selected, setSelected] = useState(0)
    const [videolink, setvideolink] = useState([])
    const [getvideolink, setGetvideolink] = useState([])
    const [desc, setdesc] = useState("")
    const [deletepopup, setdelete] = useState(false)
    const [idd, setId] = useState("")


    useEffect(() => {
        fetchData();
    }, [])


    useEffect(() => {
        if (selected != 0) {
            let data = rdata.find(data => data.id == selected)
            setTitle(data.title)
            setGetvideolink(data.links ?? [])
            setdesc(data.desc)
        }
    }, [selected])



    const fetchData = () => {

        setLoading(true)
        let request = {
            url: `whats-new/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const addValue = () => {
        if (title == "") {
            toast.error("Please Enter Title.")
            return false
        } else {
            setLoading(true)
            let request = {
                url: `whats-new/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: {
                    title: title,
                    desc: desc,
                    links: videolink.map(data => data.link),
                    uid: props.up_id
                }
            }
            api.postCustom(request).then(data => {
                setLoading(false)
                toast.success("News Created Successfully!")
                fetchData()
                setTitle("")
                setvideolink([])
                setdesc("")
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }



    const update = () => {
        setLoading(true)
        let link = [...getvideolink, ...videolink.map(data => data.link)]
        let data = rdata.find(data => data.id == selected)
        let request = {
            url: `whats-new/${data.id}/update`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                title: title,
                desc: desc,
                links: link,
                uid: props.up_id
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("News Update Successfully!")
            fetchData()
            setTitle("")
            setGetvideolink([])
            setdesc("")
            setSelected(0)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const getImageThumbnail = () => {
        if (link.indexOf("http") == -1) {
            toast.error("Please add valid Link.")
        } else if ([...getvideolink, ...videolink].length > 2) {
            toast.error("You are only allowed to upload a maximum of 3 link.")
        }
        else {
            let videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
            if (videoid != null) {
                setvideolink([...videolink, {
                    preview: `https://img.youtube.com/vi/${videoid[1]}/0.jpg`,
                    link
                }])
            } else {
                setvideolink([...videolink,
                {
                    preview: circular,
                    link
                }])
            }
            setlink("")
        }
    }


    const deleteInst = () => {
        setLoading(true)
        let request = {
            url: `whats-new/${idd}/delete`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.delete(request).then(data => {
            setLoading(false)
            toast.success("Deleted successfully")
            setId("")
            setdelete(false)
            fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const ShowonProfile = (id, status) => {
        setLoading(true)
        let request = {
            url: `whats-new/${id}/update-active/${status == true ? false : true}?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Show On Profile Updated successfully")
            fetchData()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    return (
        <>
            {loading && <Loader />}
            <div style={{ marginLeft: "30px", marginTop: "20px" }}>
                <div className="sa_home_lower_section">
                    <div className="sa_home_lower_section_inner">

                        <div className="entire_form_fillup_section">
                            <div className="fill_section_upper_heading_and_btn">

                                <div className="fillup_headings">
                                    <p style={{ fontSize: "15px" }}>Post Latest News/Anouncement</p>
                                    <div className="Super-admin-line_purple"></div>
                                </div>

                            </div>
                            <div className="form_fillup_section">

                                <div className="home_lower_cards_left_section">

                                    <div className="left_form_fillup_line1">
                                        <div className="left_form_fillup_line1_inner">
                                            <input type="text" placeholder="Title" className="Sa-input" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>

                                    </div>

                                    <div className="left_form_fillup_line2" style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="left_form_fillup_line2_inner1">
                                            <input type="text" placeholder="Link" className="Sa-input" value={link} onChange={(e) => setlink(e.target.value)} />
                                            <img src={add} style={{ cursor: "pointer" }} onClick={getImageThumbnail} />
                                            <div className="left_form_fillup_line3">
                                                Add links for files or more information
                                            </div>
                                        </div>

                                        <div class="tags-img">
                                            {getvideolink.map((item) => {
                                                return (
                                                    <div >
                                                        <img src={circular} onClick={() => { window.open(item) }} />
                                                        <img src={close} onClick={(index) => setGetvideolink(getvideolink.filter(item2 => item2 != item))} />
                                                    </div>
                                                )
                                            })
                                            }
                                            {videolink.map((item) => {
                                                return (
                                                    <div >
                                                        <img src={item.preview} onClick={() => { window.open(item.link) }} />
                                                        <img src={close} onClick={(index) => setvideolink(videolink.filter(item2 => item2.preview + item2.lastModified != item.preview + item.lastModified))} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>



                                </div>
                                <div className="home_lower_cards_right_section">
                                    <div className="lower_right_headings">
                                        More Details
                                        <font> &nbsp;&nbsp;max 250 chars</font>
                                    </div>

                                    <div className="lower_right_text_area">
                                        <textarea id="w3review" name="w3review" rows="2" cols="40" style={{ width: "100%" }} value={desc} onChange={(e) => setdesc(e.target.value)}>

                                        </textarea>
                                    </div>

                                    <div className="form_submit_section">
                                        {selected ?
                                            <button class="blue_button" onClick={update} >Update </button> :
                                            <button class="blue_button" onClick={addValue} >Post </button>
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {deletepopup == true &&
                <Delete delete={deleteInst} setdelete={setdelete} />
            }
            <Data rdata={rdata} selected={selected} setSelected={setSelected} setTitle={setTitle}
                setvideolink={setvideolink} setdesc={setdesc} setGetvideolink={setGetvideolink}
                setdelete={setdelete} setId={setId} deleteInst={deleteInst} ShowonProfile={ShowonProfile} />
            <ToastContainer />

        </>

    )

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(WhatsNew)
