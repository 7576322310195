import React from "react";
import {baseurl} from '../../utils'
const path = baseurl();

const List = ({name, url, icon}) => {
    let current_url = window.location.href;
    const urlMatch = new RegExp(`^${path + url}`, 'i')
    let classs = urlMatch.test(current_url)?"iconSidebar":"";
    
    return <div className={classs}>
        <li>
            <a className="Sa-sidebar2" href={path + url}>
                {icon && <img src={icon} />}
                <p style={{padding:"5px"}}>
                    {name}{' '}</p>
            </a>{' '}
        </li>
    </div>
}

export default List;