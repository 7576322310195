import React, { useEffect, useState } from "react";
import noun from '../../../assets/img/noun_Tip.svg'
import Loader from '../../../components/loader'
import Delete from '../../../components/delete'
import api from '../../../api/api'
import { connect } from "react-redux";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from '../../../utils'

function NewsBlock(props) {
    const [deletepopup, setdelete] = useState(false)
    const [id, setid] = useState("")
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        props.setselected(0);
    }, [])

    const deleteAlumni = () => {
        setLoading(true)
        setdelete(false)
        let request = {
            url: `news/${id}/delete`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.delete(request).then(data => {
            setLoading(false)
            toast.success("News Deleted successfully")
            props.setRdata(props.rdata.filter((item3) => item3.id != id))
            setid("")
        }).catch((err) => {
            setid("")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div class="box-section1 news">
                <div class="news-box flex" >
                    <img src={noun} class="noun-tip" />
                    <p class="mb-0">You can post any important news or latest activities related to
                        Admissions,Exams etc.<br />
                        These will be shown on your profile under Latest News Section</p>
                </div>
                <div><button class="blue_button" onClick={() => props.setForm(true)}>Add&nbsp;News</button></div>
            </div>
            <div class="box-educator h-a2 w-b-100">


                {
                    props.rdata.map((item,key) => {
                        return (
                            <div key={key}>
                                <div class="mb-8 mr-70">
                                    <p class="small-label mb-0">Title</p>
                                    <div class="name-div">
                                        <p>{item.title}</p>
                                    </div>
                                    <p class="small-label">{Moment(item.c_date).format('DD-MM-YYYY')}</p>
                                </div>
                                <div class="line-v-grey">
                                </div>
                                <div class="flex">

                                    <div class="ml-a">
                                        <button class="editbutton" onClick={() => { props.setselected(item.id); props.setMdata(item); props.setForm(true) }}> Edit</button>
                                        <button class="viewbutton ml16" onClick={() => window.alert("Will be launching soon!!")} >View</button>
                                        <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

            {deletepopup == true &&
                <Delete delete={deleteAlumni} setdelete={setdelete} />
            }



        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(NewsBlock)