import React, { useState, useEffect } from 'react'
import InstituteNavBar from "./navbar";
import Operate from "./operate";
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api/api'
import { errorMsg } from '../../utils';
import { connect } from 'react-redux';
import InstituteData from './school/data';
import CoachingData from './coaching/data';
import Loader from '../../components/loader'
import Pagination from "react-js-pagination";
import Delete from '../../components/delete'
import CollegeData from './college/data'




function Institute(props) {

  const [selectedTab, setSelectedTab] = useState("School")
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [rdata, setRdata] = useState([]);
  const [cdata, setCdata] = useState([]);
  const [collegedata, setCollegedata] = useState([]);
  const [selected, setSelected] = useState(0)
  const [active, setActive] = useState(1)
  const [active2, setActive2] = useState(1)
  const [active3, setActive3] = useState(1)
  const [total, setTotal] = useState("")
  const [totalC, setTotalC] = useState("")
  const [totalCollege, setTotalCollege] = useState("")
  const [activeCheck, setActiveCheck] = useState(false)
  const [operate, setOperate] = useState({
    added: "", location: "", fromdate: "2022-06-06", todate: "2022-12-31", statuss: "", kyc: ""
  })
  const [search, setSearch] = useState("")
  const [country, setCountry] = useState([])
  const [masterCat, setMasterCat] = useState([])
  const [master, setmaster] = useState(0)
  const [deletepopup, setdelete] = useState(false)
  const [idd, setId] = useState("")



  useEffect(() => {
    fetchCoachingData(active2);
    fetchData(active);
    fetchCollegeData(active3)
    getCountry();
    MasterCat()

  }, [])


  const fetchData = (active) => {
    setLoading2(true)
    let request = {
      url: `inst/all?pageOffset=${active}&pageSize=10&isAll=false&inst_type=School&uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.postCustom(request).then(data => {
      setLoading2(false)
      setTotal(data.data.result.total_elements)
      setRdata(data.data.result.response)
    }).catch((err) => {
      setLoading2(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }


  const fetchCoachingData = (active2) => {
    setLoading(true)
    let request = {
      url: `inst/all?pageOffset=${active2}&pageSize=10&isAll=false&inst_type=Coaching&uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.postCustom(request).then(data => {
      setTotalC(data.data.result.total_elements)
      setLoading(false)
      setCdata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  const fetchCollegeData = (active3) => {
    setLoading(true)
    let request = {
      url: `inst/all?pageOffset=${active3}&pageSize=10&isAll=false&inst_type=COLLEGE&uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.postCustom(request).then(data => {
      setTotalC(data.data.result.total_elements)
      setLoading(false)
      setCollegedata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }


  const fetchCoachingCategory = (active2, id) => {
    setLoading(true)
    let request = {
      url: `inst/all?pageOffset=${active2}&pageSize=10&isAll=false&inst_type=Coaching&m_cat_id=${id}&uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.postCustom(request).then(data => {
      setTotalC(data.data.result.total_elements)
      setLoading(false)
      setCdata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }



  const ShowonProfile = (id, status) => {
    setLoading(true)
    let request = {
      url: `inst/${id}/set-active/${status == "Active" ? false : true}?uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success("Show On Profile Updated successfully")
      fetchData(active)
      fetchCoachingData(active2)
      fetchCollegeData(active3)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })

  }

  const MasterCat = () => {
    setLoading(true)
    let request = {
      url: `master_catg/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMasterCat(data.data.result)
    }).catch((err) => {
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
      setLoading(false)
    })
  }

  const deleteInst = () => {
    setLoading(true)
    let request = {
      url: `inst/${idd}/delete?uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Deleted successfully")
      setId("")
      setdelete(false)
      if (selectedTab == "School") {
        setRdata(rdata.filter((item3) => item3.id != idd))
      } else if(selectedTab == "Coaching"){
        setCdata(cdata.filter((item3) => item3.id != idd))
      }else{
        setCollegedata(collegedata.filter((item3) => item3.id != idd))
      }
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  const getCountry = () => {
    setLoading(true)
    let request = {
      url: `country/stCtAr/all-city-names`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setCountry(data.data.result)

    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }


  const Apply = () => {
    if (operate.added == "" && operate.location == "" && operate.fromdate == "" && operate.todate == "" && operate.statuss == "" && operate.kyc == "") {
      toast.error("Please apply any filter.")
    } else {
      setLoading(true)
      let request = {
        url: `inst/all?pageOffset=${active}&pageSize=10&isAll=false&inst_type=${selectedTab}&uid=${props.up_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          add_by: operate.added,
          loc_id: operate.location,
          f_date: operate.fromdate,
          t_date: operate.todate,
          p_status: operate.statuss,
          k_status: operate.kyc,
        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        // setOperate({ ...operate, added: "", location: "", fromdate: "", todate: "", statuss: "", kyc: "" })
        if (selectedTab == "School") {
          if (data.data.result.response.length > 0) {
            setRdata(data.data.result.response)
            setTotal(data.data.result.total_elements)
            toast.success("Filter applied successfully")
          } else {
            toast.error("No Profile Found")
            setRdata([])
            setTotal(0)
          }
        } else if(selectedTab == "Coaching"){
          if (data.data.result.response.length > 0) {
            setCdata(data.data.result.response)
            setTotalC(data.data.result.total_elements)
            toast.success("Filter applied successfully")
          } else {
            toast.error("No Profile Found")
            setCdata([])
            setTotalC(0)
          }
        }else{
          if (data.data.result.response.length > 0) {
            setCollegedata(data.data.result.response)
            setTotalCollege(data.data.result.total_elements)
            toast.success("Filter applied successfully")
          } else {
            toast.error("No Profile Found")
            setCollegedata([])
            setTotalCollege(0)
          }
        }
        setActiveCheck(true)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })

    }

  }



  const reset = () => {
    setmaster(0)
    setOperate({ ...operate, added: "", location: "", fromdate: "", todate: "", statuss: "", kyc: "" })
    setActiveCheck(false)
    fetchData(active)
    fetchCoachingData(active2)
    fetchCollegeData(active3)
  }


  const handlePageChange = (active) => {
    setActive(active);
    if (activeCheck == true) {
      Apply(active)
    } else {
      fetchData(active)
    }
  }
  const handlePageChange2 = (active2) => {
    setActive2(active2);
    if (activeCheck == true) {
      Apply(active2)
    } else {
      fetchCoachingData(active2)
    }
  }

  const handlePageChange3 = (active3) => {
    setActive3(active3);
    if (activeCheck == true) {
      Apply(active3)
    } else {
      fetchCollegeData(active3)
    }
  }


  return (
    <>

      {loading && <Loader />}
      {loading2 && <Loader />}
      <div className="sa-leadsss">
        <InstituteNavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} setSearch={setSearch} setLoading={setLoading} masterCat={masterCat} fetchCoachingData={fetchCoachingData} fetchCoachingCategory={fetchCoachingCategory} master={master} setmaster={setmaster} active2={active2} />

        <Operate data={rdata.find(data => data.id == selected)} datac={cdata.find(data => data.id == selected)} setLoading={setLoading} setOperate={setOperate} Apply={Apply} operate={operate} reset={reset} setmaster={setmaster} setCountry={setCountry} country={country} />

        {selectedTab == "School" &&
          <><InstituteData data={rdata} setLoading={setLoading} setId={setId} deleteInst={deleteInst} selected={selected} setOperate={setOperate} Apply={Apply} operate={operate} setSelected={setSelected} search={search} ShowonProfile={ShowonProfile} setdelete={setdelete} /><div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={active}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={(n) => { handlePageChange(n); }} />
          </div></>
        }
        {selectedTab == "Coaching" &&
          <><CoachingData data={cdata} setLoading={setLoading} setId={setId} deleteInst={deleteInst} selected={selected} setOperate={setOperate} Apply={Apply} operate={operate} setSelected={setSelected} search={search} ShowonProfile={ShowonProfile} setdelete={setdelete} /><div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={active2}
              itemsCountPerPage={10}
              totalItemsCount={totalC}
              pageRangeDisplayed={10}
              onChange={(n) => { handlePageChange2(n); }} />
          </div></>
        }
        {selectedTab == "College" &&
          <><CollegeData data={collegedata} setLoading={setLoading} setId={setId} deleteInst={deleteInst} selected={selected} setOperate={setOperate} Apply={Apply} operate={operate} setSelected={setSelected} search={search} ShowonProfile={ShowonProfile} setdelete={setdelete} /><div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={active3}
              itemsCountPerPage={10}
              totalItemsCount={totalCollege}
              pageRangeDisplayed={10}
              onChange={(n) => { handlePageChange3(n); }} />
          </div></>
        }
        {deletepopup == true &&
          <Delete delete={deleteInst} setdelete={setdelete} />
        }

      </div>
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Institute)