import React, {useState, useEffect} from 'react'
import Data from './data'



function LeadCoaching(props) {
  
    return (
        <>
           
            <div className="Sa-Profile2">
              
                <Data data={props.rdata}  />
        
            </div>




        </>
    );
}

export default LeadCoaching;