import React, { useState } from "react";
import unlink from '../../../../assets/img/unlink.svg'

function UnlinkPopup(props) {
  return (
    <>
      <div class="modal-backdrop show">

      </div>
      <div class="modal show" id="unlinkModal" style={{ paddingRight: '4px', display: 'block' }}>
        <div class="modal-dialog">
          <div class="modal-content ">
            <div class="modal-body center-align">
              <embed src={unlink} />
              <p class="red-para">After Unlinking this educator will not be shown on your proctur profile</p>
            </div>

            <div class="modal-footer">
              <button type="button" class="white_button mr16" data-dismiss="modal" onClick={() => props.setUnlink(false)}>Close</button>
              <button type="button" class="blue_button"  onClick={() => props.Unlinkk()}>Apply</button>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default UnlinkPopup