import React, { useEffect, useState } from "react";
import api from '../../../../api/api'
import { errorMsg } from "../../../../utils";
import { connect } from "react-redux";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Delete from '../../../../components/delete'

 function RolesBlock(props) {
    const [deletepopup, setdelete] = useState(false)
    const [id, setid] = useState("")
    const [loading, setLoading] = useState(true)


    const deleteRole = () => {
        setdelete(false)
        setLoading(true)
        let request = {
          url: `role/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`
          },
        }
        api.delete(request).then(data => {
          setLoading(false)
          toast.success("Role Deleted successfully")
          props.setRdata(props.rdata.filter((item3) => item3.id != id))
          setid("")
        }).catch((err) => {
          setLoading(false)
          setid("")
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
      }


    return (
        <>
            <div class="tab-pane fade show active" id="role">
                <div class="box-section1">
                    <div>Roles</div>
                    <div><button class="blue_button" onClick={()=>props.setForm(true)} >Add Role</button></div>
                </div>
                <div class="box-educator">
                
                         {props.rdata.map((item,key)=>{
                             return (
                                     
                        <div key={key}><div class="display mb-8">
                                     <div class="name-div">
                                         <p>{item.name}</p>
                                     </div>
                                 </div><div class="display added-by">
                                         <p>Created on :</p>
                                         <p>{Moment(item.c_date).format('DD-MM-YYYY')}</p>
                                     </div><div class="line-v-grey">
                                     </div><div class="flex">
                                         {/* <div class="flex-a">
                                             <span>Assigned To</span>
                                             <div class="dropdown-1">
                                                 <div>
                                                     <a>
                                                         <span class="assign_number">2</span>
                                                     </a>
                                                     <div class="dropdown-content-1">
                                                         <p>Rohtang Pass</p>
                                                         <p>Rohtang Pass</p>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div> */}
                                         <div class="ml-a">
                                             <button class="editbutton"  onClick={()=> {props.setselected(item.id); props.setMdata(item);props.setForm(true)}}> Edit</button>

                                             <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                                         </div>
                                     </div></div>
                                
                             )
                         })

                         }
                </div>

            </div>

            {deletepopup == true &&
        <Delete delete={deleteRole} setdelete={setdelete}  />
      }

      <ToastContainer/>

        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id  ,p_type:state.auth.p_type})

export default connect(mapStateToProps)(RolesBlock)