import React from 'react';
import './cstm_noti.css'
import search_icon from '../../assets/home/search_icon.png'
import BackDrop from '../../components/backdrop/backdrop';
import Cnpopup1 from './cn_popup1';
import reduxSaga from 'redux-saga';
import { errorMsg } from "../../utils";
import { connect } from 'react-redux';
import BackDropTransparent from '../../components/backdrop_transparent/backdrop_transparent';
import searchh from '../../assets/home/search_icon.png'
import close from '../../assets/img/close.svg'
import eye from '../../assets/img/header/eye.svg'
import api from '../../api/api'
import { ToastContainer,toast } from 'react-toastify';
import Loader from '../../components/loader'
import MultiDown from './multidrop_down';


class CustomNotification extends React.Component {
    constructor(props) {
        super(props)
        this.state =
        {
            loader: false,
            popup_on: false,
            profile_type_val: "select",
            profile_type_val: "Institute"
            , show_list: false
            , show_institute_dropdown: false
            , inst_dropdown_msg: "Select Institute Type",
            table_data_all: [],

            inst_labels:
            {
                heading: "Select All",
                rows: [
                    // "For Competitive Exams",
                    // "For Academic Learning",
                    // "For Developing Skill",
                    // "For Pursuing Hobby"
                ]
            },
            table_data: [],
            table_data2: [],
            show_inst_dropdown: false,
            cat_id: "",
            settings: [],
            message: "",
            picture: "",
            image:"",
            dataItem:{},
            search:""
        }
    }

    profile_type_val = "select"
    inst_dropdown_msg = ""

    PopUpOn = (item) => {
        this.setState({ popup_on: true })
        this.setState({dataItem:item})
    }

    HandleChildBackDrop = () => {
        this.setState({ popup_on: false })
        this.setState({ show_list: "" })
        this.setState({ show_institute_dropdown: false })
        this.setState({ inst_dropdown_msg: "Select Institute Type" })
    }

    HandleChildMultidown = (f, hit_get_api, id) => {
        console.log("handel::", f, hit_get_api)

        console.log("handel::", f, hit_get_api, id)

        if (f.length == 0) {
            this.inst_dropdown_msg = "Select"
            this.setState({ inst_dropdown_msg: "Select" })
        }
        else if (f.length > 0 && f.length < 4) {
            this.inst_dropdown_msg = f.join(",")
            this.setState({ inst_dropdown_msg: f.join(",") })
        }
        else {
            this.inst_dropdown_msg = "All"
            this.setState({ inst_dropdown_msg: "All" })
        }
        if (this.inst_dropdown_msg != "Select" && hit_get_api) {
            this.instituteDropdownApi3('Institute')
            this.setState({ show_inst_dropdown: false })
            // this.setState({show_list:true})
        }
    }

    componentDidMount() {
        this.NotificationDataApi1()
        this.dropDownApiData2()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.profile_type_val != this.state.profile_type_val) {
            if (this.state.profile_type_val == "Institute") {
                this.dropDownApiData2()
            }
        }

    }


    setitem(item) {

        if (this.state.settings.indexOf(item.id) == -1) {
            this.setState({ settings: [...this.state.settings, item.id] })
        } else {
            this.setState({ settings: this.state.settings.filter(data => data != item.id) })
        }
    }


    onChange(e) {
       
         if (this.validateImage(e)) {
            this.setState({ picture: e.target.files[0] })
            this.setState({ image: URL.createObjectURL(e.target.files[0]) })
          }

    }

    validateImage = (e) => {
        console.log(e.target.files[0].size, "kjngnghg")
        let img = e.target.files[0].size / 1024
        console.log(img, "size")
        if (img > 500) {
            toast.error("Please upload image of size 500kb only.")
            return false
        }
        else return true
    }




    render() {
        { console.log(this.state.dataItem , "ids") }

        return (
            <>
                <div className="cstm_notify"
                    onClick={() => {
                        this.state.check_box_selected_all && this.setState({ check_box_selected_all: false })
                    }}
                >
                    {
                        (this.state.popup_on || this.state.loader) &&
                        <BackDropTransparent FromChildBackDrop={this.HandleChildBackDrop} />
                    }
                    {
                        this.state.loader &&
                        <Loader />
                    }
                    {
                        this.state.show_list &&
                        <BackDropTransparent FromChildBackDrop={this.HandleChildBackDrop} />
                    }
                    {
                        this.state.popup_on &&
                        <Cnpopup1 FromChildCnPopUp1={this.HandleChildBackDrop}  data={this.state.dataItem} />
                    }
                    <div className="cstm_notify_inner">
                        {console.log("jsx ::", this.state.inst_dropdown_msg)}
                        <div className="custom_message_area" style={{ height: "48vh" }}>
                            <div className="cn_msg_upper_section">
                                <div className="cn_send_noti">
                                    <div className="cn_noti_left" >
                                        <div className="cn_notileft_up">
                                            <p style={{ fontSize: "15px" }}>Post Latest News/Anouncement</p>
                                            <div className="Super-admin-line_purple"></div>
                                        </div>
                                        <div className="Sa-dropdown">
                                            <section className="sa-wrapper4">
                                                <select name="Master Category" id="Master Category" className="Sa-input"
                                                    value={this.state.profile_type_val}
                                                    onChange={(actn) => {
                                                        console.log(this.state.profile_type_val,"before drop")
                                                        this.profile_type_val = actn.target.value
                                                        this.setState({ profile_type_val: actn.target.value })
                                                        if (this.profile_type_val != "Institute") {
                                                            this.instituteDropdownApi3(actn.target.value)
                                                        }

                                                    }}
                                                    style={{ fontSize: "13px", marginTop: "15px" }} >
                                                    <option value="select"> Select Profile Type </option>
                                                    <option value="Institute">Coaching</option>
                                                    <option value="Educator">Educator</option>
                                                    <option value="School">School</option>
                                                </select>
                                            </section>
                                        </div>

                                        <div className="cn_msg_enter_text">
                                                <div className="Sa-Inputbox">
                                                    <div >
                                                        <input type="text" className="Sa-input" name="fname" placeholder="Enter Message" style={{ fontSize: "13px" }} value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="cn_msg_enter_text_textlimit">
                                                    Max 50 chars
                                                </div>
                                            </div>
                                        <div >
                                    
                                            <div>
                                                <input type="file" className="Sa-input" style={{ fontSize: "13px" }} onChange={(e) => this.onChange(e)} placeholder="Attach Files" />
                                                <div className="cn_notileft_botttom2" style={{ marginTop: "5px" }}>
                                                    max size 500Kb format accepted jpg,jpeg,png
                                                </div>
                                                {
                                                    this.state.picture != "" &&
                                                    <div class="tags-img">
                                                        <div style={{border:"1ps solid black"}}>
                                                            <img src={this.state.image}/>
                                                            <img src={close} onClick={() => { this.setState({ picture: "" }) }} />
                                                        </div>
                                                    </div>

                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="cn_noti_right"
                                    >
                                        <div className="cn_notileft_up">
                                            <div style={{ height: "50%" }}></div>
                                            {this.state.profile_type_val == "Institute" &&
                                                <div className="sa-wrapper2" style={{ fontSize: "11px" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            show_inst_dropdown: true
                                                        })
                                                    }}
                                                >
                                                    {this.state.inst_dropdown_msg}
                                                </div>
                                            }

                                            {this.state.show_inst_dropdown && <MultiDown labels={this.state.inst_labels} cat_id={this.state.cat_id} Fromparent={this.HandleChildMultidown} data={this.instituteDropdownApi3} />}

                                        </div>
                                        <div
                                        // style={{ marginTop: "10px", height: "25%" }}
                                        >
                                        </div>
                                        {
                                            ((this.state.profile_type_val == "Institute" && this.state.show_list)
                                                || (this.state.profile_type_val == "Educator" && this.state.show_list)
                                                || (this.state.profile_type_val == "School" && this.state.show_list))
                                            &&
                                            <div className="cn_profile_type_list" style={{
                                                padding: "2px", borderRadius: "10px",
                                            }} >
                                                <div className="cn_profile_type_list_inner" style={{ borderRadius: "10px", }}>
                                                    <div className="cn_list_search" style={{ padding: "3px", width: "100%" }}>
                                                        {/* <div className="cn_coti_search" style={{ margin: "0", width: "100%" }}>
                                                            <div className="cn_search_inner" style={{ border: "1px solid gray", borderRadius: "20px", width: "100%", border: 0 }}>
                                                                <div className="search_img_container">
                                                                    <img src={search_icon} height="15px"
                                                                    />
                                                                </div>
                                                                <input placeholder={"Search " + this.state.profile_type_val} onChange={(e)=>this.setState({search:e.target.value})}>
                                                                </input>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div>
                                                        <table className="cn_table2" style={{ margin: "0" }}>
                                                            <tr>
                                                                <th style={{ width: "15%" }}><div style={{}}>
                                                                    <input type="checkbox" style={{ width: "15%" }}
                                                                        checked={this.state.check_box_selected_all && "on"}
                                                                        onClick={() => { this.setState({ check_box_selected_all: true }) }}
                                                                    ></input> &nbsp;&nbsp;Select All</div> </th>
                                                                <th style={{ width: "10%" }}>Sr. No.</th>
                                                                <th style={{ width: "25%" }}>Name </th>
                                                                <th style={{ width: "25%" }}>Location</th>
                                                            </tr>
                                                            {
                                                                this.state.table_data2.map((el, index) =>
            
                                                                    <tr>
                                                                        {this.state.check_box_selected_all ? <td>
                                                                            <input type="checkbox" checked="on" style={{ width: "15%" }}></input></td>
                                                                            : <td ><input type="checkbox" style={{ width: "15%" }} onClick={() => this.setitem(el)}></input></td>
                                                                        }
                                                                        <td>{el.disp_id}</td>
                                                                        <td>{el?.inst_name ?? el.e_name}</td>
                                                                        <td>{el?.ct_nm ?? '-'}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                {/* <div className="cn_msg_enter_text">
                                    <div className="Sa-Inputbox">
                                        <div style={{ marginTop: "25px" }}>
                                            <input type="text" className="Sa-input" name="fname" placeholder="Enter Text " style={{ fontSize: "13px" }} onChange={(e) => this.setState({ message: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="cn_msg_enter_text_textlimit">
                                        Max 50 chars
                                    </div>
                                </div> */}

                            </div>

                            <div className="Super-admin-buttons" >
                                <button className="Super-admin-cancel-button" onClick={()=>{this.setState({picture:""});this.setState({message:""})}}>Cancel</button>
                                <button className="Super-admin-save-button" onClick={() => this.SentNotification()} >Save</button>
                            </div>
                        </div>
                        {
                            this.state.table_data.length > 0 &&
                            <div className="cn_noti_history_table_area" >
                                <div className="cn_noti_heading" style={{ marginLeft: "5px" }}>
                                    Notification History
                                </div>
                                {/* <div className="cn_coti_search">
                                    <div className="Sa-dropdown2">   
                                        <div className="Super-admin-searchhhhhhhhhhh" style={{padding:"0",marginRight:"10px"}}>
                                            <div className="sa-searchhh">
                                                <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                                                <input className="Super-admin-Search_Input" type="text"
                                                    placeholder="Search" style={{ outline: "0" ,width:"120px",border:"0"}} />
                                            </div>
                                        </div>
                                    </div>                          
                            </div> */}
                                <div className="cn_noti_table_container">
                                    <table id="super-admin">
                                        <tr>
                                            <th>SR NO</th>
                                            <th>Profile Type</th>
                                            <th>Recipients</th>
                                            <th>Sent On</th>
                                            <th>Action</th>
                                        </tr>
                                        {
                                            this.state.table_data.map((el, indx) =>
                                                <tr>
                                                    <td>{indx + 1}</td>
                                                    <td>{el.s_type}</td>
                                                    <td>{el.msg}</td>
                                                    <td>{el.c_date.split(" ", 1)}</td>
                                                    <td>
                                                        <img src={eye} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} onClick={()=>this.PopUpOn(el)} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {console.log(this.state.profile_type_val,"before")}
                <ToastContainer/>
            </>
        )

    }



    NotificationDataApi1() {
        this.setState({ loader: true })
        let request = {
            url: `notification/sent/all?isAll=true`,
            headers: {
                'Authorization': `Bearer ${this.props.loginToken}`
            },
        }
        api.postCustom(request).then(data => {
            this.setState({ loader: false })
            this.setState({ table_data: data.data.result.response })


        }).catch((err) => {

            // this.setState({table_data:data})
            this.setState({ loader: false })
            toast.error(errorMsg(err))
        })
    }

    instituteDropdownApi3(type) {

        console.log(type,"3 ran")
        if (type == "Institute") {
            this.setState({ loader: true })
            let request = {
                url: `inst/all?isAll=true&inst_type=Coaching&uid=${this.props.up_id}`,
                headers: {
                    'Authorization': `Bearer ${this.props.loginToken}`
                },
            }
            api.postCustom(request).then(data => {
                this.setState({ table_data2: data.data.result.response })
                this.setState({ loader: false })
                this.setState({ show_list: true })
            }).catch((err) => {

                this.setState({ loader: false })

                // toast.error(errorMsg(err))
            })
        } else if (type == "School") {
            this.setState({ loader: true })
            let request = {
                url: `inst/all?pageOffset=1&pageSize=10&isAll=true&inst_type=School&uid=${this.props.up_id}`,
                headers: {
                    'Authorization': `Bearer ${this.props.loginToken}`
                },
            }
            api.postCustom(request).then(data => {
                this.setState({ table_data2: data.data.result.response })
                this.setState({ loader: false })
                this.setState({ show_list: true })

            }).catch((err) => {

                this.setState({ loader: false })

                // toast.error(errorMsg(err))
            })
        } else if (type == "Educator") {
            this.setState({ loader: true })
            let request = {
                url: `educator/all?pageOffset=1&pageSize=10&isAll=true&inst_type=School&uid=${this.props.up_id}`,
                headers: {
                    'Authorization': `Bearer ${this.props.loginToken}`
                },
            }
            api.postCustom(request).then(data => {
                this.setState({ table_data2: data.data.result.response })
                this.setState({ loader: false })
                this.setState({ show_list: true })
            }).catch((err) => {

                this.setState({ loader: false })

                // toast.error(errorMsg(err))
            })
        }
    }

    SentNotification() {
        if(this.state.message==''){
            toast.error("Please add message.")
        }else{
        this.setState({ loader: true })
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({
            msg: this.state.message,
            s_type: this.state.profile_type_val == "Institute" ? "Coaching" : this.state.profile_type_val,
            n_type: "Push",
            edu_li: this.state.profile_type_val == "Educator" ? this.state.settings : [],
            inst_li: this.state.profile_type_val == "Institute" ? this.state.settings : [],
            u_id: this.props.u_id
        }));
        fd.append('files', this.state.picture);
        let request = {
            url: `notification/send`,
            headers: {
                'Authorization': `Bearer ${this.props.loginToken}`,
            },
            data: fd
        }
        api.postCustom(request).then(data => {
            this.setState({ loader: false })
             toast.success("Notification Sent Successfully.")
             this.NotificationDataApi1()
             this.setState({message:"" })
             this.setState({picture:"" })
        }).catch((err) => {
            this.setState({ loader: false })
            toast.error(errorMsg(err))
        })}
    }



    dropDownApiData2() {
        console.log("2 ran")
        this.setState({ loader: true })
        let request = {
            url: `master_catg/all`,
            headers: {
                'Authorization': `Bearer ${this.props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            console.log("api 2 data::", data.data.result)
            this.setState({ loader: false })
            let localHed = { ...this.state.inst_labels }
            localHed.rows = data.data.result
            this.setState({ inst_labels: localHed })

        }).catch((err) => {
            this.setState({ loader: false })
            // toast.error(errorMsg(err))
        })
    }
}




const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(CustomNotification)
