import React, { useState, useEffect, useRef } from "react";
import unexpad from '../../../../assets/img/un-expand.svg'
import expand from '../../../../assets/img/expand.svg'
import circular from '../../../../assets/img/circular.png'
import api from '../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../utils";
import Moment from 'moment';
import Loader from '../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';


export function Experience(props) {
    const [experience, setExperience] = useState([{ inst_name: "", d_name: "", e_type: "", f_date: "", t_date: "", designation: "", desc: "", is_ongoing: false, id: "", open: true }])
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [initialTime, setInitialTime] = useState(true)


    useEffect(() => {
        if (props.dataId != "") {
            getExperience()
        }
    }, [props.dataId])


    let directorRef = useRef(null);
    useEffect(() => {
        if (initialTime == false) {
            directorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }, [experience])


    const getExperience = () => {

        props.setLoading(true)
        let request = {
            url: `educator/exp/all?educator_id=${props.dataId}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setExperience(data.data.result.response.map((data) => ({ ...data, is_ongoing: data.is_ing, open: false })))
            if (data.data.result.response.length > 0) {
                setShow(true)
            }
        }).catch((err) => {
            props.setLoading(false)
              if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
    }

    const validate = (data) => {
        if (data.inst_name == "") {
            toast.error("Please Enter School/Institute Name")
            return false
        } if (data.designation == "") {
            toast.error("Please Enter Designation")
            return false
        } if (data.f_date == "") {
            toast.error("Please select From Date")
            return false
        }
        return true
    }

    const savedetails = (index) => {

        let data = { ...experience[index] };
        let id = data.id;
        delete data.id;
        delete data.open;
        if (validate(data)) {
            props.setLoading(true)
            let request = {
                url: `educator/${props.dataId}/exp/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data
            }
            if (id == "") {
                api.postCustom(request).then(data => {
                    props.setLoading(false)
                    toast.success("Experience Added successfully.")
                    setInitialTime(true)
                    setExperience(experience.map((d, i) => i == index ? { ...d, open: false, id: data.data.result } : d))
                }).catch((err) => {
                    props.setLoading(false)
                      if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
                })

            } else {
                request.url = `educator/exp/${id}/update`
                api.putOther(request).then(data => {
                    props.setLoading(false)
                    setInitialTime(true)
                    toast.success("Experience updated successfully.")
                    setExperience(experience.map((d, i) => i == index ? { ...d, open: false } : d))
                }).catch((err) => {
                    props.setLoading(false)
                      if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
                })
            }
        }

    }

    const openBox = (index) => {
        setExperience(experience.map((d, i) => i == index ? { ...d, open: true } : d))
    }

    const deleteExp = (index) => {
        props.setLoading(true)
        let data = { ...experience[index] };
        let id = data.id;
        if (id == "") {
            setExperience(experience.filter((data3, index3) => index3 != index))
            props.setLoading(false)
        }
        else {
            let request = {
                url: `educator/exp/${id}/delete`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.delete(request).then(data => {
                props.setLoading(false)
                toast.success("Experience Deleted successfully")
                setExperience(experience.filter((data3, index3) => index3 != index))
            }).catch((err) => {
                props.setLoading(false)
                  if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
            })
        }
    }


    return (
        <>

            <div style={{ background: "#00ffd00d" }}>
                <div class="flex-a pointer">
                    <img src={expand} class="mr16" onClick={() => {
                        if (props.dataId == "") {
                            toast.error("Please Add Educator")
                        } else {
                            setInitialTime(false); setShow(true); setExperience([...experience, { inst_name: "", d_name: "", e_type: "", f_date: "", t_date: "", designation: "", is_current: "", desc: "", id: "", open: true }])
                        }
                    }
                    }

                    /><label class="mb-0">Experience </label>
                </div>
            </div>

            {show == true && experience.map((data, index) => {
                return (<>
                    {data.open == true ?
                        <div ref={directorRef}><div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img src={unexpad} style={{ cursor: "pointer" }} class="mr16" onClick={() => { setInitialTime(true); deleteExp(index) }} />
                            {/* <label class="mb-0">Experience</label> */}
                        </div>
                            <div class="mt-32">
                                <label>Enter School/Institute Name/Company name <span>*</span></label>

                                <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.inst_name} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, inst_name: e.target.value } : data2))} />
                            </div><div class="mt-16 flex mob-v">
                                <div class="mr-70">
                                    <label>Enter Designation<span>*</span></label>

                                    <input contenteditable="true" class="expandale-input w-300" placeholder="Enter" value={data.designation} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, designation: e.target.value } : data2))} />
                                </div>
                                <div class="mob-top">
                                    <label>Employment Type</label>

                                    <div class="select-wrapper ">
                                        <select class="expandale-input" value={data.e_type} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, e_type: e.target.value } : data2))}>
                                            <option>Select</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Distance Learning">Distance Learning</option>
                                        </select>
                                    </div>
                                </div>
                            </div><div class="flex-a mt-16 mob-res">
                                <div class="mr-50">
                                    <label>From Date<span>*</span></label>
                                    <input type="date" class="expandale-input" onKeyDown={(e) => e.preventDefault()} required pattern="\d{4}-\d{2}-\d{2}" value={data.f_date} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, f_date: e.target.value } : data2))} />
                                </div>
                                {
                                    data.is_current != true &&
                                    <div class="mob-top">
                                        <label>To Date </label>
                                        <input type="date" class="expandale-input" onKeyDown={(e) => e.preventDefault()} disabled={data.is_ongoing == true ? true : false} value={data.t_date} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, t_date: e.target.value } : data2))} />
                                    </div>
                                }

                                <div class="flex-inline mt-36">
                                    <label class="checkbox">
                                        <span class="checkbox__input">
                                            <input type="checkbox" checked={data.is_current == true ? true : false} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, is_current: !data.is_current } : data2))} />
                                            <span class="checkbox__control">
                                                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                                                    <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </label>
                                    <span>Currently Working</span>
                                </div>

                            </div><div class="mt-16">
                                <label>Tell us more about your experience</label>
                                <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.desc} onChange={(e) => setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, desc: e.target.value } : data2))} />
                            </div>
                            <div class="display mob-top">
                                <div></div>
                                <div>
                                    <button class="white_button mr16" onClick={() => { data.id == "" ? setExperience(experience.filter((data3, index3) => index3 != index)) : setExperience(experience.map((data2, index2) => index == index2 ? { ...data2, open: false } : data2)); setInitialTime(true) }}>Cancel</button>
                                    <button class="blue_button" onClick={() => { props.dataId == "" ? toast.error("Please Add Educator") : savedetails(index) }}>Save</button>
                                </div>
                            </div></div> :
                        <div class="view-detail-short">
                            <div class="display align-s">
                                <div class="flex">
                                    <img src={circular} />
                                    <div>
                                        <p>
                                            {data.inst_name}
                                        </p>
                                        <p>
                                            {data.designation}
                                        </p>
                                        <p>
                                        {Moment(data.f_date).format('DD-MM-YYYY')} to {data.t_date==null?"Currently Working":Moment(data.t_date ).format('DD-MM-YYYY')}
                                        </p>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '12%' }}>
                                    <button class="editbutton" onClick={() => { openBox(index) }}> Edit</button>
                                    <button class="deletebutton" onClick={() => { deleteExp(index) }}> Delete</button>
                                </div>


                            </div>
                            <p></p>
                        </div>
                    }
                </>)
            })}






        </>
    )

}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Experience)