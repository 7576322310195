import React, { useState, useEffect } from "react";
import school from '../../../assets/img/school.svg'
import ExperienceForm from "./experience_forms";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../../utils'
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Educatorheader from "../../addEducators/educator_header";
import Delete from '../../../components/delete'


export function ExperienceBlock(props) {
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [deletepopup, setdelete] = useState(false)
  const [id, setid] = useState("")

  useEffect(() => {
    fetchData();
    setSelected(0);
  }, [])

  

  const fetchData = () => {
    if(props.eduId==""){
      toast.error("Please add Basic Details first.")
    }else{
    setLoading(true)
    let request = {
      url: `educator/exp/all?educator_id=${props.eduId}&pageOffset=1&pageSize=10&isAll=false`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setRdata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })}
  }


  const deleteExp = () => {
    setdelete(false)
    let request = {
      url: `educator/exp/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Experience Deleted successfully")
      setRdata(rdata.filter((item3) => item3.id != id))
      setid("")
    }).catch((err) => {
      setid("")
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }

 


  return (
    <>
      {loading && <Loader />}

      <Educatorheader
      count={props.count} setCount={props.setCount}
      setButton={props.setButton}
      setEcount={props.setEcount} 
      showform={showform}
      />


      {showform == false && <div>
        <div class="box-section1">
          <div> Experience</div>
          <div>
            <button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add Experience</button>
          </div>
        </div>
        <div class="box-educator">
          {rdata.map((item,key) => {
             {key=key}
            return (
              <div>
                <div class="display mb-8">
                  <div class="flex">
                    <img src={school} class="profile_pic" />
                    <div class="name-div">
                      <span>School/Institute Name</span>
                      <p>{item.inst_name}</p>

                    </div>
                  </div>

                </div>
                <div class="display added-by">
                  <p>Employment Type</p>
                  <p> {item.e_type}</p>
                </div>
                <div class="display added-by">
                  <p>Designation</p>
                  <p>{item.designation}</p>
                </div>
                <div class="display added-by">
                  <p>Duration</p>
                  <p>{Moment(item.f_date).format('DD-MM-YYYY')} To  {item.is_current==true?"Currently Working":Moment(item.t_date).format('DD-MM-YYYY')}</p>
                </div>
                <div class="added-by description">
                  <p>Description</p>
                  <p> {item.desc.replace(/<[^>]*>?/gm, '')}
                  </p>
                </div>
                <div class="line-v-grey">
                </div>
                <div class="flex">
                  <div class="flex-a">

                  </div>
                  <div class="ml-a">
                    <button class="editbutton" onClick={() => { setshowform(true); setSelected(item.id) }}> Edit</button>

                    <button class="unlinkbutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                  </div>
                </div>
              </div>
            )
          })

          }
        </div>
      </div>
      }


      {deletepopup == true &&
        <Delete delete={deleteExp} setdelete={setdelete} />
      }

      {
        showform == true && 
        <ExperienceForm 
        setSelected={setSelected} selected={selected} data={rdata.find(data => data.id == selected)}
          showform={showform} setshowform={setshowform}
          fetchData={fetchData} eduId={props.eduId}
        />

      }
      <ToastContainer />
    
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken })

export default connect(mapStateToProps)(ExperienceBlock)