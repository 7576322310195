import React, { useState } from 'react'
import Login from './login'
import Index from './signin'


export default function LoginContainer(props) {
    return (
        <>
            {
                (window.location.href.indexOf('type=superadmin') != -1) ?
                    <Login />
                    :
                    <Index />
            }
        </>
    )
}
