import React, { useState, useEffect } from "react";
import Loader from '../../../../../components/loader'
import api from '../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../utils";
import { ToastContainer, toast } from 'react-toastify';
import ScholarshipForms from "./scholarship_forms";
import ScholarshipBlock from "./scholarship_blocks";


function Scholarship(props) {
  let [mdata, setMdata] = useState({})
  const [loading, setLoading] = useState(true)
  const [rdata, setRdata] = useState([]);


  useEffect(() => {
    fetchData();
    props.setselected(0);
  }, [])


  const fetchData = () => {
    if(props.prof_id==""){
      toast.error("Please Add Basic Details First.")
      setLoading(false)
      }else{
    setLoading(true)
    let request = {
      url: `scholarship/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setRdata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })}
  }
  console.log(rdata)


  return (<>
    {loading && <Loader />}
    {
      props.form == false ?
        <ScholarshipBlock setLoading={setLoading} {...props} setMdata={setMdata} rdata={rdata} setRdata={setRdata}  /> :
        <ScholarshipForms setLoading={setLoading} {...props} mdata={mdata} rdata={rdata} fetchData={fetchData} />

    }
  </>)

}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(Scholarship)