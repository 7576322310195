import React, { useState, useEffect } from "react";
import DeletePopup from "../../popups/delete";
import Loader from '../../../../../components/loader'
import api from '../../../../../api/api'
import { errorMsg } from "../../../../../utils";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import EventsBlock from "./events_blocks";
import EventsForms from "./events_form";

function Event(props) {
   let [mdata, setMdata] = useState({})
   const [loading, setLoading] = useState(true)
   const [rdata, setRdata] = useState([]);


   useEffect(() => {
      fetchData();
      props.setselected(0);
   }, [])


   const fetchData = () => {
      if (props.prof_id == "") {
         toast.error("Please Add Basic Details First.")
         setLoading(false)
      } else {
         setLoading(true)
         let request = {
            url: `event/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
               'Authorization': `Bearer ${props.loginToken}`,
            },
         }
         api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)
         }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
               props.dispatch({ type: 'LOGOUT' })
            } else {
               toast.error(errorMsg(err))
            }
         })
      }
   }


   return (<>
      {loading && <Loader />}
      {
         props.form == false ?
            <EventsBlock {...props} setLoading={setLoading} setMdata={setMdata} rdata={rdata} setRdata={setRdata} /> :
            <EventsForms {...props} setLoading={setLoading} mdata={mdata} rdata={rdata} fetchData={fetchData} />

      }
   </>)

}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Event)