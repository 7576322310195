import React, { useState, useEffect, Profiler } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import ProfileMang from '../../../profilemanagmnet';
import close from '../../../assets/img/close.svg'
import { errorMsg } from '../../../utils'
import { connect } from 'react-redux'

const mdata = ProfileMang[config.env]


function Operate(props) {
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("")
    const [rdata, setRdata] = useState([]);
    const [value2,setValue2] = useState("")
    


    useEffect(() => {
        fetchData()
        if (props.selected != 0) {
            console.log(props.data,"dataa")
            setTitle(props.data.name)
            setValue2(props.data.dept_id)
            
        }
    }, [props.selected])



    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            let request = {
                url: `stream/${props.data.entity_id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: { name: title ,dept_id:value2,uid:props.up_id }
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Stream updated Successfully.  ")
                setValue2("")
                setTitle("");
                props.setSelected(0);
                props.fetchData();

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            let request = {
                url: `stream/add`,
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: { name: title ,dept_id:value2,uid:props.up_id }
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Stream added Successfully . ")
                setValue2("")
                setTitle("");
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }
  

    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `department/all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)
        }).catch((err) => {
            console.log(err,"errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const OnCancel = () => {
        setValue2("")
        setTitle("");
    }



    

    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Stream.")
            return false
        }  if (value2 === '') {
            toast.error("Please Select Department.")
            return false
        }
        return true
    }



    return (
        <>
            <div className="Sa-AddProfile">

                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Stream</p> : <p style={{ fontSize: "15px" }}>Add Stream</p>
                }
                <div className="Super-admin-line_purple"></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="Sa-dropdown">
                        <section className="sa-wrapper2">
                            <select className="Sa-input" style={{ fontSize: "13px" }} >
                                <option value="">Sub Profile Type-College</option>
                                {/* {profile.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })} */}
                            </select>
                        </section>
                    </div>
                    <div className="Sa-dropdown" style={{marginLeft:'50px'}}>
                    <section className="sa-wrapper2">
                        <select className="Sa-input" style={{ fontSize: "13px" }} value={value2} onChange={(e) => setValue2(e.target.value)}>
                            <option value="">Select Department</option>
                            {rdata.map((item) => <option value={item.entity_id}>{item.name}</option>)}
                        </select>
                    </section>
                </div>
                </div>
               

                <div className="Sa-Inputbox">
                    <div >

                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Stream" style={{ fontSize: "13px" }} />


                    </div>
                   
                </div>


                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => OnCancel()}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }

                <ToastContainer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)
