import React, { useState } from 'react'
import LeadsData from './leadsData';


function Data(props) {
    const [clicked, setClicked] = useState(false)
    return (

        <>


            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr>
                        <th>LID</th>
                        <th>School Name</th>
                        <th>Leads</th>
                    </tr>
                    {
                        props.data.map((data, key) => <tr key={key}>
                            <td>{data.id}</td>
                            <td>{data.title}</td>
                            <td style={{ cursor: "pointer" }} onClick={() => setClicked(true)}>{data.Leads}</td>

                        </tr>)
                    }



                </table>
                {clicked == true &&
                    <LeadsData setClicked={setClicked} />
                }
            </div>





        </>
    );
}

export default Data;