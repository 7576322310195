import React,{useState,useEffect} from "react";
import { errorMsg } from "../../utils";
import { connect } from 'react-redux';
import api from '../../api/api'
import { ToastContainer,toast } from 'react-toastify';

 function Notification(props){
    const [loading, setLoading] = useState(true)
    const [notification,setNotification] = useState([])
    useEffect(() => {
        fetchNotification()
      }, [])

    const fetchNotification = (active2) => {
        setLoading(true)
        let request = {
          url: `notification/sent/all?pageOffset=1&pageSize=10&isAll=false&uid=${props.up_id}`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.postCustom(request).then(data => {
          setLoading(false)
          setNotification(data.data.result.response)
        }).catch((err) => {
          setLoading(false)
            if(err.response.status == 401){
            props.dispatch({ type: 'LOGOUT' })
          }else{
            toast.error(errorMsg(err))
          } 
        })
      }

    return(
        <>
        <div className="sa-popupEnquiry"   onClick={()=>props.setNotification(false)}>
           <div class="notification-box" style={{marginTop:'60px'}}>

            {
                notification.map((data)=> {return(
                    <div>
                    <div class="notification-day">
                        <span>Today</span>
                    </div>
                    <div class="flex">
                        <span class="blue circle">P</span>
                        <p><b>Proctur Notification:</b> {data.msg}</p>
                        <span>2 min</span>
                    </div>
                    {/* <div class="flex">
                        <span class="olive circle">P</span>
                        <p><b>Proctur Notification:</b> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown </p>
                        <span>10 min</span>
                    </div> */}
                </div>
                )})
            }
               
                {/* <div>
                    <div class="notification-day">
                        <span>Yesterday</span>
                    </div>
                    <div class="flex">
                        <span class="orange circle">P</span>
                        <p><b>Proctur Notification:</b> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown </p>
                        <span>11:24 AM</span>
                    </div>
                    <div class="flex">
                        <span class="purple circle">P</span>
                        <p><b>Proctur Notification:</b> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown </p>
                        <span>11:24 AM</span>
                    </div>
                </div>
                <div>
                    <div class="notification-day">
                        <span>March 25</span>
                    </div>
                    <div class="flex">
                        <span class="orange circle">P</span>
                        <p><b>Proctur Notification:</b> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown </p>
                        <span>11:24 AM</span>
                    </div>
                    <div class="flex">
                        <span class="circle teal">P</span>
                        <p><b>Proctur Notification:</b> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
                            laying out print, graphic or web designs. The passage is attributed to an unknown </p>
                        <span>11:24 AM</span>
                    </div>
                </div> */}
            </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(Notification)