import { put, takeLatest, call } from 'redux-saga/effects';
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';



export const watchLogin = function* watchLogin() {
    yield takeLatest('BEGIN_LOGIN', beginLogin);
}

function* beginLogin(action) {
    yield put({ type: 'LOGIN_LOADING' });
    try {
        let payload = {
            password: action.password,
            phone: action.phone,
        }

        console.log("payload", payload)
        const responseData = yield call(api.postAuthHeader, { url: 'user/super-login', post: payload, headers: { timeout: 5000 } });
        console.log(responseData.data.result, "res")
        if (responseData.data.status_code == 200 && responseData.data.result) {
            let response = responseData.data.result;
            yield put({
                type: 'LOGIN_SUCCESS',
                datalogin: response,
                usertype: response.p_type,
                userid: 5,
                loginToken:response.token,
                up_id:response.up_id,
                p_type:response.p_type
            });
            action.nav()
        }
    } catch (error) {
        console.log(error.response.data.error[0].error_message, "responseData")
        // yield put({ type: 'LOGIN_ERROR', error_msg: error.response.data.error[0].error_message, error : true  });
        if (error.response && error.response.data && error.response.data.error[0].error_message) {
            
            yield put({ type: 'LOGIN_ERROR', error_msg: error.response.data.error[0].error_message,error : true  });
        } else {
            yield put({ type: 'LOGIN_ERROR', error_msg: 'Network Error' ,error : true });
        }
    }
}