import React, { useState } from "react";
import User from "./users";
import Roles from "./roles";


export default function RolesNUser() {
    const [tab, setTab] = useState("Roles")
   
   

    return (
        <>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class={tab=='Roles'?"nav-link active":"nav-link" } onClick={() => setTab("Roles")}>Role</a>
                </li>
                <li class="nav-item">
                    <a class={tab=='User'?"nav-link active":"nav-link" }  onClick={() => setTab("User")}>User</a>
                </li>
            </ul>


            {tab == "Roles" &&
                <Roles />
            }

            {tab == "User" &&
                <User />
            }



        </>
    )
}