import React, { useState, useEffect } from "react";
import add from '../../../assets/img/add.svg'
import deleteb from '../../../assets/img/header/delete.svg'
import ReactQuill from 'react-quill'
import { registry } from "chart.js";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';



function EditForm(props) {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [label, setLabel] = useState({})
    const [AddProcc, setAddProcss] = useState([{ action: "", start_date: "", end_date: "", admission_procedure_id: null}])
    const [docDetail, setDocDetail] = useState([{ document_name: "", sno: "", document_id: "" }])



    useEffect(() => {
        if(props.formDataSet==true){
        getFromDetails();}
    }, [])

   
    const savedetails = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/${props.settingid}/application-procedure-all/add?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                procedure_message: message,
                "procedure_label": Object.values(label),
                "action_detail": AddProcc,
                "document_detail": docDetail,
            }
        }
        api.postCustom(request).then(data => {
            setLoading(false)
            toast.success("Form Edited successfully.");
            props.setEdit(false)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const updatedetails = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/${props.settingid}/application-procedure-all/update?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                procedure_message: message,
                "procedure_label": Object.values(label),
                "action_detail": AddProcc,
                "document_detail": docDetail,
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Form Updated successfully.");
            props.setEdit(false)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    const getFromDetails = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/application-procedure-all/${props.settingid}/get`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setMessage(data.data.result.procedure_message)
            let temp = {}
            data.data.result.procedure_label?.forEach((element, index) => {
                temp = { ...temp, [`k${index + 1}`]: element }
            });
            setLabel(temp)
            setAddProcss(data.data.result.action_detail)
            setDocDetail(data.data.result.document_detail)
            props.setfromData(data.data.result)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const deleteAdmission = (index) => {
        let data = { ...AddProcc[index] };
        console.log(data, "data")
        let id = data.admission_procedure_id;
        if (id == "") {
            setAddProcss(AddProcc.filter((data3, index3) => index3 != index))
        }
        else {
            let request = {
                url: `website/institute/admission-setting/application-procedure/detail/${id}/delete`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.delete(request).then(data => {
                toast.success("Admission Procedure deleted successfully")
                setAddProcss(AddProcc.filter((data3, index3) => index3 != index))
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }


    const deleteDocumnet = (index) => {
        let data = { ...docDetail[index] };
        let id = data.document_id;
        if (id == "") {
            setDocDetail(docDetail.filter((data3, index3) => index3 != index))
        }
        else {
            let request = {
                url: `website/institute/admission-setting/application-procedure/document/${id}/delete`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.delete(request).then(data => {
                toast.success("Document deleted successfully")
                setDocDetail(docDetail.filter((data3, index3) => index3 != index))
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }




    return (

        <><section class="middle-top-v bulk-header-v display-v">
            <div>
                <h1 className="Heading-v">Edit Admission Process Details</h1>
            </div>
        </section><div class="p-16-2">
                <ReactQuill theme="snow" placeholder="Write your message here..." value={message} onChange={(value) => setMessage(value)} style={{ backgroundColor: '#f9f9f9' }} />

                <div class="scroll-solve-v">
                    <div class="progress_bar-v">
                        <div>

                            <div>
                                <button>

                                </button>
                                <div></div>
                            </div>
                            <input type="text" class="process-edit-v" placeholder="Enter" value={label?.k1} onChange={(e) => setLabel({ ...label, k1: e.target.value })} />
                        </div>
                        <div>

                            <div>
                                <button>
                                </button>
                                <div></div>
                            </div>
                            <input type="text" class="process-edit-v" placeholder="Enter" value={label?.k2} onChange={(e) => setLabel({ ...label, k2: e.target.value })} />
                        </div>
                        <div>

                            <div>
                                <button>

                                </button>
                                <div></div>
                            </div>
                            <input type="text" class="process-edit-v" placeholder="Enter" value={label?.k3} onChange={(e) => setLabel({ ...label, k3: e.target.value })} />
                        </div>
                        <div>

                            <div>
                                <button>

                                </button>
                                <div></div>
                            </div>
                            <input type="text" class="process-edit-v" placeholder="Enter" value={label?.k4} onChange={(e) => setLabel({ ...label, k4: e.target.value })} />
                        </div>
                        <div>

                            <div>
                                <button>

                                </button>

                            </div>
                            <input type="text" class="process-edit-v" placeholder="Enter" value={label?.k5} onChange={(e) => setLabel({ ...label, k5: e.target.value })} />

                        </div>
                    </div>
                </div>
                <p class="bold-v">Admission Procedure Details
                </p>
                <div class="table_head1 mob-res">
                    <div>Sr No.</div>
                    <div>Action </div>
                    <div>Date </div>
                    <div> Last Date</div>
                    <div class="center-v"><a> <img src={add} onClick={() => { setAddProcss([...AddProcc, { action: "", start_date: "", end_date: "", admission_procedure_id: null }]) }} /></a>
                    </div>
                </div>

                {
                    AddProcc.map((data, index) => {
                        return (
                            <div class="scrool1-1" key={index}>
                                <div class="table_body1 mob-res">
                                    <div><label>Sr No.</label>{index + 1} </div>
                                    <div> <label>Action </label><input type="text" class="process-edit-v" value={data.action} placeholder="Enter"
                                        onChange={(e) => {
                                            let temp = [...AddProcc]
                                            temp[index]['action'] = e.target.value
                                            setAddProcss([...temp])
                                        }} /></div>
                                    <div><label>Date </label><input type="date" class="process-edit-v" value={data.start_date} placeholder="Enter"
                                        onChange={(e) => {
                                            let temp = [...AddProcc]
                                            temp[index]['start_date'] = e.target.value
                                            setAddProcss([...temp])
                                        }} /></div>
                                    <div> <label> Last Date</label><input type="date" class="process-edit-v" value={data.end_date} placeholder="Enter"
                                        onChange={(e) => {
                                            let temp = [...AddProcc]
                                            temp[index]['end_date'] = e.target.value
                                            setAddProcss([...temp])
                                        }} /></div>
                                    <div class="center-v"><label>Delete</label><a><img src={deleteb} title="Delete" onClick={() => deleteAdmission(index)} /></a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <p class="bold-v">Documents Required
                </p>
                <div class="table_head1 w-40-v">
                    <div>Sr No.</div>
                    <div>Document name </div>
                    <div class="center-v"><a> <img src={add} onClick={() => { setDocDetail([...docDetail, { document_name: "", sno: "", document_id: "" }]) }} /></a></div>
                </div>

                {
                    docDetail.map((item, index) => {
                        return (
                            <div class="scrool1-1" key={index}>
                                <div class="table_body1 w-40-v">
                                    <div>{index + 1}</div>
                                    <div><input type="text" class="process-edit-v" placeholder="Enter" value={item.document_name} onChange={(e) => {
                                        let temp = [...docDetail]
                                        temp[index]['document_name'] = e.target.value
                                        temp[index]['sno'] = index + 1
                                        setDocDetail([...temp])
                                    }} /></div>
                                    <div class="center-v"><a><img src={deleteb} title="Delete" onClick={() => deleteDocumnet(index)} /></a></div>
                                </div>
                            </div>
                        )
                    })
                }

            </div><div class=" bottom-button-v">
                <div class="display-v end">
                    <button class="white_button-v" onClick={() => props.setEdit(false)}>Cancel</button>
                    {props.formDataSet==true?
                    <button class="blue_button-v ml-16-v" onClick={() => updatedetails()}>Update</button>
                    :<button class="blue_button-v ml-16-v" onClick={() => savedetails()}>Save</button>
                    }
                   
                </div>
            </div></>

    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id })

export default connect(mapStateToProps)(EditForm)