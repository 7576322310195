import React, { useState, useEffect } from 'react';
import '../../css/superAdmin/sa_reports.css'
import { errorMsg } from "../../utils";
import Loader from '../../components/loader'
import api from '../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import DailyReportData from './dailyreportdata';




function DailyReport(props) {
    const [selectedTab, setSelectedTab] = useState("School")
    const [rdata, setRdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const [FromDate, setFromDate] = useState("2022-07-01")
    const [ToDate, setToDate] = useState("2022-12-31")
    const [allData, setallData] = useState([])
    const [schoolData, setSchoolData] = useState([])
    const [coachingData, setCoachinData] = useState([])
    const [educatorData, setEducatorData] = useState([])
    const [active, setActive] = useState(1)
    const [total, setTotal] = useState("")
    const [userId, setUserId] = useState(props.up_id)


    useEffect(() => {
        fetchData(1);
        getUser()
    }, [])


    const getUser = () => {
        setLoading(true)
        let request = {
            url: `user/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

  

    const fetchData = (active) => {

        setLoading(true)
        let request = {
            url: `report/daily-reports?uid=${userId}&f_date=${FromDate}&t_date=${ToDate}&action=ALL&pageNumber=${active}&pageSize=10&sortBy`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setallData(data.data.result)
            setSchoolData(data.data.result.daily_report_respnose_for_school)
            setCoachinData(data.data.result.daily_report_respnose_for_coaching)
            setEducatorData(data.data.result.daily_report_respnose_for_educator)


        }).catch((err) => {
            console.log(err,"errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const handlePageChange2 = (active) => {
        setActive(active);
        fetchData(active)
    }


console.log(active,"begin")


    return (
        <>
            {loading && <Loader />}
            <div className="entire_reports">
                <div className="sa_reports_inner">
                    <div className="reports_heading_and_cards">
                        <div className="sa_reports_heading2">
                            <div>
                                <font onClick={() => { props.setShow("reports_initial_page") }}
                                    style={{
                                        cursor: "pointer",
                                        // border:"1px solid gray",
                                        borderRadius: "4px",
                                        color: "#0097F9",
                                        background: "rgb(236, 230, 230);",
                                    }}
                                >
                                    {" Reports > "}</font>Daily Activity Reports</div>


                        </div>


                        <div style={{ height: "50px", display: "flex", flexDirection: "row", justifyContent: "space-between", background: "#FFFFFF" }}>

                            <div style={{ marginLeft: "20px" }}>
                                <div className="Sa-dropdown">
                                    <section className="sa-wrapper2">
                                        <select name="Master Category" id="Master Category" className="Sa-input" style={{ fontSize: "13px" }} onChange={(e) => setUserId(e.target.value)} >
                                            <option value="">Select User</option>
                                            {rdata.map((item, key) => { { key = key } return (<option value={item.id}>{item.name}</option>) })}
                                        </select>
                                    </section>

                                </div>
                            </div>

                            <div className="Sa-dropdown3">
                                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }} >From date:</div>
                                <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} value={FromDate} onChange={(e) => { setFromDate(e.target.value) }} />
                            </div>
                            <div className="Sa-dropdown3">
                                <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                                <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} value={ToDate} onChange={(e) => { setToDate(e.target.value) }} />
                            </div>

                            <div className="sa-SeachButton">
                                <button className="Super-admin-save-button" onClick={() => fetchData(1)}>Apply</button>

                            </div>
                        </div>

                        <div className="sa_reports_cards" style={{ marginTop: "5px" }}>

                            <div className="sa_reports_cards_inner">

                                <div className="reports_cards" >
                                    <center>
                                        Coaching Institute
                                        <p>
                                            {allData.total_coachings}
                                        </p>
                                    </center>
                                </div>

                                <div className="reports_cards" >
                                    <center>
                                        School
                                        <p>
                                            {allData.total_schools}

                                        </p>
                                    </center>
                                </div>


                                <div className="reports_cards">
                                    <center>
                                        Educator
                                        <p>
                                            {allData.total_educators}
                                        </p>
                                    </center>
                                </div>

                            </div>

                        </div>
                        <div className="sa-LeadsNav44">
                            <div className="sa-leadsHead22">

                                <span className={selectedTab == "School" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("School"); setSchoolData(allData.daily_report_respnose_for_school); setTotal(allData.total_schools); setActive(1) }}>School</span>
                                <span className={selectedTab == "Coaching" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("Coaching"); setSchoolData(allData.daily_report_respnose_for_coaching); setTotal(allData.total_coachings); setActive(1) }}>Coaching</span>
                                <span className={selectedTab == "Educator" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("Educator"); setSchoolData(allData.daily_report_respnose_for_educator); setTotal(allData.total_educators); setActive(1) }}>Educator</span>

                            </div>
                        </div>

                        <DailyReportData
                            schoolData={schoolData} coachingData={coachingData} educatorData={educatorData} />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={active}
                                itemsCountPerPage={10}
                                totalItemsCount={total}
                                pageRangeDisplayed={10}
                                onChange={(n) => { handlePageChange2(n); }} />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(DailyReport)

