import React,{useState,useEffect} from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


export default function BranchesForm(props){
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [branches,setBranches]=useState([])
    const [name ,setname] =useState([])
   
 

    useEffect(() => {
        if(props.id !=0){
            props.setData(props.id)
        }
      }, [props.id])


    
    const savedetails = () => {

        setLoading(true)

        let request = {
            url: `/inst/d342dc5a-712f-4424-9115-56d92b58c410/sub-branch-det`,
            headers: {
                'Authorization': ""
            },
            data: {
            }
        }
        api.getCustom(request).then(data => {
            setLoading(false)
           setBranches(Object.entries(data.data.result)  )
        }).catch((err) => {
            setLoading(false)
       if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
    }


    return(
        <>
          {loading && <Loader />}
         <div class="form-section">
                <div>
                    <label>Add Branch</label>
                    <p class="line_purple"></p>
                    {/* <!--  <p class="data-info mt10">
                    You can display the companies who hired your students to be displayed on your profile .
                  </p> --> */}
                    <label>If you want to use data from Existing Branches then select Branch from Below</label>
                    <div class="select-wrapper w-450">
                        <select class="expandale-input w-450" value={props.data} onChange={(e)=>props.setData(e.target.value)}>
                            <option>Select</option>    
                            {props.rdata.map((item,key)=>{ {key=key} return(<option value={item.br_id}>{item.br_nm}</option>)})}
                            
                        </select>
                    </div>

                    <p class="class-or">
                        Or
                    </p>

                    <label>If you want to create new branch then enter Branch name below and Click on Create </label>
                    <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" onChange={(e)=>setname(e.target.value)}/>
                </div>

                <div class="button-section">
                    <div></div>
                    <div>
                        <button class="white_button mr16" onClick={()=> props.setForm(false)}>Cancel</button>
                        <button class="blue_button" onClick={() =>{
                            if(name !=""){
                                history.push(`/institute/profile/?type=${props.p_type==2?"school":"institute"}`,{name:name,state:'branch',new:true,edit:false})
                            }else{
                                history.push(`/institute/profile/?type=${props.p_type==2?"school":"institute"}?id=${props.data}`,{state:'branch',new:false,edit:false})
                            }
                            }} >Create</button>
                    </div>
                </div>
            </div>
        
        </>
    )
}