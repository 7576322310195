import React, { useState } from 'react'
import Popup from '../enquire_popup'
import edit from '../../../assets/img/header/edit.svg'


function Data(props) {
    const [showPopup, setShowPopup] = useState("false")

    console.log(showPopup)

    return (


        <>


      {showPopup == true &&
           <Popup
           setShowPopup={setShowPopup}
           
           />
       }


            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr>
                        <th>PD</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Enquiry Type</th>
                        <th>Generated On</th>
                        <th>Status</th>
                        <th>Last Update By</th>
                        <th>Last Update Time</th>
                        <th>Action</th>
                    </tr>
                    {
                        props.data.map((data, key) => (data.title.toLowerCase().indexOf(props.search.toLowerCase()) != -1) && <tr key={key}>
                            <td>{data.id}</td>
                            <td>{data.title}</td>
                            <td>{data.Email}</td>
                            <td>{data.Mobile}</td>
                            <td>{data.EnquiryType}</td>
                            <td>{data.Generated0n}</td>
                            <td>{data.Status}</td>
                            <td>{data.lub}</td>
                            <td>{data.lut}</td>
                            <td>
                            <img src={edit}   onClick={() => {props.setSelected(data.id); setShowPopup(true)}} style={{ width: "20px", height: "20px",marginRight:"5px" ,cursor:"pointer"}}  />
                                </td>


                            {/* <td><button onClick={() => props.setSelected(data.id)}>EDIT</button></td> */}
                        </tr>)
                    }



                </table>


            </div>

       {setShowPopup== "true" &&
            <Popup />
       }


        </>
    );
}

export default Data;