import React, {useState, useEffect} from 'react'
import MasterSearch from '../../../components/masterSearch'
import Loader from '../../../components/loader'
import Data from './data'
import Operate from '../../enquires/operate'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';


function EnquiresEducator(props) {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)

   
    return (
        <>
            
            <div className="Sa-Profile2">
                {/* <Operate fetchData={fetchData} setLoading={setLoading} selected={selected} setSelected={setSelected} value={search} onChange={setSearch}   data={rdata.find(data => data.id == selected)} /> */}
 
                <Data data={props.rdata} setSelected={setSelected} search={search} />
        
            </div>




        </>
    );
}

export default EnquiresEducator;