import React, { useState, useEffect } from "react";
import EducatorBlock from "./educator_block";
import EducatorForm from "./educator_form";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { errorMsg } from "../../../../utils";
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import ProfileHeader from "../profile_header";

export function Educator(props) {
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [deletepopup, setdelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [Mdata, setMdata] = useState([]);
  const [next, setNext] = useState(false)
  let standard = localStorage.getItem("stnadard")


  useEffect(() => {
    fetchData();
    fetchrequest();
  }, [])


  const fetchData = () => {
    if (props.prof_id == "") {
      toast.error("Please Add Basic Details First.")
    }else if(standard=='false'){
      toast.error("Please Add Standard.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/all?inst_id=${props.prof_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }






  const fetchrequest = () => {
    if (props.prof_id == "") {
     return(false)
    }else if(standard=='false'){
      return(false)
    } else {
    setLoading(true)
    let request = {
      url: `educator/link/all?i_p_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMdata(data.data.result.response)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })}
  }



  return (
    <>
      {loading && <Loader />}
      <ProfileHeader count={props.count} setCount={props.setCount}
        setButton={props.setButton}
        setEcount={props.setEcount}
        setEdit={props.setEdit}
        showform={showform}
        setNext={setNext}
        next={next}
      />
      {
        showform == false ?
          <EducatorBlock setLoading={setLoading} fetchrequest={fetchrequest} setform={setshowform} setRdata={setRdata} rdata={rdata} Mdata={Mdata} setSelected={setSelected} fetchData={fetchData} /> :
          <EducatorForm standard={standard} setform={setshowform} setCount={props.setCount} data={rdata.find(data => data.id == selected)} Mdata={Mdata} setRdata={setRdata} selected={selected} setSelected={setSelected} fetchData={fetchData} setNext={setNext} />

      }


    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Educator)