import React, { useState } from "react";



function Header(props) {

  let tabs = ['Addmission', 'Scholarship', 'Events', 'Achievements', 'FAQ'];
  let tab = [ 'Scholarship', 'Events', 'Achievements', 'FAQ'];
  let tabe = [ 'Scholarship', 'Events', 'Achievements','Placements', 'FAQ'];
  props.type=="institute" && tabs .splice(4, 0, 'Placements')
  return (<>
    <div>
    <div class="box-educator1 mob-v">
        <div class="scroll-solve">
          <div class="tab">
            {
                tabs.map(data => <button key="data" class={props.tab==data?"tags-e selected-tag":"tags-e"} onClick={() => { props.setTab(data); props.setForm(false) }}  >{data}</button>)
            }
          </div>
        </div>
        <div>
            {props.type=="institute" ?
            tabe.map(data => props.tab == data && <button class="blue_button" onClick={() => { props.setselected(0); props.setForm(true);}}>Add {data=='FAQ'?'Category':data}</button>):
            tab.map(data => props.tab == data && <button class="blue_button" onClick={() => { props.setselected(0); props.setForm(true);}}>Add {data=='FAQ'?'Category':data}</button>)
            }
          
        </div>
      </div>
    </div>
  </>)


}

export default Header