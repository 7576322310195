import React, { useState, useEffect } from 'react'
import arrow from '../../../assets/login/signin/arrow.png'
import loader2 from '../../../assets/login/signin/loader/loader2.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from 'react-redux'


function MultipleUser(props) {
    const multiuserData = JSON.parse(localStorage.getItem('multiuserData'));
    const[uid,setUid] = useState(0)
    const [roles,setRoles] = useState(0)
    let [loading, setLoading] = useState(false)
   

    console.log(multiuserData, "multiuserData")

    const onSubmit = () => {
            setLoading(true);
            api.post({ url: 'user/login-multi-user', data: { "uid": uid, "role_id": roles } }).then((data) => {
                localStorage.removeItem("login")
                localStorage.removeItem("newuserdata")
                console.log(data.data.result,"rolesss")
                localStorage.setItem("userdata", JSON.stringify(data.data.result));
                localStorage.setItem("token", (data.data.result.token));
                localStorage.setItem("name", (data.data.result.name));
                localStorage.setItem("roles", (data.data.result.role));
                localStorage.setItem("email", (data.data.result.email));
                localStorage.setItem("phone", (data.data.result.phone));
                localStorage.setItem("id", (data.data.result.up_id));
                localStorage.setItem("p_type", (data.data.result.p_type));
                localStorage.setItem("prof_id", data.data.result.c_id);
                 props.dispatch({
                    type: 'LOGIN_SUCCESS',
                    datalogin: data.data.result,
                    usertype: data.data.result.p_type,
                    userid: data.data.result.p_type,
                    loginToken:data.data.result.token,
                    up_id:data.data.result.up_id,
                    p_type:data.data.result.p_type
                });
                props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result.c_id });
                setTimeout(() => {window.location = '/coaching/home/'}, 500);
                setLoading(false);
            }).catch((err) => {
                console.log(err,"errr")
                setLoading(false);
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        
    }


console.log(uid,"uissj")

    return (
        <div className="signin">
            <h3>We Have found multiple accounts.</h3>
            <h4>Please select account to login</h4>

            <div style={{marginBottom:"20px"}}>
                {
                    multiuserData.map((item, key) => {
                        return (
                            <div key={key} >
                                <input type='radio' checked={item.uid==uid}  onClick={()=>{setUid(item.uid)}}/><span style={{ marginLeft: '20px' }} >{item.name} {item.type!='STAFF' && ' - Admin'}</span>

                                {
                                    item.type=='STAFF' &&
                                    item.roles.map((data,key)=>{return(<div style={{ marginLeft: '30px' }}><input type='radio' checked={data.id==roles} onClick={()=>{setRoles(data.id);setUid(item.uid)}}/><span style={{ marginLeft: '20px' }}>{data.name}</span></div>)})
                                }
                            </div>
                        )
                    })
                }
            </div>
            {
                loading ?
                    <div className="loginProgress"><img src={loader2} /></div>
                    :
                    <button onClick={onSubmit} className="flex-row">Proceed<img src={arrow} /></button>
            }

            {/* <h4 className="inst" style={{margin: '10px 0 20px 0'}}><a onClick={() => props.setSelected('Login')}>Not an Institute ? <span>Go Back</span></a></h4> */}
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}


const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(MultipleUser)