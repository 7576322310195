import React, { useState, useEffect, Profiler } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import ProfileMang from '../../../profilemanagmnet';
import close from '../../../assets/img/close.svg'
import { errorMsg } from '../../../utils'
import { connect } from 'react-redux'

const mdata = ProfileMang[config.env]


function Operate(props) {
    const [title, setTitle] = useState("")
    const [photo, setphoto] = useState("")
    const [image, setimage] = useState("")
  


    useEffect(() => {
        if (props.selected != 0) {
            setTitle(props.data.name)
            setimage(props.data.f_url)
            setphoto(props.data.f_url)
        }
    }, [props.selected])



    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title,"uid":props.up_id}));
            fd.append('photo', photo);
            let request = {
                url: `department/${props.data.entity_id}/update`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Department updated Successfully.")
                setTitle("");
                setimage("");setphoto("")
                props.setSelected(0);
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title ,"uid":props.up_id}));
            fd.append('photo', photo);
            let request = {
                url: `department/add`,
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Department added Successfully. ")
                setTitle("");
                setimage("");
                setphoto("");
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }
  
   

    const OnCancel = () => {
        setTitle("");
        setimage("")
    }



    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }

    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Department")
            return false
        } if (photo === '') {
            toast.error("Please Upload Image")
            return false
        } 
        return true
    }


    return (
        <>
            <div className="Sa-AddProfile">

                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Department</p> : <p style={{ fontSize: "15px" }}>Add Department</p>
                }
                <div className="Super-admin-line_purple"></div>
              

                <div className="Sa-Inputbox">
                    <div >

                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Department " style={{ fontSize: "13px" }} />


                    </div>
                    <div >

                        <input type="file" accept="image/*" contenteditable="true" placeholder='Upload Icon*' onChange={select_file} style={{ fontSize: "13px", borderBottom: "1px solid #939393", width: '70%' }} />
                        {
                            image != "" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close} onClick={() => { setimage("") }} />
                                </div>
                            </div>

                        }


                    </div>
                </div>


                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => OnCancel()}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }

                <ToastContainer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)
