import React, {useState} from 'react'
import Slider from './slider'
import Signin from './signin'
import SigninNext from './signinNext'
import Login from './login'
import MultipleUser from './multipleUser'
import Otp from './otp'
import style from '../../../css/style.css'
import website from '../../../css/website.css'
import close from '../../../assets/login/signin/close.png'

export default function Index(props) {
    const [selected, setSelected] = useState('Signin')
    return (
        <div className="modal-container">
            <div className="signin-div flex-row">
                <div className="signin-left-div"><Slider /></div>
                <div className="signin-right-div">
                    {/* <div className="close"><img onClick={() =>  props.setShowsignin()} src={close} /></div> */}
                    {selected == 'Login' && <div><Login setSelected={setSelected} /></div>}
                    {selected == 'Otp' && <div><Otp setSelected={setSelected}  close={() =>  props.setShowsignin()} /></div>}
                    {selected == 'Signin' && <div><Signin setSelected={setSelected} /></div>}
                    {selected == 'SigninNext' && <div><SigninNext setSelected={setSelected}  /></div>}
                    {selected == 'MultipleUser' && <div><MultipleUser setSelected={setSelected}  /></div>}
                    
                </div>
                 
            </div>
        </div>
    )
}
