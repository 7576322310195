import React, { useState, useEffect } from "react";
import add from '../../../../assets/img/add.svg'
import unexpad from '../../../../assets/img/un-expand.svg'
import close from '../../../../assets/img/close.svg'
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import pdf from '../../../../assets/img/pdf.png'
import xls from '../../../../assets/img/xls.png'
import pic from '../../../../assets/img/pic.svg'
import { connect } from "react-redux";
import config from '../../../../config'
import { errorMsg } from '../../../../utils'
import masters from "../../../../masters";



const mdata = masters[config.env]


function StandardForm(props) {
  let type = window.location.href.split("?")[1].split("=")[1]

  const [standard, setStandard] = useState({
    standard: "",
    modesofTeaching: "",
    totalSeats: "",
    UploadFile: "",
    Description: "",
    Eligibiity: "",
    keyPoints: "",
    syllabus: "",
    feeType: "",
    fees: "",
    frequency: "",
    selectDays: "",
    from: "",
    to: "",
  })
  const [dataId, setdataId] = useState("")
  const [image, setimage] = useState([])
  const [etitle, seteTitle] = useState("")
  const [value, setValue] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [addCourse, setAddCourse] = useState('');
  const [datefrom, setDatefrom] = useState('')
  const [dateto, setDateto] = useState('')
  const [modesofTeaching, setmodesofTeaching] = useState([])
  const [rdata, setRdata] = useState([])
  const [feeTypes, setFeetype] = useState([])
  const [oimage, setOimage] = useState([])
  const [stan, setStan] = useState([])
  const [mastercourse, setMastercourse] = useState([])
  const [mastercourseStream, setMastercourseStream] = useState([])
  const [loading, setLoading] = useState(true)
  const [feetype, setfeetype] = useState([{ f_type_id: "0", amt: "", freq: "0", is_act: true, is_std: true, id: "", oth_ftp_nm: "" }])
  const [feetypeCourse, setfeetypecourse] = useState([{ f_type_id: "0", amt: "", freq: "0", is_act: true, is_std: false, id: "", oth_ftp_nm: "" }])
  const [timing, setTiming] = useState([{ f_timing: "08:00", from_day: 'MONDAY', to_day: 'SATURDAY', t_timing: "14:00", is_act: true, is_std: true, id: "" }])
  const [timingCourse, setTimingCourse] = useState([{ f_timing: "08:00", from_day: 'MONDAY', to_day: 'SATURDAY', t_timing: "14:00", is_act: true, is_std: false, id: "" }])
  const [picture, setPicture] = useState("")
  const [imagess, setImagess] = useState("")
  const [department, setDepartment] = useState([])
  const [departmentValue, setDepartmentValue] = useState([])
  const [stream, setstream] = useState([])
  const [streamValue, setStreamValue] = useState("")






  useEffect(() => {
    if (props.selected != 0) {
      // console.log(props, "data")
      getData()
    }
    profiletype()
    fetchData()
    course()
    getStandard()
    getDepartment()


  }, [props.selected])



  const getData = () => {
    if (type == "school") {
      let id = props.data
      let request = {
        url: `inst-standard/${id.entity_id}/get`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        let dataa = data.data.result
        // console.log(dataa.fee_li.length, feetype, "result")
        setdataId(dataa.entity_id)
        setValue(dataa.description)
        setValue1(dataa.eligibility)
        setValue2(dataa.key_points)
        setValue3(dataa.syllabus)
        setOimage(dataa.file_li ?? [])
        setStandard({ ...standard, standard: dataa.mast_std_id, modesofTeaching: dataa.mof_teaching_id, totalSeats: dataa.total_seats })
        setTiming(dataa.schedule_li.length == 0 ? timing : (dataa.schedule_li.map((data) => ({ from_day: data.from_day ?? 'MONDAY', to_day: data.to_day ?? 'SATURDAY', f_timing: data.from_time, t_timing: data.to_time, is_act: data.active, is_std: true, id: data.entity_id }))))
        setfeetype(dataa.fee_li.length == 0 ? feetype : (dataa.fee_li.map((data) => ({ f_type_id: data.fees_type_id, amt: data.amount, freq: data.frequency, is_act: data.true, is_std: true, id: data.entity_id, oth_ftp_nm: data.other_fee_type_name }))))
      }).catch((err) => {
        console.log(err, "hsjhjh")
        // setLoading(false)
        toast.error(errorMsg(err));
      })
    } else {
      let id = props.cdata
      let request = {
        url: `inst-course/${id.entity_id}/get`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        let dataa = data.data.result
        // console.log(dataa, "result")
        setdataId(dataa.entity_id)
        setValue(dataa.description)
        setValue1(dataa.eligibility)
        setValue2(dataa.key_points)
        setValue3(dataa.syllabus)
        setImagess(dataa.photo_url)
        setPicture(dataa.photo_url)
        setOimage(dataa.file_li ?? [])
        setStandard({ ...standard, standard: dataa.mast_course_id, modesofTeaching: dataa.mof_teaching_id, totalSeats: dataa.total_seats })
        setTimingCourse(dataa.schedule_li.length == 0 ? timingCourse : (dataa.schedule_li.map((data) => ({ ...data, from_day: data.from_day ?? 'MONDAY', to_day: data.to_day ?? 'SATURDAY', sch_days: data.schedule_days, f_timing: data.from_time, t_timing: data.to_time, is_act: data.active, is_std: false, id: data.entity_id }))))
        setfeetypecourse(dataa.fee_li.length == 0 ? feetypeCourse : (dataa.fee_li.map((data) => ({ ...data, f_type_id: data.fees_type_id, amt: data.amount, freq: data.frequency, is_act: data.true, is_std: false, id: data.entity_id, oth_ftp_nm: data.other_fee_type_name }))))
        setAddCourse(dataa.course_name)
        setDatefrom(dataa.from_duration)
        setDateto(dataa.to_duration)
        setDepartmentValue(dataa.department_id)
        setStreamValue(dataa.stream_id)
        // getStream(dataa.department_id)
        // getCourse(dataa.stream_id)
      }).catch((err) => {
        // console.log("hsjhjh")
        // setLoading(false)
        toast.error(errorMsg(err));
      })
    }
  }

  useEffect(() => {
    departmentValue && getStream(departmentValue)
  }, [departmentValue])


  useEffect(() => {
    streamValue && getCourse(streamValue)
  }, [streamValue])




  console.log(departmentValue, "========", streamValue, "+++++++++", standard.standard, "00000")

  console.log(department, stream, mastercourseStream, "ohhhdhh")

  const profiletype = () => {
    let fee = mdata.find(data => data.name == "Fee Type")?.entity_id;
    let mode = mdata.find(data => data.name == "Mode of Teaching")?.entity_id;
    let request = {
      url: `master/all/sub-master/?mast_id_list=${mode},${fee}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setmodesofTeaching(data.data.result[mode])
      setFeetype(data.data.result[fee])
      //setMaster(prevState => ({...prevState ,  extracurricular :data.data.result[extra]}))
      // console.log(data.data.result[fee], "feetype")
    }).catch((err) => {
      toast.error(errorMsg(err));
    })
  }

  const fetchData = () => {
    // setLoading(true)
    let request = {
      url: `master-stand/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      // setLoading(false)
      setRdata(data.data.result)
    }).catch((err) => {
      // console.log("hsjhjh")
      // setLoading(false)
      toast.error(errorMsg(err));
    })
  }

  const validate = () => {
    if (standard.standard == '') {
      toast.error("Please Select Standard.")
      return false
    }
    if (standard.modesofTeaching == '') {
      toast.error("Please Select Mode of Teaching.")
      return false
    }
    //  if (value == '') {
    //   toast.error("Please Enter Description.")
    //   return false
    // }
    return true
  }


  const getDepartment = () => {
    setLoading(true)
    let request = {
      url: `stream/dept-wise-stream?isCourses=true`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setDepartment(data.data.result)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }
  const getStream = (id) => {
    let streamArrayFiltered = department.filter((item) => item.id == id)[0]?.sub_mst_li
    setstream(streamArrayFiltered ?? [])
  }

  const getCourse = (id) => {
    let streamArrayFiltered = stream.filter((item) => item.id == id)[0]?.sub_mst_li
    setMastercourseStream(streamArrayFiltered ?? [])
  }


  const savedetails = () => {
    if (validate()) {
      let fee = feetype.filter((item) => (item.f_type_id != 0 && item.amt != "" && item.freq != 0))
      let schedule = timing.filter((item) => (item.sch_days != 0 && item.f_timing != "" && item.t_timing != ""))
      let feeCourse = feetypeCourse.filter((item) => (item.f_type_id != 0 && item.amt != "" && item.freq != 0))
      let scheduleCourse = timingCourse.filter((item) => (item.sch_days != 0 && item.f_timing != "" && item.t_timing != ""))
      if (type == "school") {
        setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({
          i_prof_id: props.prof_id,
          mst_std: standard.standard,
          is_s_pro: false,
          t_seats: standard.totalSeats,
          eligibility: value1,
          k_points: value2,
          desc: value,
          uid: props.up_id,
          mot: standard.modesofTeaching,
          syll: value3,
          fee_li: fee,
          schedule_li: schedule,
        }));
        for (let index = 0; index < image.length; index++) {
          fd.append('files', image[index]);
        }

        let request = {
          url: `inst-standard/add `,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
          data: fd
        }
        if (dataId == "") {
          api.postCustom(request).then(data => {
            toast.success("Standard Added Successfully.")
            setLoading(false)
            setdataId("")
            props.setshowform(false)
            props.fetchData()
          }).catch((err) => {
            setLoading(false)
            toast.error(errorMsg(err))
          })
        } else {
          request.url = 'inst-standard/' + dataId + '/update/'
          api.putOther(request).then(data => {
            toast.success("Standard updated successfully.")
            setLoading(false)
            setdataId("")
            props.setshowform(false)
            props.fetchData()
          }).catch((err) => {
            setLoading(false)
            toast.error(errorMsg(err))
          })
        }
      }
      else {
        setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({
          i_prof_id: props.prof_id,
          mst_course: standard.standard,
          is_s_pro: false,
          t_seats: standard.totalSeats,
          eligibility: value1,
          k_points: value2,
          desc: value,
          mot: standard.modesofTeaching,
          syll: value3,
          c_frm_dt: datefrom,
          c_to_dt: dateto,
          c_name: addCourse,
          uid: props.up_id,
          fee_li: feeCourse,
          stream_id: streamValue,
          schedule_li: scheduleCourse,
        }));



        for (let index = 0; index < image.length; index++) {
          fd.append('files', image[index]);
        }

        fd.append('photo', picture);

        let request = {
          url: `inst-course/add`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
          data: fd
        }
        if (dataId == "") {
          api.postCustom(request).then(data => {
            toast.success("Course Added successfully.")
            setLoading(false)
            props.setshowform(false)
            props.fetchCources()
          }).catch((err) => {
            setLoading(false)
            toast.error(errorMsg(err))
          })
        } else {
          request.url = 'inst-course/' + dataId + '/update/'
          api.putOther(request).then(data => {
            toast.success("Course updated successfully.")
            setLoading(false)
            props.setshowform(false)
            props.fetchData()
          }).catch((err) => {
            setLoading(false)
            toast.error(errorMsg(err))
          })
        }
      }
    }

  }

  // console.log(feetype.map((item, i) => item.f_type_id), "pppppppp")

  const validateImage = (e) => {
    console.log(e.target.files[0].size, "kjngnghg")
    let img = e.target.files[0].size / 1024
    console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only.")
      return false
    }
    else return true
  }

  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      if ([...oimage, ...image].length > 4) {
        toast.error("You are only allowed to upload a maximum of 5 files")
      } else {
        if (e.target.files.length >= 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          setimage([...image, ...imgs])
          getExtension(image.name)
        }
      }
    }
  }


  const getExtension = (name) => {
    if (name === undefined || name === null) {
      return '';
    }
    let str = name.split('.');
    // console.log(str[str.length - 1], "prrere")
    return str[str.length - 1];
  }


  const course = () => {
    setLoading(true)
    let request = {
      url: `course/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMastercourse(data.data.result)
    }).catch((err) => {
      // console.log("hsjhjh")
      setLoading(false)
      toast.error(errorMsg(err));
    })
  }

  const getStandard = () => {
    setLoading(true)
    let request = {
      url: `master-stand/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setStan(data.data.result)
    }).catch((err) => {
      setLoading(false)

    })
  }

  const deleteFeeType = (index) => {
    if (type == 'school') {
      let data = { ...feetype[index] };
      let id = data.id;
      // console.log(data, "iddd")
      if (id == "") {
        setfeetype(feetype.filter((data3, index3) => index3 != index))
      }
      else {
        let request = {
          url: `std-course-fee-struct/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.delete(request).then(data => {
          toast.success("Fee Type Deleted successfully")
          getData()
          // props.setRdata(props.rdata.filter((item3) => item3.id != id))
        }).catch((err) => {
          setLoading(false)
          toast.error(errorMsg(err))
        })
      }
    }
    else {
      let data = { ...feetypeCourse[index] };
      let id = data.id;
      // console.log(data, "iddd")
      if (id == "") {
        setfeetypecourse(feetypeCourse.filter((data3, index3) => index3 != index))
      }
      else {
        let request = {
          url: `std-course-fee-struct/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.delete(request).then(data => {
          toast.success("Fee Type Deleted successfully")
          getData()
          // props.setRdata(props.rdata.filter((item3) => item3.id != id))
        }).catch((err) => {
          setLoading(false)
          toast.error(errorMsg(err))
        })
      }
    }
  }

  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3, index) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
      toast.error(errorMsg(err))

    })

  }


  const onChange = (e) => {
    if (validateImage(e)) {
      setPicture(e.target.files[0]);
      setImagess(URL.createObjectURL(e.target.files[0]));
    }
  }



  const deleteTimings = (index) => {
    if (type == 'school') {
      let data = { ...timing[index] };
      let id = data.id;
      // console.log(data, "iddd")
      if (id == "") {
        setTiming(timing.filter((data4, index4) => index4 != index))
      }
      else {
        let request = {
          url: `std-course-schedule/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.delete(request).then(data => {
          toast.success("Fee Type Deleted successfully")
          getData()
          // props.setRdata(props.rdata.filter((item3) => item3.id != id))
        }).catch((err) => {
          setLoading(false)
          toast.error(errorMsg(err))
        })
      }
    } else {
      let data = { ...timingCourse[index] };
      let id = data.id;
      // console.log(data, "iddd")
      if (id == "") {
        setTimingCourse(timingCourse.filter((data4, index4) => index4 != index))
      }
      else {
        let request = {
          url: `std-course-schedule/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.delete(request).then(data => {
          toast.success("Fee Type Deleted successfully")
          getData()
          // props.setRdata(props.rdata.filter((item3) => item3.id != id))
        }).catch((err) => {
          setLoading(false)
          toast.error(errorMsg(err))
        })
      }
    }

  }


  return (
    <>
      {loading && <Loader />}
      <div>
        <div class="form-section ">
          {
            type == 'school' ?
              <div>
                {
                  props.selected ?
                    <p class="heading">Edit Standard</p> :
                    <p class="heading">Add Standard</p>
                }
                <p class="line_purple"></p>
                <p class="data-info mt10">Please add details of each standard that is available in your school. This
                  standard will be shown your Proctur Profile </p>
                <div class="display f-wrap">
                  <div>
                    <label>Select Standard<span>*</span></label>
                    <div class="select-wrapper">
                      <select class="expandale-input w-200" n value={standard.standard} onChange={(e) => { setStandard({ ...standard, standard: e.target.value }) }}>
                        <option>Select</option>
                        {rdata.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label>Mode of Teaching<span>*</span></label>
                    <div class="select-wrapper">
                      <select class="expandale-input w-200" value={standard.modesofTeaching} onChange={(e) => { setStandard({ ...standard, modesofTeaching: e.target.value }) }}>
                        <option>Select</option>
                        {modesofTeaching.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label>Total Seats</label>
                    <input class="expandale-input" contenteditable="true" placeholder="Enter" type="number" min={0} value={standard.totalSeats} onChange={(e) => { setStandard({ ...standard, totalSeats: e.target.value }) }} />
                  </div>

                </div>
              </div>
              :
              <><><div>
                {
                  props.selected ?
                    <p class="heading">Edit Course</p> :
                    <p class="heading">Add Course</p>
                }
                <p class="line_purple"></p>
                <p class="data-info mt10">Please add details of each standard that is available in your school. This
                  Course will be shown your Proctur Profile </p>

                <div>
                  <label>Add Course Name<span>*</span></label>
                  <input class="expandale-input" contenteditable="true" placeholder="Enter" value={addCourse} onChange={(e) => setAddCourse(e.target.value)} />
                </div>

              </div>

                <div>
                  <label>Upload Thumbnail<span>*</span></label>
                  <label htmlFor="photo-upload" >
                    <div   >{picture == "" ?
                      <img for="photo-upload" class="pro-pic" src={pic} /> :
                      <img for="photo-upload" class="pro-pic" src={imagess} />
                    }
                    </div>
                    <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
                      display: "none",
                      visibility: "hidden"
                    }} />
                  </label>

                  <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
                </div>
              </>
                <div class="display f-wrap">
                  {
                    type == 'college' &&
                    <><div>
                      <label>Department<span>*</span></label>
                      <div class="select-wrapper">
                        <select class="expandale-input w-200" value={departmentValue} onChange={(e) => { getStream(e.target.value); setDepartmentValue(e.target.value); }}>
                          <option>Select</option>
                          {department.map((item) => { return (<option value={item.id}>{item.name}</option>); })}
                        </select>
                      </div>
                    </div><div>
                        <label>Stream<span>*</span></label>
                        <div class="select-wrapper">
                          <select class="expandale-input w-200" value={streamValue} onChange={(e) => { getCourse(e.target.value); setStreamValue(e.target.value); }}>
                            <option>Select</option>
                            {stream.map((item) => { return (<option value={item.id}>{item.name}</option>); })}
                          </select>
                        </div>
                      </div></>

                  }

                  <div>
                    {type == 'school' &&
                      <><label>Select Standard<span>*</span></label><div class="select-wrapper">
                        <select class="expandale-input w-200" n value={standard.standard} onChange={(e) => { setStandard({ ...standard, standard: e.target.value }); }}>
                          <option>Select</option>
                          {stan.map((item) => { return (<option value={item.entity_id}>{item.name}</option>); })}
                        </select>
                      </div></>}
                    {
                      type == 'institute' &&
                      <><label>Select Course<span>*</span></label><div class="select-wrapper">
                        <select class="expandale-input w-200" n value={standard.standard} onChange={(e) => { setStandard({ ...standard, standard: e.target.value }); }}>
                          <option>Select</option>
                          {mastercourse.map((item) => { return (<option value={item.entity_id}>{item.name}</option>); })}
                        </select>
                      </div></>
                    }
                    {type == 'college' &&
                      <><label>Select Course<span>*</span></label><div class="select-wrapper">
                        <select class="expandale-input w-200" n value={standard.standard} onChange={(e) => { setStandard({ ...standard, standard: e.target.value }); }}>
                          <option>Select</option>
                          {mastercourseStream.map((item) => { return (<option value={item.id}>{item.name}</option>); })}
                        </select>
                      </div></>
                    }


                  </div>
                  <div>
                    <label>Mode of Teaching<span>*</span></label>
                    <div class="select-wrapper">
                      <select class="expandale-input w-200" value={standard.modesofTeaching} onChange={(e) => { setStandard({ ...standard, modesofTeaching: e.target.value }) }}>
                        <option>Select</option>
                        {modesofTeaching.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label>Total Seats</label>
                    <input class="expandale-input" contenteditable="true" placeholder="Enter" type="number" min={0} value={standard.totalSeats} onChange={(e) => { setStandard({ ...standard, totalSeats: e.target.value }); }} />
                  </div>

                </div></>
          }
          <div>
            <label>Upload File </label>
            <p class="line_purple"></p>

            <p class="data-info">You can upload files related to admission process or any other important process</p>
            <div class="flex-p1">
              <input class="expandale-input w-450 " type="file" multiple onChange={select_MediaContent} accept="application/pdf,application/vnd.ms-excel,image/*" placeholder="Choose File" />
            </div>
            <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img, pdf, word</p>
            <div class="tags-img">
              {oimage.map((item) => {
                return (
                  <div >
                    {
                      item.f_disp.split(".")[1] == "pdf" ?
                        <img src={pdf} /> :
                        item.f_disp.split(".")[1] == "xls" ?
                          <img src={xls} /> :
                          <img src={item.f_url} />
                    }
                    {/* <img src={item.f_url} /> */}
                    <img src={close} onClick={() => { deleteImage(item.id) }} />
                    {/* Once deleted and response is 200 filter the oimage array and remove deleted image on the basis of ID  */}
                  </div>
                )
              })
              }
              {image.map((item) => {
                // console.log(item.name.split(".")[1], "setitem")
                return (
                  <div >
                    {
                      item.name.split(".")[1] == "pdf" ?
                        <img src={pdf} /> :
                        item.name.split(".")[1] == "xls" ?
                          <img src={xls} /> :
                          <img src={URL.createObjectURL(item)} />
                    }

                    <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                  </div>
                )
              })
              }
            </div>
          </div>
          <div>
            <label>Description</label>
            <p class="data-info">min 25 char and max 500 char</p>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </div>
          <div>
            <label>Eligibility</label>
            <ReactQuill theme="snow" value={value1} onChange={setValue1} />
          </div>
          <div>
            <label>Key Points / Advantages</label>
            <ReactQuill theme="snow" value={value2} onChange={setValue2} />
          </div>
          <div>
            <label>Syllabus </label>
            <ReactQuill theme="snow" value={value3} onChange={setValue3} />
          </div>

          {/* <FeeType feeType={feeType} /> */}



          {
            type == 'school' ?
              <div class="diff-position">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><img src={add} onClick={() => setfeetype([...feetype, { f_type_id: "0", amt: "", freq: "0", is_act: true, is_std: true, id: "", oth_ftp_nm: "" }])} /></div>
                {feetype.map((data, index) => {
                  // console.log(index, "setindex")
                  return <div class="display f-wrap" key={index}>
                    <div>
                      <label>Fee Type</label>

                      {
                        data.f_type_id == '37ee4e5a-3034-4026-aa99-bbf724aa2ccc' ?
                          <div>
                            <input class="expandale-input" contenteditable="true" placeholder="Enter" value={data.oth_ftp_nm} onChange={(e) => setfeetype(feetype.map((data2, index2) => index == index2 ? { ...data2, oth_ftp_nm: e.target.value } : data2))} type="text" />
                          </div> :
                          <div class="select-wrapper">
                            <select class="expandale-input w-200" value={data.f_type_id} onChange={(e) => setfeetype(feetype.map((data2, index2) => index == index2 ? { ...data2, f_type_id: e.target.value } : data2))}>
                              <option>Select</option>
                              {feeTypes.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                            </select>
                          </div>
                      }

                    </div>
                    <div>
                      <label>Fees</label>
                      <input class="expandale-input" contenteditable="true" placeholder="Enter" type="number" min={0} value={data.amt} onChange={(e) => setfeetype(feetype.map((data2, index2) => index == index2 ? { ...data2, amt: e.target.value } : data2))} />
                    </div>
                    <div>
                      <label>Frequency</label>
                      <div class="select-wrapper">
                        <select class="expandale-input w-200" value={data.freq} onChange={(e) => setfeetype(feetype.map((data2, index2) => index == index2 ? { ...data2, freq: e.target.value } : data2))}>
                          <option>Select</option>
                          {["Montly", "Quarterly", "Yearly", "Installment"].map((item) => { return (<option>{item}</option>) })}
                        </select>
                      </div>
                    </div>
                    {index > 0 &&
                      <img src={unexpad} onClick={() => deleteFeeType(index)} />
                    }

                  </div>
                })}
              </div> : <div class="diff-position">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}><img src={add} onClick={() => setfeetypecourse([...feetypeCourse, { f_type_id: "0", amt: "", freq: "0", is_act: true, is_std: false, id: "", oth_ftp_nm: "" }])} /></div>
                {feetypeCourse.map((data, index) => {
                  return <div class="display f-wrap" key={index}>
                    <div>
                      <label>Fee Type</label>
                      {
                        data.f_type_id == '37ee4e5a-3034-4026-aa99-bbf724aa2ccc' ?
                          <div>
                            <input class="expandale-input" contenteditable="true" placeholder="Enter" value={data.oth_ftp_nm} onChange={(e) => setfeetype(feetype.map((data2, index2) => index == index2 ? { ...data2, oth_ftp_nm: e.target.value } : data2))} type="text" />
                          </div> : <div class="select-wrapper">
                            <select class="expandale-input w-200" value={data.f_type_id} onChange={(e) => setfeetypecourse(feetypeCourse.map((data2, index2) => index == index2 ? { ...data2, f_type_id: e.target.value } : data2))}>
                              <option>Select</option>
                              {feeTypes.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                            </select>
                          </div>
                      }
                    </div>
                    <div>
                      <label>Fees</label>
                      <input class="expandale-input" contenteditable="true" placeholder="Enter" type="number" min={0} value={data.amt} onChange={(e) => setfeetypecourse(feetypeCourse.map((data2, index2) => index == index2 ? { ...data2, amt: e.target.value } : data2))} />
                    </div>
                    <div>
                      <label>Frequency</label>
                      <div class="select-wrapper">
                        <select class="expandale-input w-200" value={data.freq} onChange={(e) => setfeetypecourse(feetypeCourse.map((data2, index2) => index == index2 ? { ...data2, freq: e.target.value } : data2))}>
                          <option>Select</option>
                          {["Montly", "Quarterly", "Yearly", "Installment"].map((item) => { return (<option>{item}</option>) })}
                        </select>
                      </div>
                    </div>
                    {index > 0 &&
                      <img src={unexpad} onClick={() => deleteFeeType(index)} />
                    }
                  </div>
                })}

              </div>

          }

          {/* <Schedule/> */}

          {
            type == 'school' ?
              <div class="diff-position">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <img src={add} onClick={() => setTiming([...timing, { sch_days: "", f_timing: "", t_timing: "", is_act: true, is_std: true, id: "" }])} /> */}
                </div>
                {timing.map((data, index) => {
                  return <div class="display f-wrap" key={index}>
                    <div>
                      {/* <label>School Timings</label> */}
                      {
                        type == 'school' ?
                          <label>School Timings</label> :
                          <label>Coaching Timings</label>
                      }
                      <div class="select-wrapper" className="inputNew">
                        <select class="expandale-input" value={data.from_day} onChange={(e) => setTiming(timing.map((data4, index4) => index == index4 ? { ...data4, from_day: e.target.value } : data4))}>
                          <option>Select Days</option>
                          {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDY", "FRIDAY", "SATURDAY"].map((item) => { return (<option value={item}>{item}</option>) })}
                        </select>
                        <div style={{ marginRight: '15px', marginTop: '5px' }}>to</div>
                        <select class="expandale-input" value={data.to_day} onChange={(e) => setTiming(timing.map((data4, index4) => index == index4 ? { ...data4, to_day: e.target.value } : data4))}>
                          <option>Select Days</option>
                          {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDY", "FRIDAY", "SATURDAY"].map((item) => { return (<option value={item}>{item}</option>) })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label>From</label>
                      <input class="expandale-input" type="time" placeholder="From" value={data.f_timing} onChange={(e) => setTiming(timing.map((data4, index4) => index == index4 ? { ...data4, f_timing: e.target.value } : data4))} />

                    </div>
                    <div>
                      <label>To</label>
                      <input class="expandale-input" type="time" placeholder="To" value={data.t_timing} onChange={(e) => setTiming(timing.map((data4, index4) => index == index4 ? { ...data4, t_timing: e.target.value } : data4))} />
                    </div>
                    {index > 0 &&
                      <img src={unexpad} onClick={() => deleteTimings(index)} />
                    }
                  </div>

                })}

              </div> :
              <div class="diff-position">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <img src={add} onClick={() => setTimingCourse([...timingCourse, { sch_days: "", f_timing: "", t_timing: "", is_act: true, is_std: false, id: "" }])} /> */}
                </div>
                {timingCourse.map((data, index) => {
                  return <div class="display f-wrap" key={index}>
                    <div>
                      {/* <label>School Timings</label> */}
                      {
                        type == 'school' ?
                          <label>School Timings</label> :
                          <label>Coaching Timings</label>
                      }
                      <div class="select-wrapper" className="inputNew">
                        <select class="expandale-input " value={data.from_day} onChange={(e) => setTimingCourse(timingCourse.map((data4, index4) => index == index4 ? { ...data4, from_day: e.target.value } : data4))}>
                          <option>Select Days</option>
                          {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDY", "FRIDAY", "SATURDAY"].map((item) => { return (<option value={item}>{item}</option>) })}
                        </select>
                        <div style={{ marginRight: '15px', marginTop: '5px' }}>to</div>
                        <select class="expandale-input" value={data.to_day} onChange={(e) => setTimingCourse(timingCourse.map((data4, index4) => index == index4 ? { ...data4, to_day: e.target.value } : data4))}>
                          <option>Select Days</option>
                          {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDY", "FRIDAY", "SATURDAY"].map((item) => { return (<option value={item}>{item}</option>) })}
                        </select>

                      </div>
                    </div>
                    <div>
                      <label>From</label>
                      <input class="expandale-input " type="time" placeholder="From" value={data.f_timing} onChange={(e) => setTimingCourse(timingCourse.map((data4, index4) => index == index4 ? { ...data4, f_timing: e.target.value } : data4))} />

                    </div>
                    <div>
                      <label>To</label>
                      <input class="expandale-input" type="time" placeholder="To" value={data.t_timing} onChange={(e) => setTimingCourse(timingCourse.map((data4, index4) => index == index4 ? { ...data4, t_timing: e.target.value } : data4))} />
                    </div>
                    {index > 0 &&
                      <img src={unexpad} onClick={() => deleteTimings(index)} />
                    }
                  </div>

                })}

              </div>

          }




          {type == 'institute' &&
            <><div>
              <label>Course Duration</label>
              <p class="line_purple"></p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <label>From</label>
                  <input class="expandale-input" contenteditable="true" onKeyDown={(e) => e.preventDefault()} type="date" value={datefrom} onChange={(e) => setDatefrom(e.target.value)} />

                </div>
                <div style={{ marginLeft: "100px" }}>
                  <label>To</label>
                  <input class="expandale-input" contenteditable="true" onKeyDown={(e) => e.preventDefault()} type="date" value={dateto} onChange={(e) => setDateto(e.target.value)} />

                </div>
              </div>
            </div></>
          }

          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => props.setshowform(false)}>Cancel</button>
              <button class="blue_button" onClick={() => { props.prof_id == "" ? toast.error("Please Add Basic Details First.") : savedetails() }}>Save</button>
            </div>
          </div>
        </div>
      </div>


      <div />

    </>
  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(StandardForm)
