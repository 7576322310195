import React, { useEffect, useState } from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { errorMsg } from "../../../utils";
import { ToastContainer, toast } from 'react-toastify';
import Delete from '../../../components/delete'
import { connect } from 'react-redux';
import Moment from 'moment';


function UserBlock(props) {
    const [deletepopup, setdelete] = useState(false)
    const [id, setid] = useState("")
    const [loading, setLoading] = useState(false)


    const deleteUser = () => {
        setdelete(false)
        setLoading(true)
        let request = {
            url: `user/${id}/delete`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.delete(request).then(data => {
            setLoading(false)
            toast.success("User Deleted successfully")
            props.setRdata(props.rdata.filter((item3) => item3.id != id))
            setid("")
            props.fetchData()
        }).catch((err) => {
            setLoading(false)
            setid("")
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    return (
        <>
            {loading && <Loader />}
            <div class="box-section1">
                <div>Users</div>
                <div><button class="blue_button" onClick={() => { props.setForm(true); props.setselected(0) }}>Add User</button></div>
            </div>
            <div class="box-educator">
                {
                    props.rdata.map((item,key) => {
                        {key=key}
                        return (

                            <div><div class="mb-8 flex-a ">
                                <img src={item.p_url} class="profile_pic" />
                                <div class="name-div">

                                    <p>{item.name}</p>
                                </div>
                            </div><div class="display added-by">
                                    <p>Created on :</p>
                                    <p>{Moment(item.c_date).format('DD-MM-YYYY')}</p>
                                </div><div class="line-v-grey">
                                </div><div class="flex">
                                    {/* <div class="flex-a">
                                        <span>Assigned Roles</span>
                                        <div class="dropdown-1">
                                            <div>
                                                <a>
                                                    <span class="assign_number">{item.roles}</span>
                                                </a>
                                                <div class="dropdown-content-1">
                                                    <p>Manager</p>
                                                    <p>Teacher</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="ml-a">
                                        <button class="editbutton" onClick={() => { props.setselected(item.id); props.setMdata(item); props.setForm(true) }}> Edit</button>

                                        <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                                    </div>
                                </div></div>

                        )
                    })
                }
            </div>
            {deletepopup == true &&
                <Delete delete={deleteUser} setdelete={setdelete} />
            }
            <ToastContainer />

        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken })

export default connect(mapStateToProps)(UserBlock)