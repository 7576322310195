import './backdrop_transparent.css'

function BackDropTransparent(props)
{
  return(
      <div className="backdrop_at_all_transparent" onClick={props.FromChildBackDrop}>

      </div>
  )
  
}

export default BackDropTransparent