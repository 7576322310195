import React, { useState, useEffect, useRef } from "react";
import mob from '../../../../assets/img/mobile.svg'
import whts from '../../../../assets/img/basic/whts.png'
import telephone from '../../../../assets/img/basic/telephone.png'
import add from '../../../../assets/img/add.svg'
import unexpad from '../../../../assets/img/un-expand.svg'
import ReactQuill from 'react-quill';
import api from '../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../../../utils'
import 'react-quill/dist/quill.snow.css';
import Autocomplete from "react-google-autocomplete";
import pic from '../../../../assets/img/pic.svg'
import { ToastContainer, toast } from 'react-toastify';





function YourProfile(props) {
  let type = window.location.href.split("?")[1].split("=")[1]
  const [image, setImage] = useState("")
  const [UpdatedImg, setUpdateImg] = useState("")
  const [initialTime, setInitialTime] = useState(true)



  let directorRef = useRef(null);
  useEffect(() => {
    if (initialTime == false) {
      directorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [props.director])



  const onChange = (e) => {
    if (validateImage(e)) {
      props.setProfile({ ...props.Profile, profilePhoto: e.target.files[0], image: URL.createObjectURL(e.target.files[0]) })
    }
  }
  


  const validateImage = (e) => {
    console.log(e.target.files[0].size, "kjngnghg")
    let img = e.target.files[0].size / 1024
    console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }



  const validateEmail = (email) => {
    var reg =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!reg.test(email))
      toast.error("Please enter a valid email address");

  }

  const validateMobileNumber = (number) => {
    var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    if (!reg.test(number)) {
      toast.error("Please enter a valid 10 digit Number");
    }
  }

  const validateText = (name) => {
    var reg = /^[A-Z a-z]*$/
    if (!reg.test(name)) {
      return false;
    }
    return true;
  }


  const deleteDirector = (index) => {
    let data = { ...props.director[index] };
    let id = data.id;
    if (id == "") {
      props.setDirector(props.director.filter((data3, index3) => index3 != index))
    }
    else {
      let request = {
        url: `inst/${props.prof_id}/delete-dir-det/${id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.delete(request).then(data => {
        toast.success("Director Deleted successfully")
        props.setDirector(props.director.filter((data3, index3) => index3 != index))
      }).catch((err) => {
        // setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }


  return (
    <>

      <div>
        <p class="heading">Your Profile
          {
            type == "school" ?
              <span>School</span> :
              <span>{type == "institute"?"Coaching":"College"}</span>
          }
        </p>
        <p class="line_purple"></p>
        <label>Institute Name<span>*</span></label>
        <input value={props.Profile.instName} onChange={(e) => props.setProfile({ ...props.Profile, instName: e.target.value })} type="text" class="expandale-input w-450" name="fname" placeholder="Enter  " />
      </div>

      <div>
        <label>Institute Address<span>*</span></label>
        <Autocomplete
          apiKey={"AIzaSyBgjbTWg8b3WbMXvdykC5zn28RdmNKil6k"}
          class="expandale-input w-450"
          value={props.address.address}
          onChange={(e) => (props.setaddress({ ...props.address, address: e.target.value }))}
          onPlaceSelected={(place) => {
            let zip = place.address_components.find(item => item.types[0] === 'postal_code')?.long_name ?? "-";
            let state = place.address_components.find(item => item.types[0] === 'administrative_area_level_1')?.long_name ?? "-";
            let city = place.address_components.find(item => item.types[0] === 'administrative_area_level_2')?.long_name ?? "-";
            let area = place.address_components.find(item => item.types[0] === 'sublocality_level_1')?.long_name ?? "-";
            props.setaddress({ ...props.address, address: place.formatted_address, pin: zip, state: state, city: city, area: area, lat: place.geometry.location.lat(), long: place.geometry.location.lng() })
          }}
          options={{
            types: ['geocode', 'establishment'],
            componentRestrictions: { country: "in" },
          }}
        />
      </div>
      <div class="flex">
        <div>
          <label>Country <span>*</span></label>
          <div class="select-wrapper">
            <select class="expandale-input w-200" value="India" onChange={(e) => { (props.setaddress({ ...props.address, country: e.target.value })) }}>
              <option value="1">Select</option>
              <option value='India'>India</option>
            </select>
          </div>
        </div>
        <div class="mob-top">
          <label>Pin code <span>*</span></label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.pin} />
        </div>
      </div>
      <div class="flex">
        <div class="opacity-5">
          <label>State</label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.state} />
        </div>
        <div class="mob-top opacity-5">
          <label>City</label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.city} />
        </div>
      </div>

      <div>
        <label>Email Address<span>*</span></label>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
        <div class="flex-p1 w-70">
          <input class="expandale-input w-450" type="email" contenteditable="true" onBlur={(e) => validateEmail(e.target.value)} placeholder="Enter" value={props.Profile.mail} onChange={(e) => props.setProfile({ ...props.Profile, mail: e.target.value })}></input>
          <a href=""
            data-target="#changeModal" data-toggle="modal" onClick={() => { props.setChange(true); props.setChangeMob("Email") }}>Change</a>
        </div>
      </div>
      <div>
        <label><img src={mob} />Mobile Number<span>*</span></label>
        <p class="data-info" >This detail is for internal use only. Will not be shown on profile</p>
        <div class="flex-p1 w-70">

          <div class="select-wrapper">
            <select class="expandale-input w-200 india" value={props.Profile.pin} onChange={(e) => props.setProfile({ ...props.Profile, pin: e.target.value })}>
              <option value="ind">+91</option>
              <option>+1</option>
            </select>
          </div>
          <button></button>
          <input class="expandale-input w-450" contenteditable="true" maxlength="10" placeholder="Enter" value={props.Profile.mobNo}
            onKeyPress={(e) => {
              let regex = /^\d+$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, mobNo: e.target.value })
              }
            }}
            onBlur={(e) => validateMobileNumber(e.target.value)}
            onChange={(e) => {
              let regex = /^\d+$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, mobNo: e.target.value })
              }
            }}
          >
          </input>
          <a href="" data-target="#changeModal" data-toggle="modal" onClick={() => { props.setChange(true); props.setChangeMob("Mobile") }}>Change</a>
        </div>
      </div>
      <div>
        <label><img src={whts} /> WhatsApp Number</label>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
        <div class="flex-a mob-res">
          <div class="flex-p1 w-70">
            <div class="select-wrapper">
              <select class="expandale-input w-200 india">
                <option class="india">+91</option>
              </select>
            </div>
            <button></button>
            <input class="expandale-input w-450" contenteditable="true" placeholder="Enter" maxlength="10" value={props.Profile.appNo}
              onChange={(e) => {
                let regex = /^\d+$/;
                if (((e.target.value === '' || regex.test(e.target.value)))) {
                  props.setProfile({ ...props.Profile, appNo: e.target.value })
                }
              }}
              onBlur={(e) => validateMobileNumber(e.target.value)}
              onKeyPress={(e) => {
                let regex = /^\d+$/;
                if (((e.target.value == '' || regex.test(e.target.value)))) {
                  props.setProfile({ ...props.Profile, appNo: e.target.value })
                }
              }
              } ></input><a href=""
                data-target="#changeModal" data-toggle="modal" onClick={() => { props.setChange(true); props.setChangeMob("Mobile") }}>Change</a>
          </div>
          <div class="flex-inline">
            <label class="checkbox">
              <span class="checkbox__input">
                <input type="checkbox" name="checked" onClick={(e) => { e.target.checked == true ? props.setProfile({ ...props.Profile, appNo: props.Profile.mobNo }) : props.setProfile({ ...props.Profile, appNo: "" }) }} />
                <span class="checkbox__control">
                  <svg viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                    <path fill='none' stroke='currentColor' d='M1.3 12.91l6.37 6.37L22.79 4.59' /></svg>
                </span>
              </span>
            </label>
            <span>Same as Mobile Number</span>
          </div>
        </div>
      </div>
      <div>
        <label><img src={telephone} /> Telephone Number</label>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
        <div class="flex-p1 w-70">
          <input class="expandale-input w-450" contenteditable="true" placeholder="Enter" value={props.Profile.teleNo} maxlength={12}
            onChange={(e) => {
              let regex = /^[\d ]*$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, teleNo: e.target.value })
              }
            }}
            onKeyPress={(e) => {
              let regex = /^[\d ]*$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, teleNo: e.target.value })
              }
            }}
          ></input><a href=""
            data-target="#changeModal" data-toggle="modal" onClick={() => props.setChange(true)}></a>
        </div>
      </div>
      <div>
        <label>Contact Person Name </label>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
        <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={props.Profile.contactPerson} onChange={(e) => {
          if (validateText(e.target.value)) { props.setProfile({ ...props.Profile, contactPerson: e.target.value }) }
        }}></input>
      </div>


      <div>
        <label>Logo<span>*</span></label>
        <label htmlFor="photo-upload" >
          <div   >{props.Profile.profilePhoto == "" ?
            <img for="photo-upload" class="pro-pic" src={pic} /> :
            <img for="photo-upload" class="pro-pic" src={props.Profile.image} />
          }
          </div>
          <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
            display: "none",
            visibility: "hidden"
          }} />
        </label>

        <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
      </div>

      <div>
        <label>Overview <span>*</span></label>
        <p class="line_purple"></p>
        <p class="data-info">Give a brief about your Institute's Vision and Mission</p>
        <ReactQuill theme="snow" value={props.overview} onChange={(value1) => { props.setOverview(value1) }}
        />
      </div>

      <div>
        {
          type == "school" ?
            <label>School Tagline </label> :
            <label>Coaching Tagline </label>
        }
        <p class="line_purple"></p>
        <p class="data-info">This will be shown as your schools profile along with school
          name <b>( eg- Ranked No1 by government since last 3 years) </b> </p>
        <ReactQuill theme="snow" value={props.tagline} onChange={(value) => { props.setTagline(value) }} />

      </div>

      <div ref={directorRef}>
        {
          props.director.map((data, index) => {
            return (
              <div key={index} class="diff-position" id={"director_" + index}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {index > 0 &&
                    <img src={unexpad} style={{ cursor: "pointer" }} onClick={() => deleteDirector(index)} />
                  }
                  <img src={add} style={{ cursor: "pointer" }} onClick={() => { props.setDirector([...props.director, { d_nm: "", d_msg: "", ph_url: "", mode: 'add', id: "" }]); setInitialTime(false) }} />
                </div>
                <><label>{type == "school" ? "Principal's / Owner's Message" : "Director's / Owner's Message"}</label><p class="line_purple"></p><p class="data-info">Give a brief message to your students.</p><input class="expandale-input w-450" placeholder="Enter" value={data.d_msg}
                  onChange={(e) => {
                    let temp = [...props.director]
                    temp[index]['d_msg'] = e.target.value
                    props.setDirector([...temp])
                  }
                  }></input>
                  <div style={{ marginTop: "20px" }}>
                    <label>{type == "school" ? "Principal/Owner Name" : "Director / Owner Name"}</label>
                   
                    <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.d_nm}
                      onChange={(e) => {
                        if (validateText(e.target.value)) {
                          let temp = [...props.director]
                          temp[index]['d_nm'] = e.target.value
                          props.setDirector([...temp])
                        }
                      }
                      }
                   
                    >

                    </input>
                  </div>

                  <div style={{ marginTop: '20px' }}>

                    <label>Profile Photo</label>

                    <label htmlFor={"photo-upload" + index}>
                      <div>{data.ph_url == "" ?
                        <img for={"photo-upload" + index} class="pro-pic" src={pic} /> :
                        <img for={"photo-upload" + index} class="pro-pic" src={(data.ph_url && data.ph_url.name) ? URL.createObjectURL(data.ph_url) : data.ph_url} />}
                      </div>
                      <input id={"photo-upload" + index} type="file" accept="image/*"
                        onChange={(e) => {
                          if (validateImage(e)) {
                            let temp = [...props.director]
                            temp[index]['ph_url'] = e.target.files[0]
                            props.setDirector([...temp])
                            console.log(temp, "temp")
                          }
                        }
                        }
                        style={{
                          display: "none",
                        }}
                      />
                    </label>
                    <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
                  </div></>
              </div>

            )
          })
        }

      </div>


    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(YourProfile)

