import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './login.css'


class Login extends React.Component {
  constructor(props) {
    super(props)
    // if (props.user_id != 0) {
    //   window.location = '/home/'
    // }


    this.state = {
      toggle: 'false',
      phone: '',
      password: '',
      device_id: null,
      show_forgot_password: false,
      // logo: Procturr,
    }
  }



  submit = () => {
    if (this.validation()) {
      if (this.state.phone == 'Coaching') {
        this.props.dispatch({
          type: 'LOGIN_SUCCESS',
          usertype: 2,
          userid: 5
        })
        setTimeout(() => { window.location = '/coaching/home/' }, 500);

      } else if (this.state.phone == 'School') {
        this.props.dispatch({
          type: 'LOGIN_SUCCESS',
          usertype: 3,
          userid: 5
        })
        setTimeout(() => { window.location = '/coaching/home/' }, 500);
      } else if (this.state.phone == 'Educator') {
        this.props.dispatch({
          type: 'LOGIN_SUCCESS',
          usertype: 4,
          userid: 5
        })
        setTimeout(() => { window.location = '/coaching/home/' }, 500);
      } else {
        localStorage.setItem("superadmin", "true");
        this.props.dispatch({
          type: 'BEGIN_LOGIN',
          phone: this.state.phone,
          password: this.state.password,
          nav: () => {
            setTimeout(() => { window.location = '/home/' }, 500)
          }
        })
      }
    }
  }



  componentDidUpdate(prevProps, prevState) {
    if (this.props.error === true) {
      toast.error(this.props.error_msg)
      this.props.dispatch({ type: 'LOGIN_ERROR', error: false })
    }
  }


  validation = () => {
    if (this.state.phone === '') {
      toast.error('Email or Phone Number is required')
      return false
    } else if (this.state.password === '') {
      toast.error('Password is required')
      return false
    }
    return true
  }


  _handleKeyDown = (e) => {
    console.log(e, "handel key down ")
    if (e.key === 'Enter') {
      console.log(e, "handel key down  inside")
      this.submit()

    }
  }

  render() {
    return (
      <>


        <div>


          <div className='sa-containers'>
            <div className='sa-login-container-6'>
            </div>

            <div className='sa-login-container-4'>
              <div class='sa-formbg'>
                <div className='sa-login-container-head'>
                  <span className='sa-login-heading-b'>Sign In</span>
                  <span className='sa-login-heading-m'>to your account</span>
                </div>
                <form id='sa-stripe-login'>
                  <div class='sa-login-input-container'>
                    <label className='sa-login-text-s'>Login</label>
                    <input
                      onKeyDown={this._handleKeyDown}
                      onChange={(event) =>
                        this.setState({
                          phone: event.target.value,
                        })
                      }
                      type='text'
                      name='Login'
                      placeholder='Email or Phone Number'
                      className='login_placeholder'
                    />
                  </div>
                  <div>
                    <label className='sa-login-text-s'>Password</label>
                    <div className='sa-login-password-field'>
                      <i class='fas fa-lock' style={{ color: '#BABABA', textIndent: "0", paddingTop: "0", fontSize: "15px" }}></i>
                      <input
                        onKeyDown={this._handleKeyDown}
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        type={this.state.toggle ? 'password' : 'text'}
                        name='password'
                        placeholder='Password'
                        className='login_placeholder'
                      />
                      <i
                        style={{ color: '#BABABA', textIndent: "0", paddingTop: "0", fontSize: "15px" }}
                        class={
                          this.state.toggle
                            ? 'fas fa-eye password-eye'
                            : 'fas fa-eye-slash password-eye'
                        }
                        onClick={() => {
                          this.setState({ toggle: !this.state.toggle })
                        }}
                      ></i>
                    </div>
                  </div>

                  <div class='sa-login-submit-button' onClick={this.submit}>
                    Login{' '}
                    <span >
                      <i class="fa fa-angle-right" aria-hidden="true"  ></i>
                    </span>
                  </div>

                  <div class='sa-field'>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, error: state.auth.error,
  error_msg: state.auth.error_msg
})

export default connect(mapStateToProps)(Login)
