import React, { useState, useEffect } from "react";
import school from '../../../assets/img/school.svg'
import Delete from '../../../components/delete'
import QualificationForm from "./qualification_form";
import Loader from '../../../components/loader'
import { connect } from "react-redux";
import { errorMsg } from '../../../utils'
import api from '../../../api/api'
import Moment from 'moment';
import Educatorheader from "../../addEducators/educator_header";
import { ToastContainer, toast } from 'react-toastify';


function QualificationBlock(props) {
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [deletepopup, setdelete] = useState(false)
  const [id, setid] = useState("")



  useEffect(() => {
    fetchData();
    setSelected(0);
  }, [])


  const fetchData = () => {
    if (props.eduId == "") {
      console.log("add")
      toast.error("Please add Basic Details first.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/qualification/all?educator_id=${props.eduId}&pageOffset=1&pageSize=10&isAll=false `,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }


  const deletequalification = () => {
    setdelete(false)
    let request = {
      url: `educator/qualification/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Qualification Deleted successfully")
      setRdata(rdata.filter((item3) => item3.id != id))
      setid("")
    }).catch((err) => {
      setid("")
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  const ShowonProfile = (id, status) => {
    setLoading(true)
    let request = {
      url: `educator/qualification/${id}/highest/${!status}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success("Set as highest qualification updated successfully.")
      fetchData()
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })

  }



  return (<>

    {loading && <Loader />}
    <Educatorheader
      count={props.count} setCount={props.setCount}
      setButton={props.setButton}
      setEcount={props.setEcount}
      showform={showform}
    />


    {showform == false && <div>
      <div class="box-section1">
        <div>Educator Qualifications</div>
        <div><button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add Education</button></div>
      </div>

      <div class="box-educator">
        {
          rdata.map((item,key) => {
            return (
              <div key={key}>
                <div class="display mb-8">
                  <div class="flex">
                    <img src={school} class="profile_pic" />
                    <div class="name-div">
                      <span>School/Institute Name</span>
                      <p>{item.inst_name}</p>

                    </div>
                  </div>

                </div>
                <div class="display added-by">
                  <p>Education Type</p>
                  <p> {item.e_type}</p>
                </div>
                <div class="display added-by">
                  <p>Degree</p>
                  <p> {item.d_name}</p>
                </div>
                <div class="display added-by">
                  <p>Duration</p>
                  <p>{Moment(item.f_date).format('DD-MM-YYYY')} To {item.is_ongoing==true?"Ongoing":Moment(item.t_date).format('DD-MM-YYYY')}</p>
                </div>
                <div class="added-by description">
                  <p>Description</p>
                  <p>{item.desc.replace(/<[^>]*>?/gm, '')}
                  </p>
                </div>
                <div class="line-v-grey">
                </div>
                <div class="flex">
                  <div class="flex-a">
                    <span>Set as Highest Qualification</span>
                    <label class="switch1">
                      <input type="checkbox" checked={item.ht_qual == true ? true : false} style={{ marginLeft: "30px" }}
                        onClick={() => ShowonProfile(item.id, item.ht_qual)}
                      />
                      <span class="slider1 round"></span>
                    </label>
                  </div>
                  <div class="ml-a">
                    <button class="editbutton" onClick={() => { setshowform(true); setSelected(item.id) }}> Edit</button>

                    <button class="unlinkbutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                  </div>
                </div>
              </div>


            )
          })
        }
      </div>
    </div>

    }


    {deletepopup == true &&
      <Delete delete={deletequalification} setdelete={setdelete} />
    }

    {
      showform == true &&
      <QualificationForm setSelected={setSelected} selected={selected} data={rdata.find(data => data.id == selected)}
        showform={showform} setshowform={setshowform} eduId={props.eduId}
        fetchData={fetchData}
      />
    }
    <ToastContainer />
  </>)


}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, edu_id: state.auth.edu_id })

export default connect(mapStateToProps)(QualificationBlock)