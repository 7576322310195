import React from "react";
import { connect } from 'react-redux';
import '../../css/vAdmin/admin.css'
import proctur_logo2 from '../../assets/img/sidebar/procturlogo_2.png'
import List from './list'
import { checkUserType } from '../../utils';
import menus from './menus'
import home from '../../assets/img/sidebar/home.svg'
import dashboard from '../../assets/img/sidebar/dashboard.svg'
import profile from '../../assets/img/sidebar/profile.svg'
import review from '../../assets/img/sidebar/review.svg'
import branches from '../../assets/img/sidebar/branches.svg'
import news from '../../assets/img/sidebar/news.svg'
import users from '../../assets/img/sidebar/users.svg'
import exporttt from '../../assets/img/sidebar/exporttt.svg'


function SideBar(props) {

  let prof_id = localStorage.getItem("prof_id")
  let roles = (props.auth && props.auth.datalogin && props.auth.datalogin.role) ? props.auth.datalogin.role.f_li : [];


  const setStafftype = () => {
    if (props.auth.datalogin?.staff_inst_type == "COACHING") {
      if (props.prof_id == null) {
        return '/coaching/profile/?type=institute'
      } else {
        return `/coaching/profile/?type=institute?id=${props.prof_id}`
      }
    } else if (props.auth.datalogin?.staff_inst_type == "SCHOOL") {
      if (props.prof_id == null) {
        return '/coaching/profile/?type=school'
      } else {
        return `/coaching/profile/?type=school?id=${props.prof_id}`
      }
    }

  }

  const superadmin = props.up_id == "dbda4112-006c-4957-8f55-0e91fe2ef9e6" ?
    [
      {
        icon: home,
        link: '/home/',
        text: 'Home',
        show: true
      },

      {
        icon: dashboard,
        link: '/dashboard/',
        text: 'Dashboard',
        show: true
      },
      {
        icon: profile,
        link: '/profilemanagement/profile/',
        text: 'Profile Master',
        show: true
      },
      {
        icon: profile,
        link: '/superadmin/masters/ownership/',
        text: 'Master',
        show: true
      },
      {
        icon: review,
        link: '/institute/',
        text: 'Institutes',
        show: true
      },
      {
        icon: branches,
        link: '/educator/',
        text: 'Educator',
        show: true
      },
      {
        icon: review,
        link: '/school/',
        text: 'Student',
        show: true
      },
      // {
      //   icon: news,
      //   link: '/leads/',
      //   text: 'Leads',
      //   show: true
      // },

      {
        icon: exporttt,
        link: '/reviewRating/',
        text: 'Review Rating',
        show: true
      },
      {
        icon: exporttt,
        link: '/reports/',
        text: 'Reports',
        show: true
      },
      {

        icon: exporttt,
        link: '/roles/',
        text: 'Roles And User',
        show: true
      },
      {
        icon: exporttt,
        link: '/customNotification/',
        text: 'Custom Notification',
        show: true
      },
      {
        icon: exporttt,
        link: '/whatsnew/',
        text: "What's New",
        show: true
      },
    ] : [
      {
        icon: home,
        link: '/home/',
        text: 'Home',
        show: true
      },

      {
        icon: dashboard,
        link: '/dashboard/',
        text: 'Dashboard',
        show: true
      },
      {
        icon: review,
        link: '/institute/',
        text: 'Institutes',
        show: true
      },
      {
        icon: branches,
        link: '/educator/',
        text: 'Educator',
        show: true
      },
    ]


  const institute = [

    {
      icon: home,
      link: '/coaching/home/',
      text: 'Home',
      show: true
    },
    {
      icon: dashboard,
      link: '/coaching/dashboard/',
      text: 'Dashboard',
      show: true
    },
    {
      icon: profile,
      link: props.prof_id == null ? '/coaching/profile/?type=institute' : `/coaching/profile/?type=institute?id=${props.prof_id}`,
      text: 'Profile',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/leads/',
      text: 'Leads',
      show: true
    },
    {
      icon: review,
      link: '/coaching/review/',
      text: 'Review',
      show: true
    },
    {
      icon: review,
      link: '/coaching/onlineAdmission/',
      text: 'Online Admission',
      show: true
    },
    {
      icon: branches,
      link: '/coaching/branches/',
      text: 'Branches',
      show: true
    },
    {
      icon: news,
      link: '/coaching/news/',
      text: 'News/Updates',
      show: true
    },
    {
      icon: users,
      link: '/coaching/rolesnuser/',
      text: 'Role & Users',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/setting/',
      text: 'Settings',
      show: true
    },

  ]

  const school = [

    {
      icon: home,
      link: '/coaching/home/',
      text: 'Home',
      show: true
    },

    {
      icon: dashboard,
      link: '/coaching/dashboard/',
      text: 'Dashboard',
      show: true
    },
    {
      icon: profile,
      link: props.prof_id == null ? '/coaching/profile/?type=school' : `/coaching/profile/?type=school?id=${props.prof_id}`,
      text: 'Profile',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/leads/',
      text: 'Leads',
      show: true
    },
    {
      icon: review,
      link: '/coaching/review/',
      text: 'Review',
      show: true
    },
    {
      icon: review,
      link: '/coaching/onlineAdmission/',
      text: 'Online Admission',
      show: true
    },
    {
      icon: branches,
      link: '/coaching/branches/',
      text: 'Branches',
      show: true
    },
    {
      icon: news,
      link: '/coaching/news/',
      text: 'News/Updates',
      show: true
    },
    {
      icon: users,
      link: '/coaching/rolesnuser/',
      text: 'Role & Users',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/setting/',
      text: 'Settings',
      show: true
    },

  ]


  const educator = [

    {
      icon: home,
      link: '/coaching/home/',
      text: 'Home',
      show: true
    },

    {
      icon: dashboard,
      link: '/coaching/dashboard/',
      text: 'Dashboard',
      show: true
    },
    {
      icon: profile,
      link: prof_id == "null" ? `/educator/profile/` : `/educator/profile/?id=${prof_id}`,
      text: 'Profile',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/leads/',
      text: 'Leads',
      show: true
    },
    {
      icon: review,
      link: '/coaching/review/',
      text: 'Review',
      show: true
    },
    {
      icon: exporttt,
      link: '/coaching/setting/',
      text: 'Settings',
      show: true
    },

  ]

  const staff = [
    {
      icon: home,
      link: '/coaching/home/',
      text: 'Home',
      show: true,
      featureID: 1001 || 1000
    },

    {
      icon: dashboard,
      link: '/coaching/dashboard/',
      text: 'Dashboard',
      show: true,
      featureID: 2001 || 2000
    },
    {
      icon: profile,
      link: setStafftype(),
      text: 'Profile',
      show: true,
      featureID: 6001 || 6000
    },
    {
      icon: exporttt,
      link: '/coaching/leads/',
      text: 'Leads',
      show: true,
      featureID: 1001
    },
    // {
    //   icon: review,
    //   link: '/coaching/review/',
    //   text: 'Review',
    //   show: true
    // },
    {
      icon: branches,
      link: '/coaching/branches/',
      text: 'Branches',
      show: true,
      featureID: 10001 || 10000
    },
    {
      icon: news,
      link: '/coaching/news/',
      text: 'News/Updates',
      show: true,
      featureID: 1001
    },
    {
      icon: users,
      link: '/coaching/rolesnuser/',
      text: 'Role & Users',
      show: true,
      featureID: 9001 || 9000
    },
    {
      icon: exporttt,
      link: '/coaching/setting/',
      text: 'Settings',
      show: true,
      featureID: 8001 || 8000 || 1001
    },
  ].filter(el => {
    return roles.find(element => {
      return element === el.featureID;
    });
  })

  console.log(roles, "roles")

  const type = checkUserType(props.auth)
  let list = []
  if (type == 'Superadmin') {
    list = superadmin
  } else if (type == 'School') {
    list = school
  } else if (type == 'Educator') {
    list = educator
  } else if (type == 'Staff') {
    list = staff
  } else {
    list = institute
  }



  return (
    <>
      <div class="paneldd"></div>
      <div class="page-wrapper">
        <nav id="sidebar" class="sidebar-wrapper" >
          <div class="sidebar-content"  >
            <div class="sidebar-brand">
              <img src={proctur_logo2} />
            </div>
            <ul class="icons">
              {list.map((objLink, i) =>
                objLink.show &&
                <List name={objLink.text} key={i} url={objLink.link} icon={objLink.icon} />)}
            </ul>
          </div>
        </nav>

      </div>
    </>

  )
}
const mapStateToProps = state => ({ auth: state.auth, up_id: state.auth.up_id, prof_id: state.auth.prof_id })
export default connect(mapStateToProps)(SideBar)