import React from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";




function Educatorheader(props) {
  const history = useHistory()
  return (<>

    <div style={{ margin: "15px 0px 0px 25px" }}>
      <div class="display progress_bar-outter">
        <div class="scroll-solve">
          <div class="progress_bar">
            <div className={props.count >= 1 ? "selected" : "bullet"} onClick={() => { props.setCount(1); props.setButton(""); props.setEcount(true) }}>
              <span  >Basic Details</span>
              <div>
                <button>

                </button>
                <div></div>
              </div>
            </div>
            <div className={props.count >= 2 ? "selected" : "bullet"} onClick={() => { props.setCount(2); props.setButton(""); props.setEcount(true) }}>
              <span>Qualifications</span>
              <div>
                <button>

                </button>
                <div></div>
              </div>
            </div>
            <div className={props.count >= 3 ? "selected" : "bullet"} onClick={() => { props.setCount(3); props.setButton(""); props.setEcount(true) }}>
              <span> Experience </span>
              <div>
                <button>

                </button>
                <div></div>
              </div>
            </div>
            <div className={props.count >= 4 ? "selected" : "bullet"} onClick={() => { props.setCount(4); props.setButton(""); props.setEcount(true) }}>
              <span> Achievements</span>
              <div>
                <button>

                </button>
                <div></div>
              </div>
            </div>
            <div className={props.count >= 5 ? "selected" : "bullet"} onClick={() => { props.setCount(5); props.setButton(""); props.setEcount(true) }}>
              <span>KYC Documents</span>
              <div>
                <button>

                </button>
                <div></div>
              </div>
            </div>
            <div className={props.count >= 6 ? "selected" : "bullet"} onClick={() => { props.setCount(6); props.setButton(""); props.setEcount(true) }}>
              <span>Link to Institute/School</span>
              <div>
                <button>
                </button>
              </div>
            </div>

          </div>
        </div>
        <div>
          {/* <!-- Save button has functionality of back for my use, it can be changed by removing id --> */}

          {props.showform == false &&
            (props.count == 1 ?
              <><button class="white_button mr16" id="previousBtn" onClick={() => { props.savedetails() }}>Save</button>
                <button class="blue_button" id="nextBtn" onClick={() => { props.count >= 6 ? props.setCount(6) : props.setCount(props.count + 1); }}>Next</button></> :

              <><button class="white_button mr16" id="previousBtn" onClick={() => {
                if (props.count <= 1) {
                  props.setCount(1)
                } else {
                  props.setCount(props.count + 1)
                }
              }}>Save</button>
                <button class="blue_button" id="nextBtn" onClick={() => {
                  if (props.count >= 6) {
                    if (props.p_type == 6) {
                      props.setCount(1)
                    } else {
                      toast.success("Profile Created successfully.")
                      setTimeout(() => {
                        history.push(`/coaching/dashboard/`)
                      }, 5000);
                    }
                  } else {
                    props.setCount(props.count + 1)
                  }
                }}>{props.count == 6 ? "Finish" : "Next"}</button></>)
          }


          <p>Last Updated on {new Date().toLocaleString().replace(',', '')}</p>
        </div>
      </div>

    </div>
    <ToastContainer />
  </>)

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(Educatorheader)
