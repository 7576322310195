import React, { useEffect, useState } from "react";
import close from '../../../assets/img/close.svg'
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export function AcheivemnetForm(props) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [year, setYear] = useState("")
  const [awarded, setawarded] = useState("")
  const [value, setValue] = useState('');
  const [image, setimage] = useState([])
  const [oimage, setOimage] = useState([])



  useEffect(() => {
    if (props.selected != 0) {
      setLoading(true)
      let request = {
        url: `educator/achv/${props.data.id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
         setLoading(false)
         setName(data.data.result.title)
        setYear(data.data.result.yr)
        setawarded(data.data.result.awarded_by)
        setValue(data.data.result.desc)
        setOimage(data.data.result.files??[])
      }).catch((err) => {
        setLoading(false)
        if(err.response.status == 401){
          props.dispatch({ type: 'LOGOUT' })
        }else{
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        } 
      })
    }
    
  }, [props.selected])



  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        title: name,
        yr: year,
        desc: value,
        awarded_by: awarded,
      }));
      for (let index = 0; index < image.length; index++) {
        fd.append('files', image[index]);
      }
      let request = {
        url: `educator/${props.eduId}/achv/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Acheivement Added successfully");
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
        if(err.response.status == 401){
          props.dispatch({ type: 'LOGOUT' })
        }else{
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        } 
      })
    }
  }

  const updateValue = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        title: name,
        yr: year,
        desc: value,
        awarded_by: awarded,
      }));
      for (let index = 0; index < image.length; index++) {
        fd.append('files', image[index]);
      }
      let request = {
        url: `educator/achv/${props.data.id}/update`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.putOther(request).then(data => {
        setLoading(false)
        toast.success(" Acheivement updated successfully.")
        props.setSelected(0);
        props.setshowform(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
        if(err.response.status == 401){
          props.dispatch({ type: 'LOGOUT' })
        }else{
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        } 
      })
    }
  }

  const validate = () => {
    if (name === '') {
      toast.error("Please Enter Enter Achievement Title")
      return false
    }
    if (year === '') {
      toast.error("Please Enter Year")
      return false
    }
    //     if (awarded === '') {
    //       toast.error("Please Enter Awarded By")
    //       return false
    //   } if (value === '') {
    //     toast.error("Please Enter Description")
    //     return false
    // }
    return true
  }

  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3,index) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
      if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      } 

    })
  }


  const validateImage = (e) => {
    let img = e.target.files[0].size / 1024
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }


  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      if ([...oimage, ...image].length > 1) {
        toast.error("You are only allowed to upload a maximum of 5 files")
      } else {
        if (e.target.files.length > 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          setimage([...image, ...imgs])
         
        }
      }
    }
  }

  return (<>
 {loading && <Loader />}
    <div class="form-section ">
      <div>
        {props.selected ? <p class="heading">Edit  Achievement</p> : <p class="heading">Add  Achievement</p>}
        <p class="line_purple"></p>
        <div>
          <label>Enter Achievement Title<span>*</span></label>
          <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
        </div>
      </div>
      <div class="flex mob-v">
        <div class="mr-70">
          <label>Year<span>*</span></label>
          <input class="expandale-input w-450" type="date" placeholder="Enter" value={year} onChange={(e) => setYear(e.target.value)}></input>
        </div>
        <div class="mob-top">
          <label>Awarded By</label>
          <input contenteditable="true" class="expandale-input w-300" placeholder="Enter" value={awarded} onChange={(e) => setawarded(e.target.value)} />
        </div>
      </div>
      <div class="mt-16">

        <label>Add Photo</label>
        <p class="data-info">Submit your achievement related picture such as trophy, certificate etc .</p>
        <div class="flex-p1">
          <input class="expandale-input w-450 choose" type="file" id="myFile" accept="image/*" multiple onChange={select_MediaContent} disabled={image.length === 5} placeholder="Choose File" />
        </div>
        <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
        <div class="tags-img">
        {oimage.map((item,key) => {
                 {key=key}
                return (
                  <div>
                    <img src={item.f_url} />
                    <img src={close} onClick={() => { deleteImage(item.id) }} />
                  </div>
                )
              })
              }
          {image.map((item,key) => {
             
            return (
              <div key={key}>
                <img src={URL.createObjectURL(item)} />
                <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
              </div>
            )
          })
          }
        </div>

      </div>
      <div>
        <label>Achievement Details</label>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>
      <div class="button-section">
        <div></div>
        <div>
          <button class="white_button mr16" onClick={() => { props.setSelected(0); props.setshowform(false); }} >Cancel</button>
          {
            props.selected ?
              <button class="blue_button" onClick={updateValue}>Update</button> :
              <button class="blue_button" onClick={()=>{
                if(props.eduId==""){
                  toast.error("Please add Basic Details first.")
                }else{
                  savedetails()
                }
                }}>Save</button>
          }
        </div>
      </div>
    </div>
    <ToastContainer />

  </>)
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(AcheivemnetForm)