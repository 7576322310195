import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import { errorMsg } from '../../../utils'
import close from '../../../assets/img/close.svg'
import { connect } from 'react-redux'


function Operate(props) {
    const [title, setTitle] = useState("")
    const [etitle, seteTitle] = useState("")
    const [image, setimage] = useState("")
    const [photo, setphoto] = useState("")
    const [check, setcheck] = useState(false)

    useEffect(() => {
        if (props.selected != 0) {
            setTitle(props.data.name)
            setimage(props.data.f_url)
            setcheck(props.data.s_web)
            setphoto(props.data.f_url)
        }
    }, [props.selected])



    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, s_web: check }));
            fd.append('file', photo);
            let request = {
                url: `master-stand/${props.data.entity_id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Standards updated Successfully  ")
                setTitle("");
                seteTitle("");
                setimage("");
                setcheck("")
                props.setSelected(0);
                props.fetchData();

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, s_web: check }));
            fd.append('file', photo);
            let request = {
                url: `master-stand/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }

            api.postCustom(request).then(data => {
                toast.success("Standards added Successfully  ")
                props.setLoading(false)
                setTitle("");
                seteTitle("");
                setimage("");
                setcheck("")
                props.fetchData();


            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const OnCancel = () => {
        setTitle("");
        seteTitle("");
        setimage("");
        setcheck("")
    }

    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Standard")
            return false
        } if (photo === '') {
            toast.error("Please Upload icon")
            return false
        }
        return true
    }

    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }



    return (
        <>

            <div className="Sa-AddProfile">
                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Standard</p> : <p style={{ fontSize: "15px" }}>Add Standard</p>
                }
                <div className="Super-admin-line_purple"></div>


                <div className="Sa-Inputbox">
                    <div>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Standard " style={{ fontSize: "13px" }} />
                    </div>
                    <div>

                        <input type="file" accept="image/*" contenteditable="true" placeholder='Upload Icon*' onChange={select_file} style={{ fontSize: "13px", borderBottom: "1px solid #939393", width: '70%' }} />
                        {
                            image != "" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close} onClick={() => { setimage("") }} />
                                </div>
                            </div>

                        }
                    </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <input type="checkbox" style={{ marginRight: "10px" }} checked={check} onChange={() => setcheck(!check)} />Show on App
                </div>
                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => OnCancel()}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }
                <ToastContainer />

            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)