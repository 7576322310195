import React, { useState, useEffect } from 'react';
import '../../css/superAdmin/sa_inst_reports.css'
import filter_icon from '../../assets/home/filter_icon.png'
import api from '../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../utils'
import ReportsData from './data';
import Loader from '../../components/loader'
import KYCData from './kycData';
import ReportsInside from './reportsinside';
import { ToastContainer, toast } from 'react-toastify';



function EduReports(props) {
    const [rdata, setRdata] = useState()
    const [Show, setShow] = useState("reports_initial_page")
    const [loading, setLoading] = useState(false)
    const [FromDate, setFromDate] = useState("2022-07-01")
    const [ToDate, setToDate] = useState("2022-12-31")
    const [initialList, setInitialList] = useState("Initial")
    const [tag, setTag] = useState("")
    const [data, setData] = useState([])
    const [kycdata, setKycData] = useState([])


    useEffect(() => {
        getTotalCountInst(FromDate, ToDate)
    }, [])



    const getTotalCountInst = (FromDate, ToDate) => {
        setLoading(true)
        let request = {
            url: `report/educator/count-all?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {

            setLoading(false)
            setRdata(data.data.result)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const Url = (type) => {
        if (type == " > Listed in last 15 days") {
            return `report/educator/last-15-days?uid=${props.up_id}`
        } if (type == " > KYC Verified") {
            return `educator/kyc/all/get?isAll=true&fromDate=${FromDate}&toDate=${ToDate}&isStatusVarified=true&pageOffset&pageSize`
        } if (type == " > KYC Not Verified") {
            return `educator/kyc/all/get?isAll=true&fromDate=${FromDate}&toDate=${ToDate}&isStatusVarified=false&pageOffset&pageSize`
        } if (type == " > Linked to Multiple Institutes") {
            return `report/educator/linked-to-inst?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > State Wise") {
            return `report/educator/state-wise/count?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > City Wise") {
            return `report/educator/city-wise/count?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Top Rated (More than 4 stars)") {
            return `report/educator/ratings/Top?&uid=${props.up_id}&cat_ids=d98738df-b11e-4e00-ade1-11bd14568d38, dae6b033-fa5f-4610-b920-e5f090fe7e27&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Avg Rated (between 1 to 4 stars )") {
            return `report/educator/ratings/Average?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Least Rated (less than 1 stars )") {
            return `report/educator/ratings/Least?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Duplicate Names") {
            return `report/educator/duplicate-named?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Inactive Profiles") {
            return `report/educator/inactive-inst?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Incomplete Profiles") {
            return `report/educator/incomplete-prof?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Direct Registration") {
            return `report/educator/direct-reg?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        } if (type == " > Profile Claim Reuqests") {
            return `report/educator/claimed-list?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}`
        }
        if (type == " > Leads Generated") {
            return `enquiry/lead/EDUCATOR/get-all?fromDate=${FromDate}&toDate=${ToDate}&pageOffset=1&pageSize=3&isAll`
        }
    }


    const getTableData = (type) => {
        setLoading(true)
        let request = {
            url: Url(type),
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(res => {
            console.log(res.data.result, "leads dat")
            setLoading(false)
            setData(Array.isArray(res.data.result) ? res.data.result : res.data.result.response)
            setKycData(res.data.result.response ?? [])
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    return (
        <>
            {loading && <Loader />}

            <div className="inst_reports">

                <div className="inst_reports_inner">
                    <div className="entire_reports_2">
                        <div className="sa_reports_inner">
                            <div className="reports_heading_and_cards">
                                <div className="sa_reports_heading2">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <font onClick={() => { props.setShow("reports_initial_page") }}

                                            style={{
                                                cursor: "pointer",
                                                borderRadius: "4px",
                                                color: "#0097F9",
                                                background: "rgb(236, 230, 230);",
                                            }}
                                        >
                                            {" Reports > "}</font> <div style={{ cursor: "pointer" }} onClick={() => { setInitialList("Initial"); setTag(""); setData([]) }}>Educator</div>
                                        <div>{tag}</div></div>

                                    <div className="reports_heading_filters" >
                                        <img src={filter_icon} height="22px" />
                                        <div className="reports_from_date_filter_area">



                                            <div className="reports_non_absolute_inputs">
                                                {<input placeholder="From Date" id="input1" type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    value={FromDate}
                                                    style={{ cursor: "pointer", outline: "0" }}

                                                    onChange={(e) => {
                                                        setFromDate(e.target.value);
                                                        getTotalCountInst(e.target.value, ToDate)
                                                    }}
                                                />
                                                }
                                            </div>

                                        </div>


                                        <div className="reports_from_date_filter_area">

                                            <div className="reports_non_absolute_inputs">
                                                {
                                                    <input placeholder="To Date" id="input1" type="date"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        value={ToDate}
                                                        style={{ cursor: "pointer", outline: "0" }}
                                                        onChange={(e) => {
                                                            setToDate(e.target.value)
                                                            getTotalCountInst(FromDate, e.target.value)
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    initialList == "Last" &&
                                    <ReportsData data={data} />
                                }
                                {
                                    initialList == "Kyc" &&
                                    <KYCData data={kycdata} getTableData={getTableData} setTag={setTag} tag={tag} type='educator' />
                                }

                                {initialList == 'inside' &&
                                    <ReportsInside data={data} setData={setData} setInitialList={setInitialList} setTag={setTag} tag={tag} Show={props.Show} />

                                }

                                {
                                    initialList == "Initial" &&

                                    <><div className="sa_reports_cards">
                                        <div className="sa_reports_cards_inner_2">
                                            <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Listed in last 15 days"); getTableData(" > Listed in last 15 days"); }}>
                                                <center>
                                                    Listed in Last 15 days
                                                    <p>
                                                        {rdata?.last_15_days ?? "-"}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards" onClick={() => { setInitialList("Kyc"); setTag(" > KYC Verified"); getTableData(" > KYC Verified") }}>
                                                <center>
                                                    KYC Verified
                                                    <p>
                                                        {rdata?.kyc_verified ?? "-"}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards" onClick={() => { setInitialList("Kyc"); setTag(" > KYC Not Verified"); getTableData(" > KYC Not Verified") }}>
                                                <center>
                                                    KYC Not Verified
                                                    <p>
                                                        {rdata?.kyc_not_verified ?? "-"}
                                                    </p>
                                                </center>
                                            </div>
                                        </div>
                                    </div><div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" >  Linked to Multiple Institutes"); getTableData(" > Linked to Multiple Institutes") }}>
                                                    <center>
                                                        Linked to Multiple Institutes
                                                        <p>
                                                            {rdata?.linked_multi_inst ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("inside"); setTag(" > State Wise"); getTableData(" > State Wise") }}>
                                                    <center>
                                                        State Wise
                                                        <p>
                                                            {rdata?.state_wise ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("inside"); setTag(" > City Wise"); getTableData(" > City Wise") }}>
                                                    <center>
                                                        City Wise
                                                        <p>
                                                            {rdata?.city_wise ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div><div className="sa_reports_cards" >
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Leads Generated"); getTableData(" > Leads Generated") }}>
                                                    <center>
                                                        Leads Generated
                                                        <p>
                                                            {rdata?.leads_generated ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Duplicate Names"); getTableData(" > Duplicate Names") }}>
                                                    <center>
                                                        Duplicate Names
                                                        <p>
                                                            {rdata?.duplicate_named ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Inactive Profiles"); getTableData(" > Inactive Profiles") }}>
                                                    <center>
                                                        Inactive Profiles
                                                        <p>
                                                            {rdata?.inactive_profiles ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                               
                                            </div>
                                        </div>
                                        {/* <div className="sa_reports_cards"> */}
                                            {/* <div className="sa_reports_cards_inner_2"> */}
                                                {/* <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Least Rated (less than 1 stars )"); getTableData(" > Least Rated (less than 1 stars )") }}>
                                                    <center>
                                                        Least Rated (less than 1 stars )
                                                        <p>
                                                            {rdata?.avg_rated ?? "-"}
                                                        </p>
                                                    </center>
                                                </div> */}

                                                {/* <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Top Rated (More than 4 stars)"); getTableData(" > Top Rated (More than 4 stars)") }}>
                                                    <center>
                                                        Top Rated (More than 4 stars)
                                                        <p>
                                                            {rdata?.top_rated ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Avg Rated (between 1 to 4 stars )"); getTableData(" > Avg Rated (between 1 to 4 stars )") }}>
                                                    <center>
                                                        Avg Rated (between 1 to 4 stars )
                                                        <p>
                                                            {rdata?.last_15_days ?? "-"}
                                                        </p>
                                                    </center>
                                                </div> */}
                                            {/* </div> */}
                                        {/* </div> */}
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Incomplete Profiles"); getTableData(" > Incomplete Profiles") }}>
                                                    <center>
                                                        Incomplete Profiles
                                                        <p>
                                                            {rdata?.incomplete_profiles ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Direct Registration"); getTableData(" > Direct Registration") }}>
                                                    <center>
                                                        Direct Registration
                                                        <p>
                                                            {rdata?.direct_registration ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Profile Claim Reuqests"); getTableData(" > Profile Claim Reuqests") }}>
                                                    <center>
                                                        Profile Claim requests
                                                        <p>
                                                        {rdata?.profile_claim_reqs ?? "-"}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div></>

                                }

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )

}


const mapStateToProps = state => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })
export default connect(mapStateToProps)(EduReports)
