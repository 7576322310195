import React, { useState } from 'react'



function DataInsideReport(props) {
  

    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Added By</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>Added On</th>
                        <th>Status</th>
                        <th>KYC</th>
                        <th>Last Updated </th>
                        <th>Last Updated By</th>
                    </tr>
                    {
                        props.dataReport.map((data, key) => <tr key={key} >
                            <td >{data.disp_id}</td>
                            <td>{data.name}</td>
                            <td>{data.add_by}</td>
                            <td>{data.loc_name}</td>
                            <td>{data.email}</td>
                            <td>{data.mob_no}</td>
                            <td>{data.add_on.split(" ",1)}</td>
                            <td>{data.p_status}</td>
                            <td>{data.k_status}</td>
                            <td>{data.u_date.split(" ",1)}</td>
                            <td>{data.u_by}</td>
                        </tr>)
                    }
                </table>


            </div>                           




        </>
    );
}

export default DataInsideReport ;