import axios from 'axios'
import config from '../config'
import URL from './url'

import { rootstore } from "../redux/store";

const api = {

    get: (url) => axios.get(URL[config.env].BASE_URL + url),

    getCountry: (request) => axios.get(
        URL[config.env].BASE + request.url
    ),



    delete: (request) => axios.delete(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { ...request.headers}
        }
    ),

   
    getCustom: (request) => axios.get(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { ...request.headers}
        }
    ),
    post: (request) => axios.post(
        URL[config.env].BASE_URL + request.url,
        request.data
    ),

    postAuthHeader: (requestData) => axios.post(
        URL[config.env].BASE_URL+requestData.url, 
        requestData.post, {...requestData.headers},
        
    ),


    postAuth: (request) => axios.post(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { Authorization: request.token }
        }
    ),


    postCustom: (request) => axios.post(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { ...request.headers, }
        }
    ),

    patch: (request) => axios.patch(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { ...request.headers, }
        }
    ),

    put: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data
    ),
    
    putAuth: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { Authorization: request.token }
        }
    ),
    putOther: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { ...request.headers }
        }
    )
}


export default api