import React, { useEffect, useState, useRef } from "react";
import unexpad from '../../../../assets/img/un-expand.svg'
import expand from '../../../../assets/img/expand.svg'
import circular from '../../../../assets/img/circular.png'
import api from '../../../../api/api'
import masters from "../../../../masters";
import config from '../../../../config'
import { errorMsg } from "../../../../utils";
import { connect } from "react-redux";
import Moment from 'moment';
import Loader from '../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';


const mdata = masters[config.env]
export function Qualifications(props) {
  const [Qualification, setQualification] = useState([{ inst_name: "", d_name: "", e_type: "", f_date: "", t_date: "", desc: "", is_ongoing: false, open: true, id: "" }])
  const [loading, setLoading] = useState(false)
  const [education, seteducation] = useState([])
  const [quali, setQuali] = useState(false)
  const [initialTime, setInitialTime] = useState(true)



  useEffect(() => {
    profiletype()
    if (props.dataId != "") {
      getQualification()
    }
  }, [props.dataId])






  let directorRef = useRef(null);
  useEffect(() => {
    if (initialTime == false) {
      directorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [Qualification])



  const getQualification = () => {
    props.setLoading(true)
    let request = {
      url: "educator/qualification/all?educator_id=" + props.dataId + "&pageOffset=1&pageSize=10&isAll=false",
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      props.setLoading(false)
      setQualification(data.data.result.response.map((data) => ({ ...data, is_ongoing: data.is_ongoing, open: false })))
      if (data.data.result.response.length > 0) {
        setQuali(true)
      }
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }

  const validate = (data) => {
    if (data.inst_name == "") {
      toast.error("Please Enter School/Institute Name")
      return false
    } if (data.d_name == "") {
      toast.error("Please Enter Degree Name")
      return false
    } if (data.f_date == "") {
      toast.error("Please select From Date")
      return false
    }
    return true
  }

  const savedetails = (index) => {
    let data = { ...Qualification[index] };
    let id = data.id;
    delete data.id;
    delete data.open;
    if (validate(data)) {
      props.setLoading(true)
      let request = {
        url: `educator/${props.dataId}/qualification/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data
      }
      if (id == "") {
        api.postCustom(request).then(data => {
          props.setLoading(false)
          toast.success("Educator Added successfully.")
          setInitialTime(true)
          setQualification(Qualification.map((d, i) => i == index ? { ...d, open: false, id: data.data.result } : d))
        }).catch((err) => {
          props.setLoading(false)
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })

      } else {
        request.url = `educator/qualification/${id}/update`
        api.putOther(request).then(data => {
          props.setLoading(false)
          toast.success("Educator updated successfully.")
          setInitialTime(true)
          setQualification(Qualification.map((d, i) => i == index ? { ...d, open: false } : d))
        }).catch((err) => {
          props.setLoading(false)
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
      }
    }
  }

  const profiletype = () => {
    let education_type = mdata.find(data => data.name == "Co-Education")?.entity_id;
    let request = {
      url: `master/all/sub-master/?mast_id_list=${education_type}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      seteducation(data.data.result[education_type])
    }).catch((err) => {
      if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
    })
  }


  const openBox = (index) => {
    setQualification(Qualification.map((d, i) => i == index ? { ...d, open: true } : d))
  }


  const deleteQualification = (index) => {
    props.setLoading(true)
    let data = { ...Qualification[index] };
    let id = data.id;
    if (id == "") {
      setQualification(Qualification.filter((data3, index3) => index3 != index))
      props.setLoading(false)
    }
    else {
      let request = {
        url: `educator/qualification/${id}/delete`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.delete(request).then(data => {
        props.setLoading(false)
        toast.success("Quallification Deleted successfully")
        setQualification(Qualification.filter((data3, index3) => index3 != index))
      }).catch((err) => {
        props.setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const ShowonProfile = (id, status) => {
    // setSOP(sop => (!sop));
    props.setLoading(true)
    let request = {
      url: `educator/qualification/${id}/highest/${!status}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.putOther(request).then(data => {
      props.setLoading(false)
      toast.success("Set as highest quallification updated successfully.")
      getQualification()
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })

  }

  return (
    <>
      <div style={{ background: "#00ffd00d" }}>
        <div class="flex-a pointer">
          <img src={expand} class="mr16" onClick={() => {
          if( props.dataId == ""){
            toast.error("Please Add Educator")
          }else{
            setInitialTime(false);
            setQuali(true);
            setQualification([...Qualification, { inst_name: "", d_name: "", e_type: "", f_date: "", t_date: "", desc: "", is_ongoing: "", id: "", open: true }])
          }}} /><label class="mb-0">Educational Qualifications </label>
        </div>
      </div>

      {quali == true && Qualification.map((data, index) => {
        return (<>
          {
            data.open == true ? <div ref={directorRef}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img src={unexpad} style={{ cursor: "pointer" }} class="mr16" onClick={() => { setInitialTime(true); deleteQualification(index) }} />
              </div>
              <div class="mt-32">
                <label>Enter School/Institute Name <span>*</span></label>

                <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.inst_name} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, inst_name: e.target.value } : data2))} />
              </div>
              <div class="mt-16 flex mob-v">
                <div class="mr-70">
                  <label>Degree Name <span>*</span></label>

                  <input contenteditable="true" class="expandale-input w-300" placeholder="Enter" value={data.d_name} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, d_name: e.target.value } : data2))} />
                </div>
                <div class="mob-top">
                  <label>Education Type</label>

                  <div class="select-wrapper ">
                    <select class="expandale-input" value={data.e_type} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, e_type: e.target.value } : data2))}>
                      <option>Select</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Distance Learning">Distance Learning</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex-a mt-16 mob-res">
                <div class="mr-50">
                  <label>From Date<span>*</span></label>
                  <input type="date" class="expandale-input" placeholder="dd-mm-yyyy" onKeyDown={(e) => e.preventDefault()} value={data.f_date} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, f_date: e.target.value } : data2))} />
                </div>
                {
                  data.is_ongoing != true &&
                  <div class="mob-top">
                    <label>To Date{data.is_ongoing == true ? "(Expected)" : ""}</label>
                    <input type="date" class="expandale-input" placeholder="dd-mm-yyyy" value={data.t_date} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, t_date: e.target.value } : data2))} />
                  </div>
                }

                <div class="flex-inline mt-36">
                  <label class="checkbox">
                    <span class="checkbox__input">
                      <input type="checkbox" name="checked" checked={data.is_ongoing == true ? true : false} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, is_ongoing: !data.is_ongoing } : data2))} />
                      <span class="checkbox__control">
                        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                          <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                        </svg>
                      </span>
                    </span>
                  </label>
                  <span>Currently Ongoing</span>
                </div>
              </div>
              <div class="mt-16">
                <label>Tell us more about your experience</label>

                <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={data.desc} onChange={(e) => setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, desc: e.target.value } : data2))} />
              </div>
              <div class="display mob-top">
                <div></div>
                <div>
                  <button class="white_button mr16" onClick={() => { data.id == "" ? setQualification(Qualification.filter((data3, index3) => index3 != index)) : setQualification(Qualification.map((data2, index2) => index == index2 ? { ...data2, open: false } : data2)); setInitialTime(true) }}>Cancel</button>
                  <button class="blue_button" onClick={() => { props.dataId == "" ? toast.error("Please Add Educator") : savedetails(index) }}>Save</button>
                </div>
              </div>
            </div> :
              <div class="view-detail-short">
                <div class="display align-s">
                  <div class="flex">
                    <img src={circular} />
                    <div>
                      <p>
                        {data.inst_name}
                      </p>
                      <p>
                        {data.d_name}
                      </p>
                      <p>
                      {Moment(data.f_date).format('DD-MM-YYYY')} to {data.t_date==null?"Currently Ongoing":Moment(data.t_date ).format('DD-MM-YYYY')}
                      </p>
                    </div>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '42%' }}>
                    <div class="flex-a">
                      <span style={{ marginRight: '5px' }}>Set as highest qualificaton</span>
                      <label class="switch1">
                        <input type="checkbox" checked={data.ht_qual == true ? true : false} style={{ marginLeft: "30px" }}
                          onClick={() => ShowonProfile(data.id, data.ht_qual)}
                        />
                        <span class="slider1 round"></span></label>
                    </div>
                    <button class="editbutton" onClick={() => { openBox(index) }}> Edit</button>
                    <button class="deletebutton" onClick={() => { deleteQualification(index) }}> Delete</button>
                  </div>
                </div>
                <p></p>
              </div>
          }
        </>
        )
      })}
    </>
  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Qualifications)