import React, { useState, useEffect } from "react";
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import EducatorBasicProfile from "./profile";
import Loader from '../../../components/loader'
import Educatorheader from "../../addEducators/educator_header";
import { connect } from "react-redux";
import { errorMsg } from '../../../utils'
import ClassDetails from "./classdetails";
import Media from "./media";



function BasicDetail(props) {
  const [loading, setLoading] = useState(false)
  const [dataId, setDataId] = useState("")
  let [detail, setDetail] = useState([])
  let [courseDeatil, setcourseDetail] = useState([])
  const [getvideolink, setGetvideolink] = useState([])

  const [Profile, setProfile] = useState({
    title: "",
    name: "",
    noOfStudents: "",
    profileHeading: "",
    address: "",
    country: "",
    mobNo: "",
    pin: "",
    appNo: "",
    profilePhoto: "",
    image: '',
    mail: "",
    onlineonly: false
  })

  const [classdetails, setClassDetails] = useState({
    standard: [],
    subjects: [],
    modeofTution: [],
    tution: "",
    fee: ""
  })

  const [Master, setMaster] = useState({
    standard: [],
    subjects: [],
    category: [],
    courseSub: []
  })


  const [media, setMedia] = useState({
    videoLink: [],
    website: "",
    yt: "",
    li: "",
    fb: "",
    insta: "",
    tw: "",

  })

  const [address, setaddress] = useState({
    address: "",
    country: "",
    mobNo: "",
    pin: "",
    area: '',
    state: "",
    city: "",
    lat: "",
    long: "",
  })

  useEffect(() => {
    if (window.location.href.split("?").length > 1 || props.eduId != "") {
      SeletedEducator(props.eduId);
    }
    getStandard();
    getSubject();
    Category();

  }, [])





  const SeletedEducator = () => {
    props.setId(props.eduId != "" ? props.eduId : window.location.href.split("?")[1].split("=")[1])
    setLoading(true)
    let request = {
      url: `educator/${props.eduId != "" ? props.eduId : window.location.href.split("?")[1].split("=")[1]} `,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      let rdata = data.data.result;
      setDataId(rdata.id)
      setProfile({ ...Profile, title: rdata.title, name: rdata.e_name, noOfStudents: rdata.n_of_s, profileHeading: rdata.p_headline, onlineonly: rdata.onl_only, mobNo: rdata.phone, pin: rdata.p_code, appNo: rdata.w_number, profilePhoto: rdata.ph_url, image: rdata.ph_url, mail: rdata.email })
      setDetail(rdata.std_sub_li)
      setcourseDetail(rdata.crs_sub_li)
      setClassDetails({ ...classdetails, modeofTution: rdata.mot ?? [], tution: rdata.p_tuitions, fee: rdata.f_pr_hr })
      setGetvideolink(rdata.vdo_link ?? [])
      setaddress({ ...Profile, address: rdata.loc_det.address, pin: rdata.loc_det.p_code, state: rdata.loc_det.s_nm, city: rdata.loc_det.ct_nm, area: rdata.loc_det.ar_nm, })
      setMedia({
        ...media, fb: rdata.s_m_link.fb, website: rdata.s_m_link.website,
        yt: rdata.s_m_link.yt,
        li: rdata.s_m_link.li,
        insta: rdata.s_m_link.insta,
        tw: rdata.s_m_link.twitter,
      })
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }




  const getStandard = () => {
    setLoading(true)
    let request = {
      url: `master-stand/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, standard: data.data.result }))
    }).catch((err) => {
      setLoading(false)

    })
  }

  const Category = () => {
    setLoading(true)
    let request = {
      url: `course/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, category: data.data.result }))

    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }




  const getSubject = () => {
    setLoading(true)
    let request = {
      url: `master-sub/all`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setMaster(Master => ({ ...Master, subjects: data.data.result.all_std_subject }))
      setMaster(Master => ({ ...Master, courseSub: data.data.result.all_course_subject }))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  function isObject(val) {
    return val instanceof Object;
  }

  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      let link = [...getvideolink, ...media.videoLink.map(data => data.link)]
      let tempdata = {
        title: Profile.title,
        e_name: Profile.name,
        n_of_s: Profile.noOfStudents * 1,
        p_headline: Profile.profileHeading,
        email: Profile.mail,
        phone: Profile.mobNo,
        w_number: Profile.appNo,
        uid: props.up_id,
        s_m_link: { fb: media.fb, li: media.li, website: media.website, yt: media.yt, insta: media.insta, twitter: media.tw },
        vdo_link: link,
        mot: classdetails.modeofTution,
        f_pr_hr: classdetails.fee * 1,
        p_tuitions: classdetails.tution == "No" ? false : true,
        ph_url: dataId != "" ? isObject(Profile.profilePhoto) ? "" : Profile.profilePhoto : "",
        onl_only: Profile.onlineonly,
        ss_det: detail,
        cs_det: courseDeatil,
        loc_det: {
          c_nm: "India",
          s_nm: address.state,
          ct_nm: address.city,
          ar_nm: address.area,
          p_code: address.pin,
          address: address.address,
          lat: address.lat,
          long: address.long
        },
        is_by_proc: props.p_type == 6 ? true : false
      }

      fd.append("u_data", JSON.stringify(tempdata));
      fd.append('file', Profile.profilePhoto);
      let request = {
        url: `educator/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      if (dataId == "") {
        api.postCustom(request).then(data => {
          setLoading(false)
          toast.success("Educator Added successfully.")
          setDataId(data.data.result)
          props.setId(data.data.result)
          localStorage.setItem("prof_id", data.data.result);
          setTimeout(() => {
            props.setCount(2)
          }, [3000]);
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      } else {
        request.url = 'educator/' + dataId + '/update'
        api.putOther(request).then(data => {
          setLoading(false)
          toast.success("Educator updated successfully.")
          setTimeout(() => {
            props.setCount(2)
          }, [3000]);
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      }
    }
  }


  const validate = () => {
    if (Profile.title == "") {
      toast.error("Please select Title.")
      return false
    } if (Profile.name == "") {
      toast.error("Please enter Full Name.")
      return false
    } if (Profile.profileHeading == "") {
      toast.error("Please enter Profile heading.")
      return false
    } if (address.address == "") {
      toast.error("Please enter Address.")
      return false
    } if (Profile.mail == "") {
      toast.error("Please enter Email.")
      return false
    } if (Profile.mobNo == "") {
      toast.error("Please enter Mobile number.")
      return false
    } if (Profile.profilePhoto == "") {
      toast.error("Please upload Profile Photo.")
      return false
    } if (detail == "") {
      toast.error("Please select Standard and Subject.")
      return false
    } if (courseDeatil == "") {
      toast.error("Please select Category and Subject.")
      return false
    }
    return true
  }


  const Cancel = () => {
    setProfile({ ...Profile, title:"", name: "", noOfStudents: "", profileHeading: "", onlineonly: "", mobNo: "", pin: "", appNo: "", profilePhoto: "", image: "", mail: "" })
    setDetail([])
    setcourseDetail([])
    setClassDetails({ ...classdetails, modeofTution: [], tution: "", fee: "" })
    setGetvideolink([])
    setaddress({ ...Profile, address: '', pin: '', state: '', city: '', area: '' })
    setMedia({
      ...media, fb: '', website: '',
      yt: '',
      li: '',
      insta: '',
      tw: '',
    })

  }



  return (<>

    {loading && <Loader />}

    <div>
      <Educatorheader
        count={props.count} setCount={props.setCount}
        setButton={props.setButton}
        setEcount={props.setEcount}
        savedetails={savedetails}
        showform={false}
      />

      <div class="form-section ">

        <EducatorBasicProfile
          Profile={Profile} setProfile={setProfile}
          address={address} setaddress={setaddress}
        />


        <ClassDetails
          classdetails={classdetails}
          setClassDetails={setClassDetails}
          Master={Master}
          setMaster={setMaster}
          setDetail={setDetail}
          detail={detail}
          courseDeatil={courseDeatil}
          setcourseDetail={setcourseDetail}
        />


        <Media
          savedetails={savedetails}
          media={media} setMedia={setMedia}
          Master={Master}
          setMaster={setMaster}
          setGetvideolink={setGetvideolink}
          getvideolink={getvideolink}
          Cancel={Cancel}
        />
      </div>
    </div>

    <ToastContainer />
  </>)
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(BasicDetail)