import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import edit from '../../../assets/img/header/edit.svg'
import deleteb from '../../../assets/img/header/delete.svg'
import { ToastContainer, toast } from "react-toastify";
import eye from '../../../assets/img/header/eye.svg'
import config from '../../../config';



function Data(props) {
    const history = useHistory()




    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Added By</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>Added On</th>
                        <th>Status</th>
                        <th>KYC</th>
                        <th>Last Updated </th>
                        <th>Last Updated By</th>
                        <th>Action</th>

                    </tr>


                    {
                        props.data.map((data, key) => (data.e_name.toLowerCase().indexOf(props.search.toLowerCase()) != -1) && <tr key={key} >
                            <td >{data.disp_id}</td>
                            <td>{data.e_name}</td>
                            <td>{data.added_by}</td>
                            <td>{data?.ct_nm ?? "-"}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{data.c_date.split(" ", 1)}</td>
                            <td>{data.status}</td>
                            <td>{data.kyc_status}</td>
                            <td>{data.u_date}</td>
                            <td>{data.upd_by}</td>
                            <td>

                                <img src={edit} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} onClick={() => { props.setSelected(data.id); history.push(`/educator/profile/?id=${data.id}`) }} />
                                <img src={deleteb} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
                                    onClick={() => { props.setdelete(true); props.setId(data.id) }}
                                />
                                <label class="switch1" >
                                    <input type="checkbox" checked={data.status == "Active" ? true : false}
                                        onChange={() => { props.ShowonProfile(data.id, data.status) }} />
                                    <span class="slider1 round"></span>
                                </label>

                                <img src={eye} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
                                    onClick={() => {
                                        window.open(`${config.env == 'sit' ? "https://discovery-student.proctur.com/" : "https://discover.proctur.com/"}details/educator/${(data.e_name)?.replace(/ /g, '-').toLowerCase()}?id=${data?.id}`)
                                    }} />


                            </td>
                        </tr>)
                    }
                </table>


            </div>


            <ToastContainer />

        </>
    );
}

export default Data;