import React, { useState, useEffect } from 'react'
import Pagination from "react-js-pagination";
// import EnquiresEducator from '../enquires/educator/index'
// import EnquiresSchool from '../enquires/coaching/index'
import { connect } from "react-redux";
import NavBar from './navBar';
import Loader from '../../components/loader'
import { errorMsg } from '../../utils';
import api from '../../api/api'
import Data from './data';
import { ToastContainer, toast } from 'react-toastify';
import Operate from './operate'


function ReviewRating(props) {
    const [selectedTab, setSelectedTab] = useState("SCHOOL")
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("");
    const [popup, setPopup] = useState(false)
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)
    const [active, setActive] = useState(1)
    const [total, setTotal] = useState("")
    const [activeCheck, setActiveCheck] = useState(false)
    const [reviewData, setReviewData] = useState({})
    const [operate, setOperate] = useState({
        search_text: "", ratings: "0", f_date: "2022-06-06", t_date: "2022-12-31", reported: ""
    })

    useEffect(() => {
        if(selectedTab=='EDUCATOR'){
            fetchDataEducator()
        }else{
            fetchData();
        }
    }, [selectedTab])

    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `report/institute-review/${selectedTab}?pageOffset=${active}&pageSize=10`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.postCustom(request).then(data => {
            setLoading(false)
            setTotal(data.data.result.total_elements)
            setRdata(data.data.result.response)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const fetchDataEducator = () => {
        setLoading(true)
        let request = {
            url: `report/educator-review?pageOffset=${active}&pageSize=10`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.postCustom(request).then(data => {
            setLoading(false)
            setTotal(data.data.result.total_elements)
            setRdata(data.data.result.response)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const Apply = () => {
        if (operate.search_text == "" && operate.ratings == "" && operate.f_date == "" && operate.t_date == "" && operate.reported == "") {
            toast.error("Please apply any filter.")
        } else {
            setLoading(true)
            let request = {
                url: `${selectedTab=="EDUCATOR"?`report/educator-review?`:`report/institute-review/${selectedTab}&`}pageOffset=${active}&pageSize=10`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: operate
            }
            api.postCustom(request).then(data => {
                setRdata(data.data.result.response)
                setTotal(data.data.result.total_elements)
                toast.success("Filter applied successfully")
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })

        }

    }

    const getReviewbyId = (id) => {
        setLoading(true)
        let request = {
            url: `institute/review/${id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setPopup(true)
            setReviewData(data.data.result)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const Delete = (id) => {
        setLoading(true)
        let request = {
            url: `${selectedTab=="EDUCATOR"?"educator/review/":"institute/review/"}${id}/delete`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.delete(request).then(data => {
            setLoading(false)
            if(selectedTab=="EDUCATOR"){
                fetchDataEducator()
            }else{
                fetchData();
            }
            toast.success("Deleted successfully.")
            setPopup(false)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                // props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const handlePageChange = (active) => {
        setActive(active);
        if (activeCheck == true) {
          Apply(active)
        } else {
          fetchData(active)
        }
      }

    return (
        <>
            {loading && <Loader />}
            <div className="sa-leadsss">

                <NavBar
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />

                <Operate fetchData={fetchData}
                    setLoading={setLoading} selected={selected} setSelected={setSelected} value={search} setOperate={setOperate} Apply={Apply} operate={operate} data={rdata.find(data => data.id == selected)} />

                <Data rdata={rdata}
                    getReviewbyId={getReviewbyId}
                    setReviewData={reviewData}
                    popup={popup} setPopup={setPopup}
                    Delete={Delete}
                />

                <div style={{display:"flex",justifyContent:'center'}}>
                <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={active}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={10}
                    onChange={(n) => { handlePageChange(n); }} />

                </div>

                

                {/* {selectedTab == "School" &&
                    <EnquiresSchool rdata={rdata} />
                }

                {selectedTab == "Coaching" &&
                    <EnquiresCoaching rdata={rdata} />
                }

                {selectedTab == "Educator" &&
                    <EnquiresEducator rdata={rdata} />
                } */}


            </div>
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(ReviewRating)
