import React, { useState ,useEffect} from "react";
import ReportPopup from "./reportPopup";


export default function ReviewDetails(props) {
   
    console.log(props.mdata,"mdtss")

    return (
        <>
            <div class="form-section review">
                <div>
                    <label>Reviewer Details</label>
                    <p class="line_purple"></p>
                    <div class="flex w-501">
                        <div class="flex">
                            <label><b>Reviewer Name:</b></label><label class="ml16">{props.mdata.stud_nm}</label>
                        </div>
                        <div class="flex">
                            <label><b>Reviewed on:</b></label><label class="ml16">{props.mdata.u_date}
                            </label>
                        </div>
                    </div>
                </div>
                <div>
                    <label>Review Details</label>
                    <p class="line_purple"></p>

                    <div class="flex w-501 review-star">
                        <div class="flex-a">
                            <label><b>Over All:</b></label>
                            <span>   
                              {Array.from(Array(props.mdata.overall).keys()).map((data)=><i class="fa fa-star"></i>)}

                                {/* <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star-half-o"></i> */}
                            </span>
                        </div>
                        <div class="flex-a">
                            <label><b>Infrastructure:</b></label>
                            <span>

                            {Array.from(Array(props.mdata.infra).keys()).map((data)=><i class="fa fa-star"></i>)}
                            </span>
                        </div>
                    </div>
                    <div class="flex w-501 mt-16 review-star">
                        <div class="flex-a">
                            <label><b>Faculty:</b></label>
                            <span>
                            {Array.from(Array(props.mdata.faculty).keys()).map((data)=><i class="fa fa-star"></i>)}
                            </span>
                        </div>
                        <div class="flex-a">
                            <label><b>Placements:</b></label>
                            <span>

                            {Array.from(Array(props.mdata.placements).keys()).map((data)=><i class="fa fa-star"></i>)}
                            </span>
                        </div>
                    </div>
                    <div class="flex w-501 mt-16 review-star">
                        <div class="flex-a">
                            <label><b>Academics:</b></label>
                            <span>
                            {Array.from(Array(props.mdata.academics).keys()).map((data)=><i class="fa fa-star"></i>)}
                            </span>
                        </div>
                        <div class="flex-a">
                            <label><b>Study&nbsp;Material:</b></label>
                            <span>

                            {Array.from(Array(props.mdata.std_mat).keys()).map((data)=><i class="fa fa-star"></i>)}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <label>Review Details</label>
                    <p class="line_purple"></p>
                   
           
                        <p class="mb-0"> {props.mdata.desc}</p>
                     
                </div>
                <div class="button-section">
                    <div></div>
                    <div>
                      <button class="white_button mr16" onClick={()=>props.setForm(false)}>Cancel</button>
                      <button class="blue_button" onClick={()=>{props.setpopup(true);props.setselected(props.mdata.id)}}>Report</button>
                    </div>
                  </div>
            </div>

           
        </>
    )
}