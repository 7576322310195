import React, { useState, useEffect } from "react";
import DeletePopup from "../../popups/delete";
import Loader from '../../../../../components/loader'
import api from '../../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import FAQBlock from "./faq/faq_block";
import { errorMsg } from "../../../../../utils";
import { connect } from "react-redux";
import FAQForm from "./faq/faq_form";
import Category from "./category/category_form";


function FAQ(props) {
    let [mdata, setMdata] = useState({})
    const [type, setType] = useState("Category")
    const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([]);
     const [faq,setfaq]=useState([])


    useEffect(() => {
        fetchData();
        fetchDataFaq();
        props.setselected(0);
      }, [])
      
      const fetchData = () => {
        if(props.prof_id==""){
          toast.error("Please Add Basic Details First.")
          setLoading(false)
          }else{
        setLoading(true)
        let request = {
          url: `faq/catg/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false `,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.getCustom(request).then(data => {
          setLoading(false)
          setRdata(data.data.result.response)
        }).catch((err) => {
          setLoading(false)
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })}
      }
    
      const fetchDataFaq = () => {
        setLoading(true)
        let request = {
          url: `faq/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.getCustom(request).then(data => {
          setLoading(false)
          setfaq(data.data.result.response)
        }).catch((err) => {
          setLoading(false)
            if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
      }
    

    
    return (<>
      {loading && <Loader />}
        {
            props.form == false &&
            <FAQBlock {...props} setLoading={setLoading} setMdata={setMdata} setType={setType} type={type} rdata={rdata} setRdata={setRdata} faq={faq} setfaq={setfaq} fetchDataFaq={fetchDataFaq} fetchData={fetchData}/>
        }

        {props.form == true && type == "Category" &&
            <Category {...props} setLoading={setLoading} mdata={mdata} fetchData={fetchData} fetchDataFaq={fetchDataFaq}/>
        }
        {props.form == true && type == "Faq" &&
            <FAQForm {...props} setLoading={setLoading} mdata={mdata}  setMdata={setMdata} fetchDataFaq={fetchDataFaq} setType={setType} rdata={rdata}/>
        }


    </>)

}


const mapStateToProps = (state) => ({loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(FAQ)