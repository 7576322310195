import React, { useState, useEffect } from 'react'
import EducatorNavBar from "./navbar";
import Operate from "./operate";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/loader'
import { errorMsg } from '../../utils';
import api from '../../api/api'
import { connect } from 'react-redux';
import Data from './school/data';
import Pagination from "react-js-pagination";
import Delete from '../../components/delete'



function Educator(props) {

  const [loading, setLoading] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [selected, setSelected] = useState(0)
  const [operate, setOperate] = useState({
    added: "", location: "", fromdate: "2022-06-06", todate: "2022-12-31", statuss: "", kyc: ""
  })
  const [search, setSearch] = useState("")
  const [active, setActive] = useState(1)
  const [country, setCountry] = useState([])
  const [activeCheck, setActiveCheck] = useState(false)
  const [total, setTotal] = useState("")
  const [idd, setId] = useState("")
  const [deletepopup, setdelete] = useState(false)


  useEffect(() => {
    fetchData(active);
    getCountry()
  }, [])


  const fetchData = (active) => {
    setLoading(true)
    let request = {
      url: `educator/all?pageOffset=${active}&pageSize=10&isAll=false&uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.postCustom(request).then(data => {
      setRdata(data.data.result.response)
      setLoading(false)
      setTotal(data.data.result.total_elements)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }
  const getCountry = () => {
    // setLoading(true)
    let request = {
      url: `country/stCtAr/all-city-names`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.getCustom(request).then(data => {
      //   setLoading(false)
      setCountry(data.data.result)

    }).catch((err) => {
      //   setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }

  const Apply = () => {
    if (operate.added == "" && operate.location == "" && operate.fromdate == "" && operate.todate == "" && operate.statuss == "" && operate.kyc == "") {
      toast.error("Please apply any filter.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/all?is_own=false&pageOffset=${active}&pageSize=10&isAll=false&uid=${props.up_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          add_by: operate.added,
          loc_id: operate.location,
          f_date: operate.fromdate,
          t_date: operate.todate,
          p_status: operate.statuss,
          k_status: operate.kyc,
        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
        setTotal(data.data.result.total_elements)
        toast.success("Filter applied successfully")
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const reset = () => {
    setOperate({ ...operate, added: "", location: "", fromdate: "", todate: "", statuss: "", kyc: "" })
    fetchData(active)
  }


  const deleteEducator = () => {
    setLoading(true)
    let request = {
      url: `educator/${idd}/delete?uid=${props.up_id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Deleted successfully")
      setId("")
      setdelete(false)
      setRdata(rdata.filter((item3) => item3.id != idd))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  const handlePageChange = (active) => {
    setActive(active);
    if (activeCheck == true) {
      Apply(active)
    } else {
      fetchData(active)
    }
  }


  const ShowonProfile = (id, status) => {
    setLoading(true)
    let request = {
      url: `educator/${id}/set-active?is_act=${status == "Active" ? false : true}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success("Show On Profile Updated successfully")
      fetchData(active)
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }




  return (
    <>
      {loading && <Loader />}
      <div className="sa-leadsss">
        <EducatorNavBar Apply={Apply} setSearch={setSearch} setLoading={setLoading} />

        <Operate data={rdata.find(data => data.id == selected)} setOperate={setOperate} Apply={Apply} operate={operate} reset={reset} setCountry={setCountry} country={country} />

        <Data data={rdata} setSelected={setSelected} search={search} setLoading={setLoading} ShowonProfile={ShowonProfile}
          setdelete={setdelete} setId={setId} deleteEdu={deleteEducator}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={active}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={(n) => { handlePageChange(n); }} />
        </div>

        {deletepopup == true &&
          <Delete delete={deleteEducator} setdelete={setdelete} />
        }


      </div>
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Educator)
