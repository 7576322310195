import React, { useState } from "react";
import mob from '../../../assets/img/mobile.svg'
import whts from '../../../assets/img/basic/whts.png'
import pic from '../../../assets/img/pic.svg'
import Autocomplete from "react-google-autocomplete";
import { ToastContainer, toast } from 'react-toastify';



export default function EducatorBasicProfile(props) {
  
  const onChange = (e) => {
    if(validateImage(e)){
    props.setProfile({ ...props.Profile, profilePhoto:(e.target.files[0]) ,image: URL.createObjectURL(e.target.files[0]) })}
  }

  const validateImage = (e) => {
    let img = e.target.files[0].size/ 1024
    if (img > 500) {
      toast.error("Please upload image of size 500kb only.")
      return false
    }
    else return true
  }
  
  const validateText = (name) => {
    var reg = /^[A-Z a-z]*$/
    if (!reg.test(name)) {
      return false;
    }
    return true;
  }

  const validateEmail = (email) => {
    var reg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!reg.test(email))
      toast.error("Please enter a valid email address");

  }

  const validateMobileNumber = (number) => {
    var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    if (!reg.test(number)) {
      toast.error("Please enter a valid 10 digit Number");
    }
  }

  return (
    <>
      <div>
        <p class="heading">Your Profile<span>Educator</span></p>
        <p class="line_purple"></p>
        <div class="flex f-wrap">
          <div>
            <label>Title<span>*</span></label>
            <div class="select-wrapper w-20">
              <select class="expandale-input w-20"  value={props.Profile.title} onChange={(e) => props.setProfile({ ...props.Profile, title: e.target.value })} >
                <option>Select</option>
                <option>Mr</option>
                <option>Mrs</option>
                <option>Ms</option>
                <option>Dr</option>
                <option>Hon</option>
                <option>Prof</option>
                <option>Jr</option>

              </select>
            </div>
          </div>
          <div>
            <label>Full Name <span>*</span></label>

            <input contenteditable="true" class="expandale-input w-450 mob-20" placeholder="Enter" value={props.Profile.name} onChange={(e) => {if (validateText(e.target.value)){props.setProfile( { ...props.Profile, name: e.target.value })}}} />
          </div>
          <div class="w-14">
            <label>Number of Students Taught</label>

            <input contenteditable="true" class="expandale-input" placeholder="Enter" value={props.Profile.noOfStudents}  type="number" min={0} onChange={(e) => props.setProfile({ ...props.Profile, noOfStudents: e.target.value })} />
            <p class="data-info mt10">This number shows how many students you taught till date</p>
          </div>
        </div>
      </div>
      <div>
        <label>Profile Headline<span>*</span></label>
        <p class="data-info">Brief about your educational and teaching experience. Also mention your expertise in
          subjects if any.</p>
        <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={props.Profile.profileHeading} onChange={(e) => props.setProfile({ ...props.Profile, profileHeading: e.target.value })} />
      </div>
      <div>
        <label>Address<span>*</span></label>
        <div>
        <Autocomplete
          apiKey={"AIzaSyBgjbTWg8b3WbMXvdykC5zn28RdmNKil6k"}
          class="expandale-input w-450"
          value={props.address.address}
          onChange={(e) => (props.setaddress({ ...props.address, address: e.target.value }))}
          onPlaceSelected={(place) => {
            // console.log(place.geometry.location.lng(), "place");
            let zip = place.address_components.find(item => item.types[0] === 'postal_code')?.long_name ?? "-";
            let state = place.address_components.find(item => item.types[0] === 'administrative_area_level_1')?.long_name ?? "-";
            let city = place.address_components.find(item => item.types[0] === 'administrative_area_level_2')?.long_name ?? "-";
            let area = place.address_components.find(item => item.types[0] === 'sublocality_level_1')?.long_name ?? "-";
            props.setaddress({ ...props.address, address: place.formatted_address, pin: zip, state: state, city: city, area: area, lat: place.geometry.location.lat(), long: place.geometry.location.lng() })
          }}
          options={{
            types: ['geocode', 'establishment'],
            componentRestrictions: { country: "in" },
          }}
        />

       
        </div>
        <div class="flex-inline">
          <label class="checkbox">
            <span class="checkbox__input">
              <input type="checkbox" name="checked" checked={props.Profile.onlineonly} onClick={(e) => props.setProfile({ ...props.Profile, onlineonly: !props.Profile.onlineonly })}  />
              <span class="checkbox__control">
                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                  <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                </svg>
              </span>
            </span>
          </label>
          <span class="branch"> Online only <span class="data-info">Select if your institute provides only online
            courses</span></span>
        </div>
      </div>
      <div class="flex">
        <div>
          <label>Country <span>*</span></label>
          <div class="select-wrapper">
            <select class="expandale-input w-200"  onChange={(e) => { (props.setaddress({ ...props.address, country: e.target.value })) }}>
              <option value="1">India</option>

            </select>
          </div>
        </div>
        <div class="mob-top">
          <label>Pin code <span>*</span></label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.pin} />
        </div>
      </div>
      <div class="flex">
        <div class="opacity-5">
          <label>State</label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.state} />
          <p class="data-info">State, City will be fetched from pin code</p>
        </div>
        <div class="mob-top opacity-5">
          <label>City</label>
          <input contenteditable="true" class="expandale-input w-200" placeholder="Enter" value={props.address.city} />
        </div>
      </div>
      <div>
        <label>Email Address<span>*</span></label>
        <div class="flex-p1 w-70">
        <input class="expandale-input w-450" type="email" contenteditable="true" onBlur={(e) => validateEmail(e.target.value)} placeholder="Enter" value={props.Profile.mail} onChange={(e) => props.setProfile({ ...props.Profile, mail: e.target.value })}></input>
        <a href="#" data-target="#changeModal" data-toggle="modal">Change</a>
        </div>
        <p class="data-info">This detail is for Internal Use only.Will not be shown on profile</p>

      </div>
      <div>
        <label><img src={mob} />Mobile Number<span>*</span></label>

        <div class="flex-p1 w-70">

          <div class="select-wrapper">
            <select class="expandale-input w-200 india">
              <option value="ind">+91</option>
              <option>+1</option>
            </select>
          </div>
          <button></button>
          <input class="expandale-input w-450" contenteditable="true" maxlength="10" placeholder="Enter" value={props.Profile.mobNo}
            onKeyPress={(e) => {
              let regex = /^\d+$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, mobNo: e.target.value })
              }
            }}
            onBlur={(e) => validateMobileNumber(e.target.value)}
            onChange={(e) => {
              let regex = /^\d+$/;
              if (((e.target.value === '' || regex.test(e.target.value)))) {
                props.setProfile({ ...props.Profile, mobNo: e.target.value })
              }
            }}
          >
          </input>
          <a href="#">Change</a>

        </div>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
      </div>
      <div>
        <label><img src={whts} /> WhatsApp Number</label>
        <p class="data-info">This detail is for internal use only. Will not be shown on profile</p>
        <div class="flex-a mob-res">
          <div class="flex-p1 w-70">
            <div class="select-wrapper">
              <select class="expandale-input w-200 india">
                <option class="india">+91</option>
              </select>
            </div>
            <button></button>
            <input class="expandale-input w-450" contenteditable="true" placeholder="Enter" maxlength="10" value={props.Profile.appNo}
              onChange={(e) => {
                let regex = /^\d+$/;
                if (((e.target.value === '' || regex.test(e.target.value)))) {
                  props.setProfile({ ...props.Profile, appNo: e.target.value })
                }
              }}
              onBlur={(e) => validateMobileNumber(e.target.value)}
              onKeyPress={(e) => {
                let regex = /^\d+$/;
                if (((e.target.value === '' || regex.test(e.target.value)))) {
                  props.setProfile({ ...props.Profile, appNo: e.target.value })
                }
              }
              } ></input><a href="#">Change</a>
          </div>
          <div class="flex-inline">
            <label class="checkbox">
              <span class="checkbox__input">
                <input type="checkbox" name="checked"  onClick={(e) => { e.target.checked == true ? props.setProfile({ ...props.Profile, appNo: props.Profile.mobNo }) : props.setProfile({ ...props.Profile, appNo: "" }) }}/>
                <span class="checkbox__control">
                  <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                  </svg>
                </span>
              </span>
            </label>
            <span>Same as Mobile Number</span>
          </div>
        </div>
      </div>
      <div>
        <label>Profile Photo<span>*</span></label>
        <label htmlFor="photo-upload" >
          <div   >{props.Profile.profilePhoto == "" ?
            <img for="photo-upload" class="pro-pic" src={pic} /> :
            <img for="photo-upload" class="pro-pic" src={props.Profile.image} />
          }
          </div>
          <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
            display: "none",
            visibility: "hidden"
          }} />
        </label>

        <p class="data-info mt10">Max size 500kb. Format accepted: jpg, jpeg, img.</p>
      </div>



    </>
    
  )

}


