import React, { useState,useEffect } from "react";
import api from '../../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../../utils";
import Loader from '../../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';

function FAQForm(props) {
  const [category, setCategory] = useState("")
  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [loading, setLoading] = useState(false)
  const [etitle, seteTitle] = useState("")


  useEffect(() => {
    if (props.selected != 0) {
      seteTitle(props.mdata.question)
      setAnswer(props.mdata.ans)
      setCategory(props.mdata.catg_id)
    }
    return category
  }, [props.selected])


  console.log(props.mdata)

  const savedetails = () => {
    if(validate()){
    setLoading(true)
    let request = {
      url: `faq/add`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: {
        catg_id: category,
        question: question,
        ans: answer,
        i_p_id: props.prof_id
      }
    }
    api.postCustom(request).then(data => {
      setLoading(false)
      toast.success("FAQ added successfully")
      props.setselected(0); props.setForm(false);
      props.fetchDataFaq();
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }
}


  const updateValue = () => {
    props.setLoading(true)
    let request = {
        url: `faq/${props.mdata.id}/update`,
        headers: {
            'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          catg_id: category,
        question: etitle,
        ans: answer,
        i_p_id: props.prof_id
        }
    }
    api.putOther(request).then(data => {
         props.setLoading(false)
         toast.success("FAQ Updated successfully")
         props.setselected(0); props.setForm(false);
      props.fetchDataFaq();
    }).catch((err) => {
         props.setLoading(false)
           if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
    })
  }


  const validate = () => {
    if (category === '') {
        toast.error("Please Select Category")
        return false
    }
    if (question === '') {
        toast.error("Please Enter question")
        return false
    }
    if (answer === '') {
      toast.error("Please Enter answer")
      return false
  }
    return true
}


  return (

    <>
      {loading && <Loader />}
      <div class="form-section ">
        <div>
          <p class="heading">Add FAQ</p>
          <p class="line_purple"></p>
          <label>Category <span>*</span></label>

          <div class="select-wrapper">
            <select class="expandale-input w-200" value={category} onChange={(e) => setCategory(e.target.value)}>
              <option>Select</option>
              {props.rdata.map((item,key) => { return (<option  key={key} value={item.id}>{item.name}</option>) })}
            </select>
          </div>
          <p class="data-info"></p>

        </div>
        <div>
          <label>Question<span>*</span></label>
          {props.selected ?
          <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
          <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={question} onChange={(e) => setQuestion(e.target.value)} />
        }
         
          <p class="data-info">Max 50 chars, Alphanumeric allowed.</p>

        </div>
        <div>
          <label>Answer<span>*</span></label>

          <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={answer} onChange={(e) => setAnswer(e.target.value)} />
          <p class="data-info">Max 150 chars, Alphanumeric allowed.</p>

        </div>
        <div class="button-section">
          <div></div>
          <div>
          <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); props.setType("Category") }}>Cancel</button>
          {
            props.selected ?
              <button class="blue_button" onClick={updateValue}>Update</button> :
              <button class="blue_button" onClick={()=>{
                if(props.prof_id==""){
                  toast.error("Please Add Basic Details First.")
                }else{
                  savedetails()
                }
               }}>Save</button>
          }
        </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(FAQForm)