import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import edit from '../../../assets/img/header/edit.svg'
import eye from '../../../assets/img/header/eye.svg'
import deleteb from '../../../assets/img/header/delete.svg'
import { connect } from 'react-redux';
import config from '../../../config';





function InstituteData(props) {
    const history = useHistory()

    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>Added On</th>
                        <th>Added By</th>
                        <th>Status</th>
                        <th>KYC</th>
                        {/* <th>Notify</th> */}
                        <th>Last Updated </th>
                        <th>Last Updated By</th>
                        <th>Score</th>
                        <th>Action</th>
                        {/* <th>Preview</th> */}
                    </tr>
                    {
                        props.data.map((data, key) => (data.inst_name.toLowerCase().indexOf(props.search.toLowerCase()) != -1) && <tr key={key} >
                            <td >{data.disp_id}</td>
                            <td>{data.inst_name}</td>
                            <td>{data?.ct_nm ?? '-'}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{data.c_date.split(" ", 1)}</td>
                            <td>{data.added_by}</td>
                            <td>{data.status}</td>
                            <td>{data.kyc_status}</td>
                            {/* <td><img src={bell} /></td> */}
                            <td>{data.u_date.split(" ", 1)}</td>
                            <td>{data.upd_by}</td>
                            <td>{data.p_c_percent}%</td>
                            <td>
                                <img src={edit} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }}
                                    onClick={() => {
                                        props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.id });
                                        props.setSelected(data.id);
                                        history.push(`/institute/profile/?type=school?id=${data.id}`);
                                    }} />
                                <img src={deleteb}  onClick={()=>{props.setdelete(true); props.setId(data.id)}} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
                        
                                    <label class="switch1">
                                        <input type="checkbox" checked={data.status == "Active" ? true : false}
                                            onClick={() => props.ShowonProfile(data.id,data.status)}
                                        />
                                        <span class="slider1 round"></span>

                                    </label>

                                    <img src={eye} style={{ width: "20px", height: "20px", marginLeft:"8px", cursor: "pointer" }}
                                onClick={() => {
                                  window.open(`${config.env=='sit'?"https://discovery-student.proctur.com/":"https://discover.proctur.com/"}details/school/${(data.inst_name)?.replace(/ /g, '-').toLowerCase()}?id=${data?.id}`)
                                }} />
                            
                            </td>
                          

                            {/* <td><button onClick={() => props.setSelected(data.id)}>EDIT</button></td> */}
                        </tr>)
                    }
                </table>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(InstituteData)
