import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorMsg } from '../../../utils'
import ProfileMang from "../../../profilemanagmnet"
import { connect } from 'react-redux'
import config from '../../../config'




const mdata = ProfileMang[config.env]
const entity_id = mdata.find(data => data.path == window.location.pathname)?.entity_id;


function Operate(props) {
    const [subProfile, setSubProfile] = useState("")
    const [esubProfile, seteSubProfile] = useState("")
    const [position, setPosition] = useState("")
    const [eposition, setePosition] = useState("")
    const [profileType, setProfileType] = useState([])
    const [prof, setprof] = useState("")


    useEffect(() => {
        profiletype()
        if (props.selected != 0) {
            seteSubProfile(props.data.name)
            // setePosition(props.data.position)
            setprof(props.data.sub_profile_id)
        }
    }, [props.selected])


    const profiletype = () => {
        let profile_eid = mdata.find(data => data.name == "Profile Type")?.entity_id;
        let request = {
            url: `master/all/sub-master/?mast_id_list=${profile_eid}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setProfileType(data.data.result[profile_eid])

        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }
    const updateValue = () => {
        props.setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({ name: esubProfile, pos: eposition * 1, s_m_id: prof }));
        let request = {
            url: `master/${props.entity_id}/sub-master/update/${props.data.entity_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: fd
        }
        api.putOther(request).then(data => {
            props.setLoading(false)
            setSubProfile(""); setPosition(""); setprof("")
            toast.success("Sub Profile updated Successfully")
            props.setSelected(0);
            props.fetchData();

        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: subProfile, pos: position * 1, s_m_id: prof }));
            let request = {
                url: `master/${props.entity_id}/sub-master/add`,
                headers: { 'Authorization': `Bearer ${props.loginToken}` },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                setSubProfile(""); setPosition(""); setprof("")
                toast.success("Sub Profile added Successfully  ")
                // setTitle("");
                // seteTitle("");
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const isNumber = (num) => {
        return /^[0-9\b]+$/.test(num)
    }

    const validate = () => {
        if (subProfile === '') {
            toast.error("Please enter Sub Profile Type.")
            return false
        }
        if (prof === "") {
            toast.error("Please Select the Profile Type")
            return false
        }
        return true
    }



    return (
        <>
            <div className="Sa-AddProfile">
                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit  Sub Profile  Type</p> : <p style={{ fontSize: "15px" }}>Add Sub Profile  Type</p>
                }
                <div className="Super-admin-line_purple"></div>
                <div className="Sa-dropdown">
                    <section className="sa-wrapper2">
                        <select name="Master Category" id="profile" className="Sa-input" style={{ fontSize: "13px" }} value={prof} onChange={(e) => setprof(e.target.value)}>
                            <option value="">Profile Type</option>
                            {profileType.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                        </select>
                    </section>
                </div>
                <div className="Sa-Inputbox">


                    <div>
                        {
                            props.selected ?
                                <input value={esubProfile} onChange={(e) => seteSubProfile(e.target.value)} type="text" id="subProfile" className="Sa-input" name="fname" placeholder="Add Sub Profile Type " style={{ fontSize: "13px" }} /> :

                                <input value={subProfile} onChange={(e) => setSubProfile(e.target.value)} type="text" id="subProfile" className="Sa-input" name="fname" placeholder="Add Sub Profile Type " style={{ fontSize: "13px" }} />
                        }
                    </div>
                </div>


                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); setSubProfile(""); setPosition(""); setprof("") }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { setSubProfile(""); setPosition(""); setprof("") }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }
                <ToastContainer />

            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)