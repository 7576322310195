import React, { useEffect, useState } from "react";
import api from '../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../utils";
import Loader from '../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';

function ScholarshipForms(props) {
  const [name, setName] = useState("")
  const [Details, setDetails] = useState("")
  const [Criteria, setCriteria] = useState("")
  const [loading, setLoading] = useState(false)
  const [etitle, seteTitle] = useState("")
  const [edetails, setedetails] = useState("")
  const [eciteria, setecriteria] = useState("")
  


  useEffect(() => {
    if (props.selected != 0) {
      seteTitle(props.mdata.name)
      setedetails(props.mdata.desc)
      setecriteria(props.mdata.criteria)
    }
    return name
  }, [props.selected])


  const savedetails = () => {
    if(validate()){
    setLoading(true)
    let request = {
      url: `scholarship/add`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
        'Content-Type': "application/json"
      },
      data: {
        name: name,
        desc: Details,
        criteria: Criteria,
        uid: props.up_id,
        i_p_id: props.prof_id

      }
    }
    api.postCustom(request).then(data => {
      setLoading(false)
      toast.success("Scholarship Added successfully")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
}
  }

  const updateValue = () => {
    props.setLoading(true)
    let request = {
      url: `scholarship/${props.mdata.id}/update`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
        'Content-Type': "application/json"

      },
      data: {
        name: etitle,
        desc: edetails,
        criteria: eciteria,
        uid: props.up_id,
        i_p_id:props.prof_id
      }
    }
    api.putOther(request).then(data => {
      props.setLoading(false)
      toast.success("Scholarship Updated successfully.")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }


  const validate = () => {
    if (name === '') {
        toast.error("Please Enter Name")
        return false
    }
    if (Details === '') {
        toast.error("Please Enter Details")
        return false
    }
    if (Criteria === '') {
      toast.error("Please Enter Criteria")
      return false
  }
    return true
}



  return (
    <>
       {loading && <Loader />}
      <div>
        <div class="form-section">
          <div>
            {props.selected ?
              <p class="heading">Edit Scholarship</p> : <p class="heading">Add Scholarship</p>}
            <p class="line_purple"></p>
            <p class="data-info mt10">
              You can Add scholarships which are available to meritorious students to be displayed on your profile.
            </p>
            <label>Name <span>*</span></label>
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
              <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
            }
          </div>
          <div>


            <label>Details <span>*</span></label>
            
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={edetails} onChange={(e) => setedetails(e.target.value)}></input> :
              <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" onChange={(e) => setDetails(e.target.value)}></input>
            }
          </div>
          <div>


            <label>Criteria <span>*</span></label>
           
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={eciteria} onChange={(e) => setecriteria(e.target.value)}></input> :
              <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" onChange={(e) => setCriteria(e.target.value)}></input>
            }
          </div>
          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
              {
                props.selected ?
                  <button class="blue_button" onClick={updateValue}>Update</button> :
                  <button class="blue_button" onClick={()=>{
                    if(props.prof_id==""){
                      toast.error("Please Add Basic Details First.")
                    }else{
                      savedetails()
                    }
                    }}>Save</button>
              }
            </div>

          </div>
        </div>

      </div>
    
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(ScholarshipForms)