import React, {useState, useEffect} from 'react'
import MasterSearch from '../../../components/masterSearch'
import Loader from '../../../components/loader'
import Data from './data'
import Operate from '../../leads/operate'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';


function LeadEducator(props) {
   

    

    return (
        <>
            {/* {loading && <Loader />} */}
            <div className="Sa-Profile2">
                <Data data={props.rdata}  />
            </div>




        </>
    );
}

export default LeadEducator;