import React, { useState, useEffect } from "react";
import dwld from '../../assets/home/upload.svg'
import searchh from '../../assets/home/search_icon.png'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { errorMsg } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api/api'


function EducatorNavBar(props) {
    const history = useHistory()
    const [uploadFile, setUploadFile] = useState({})


    const Bulkupload = (temp) => {
        props.setLoading(true)
        console.log(temp)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({ uid: props.up_id, upld_for: 'EDUCATOR' }));
        fd.append('file', temp);
        let request = {
            url: `educator/bulk-upload`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: fd
        }
        api.postCustom(request).then(data => {
            props.setLoading(false)
            toast.success("File Uploaded successfully")
            setUploadFile({})

        }).catch((err) => {
            props.setLoading(false)
            setUploadFile({})
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    return (<>

        <div className="sa-LeadsNav">
            <div className="sa-leadsHead2" style={{ marginTop: "10px" }}>

            </div>


            <div className="sa-leads">
                <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ padding: "0", marginRight: "10px" }}>
                    <div className="sa-searchhh">
                        <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                        <input className="Super-admin-Search_Input" type="text"
                            placeholder="Search" style={{ outline: "0" }} onChange={(e) => props.setSearch(e.target.value)} />
                    </div>
                </div>
                </div>
                <div className="sa-add">
                    <button class="blue_button" onClick={() => history.push('/educator/profile/')}  >Add Educator </button>
                </div>
                <label htmlFor="photo-upload" >
                    <div >
                        <img src={dwld} style={{ height: "20px", width: "20px", marginRight: "5px", cursor: 'pointer' }} />
                    </div>
                    <input id="photo-upload" type="file" accept="application/vnd.ms-excel" style={{ display: "none", visibility: "hidden" }}
                        onChange={(e) => {
                            Bulkupload(e.target.files[0])
                        }} ></input>
                </label>
                <span >Bulk Upload</span>
            </div>
        </div>


    </>)

}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(EducatorNavBar)

