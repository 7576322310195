import React, { useState } from "react";
import BasicDetailForm from "./basicdetails/basic_detail_form";
import StandardBlocks from "./standard/standardBlocks";
import Educator from "../profile/educators"
import AluminiBlock from "./alumini/alumini_blocks";
import AluminiForm from "./alumini/alumini_form";
import ManagementBox from "./form_management/management_box";
import ManagementForm from "./form_management/managemnt_form";
import { ToastContainer } from "react-toastify";
import MisslHeader from "./MisslForms/"




function ProfileInstitute() {
    const [count, setCount] = useState(1);
    const [button, setButton] = useState("")
    const [ecount, setEcount] = useState(true)
    const [edit_data, setEdit] = useState([])

    console.log(button)
    console.log(count)
    return (
        <>
            <ToastContainer />
            <div style={{ width: '100%' }}>

                {/* <ProfileHeader
                    count={count} setCount={setCount}
                    setButton={setButton}
                    setEcount={setEcount}
                    setEdit={setEdit}
                /> */}


                {count == 1 &&
                    <BasicDetailForm  button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} count={count} setCount={setCount} setEdit={setEdit} />
                }
                {count == 2 &&
                    <StandardBlocks button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} count={count} setCount={setCount} setEdit={setEdit}/>
                }

                {count == 3 && ecount == true &&
                    <Educator button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit} count={count} setCount={setCount}  />
                }

                {/* {count == 3 && ecount == true &&
                    <EducatorBlocks button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit}/>
                }
                {button == "Educator" && ecount == false &&
                    <EducatorForm button={button} setButton={setButton} ecount={ecount} setEcount={setEcount}  setEdit={setEdit} edit_data={edit_data}/>
                } */}


                {count == 4 && ecount == true &&
                    <AluminiBlock button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit} count={count} setCount={setCount} />
                }

                {button == "Alumni" && ecount == false &&
                    <AluminiForm button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit} edit_data={edit_data} count={count} setCount={setCount} />
                }


                {count == 5 && ecount == true &&
                    <ManagementBox button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit} count={count} setCount={setCount} />
                }

                {button == "Management" && ecount == false &&
                    <ManagementForm button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} setEdit={setEdit} edit_data={edit_data} count={count} setCount={setCount} />
                }

                {count == 6 &&
                    <MisslHeader  button={button} setButton={setButton} ecount={ecount} setEcount={setEcount} count={count} setCount={setCount} setEdit={setEdit}/>

                }

            </div>
        </>
    )
}

export default ProfileInstitute