import React, { useState } from "react";
import close from '../../../../assets/img/close.svg'
import add from '../../../../assets/img/add.svg'
import circular from '../../../../assets/img/circular.png'
import video from '../../../../assets/img/video.png'
import globe from '../../../../assets/img/basic/globe.png'
import yt from '../../../../assets/img/yt.svg'
import linkk from '../../../../assets/img/linkedin.svg'
import fb from '../../../../assets/img/fb.svg'
import insta from '../../../../assets/img/basic/insta.png'
import tw from '../../../../assets/img/tw.svg'
import { toast } from "react-toastify";
import api from '../../../../api/api'
import Loader from '../../../../components/loader'
import { errorMsg } from "../../../../utils";
import { connect } from "react-redux";

function Media(props) {
  let type = window.location.href.split("?")[1].split("=")[1]
  const [link, setlink] = useState("")


  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      console.log([...props.Social.gallary, ...props.getGallary].length, "length")
      if ([...props.Social.gallary, ...props.getGallary].length > 10) {
        toast.error("You are only allowed to upload a maximum of 10 files")
      } else {
        if (e.target.files.length > 0) {
          let imgs = [];
          if((props.Social.gallary.length + e.target.files.length) > 10){
            toast.error("You are only allowed to upload a maximum of 10 files")
          } else{
            for (const [key, value] of Object.entries(e.target.files)) {
              imgs.push(value)
            }
            props.setSocial({ ...props.Social, gallary: [...props.Social.gallary, ...imgs] })
          }          
        }
      }
    }
  }


  


  const select_CoverImage = (e) => {
    if (validateImage(e)) {
      if ([...props.Social.coverImage, ...props.getCover].length > 4) {
        toast.error("You are only allowed to upload a maximum of 5 files")
      } else {
        if (e.target.files.length > 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          props.setSocial({ ...props.Social, coverImage: [...props.Social.coverImage, ...imgs] })
        }
      }
    }
  }

  const validateImage = (e) => {
    console.log(e.target.files[0].size, "kjngnghg")
    let img = e.target.files[0].size / 1024
    console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }



  const deleteImage = (id, check) => {
   
    props.setLoading(true)
    let request = {
      url: `inst/deleteFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      props.setLoading(false)
      toast.success("Image Deleted successfully")
      if (check == "galary") {
        props.setGetGallaryx(props.getGallary.filter((item3) => item3.id != id))
      } else {
        props.setGetCover(props.getCover.filter((item3) => item3.id != id))
      }
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 

    })
  }




  const getImageThumbnail = () => {
    if (link.indexOf("http") == -1) {
      toast.error("Please Add Video Link")
    } else if ([...props.getvideolink, ...props.Social.videolink].length > 2) {
      toast.error("You are only allowed to upload a maximum of 3 video link.")
    }
    else {
      let videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        props.setSocial({
          ...props.Social, videolink: [...props.Social.videolink, {
            preview: `https://img.youtube.com/vi/${videoid[1]}/0.jpg`,
            link
          }]
        })
      } else {
        props.setSocial({
          ...props.Social, videolink: [...props.Social.videolink, {
            preview: circular,
            link
          }]
        })
      }
      setlink("")
    }
  }

  const YouTubeGetID = (url) => {
    var ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    return ID;
  }



  return (<>

    <div>

      <label>Facilities</label>
      <p class="line_purple"></p>
      <p class="data-info">Please select facilities that you offer to students</p>
      <div class="select-wrapper">

        <select class="expandale-input w-200" onChange={(e) => props.setSocial({ ...props.Social, facilities: [...props.Social.facilities, e.target.value] })} >
          <option value="0">Select</option>
          {props.Master.facilities.map((item,key) => props.Social.facilities.indexOf(item.entity_id) == -1 && <option value={item.entity_id}>{item.name}</option>)}
        </select>
      </div>
      <div class="tags">
        {props.Master.facilities.map((item,key) => props.Social.facilities.indexOf(item.entity_id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, facilities: props.Social.facilities.filter((data) => data != item.entity_id) })} /></button>)}
      </div>
    </div>
    {
      type == 'school' &&
      <div>
        <label>Extra Curricular Activities</label>
        <p class="line_purple"></p>
        <p class="data-info">Please select extra curricular activities that you offer to students</p>
        <div class="select-wrapper">
          <select class="expandale-input w-200" onChange={(e) => props.setSocial({ ...props.Social, extracurricular: [...props.Social.extracurricular, e.target.value] })} >
            <option value="0">Select</option>
            {props.Master.extracurricular.map((item,key) => props.Social.extracurricular.indexOf(item.entity_id) == -1 && <option value={item.entity_id}>{item.name}</option>)}
          </select>
        </div>
        <div class="tags">
          {props.Master.extracurricular.map((item,key) => props.Social.extracurricular.indexOf(item.entity_id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, extracurricular: props.Social.extracurricular.filter((data) => data != item.entity_id) })} /></button>)}
        </div>
      </div>
    }

    <div>
      <label>Media Content </label>
      <p class="line_purple"></p>
      <label>Gallery</label>
      <p class="data-info">These photos will be shown in Your Gallery section on Your Proctur Profile. You can
        display your Campus, Activities etc through these photos.</p>
      <div class="flex-p1">
        <input class="expandale-input w-450 choose" type="file" accept="image/*" multiple onChange={select_MediaContent} disabled={[...props.Social.gallary, ...props.getGallary].length === 10} placeholder="Choose File" />
      </div>
      <p class="data-info">Max 10 images with Format jpeg,jpg, image max file size 500kb</p>
      <div class="tags-img">
        {props.getGallary.map((item,key) => {
           {key=key}
          return (
            <div >
              <img src={item.f_url} />
              <img src={close} onClick={() => { deleteImage(item.id, "galary"); }} />
              {/* Once deleted and response is 200 filter the oimage array and remove deleted image on the basis of ID  */}
            </div>
          )
        })
        }
        {props.Social.gallary.map((item,key) => {
           {key=key}
          return (
            <div>
              <img src={URL.createObjectURL(item)} />
              <img src={close} onClick={(index) => props.setSocial({ ...props.Social, gallary: (props.Social.gallary.filter(item2 => item2.name + item2.lastModified != item.name + item.lastModified)) })} />
            </div>
          )
        })
        }
      </div>
    </div>
    <div>
      <label>Video Link </label>
      <p class="data-info">You can enter YouTube, Vimeo or any other video links</p>
      <div class="flex-p1">
        <input class="expandale-input w-450 choose" type="text" placeholder="Add Link"  value={link} onChange={(e) => setlink(e.target.value)}></input>
        <img src={add} style={{ cursor: "pointer" }} onClick={getImageThumbnail} />
      </div>
      <div class="tags-img">
        {props.getvideolink.map((item,key) => {
           {key=key}
          return (
            <div >
              <img src={`https://img.youtube.com/vi/${YouTubeGetID(item)}/0.jpg`} onClick={() => { window.open(item) }} />
              <img src={close} onClick={(index) => props.setGetvideolink(props.getvideolink.filter(item2 => item2 != item))} />
            </div>
          )
        })
        }
        {props.Social.videolink.map((item,key) => {
           {key=key}
          return (
            <div >
              <img src={item.preview} onClick={() => { window.open(item.link) }} />
              <img src={close} onClick={(index) => props.setSocial({ ...props.Social, videolink: (props.Social.videolink.filter(item2 => item2.preview + item2.lastModified != item.preview + item.lastModified)) })} />
            </div>
          )
        })
        }
      </div>
    </div>
    <div>
      <label>Cover Images</label>
      <p class="line_purple"></p>
      <p class="data-info">These photos will be shown as cover images on your profile </p>
      <div class="flex-p1">
        <input type="file" class="expandale-input w-450 choose" accept="image/*" multiple onChange={select_CoverImage} disabled={[...props.Social.coverImage, ...props.getCover].length === 5} placeholder="Choose File" />
      </div>
      <p class="data-info">Max 5 images with Format jpeg, jpg, Optimal Dimensions 3200*320 and Max Size upto 500kb</p>
      <div class="tags-img">
        {props.getCover.map((item,key) => {
           {key=key}
          return (
            <div >
              <img src={item.f_url} />
              <img src={close} onClick={() => { deleteImage(item.id, "cover"); }} />
              {/* Once deleted and response is 200 filter the oimage array and remove deleted image on the basis of ID  */}
            </div>
          )
        })
        }
        {props.Social.coverImage.map((item,key) => {
           {key=key}
          return (
            <div>
              <img src={URL.createObjectURL(item)} />
              <img src={close} onClick={(index) => props.setSocial({ ...props.Social, coverImage: (props.Social.coverImage.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified)) })} />
            </div>
          )
        })
        }
      </div>
    </div>
    <div>
      <label>Social Media Links</label>
      <p class="line_purple"></p>
      <div class="flex-p1 m-24">
        <img src={globe} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Website Link" value={props.Social.website} onChange={(e) => props.setSocial({ ...props.Social, website: e.target.value })}></input>
      </div>
      <div class="flex-p1 m-24">
        <img src={yt} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Youtube Link" value={props.Social.yt} onChange={(e) => props.setSocial({ ...props.Social, yt: e.target.value })}></input>
      </div>

      <div class="flex-p1 m-24">
        <img src={linkk} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Linkedin Link" value={props.Social.li} onChange={(e) => props.setSocial({ ...props.Social, li: e.target.value })}></input>
      </div>
      <div class="flex-p1 m-24">
        <img src={fb} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Facebook Link" value={props.Social.fb} onChange={(e) => props.setSocial({ ...props.Social, fb: e.target.value })}></input>
      </div>

      <div class="flex-p1 m-24">
        <img src={insta} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Instagram Link" value={props.Social.insta} onChange={(e) => props.setSocial({ ...props.Social, insta: e.target.value })}></input>
      </div>
      <div class="flex-p1">
        <img src={tw} style={{ height: "25px", width: "25px" }} />
        <input class="expandale-input w-450 ml-8" contenteditable="true" placeholder="Enter Your Twitter Link" value={props.Social.twitter} onChange={(e) => props.setSocial({ ...props.Social, twitter: e.target.value })}></input>
      </div>

    </div>
    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end' }}>
      <button class="white_button mr16" onClick={() => { props.clearDetails(); window.scrollTo(0, 0) }}>Cancel</button>
      <button class="blue_button" onClick={() => props.savedetails()}>Save</button>
    </div>

    {/* <!-- Basic Details Form Ends--> */}
  </>)
}



const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Media)

