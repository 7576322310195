import React from 'react';
import '../../css/superAdmin/sa_inst_reports.css'
import filter_icon from '../../assets/home/filter_icon.png'


class StuReports extends React.Component {
    constructor(props) {
        super(props)
        this.state =
        {
            rprt_data1: [2318, 467, 1111],
            state_wise_data: [10, 2, 45, 777, 21, 34],
            states: ["Maharashtra", "Karnatka", "Gujarat", "Uttar Pradesh", "Goa"],
            cards_data: [1111, 49, 499, 49, 802, 17]
        }
    }
    render() {


        return (
            <>
                <div className="inst_reports">

                    <div className="inst_reports_inner">
                        <div className="entire_reports_2">
                            <div className="sa_reports_inner">
                                <div className="reports_heading_and_cards">
                                    <div className="sa_reports_heading2">
                                        <div>
                                            <font onClick={()=>{this.props.setShow("reports_initial_page")}}
                                                style={{
                                                    cursor: "pointer",
                                                    // border:"1px solid gray",
                                                    borderRadius: "4px",
                                                    color: "#0097F9",
                                                    background: "rgb(236, 230, 230);",
                                                }}
                                            >
                                                {" Reports > "}</font> Students Report</div>

                                        <div className="reports_heading_filters" >
                                            <img src={filter_icon} height="22px" />
                                            <div className="reports_from_date_filter_area">

                                                {/* <div className="reports_absolute_inputs">
                                                 <input  type="date"/>
                                             </div> */}

                                                <div className="reports_non_absolute_inputs">
                                                    {<input placeholder="From Date" id="input1"
                                                        style={{ cursor: "pointer", outline: "0" }}
                                                        type={this.state.input1 ? "date" : "text"}
                                                        onClick={() => { this.setState({ input1: true }) }}
                                                    />
                                                    }
                                                </div>

                                            </div>
                                            {/* { this.document.getElementById("input1").click()} */}

                                            <div className="reports_from_date_filter_area">
                                                {/* <div className="reports_absolute_inputs">
                                                 <input  type="date"/>
                                             </div> */}
                                                <div className="reports_non_absolute_inputs">
                                                    {
                                                        <input placeholder="To Date" id="input1"
                                                            style={{ cursor: "pointer", outline: "0" }}
                                                            type={this.state.input1 ? "date" : "text"}
                                                            onClick={() => { this.setState({ input1: true }) }}
                                                        />
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sa_reports_cards">
                                        <div className="sa_reports_cards_inner_2">
                                            <div className="reports_cards">
                                                <center >
                                                  Inactive  &gt; 7
                                                    <p>
                                                        {this.state.rprt_data1[1]}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards">
                                                <center>
                                                     + 5 Reviews
                                                    <p>
                                                        {this.state.rprt_data1[2]}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards">
                                                <center>
                                                    Online Admissions(fee paid,incomplete application)
                                                   
                                                    <p>
                                                        {this.state.rprt_data1[2]}
                                                    </p>
                                                </center>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="sa_reports_cards">
                                        <div className="sa_reports_cards_inner_2">
                                            <div className="reports_cards">
                                                <center >
                                                 Most Search Location
                                                 <div style={{display:"flex",justifyContent:"space-between",marginLeft:"20px",marginRight:"30px"}}>
                                                 <p> Website -{this.state.rprt_data1[1]}</p>
                                            
                                                    <p> App  -{this.state.rprt_data1[1]}</p>
                                                    
                                                    </div>

                                                </center>
                                            </div>

                                            <div className="reports_cards">
                                            <center >
                                                 Most Shared Education Level
                                                 <p>
                                                     Website-
                                                        {this.state.rprt_data1[2]}
                                                    </p>

                                                </center>
                                            </div>
                                            <div className="reports_cards">
                                                <center>
                                                   Most Used Filter
                                                   
                                                   <div style={{display:"flex",justifyContent:"space-between",marginLeft:"20px",marginRight:"30px"}}>
                                                 <p> School/Institute-{this.state.rprt_data1[1]}</p>
                                            
                                                    <p> Educator-{this.state.rprt_data1[1]}</p>
                                                    
                                                    </div>
                                                </center>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="sa_reports_cards">
                                        <div className="sa_reports_cards_inner_2">
                                            <div className="reports_cards">
                                                <center >
                                                 Usage
                                                 <div style={{display:"flex",justifyContent:"space-between",marginLeft:"20px",marginRight:"30px"}}>
                                                 <p> Website-{this.state.rprt_data1[1]}</p>
                                                    <p> Mobile-{this.state.rprt_data1[1]}</p>
                                                    </div>
                                                </center>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </>
        )
    }
}
export default StuReports