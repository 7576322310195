import React, { useState, useEffect, Profiler } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import ProfileMang from '../../../profilemanagmnet';
import close from '../../../assets/img/close.svg'
import { errorMsg } from '../../../utils'
import { connect } from 'react-redux'

const mdata = ProfileMang[config.env]


function Operate(props) {
    const [title, setTitle] = useState("")
    const [etitle, seteTitle] = useState("")
    const [photo, setphoto] = useState("")
    const [image, setimage] = useState("")
    const [value, setValue] = useState("")
    const [value2, setValue2] = useState("")
    const [check, setcheck] = useState(false)
    const [profile, setprofile] = useState([])
    const [subprofile, setSubprofile] = useState([])


    useEffect(() => {
        fetchData()
        profiletype()
        if (props.selected != 0) {
            setTitle(props.data.name)
            setValue2(props.data.sub_profile_id)
            setValue(props.data.profile_id)
            setcheck(props.data.show_on_website)
            setimage(props.data.file_url)
            setphoto(props.data.file_url)
        }
    }, [props.selected])



    const updateValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, pr_type: value, sub_pr: value2, s_web: check }));
            fd.append('file', photo);
            let request = {
                url: `master_catg/${props.data.entity_id}/update`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.putOther(request).then(data => {
                props.setLoading(false)
                toast.success("Master Category updated Successfully  ")
                setValue2("")
                setValue("")
                setTitle("");
                seteTitle("");
                setimage("")
                setcheck("")
                props.setSelected(0);
                props.fetchData();

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, pr_type: value, sub_pr: value2, s_web: check }));
            fd.append('file', photo);
            let request = {
                url: `master_catg/add`,
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Master category added Successfully  ")
                setValue2("")
                setValue("")
                setTitle("");
                seteTitle("");
                setimage("");
                setcheck("")
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }
    }
    const profiletype = () => {
        let profile_eid = mdata.find(data => data.name == "Profile Type")?.entity_id;
        // let subprofile_eid = mdata.find(data => data.name == "Sub Profile Type")?.entity_id;
        let request = {
            url: `master/all/sub-master/?mast_id_list=${profile_eid}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setprofile(data.data.result[profile_eid])
            // setSubprofile(data.data.result[subprofile_eid])

        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const fetchData = () => {

        let request = {
            url: `master/3b6df447-d9af-4958-bc0a-224273cae73f/sub-master/all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setSubprofile(data.data.result)
        }).catch((err) => {
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };

        })
    }

    const OnCancel = () => {
        setValue2("")
        setValue("")
        setTitle("");
        seteTitle("");
        setimage("")
        setcheck("")
    }



    const select_file = (e) => {
        setphoto(e.target.files[0])
        setimage(URL.createObjectURL(e.target.files[0]));
    }

    const validate = () => {
        if (title === '') {
            toast.error("Please Enter Master Category")
            return false
        } if (photo === '') {
            toast.error("Please Upload Image")
            return false
        } if (value === '') {
            toast.error("Please Select Profile Type")
            return false
        }
        return true
    }



    return (
        <>
            <div className="Sa-AddProfile">

                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Master Category</p> : <p style={{ fontSize: "15px" }}>Add Master Category</p>
                }
                <div className="Super-admin-line_purple"></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="Sa-dropdown">
                        <section className="sa-wrapper2">
                            <select className="Sa-input" style={{ fontSize: "13px" }} value={value} onChange={(e) => setValue(e.target.value)}>
                                <option value="">Profile Type</option>
                                {profile.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })}
                            </select>
                        </section>
                    </div>
                    <div style={{ marginLeft: "100px", marginTop: "10px" }}>
                        <input type="checkbox" style={{ marginRight: "10px" }} checked={check} onChange={() => setcheck(!check)} />Show on App
                    </div>
                </div>
                <div className="Sa-dropdown">
                    <section className="sa-wrapper2">
                        <select className="Sa-input" style={{ fontSize: "13px" }} value={value2} onChange={(e) => setValue2(e.target.value)}>
                            <option value="">Sub Profile Type</option>
                            {subprofile.map((item) => item.sub_profile_id == value && <option value={item.entity_id}>{item.name}</option>)}
                        </select>
                    </section>
                </div>

                <div className="Sa-Inputbox">
                    <div >

                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Master Category " style={{ fontSize: "13px" }} />


                    </div>
                    <div >

                        <input type="file" accept="image/*" contenteditable="true" placeholder='Upload Icon*' onChange={select_file} style={{ fontSize: "13px", borderBottom: "1px solid #939393", width: '70%' }} />
                        {
                            image != "" &&
                            <div class="tags-img">
                                <div>
                                    <img src={image} />
                                    <img src={close} onClick={() => { setimage("") }} />
                                </div>
                            </div>

                        }


                    </div>
                </div>


                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); OnCancel() }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => OnCancel()}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }

                <ToastContainer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)
