const masters = {
    sit:[ {
        "entity_id": "55cff4e3-6a46-4ddb-ae85-a675b32e3b42",
        "name": "Ownership Type",
        "path": "/superadmin/masters/ownership/",
    }, {
        "entity_id": "62eea39e-b473-49d3-b224-386822c0a998",
        "name": "Education Board",
        "path": "/superadmin/masters/educationboard/",
    }, 
    {
        "entity_id": "981413d8-30b2-4a9a-bceb-c95f6f340d5d",
        "name": "Facilities",
        "path": "/superadmin/masters/facilities/",
    }, {
        "entity_id": "9bd953f8-29f3-4506-8006-2fb93b67efc4",
        "name": "Mode of Teaching",
        "path": "/superadmin/masters/modeofteaching/",
    }, {
        "entity_id": "a91569f2-a4d8-4985-a5c0-5c557aa85a4b",
        "name": "Extra Curricular Activities",
        "path": "/superadmin/masters/extracurricular/",
    }, {
        "entity_id": "30f578b7-4cce-4203-95c0-d0b87a6083d6",
        "name": "University",
        "path": "/superadmin/masters/university/",
    },{
        "entity_id": "75bd6c67-9648-4a6c-a404-4b71c511666c",
        "name": "College Type",
        "path": "/superadmin/masters/collegetype/",
    },{
        "entity_id": "ae28fa9c-a38f-4cde-95b4-acea1683ebca",
        "name": "Co-Education",
        "path": "/superadmin/masters/coeducation/",
    }, {
        "entity_id": "c80ab19e-da76-4038-97ab-16537ac8f934",
        "name": "Medium of Instruction",
         "path": "/superadmin/masters/mediumofinstructions/",
    }, {
        "entity_id": "cac26d2f-2de2-4836-ac76-3b25e7070130",
        "name": "Education Level",
         "path": "/superadmin/masters/educationlevel/",
    }, {
        "entity_id": "d1765f9f-a998-4ebf-a3ad-2a5a02b6a4a2",
        "name": "Gender",
        "path": "/superadmin/masters/gender/",
    }, {
        "entity_id": "d1bf3d7f-a829-4b3b-a491-28025c4a44e5",
        "name": "Title",
        "path": "/superadmin/masters/title/",
    }, {
        "entity_id": "d5f6e318-1b79-408d-ac7f-53a33df39e1f",
        "name": "Fee Type",
         "path": "/superadmin/masters/feetype/",
    }, {
        "entity_id": "dac7c643-d5d3-42d0-b828-4b9f551c158f",
        "name": "School Type",
        "path": "/superadmin/masters/schooltype/",
    },{
        "entity_id": "e59e2cdc-6dd4-4508-9104-a91fefac1a9c",
        "name": "Campus Type",
        "path": "/superadmin/masters/campustype/",
    }],
    prod: [ {
        "entity_id": "55cff4e3-6a46-4ddb-ae85-a675b32e3b42",
        "name": "Ownership Type",
        "path": "/superadmin/masters/ownership/",
    }, {
        "entity_id": "62eea39e-b473-49d3-b224-386822c0a998",
        "name": "Education Board",
        "path": "/superadmin/masters/educationboard/",
    }, 
    {
        "entity_id": "981413d8-30b2-4a9a-bceb-c95f6f340d5d",
        "name": "Facilities",
        "path": "/superadmin/masters/facilities/",
    }, {
        "entity_id": "9bd953f8-29f3-4506-8006-2fb93b67efc4",
        "name": "Mode of Teaching",
        "path": "/superadmin/masters/modeofteaching/",
    }, {
        "entity_id": "a91569f2-a4d8-4985-a5c0-5c557aa85a4b",
        "name": "Extra Curricular Activities",
        "path": "/superadmin/masters/extracurricular/",
    }, {
        "entity_id": "30f578b7-4cce-4203-95c0-d0b87a6083d6",
        "name": "University",
        "path": "/superadmin/masters/university/",
    },{
        "entity_id": "75bd6c67-9648-4a6c-a404-4b71c511666c",
        "name": "College Type",
        "path": "/superadmin/masters/collegetype/",
    },{
        "entity_id": "ae28fa9c-a38f-4cde-95b4-acea1683ebca",
        "name": "Co-Education",
        "path": "/superadmin/masters/coeducation/",
    }, {
        "entity_id": "c80ab19e-da76-4038-97ab-16537ac8f934",
        "name": "Medium of Instruction",
         "path": "/superadmin/masters/mediumofinstructions/",
    }, {
        "entity_id": "cac26d2f-2de2-4836-ac76-3b25e7070130",
        "name": "Education Level",
         "path": "/superadmin/masters/educationlevel/",
    }, {
        "entity_id": "d1765f9f-a998-4ebf-a3ad-2a5a02b6a4a2",
        "name": "Gender",
        "path": "/superadmin/masters/gender/",
    }, {
        "entity_id": "d1bf3d7f-a829-4b3b-a491-28025c4a44e5",
        "name": "Title",
        "path": "/superadmin/masters/title/",
    }, {
        "entity_id": "d5f6e318-1b79-408d-ac7f-53a33df39e1f",
        "name": "Fee Type",
         "path": "/superadmin/masters/feetype/",
    }, {
        "entity_id": "dac7c643-d5d3-42d0-b828-4b9f551c158f",
        "name": "School Type",
        "path": "/superadmin/masters/schooltype/",
    },{
        "entity_id": "e59e2cdc-6dd4-4508-9104-a91fefac1a9c",
        "name": "Campus Type",
        "path": "/superadmin/masters/campustype/",
    }],
    uat: [ {
        "entity_id": "55cff4e3-6a46-4ddb-ae85-a675b32e3b42",
        "name": "Ownership Type",
        "path": "/superadmin/masters/ownership/",
    }, {
        "entity_id": "62eea39e-b473-49d3-b224-386822c0a998",
        "name": "Education Board",
        "path": "/superadmin/masters/educationboard/",
    }, 
    {
        "entity_id": "981413d8-30b2-4a9a-bceb-c95f6f340d5d",
        "name": "Facilities",
        "path": "/superadmin/masters/facilities/",
    }, {
        "entity_id": "9bd953f8-29f3-4506-8006-2fb93b67efc4",
        "name": "Mode of Teaching",
        "path": "/superadmin/masters/modeofteaching/",
    }, {
        "entity_id": "a91569f2-a4d8-4985-a5c0-5c557aa85a4b",
        "name": "Extra Curricular Activities",
        "path": "/superadmin/masters/extracurricular/",
    }, {
        "entity_id": "30f578b7-4cce-4203-95c0-d0b87a6083d6",
        "name": "University",
        "path": "/superadmin/masters/university/",
    },{
        "entity_id": "75bd6c67-9648-4a6c-a404-4b71c511666c",
        "name": "College Type",
        "path": "/superadmin/masters/collegetype/",
    },{
        "entity_id": "ae28fa9c-a38f-4cde-95b4-acea1683ebca",
        "name": "Co-Education",
        "path": "/superadmin/masters/coeducation/",
    }, {
        "entity_id": "c80ab19e-da76-4038-97ab-16537ac8f934",
        "name": "Medium of Instruction",
         "path": "/superadmin/masters/mediumofinstructions/",
    }, {
        "entity_id": "cac26d2f-2de2-4836-ac76-3b25e7070130",
        "name": "Education Level",
         "path": "/superadmin/masters/educationlevel/",
    }, {
        "entity_id": "d1765f9f-a998-4ebf-a3ad-2a5a02b6a4a2",
        "name": "Gender",
        "path": "/superadmin/masters/gender/",
    }, {
        "entity_id": "d1bf3d7f-a829-4b3b-a491-28025c4a44e5",
        "name": "Title",
        "path": "/superadmin/masters/title/",
    }, {
        "entity_id": "d5f6e318-1b79-408d-ac7f-53a33df39e1f",
        "name": "Fee Type",
         "path": "/superadmin/masters/feetype/",
    }, {
        "entity_id": "dac7c643-d5d3-42d0-b828-4b9f551c158f",
        "name": "School Type",
        "path": "/superadmin/masters/schooltype/",
    },{
        "entity_id": "e59e2cdc-6dd4-4508-9104-a91fefac1a9c",
        "name": "Campus Type",
        "path": "/superadmin/masters/campustype/",
    }],
}

export default masters;