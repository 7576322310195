const config = {
    env: 'prod',
  // env:'sit'
  // env: 'testbed',
   // env: 'uat',
   // other data here
 }
 
 export default config
 
