import React, { useState, useEffect } from "react";
import review from '../../assets/home/review.png'
import group from '../../assets/home/Group.png'
import arrow from '../../assets/home/arrow.png'
import too from '../../assets/img/tootip.svg'
import { Bar, Chart } from 'react-chartjs-2';
import { Doughnut } from "react-chartjs-2";
import api from '../../api/api'
import Loader from '../../components/loader'
import { errorMsg } from '../../utils'
import { connect } from "react-redux";
import ComingSoon from "./comingSoon";
import VerifiedNow from "./VerfiedNow";
import { ToastContainer, toast } from 'react-toastify';



function Dashboard(props) {
    const [loading, setLoading] = useState(false)
    const [branch, setBranch] = useState([])
    const [rdata, setRdata] = useState({})
    const [month, setMonth] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
    const [value, setValue] = useState([20,40,55,70,40,20,60,80,30,45,90,55])
    const [lead, setlead] = useState(["Open", "Student Admitted", "In-Progress", "Lost"])
    const [valueLead, setValueLead] = useState([40,50,30,60])
    const [verified, setVerifiles] = useState(false)
    const [comingSoon,setComingSoon] = useState(true)

    useEffect(() => {
        getCount();
        if (props.p_type != 3) {
            fetchData();
        }

    }, [])



    const getCount = () => {
        let prof_id = localStorage.getItem("prof_id")
        if (props.p_type == 3 ? prof_id == 'null' : props.prof_id == null) {
            toast.error("Please add Basic details first")
        } else {
        setLoading(true)
        let prof_id = localStorage.getItem("prof_id")
        let request = {
            url: `dashboard/${props.p_type == 3 ? "edu" : "inst"}/${props.p_type == 3 ? prof_id : props.prof_id}/all-details`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)
            // setMonth(Object.keys(data.data.result.perf_monthly))
            // setValue(Object.values(data.data.result.perf_monthly))
            // setlead(Object.keys(data.data.result.leads_status))
            // setValueLead(Object.values(data.data.result.leads_status))
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                // toast.error(errorMsg(err))
            };
        })}
    }

    const fetchData = () => {
        setLoading(true)
        let prof_id = localStorage.getItem("prof_id")
        let request = {
            url: `inst/${props.prof_id}/all-branches`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setBranch(data.data.result)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    const data = {
        labels: lead,
        datasets: [
            {
                data: valueLead,
                backgroundColor: ["#B4D7FF", "#59A7FF", "#2E74C4", "#2A5F9B"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            }
        ]
    };

    const graph_data1 =
    {
        labels: month,
        datasets: [{
            label: 'Value',
            data: value,
            backgroundColor: [
                '#59A7FF',
            ],
            borderColor: "0",
        }],
    }
    return (
        <>
            {loading && <Loader />}
            <div className="Dis-dashHeader">
                {
                    props.p_type == 3 ?
                        <div style={{ marginLeft: "30px", width: "40%" }}>
                            <lable className="dis-branchname">{rdata?.edu_nm ?? "Educator Name"}</lable>
                        </div> :
                        <div style={{ marginLeft: "30px", width: "40%" }}>
                            <select className="dis-branchname" value={props.prof_id} onChange={(e) => props.dispatch({ type: 'BASIC_DETAILS', prof_id: e.target.value })}>
                                {branch.map((item) => <option value={item.br_id}>{item.br_nm}</option>)}
                            </select>
                            {/* <lable className="dis-branchname">{rdata?.i_p_nm ?? "Branch Name"}</lable> */}
                        </div>
                }

                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="dis-profilepercent">{rdata?.p_c_score ?? "0"}%</div>
                    <div className="dis-profile">Profile Completed</div>
                </div> */}
                {
                    (rdata.kyc_stat == 'PENDING' || rdata.kyc_stat == 'Pending') &&
                    <div style={{ display: "flex", flexDirection: "row", marginRight: "20px" }}>
                        <div className="dis-profile">Verification Inprocess</div>
                        <button className="dis-headerbutton" 
                         onClick={() => toast.error("KYC verification Inprocess, we will contact you in 48 hrs.")}>Pending</button>
                    </div>
                }

                {
                    (rdata.kyc_stat == 'OPEN' || rdata.kyc_stat == 'Open') &&
                    <div style={{ display: "flex", flexDirection: "row", marginRight: "20px" }}>
                        <div className="dis-profile">Your Profile is Not Verified</div>
                        <button className="dis-headerbutton" onClick={() => setVerifiles(true)}> Get verified Now</button>
                    </div>
                }

                {
                    (rdata.kyc_stat == 'VERIFIED' || rdata.kyc_stat == 'Verified') &&
                    <div style={{ display: "flex", flexDirection: "row", marginRight: "20px" }}>
                        {/* <div className="dis-profile">Your Profile is Not Verified</div> */}
                        <button className="dis-headerbutton" disabled onClick={() => setVerifiles(true)}>Profile verified</button>
                    </div>
                }

                {
                    (rdata.kyc_stat == 'NOTVERIFIED' || rdata.kyc_stat == 'NotVerified') &&
                    <div style={{ display: "flex", flexDirection: "row", marginRight: "20px" }}>
                        {/* <div className="dis-profile">Your Profile is Not Verified</div> */}
                        <button className="dis-headerbutton" disabled onClick={() => setVerifiles(true)}>Not verified</button>
                    </div>
                }

            </div>
            <div>
                <div className="Sa-dasboarddiv">
                    <div className='Sa-DashboardBlock'>
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Your Profile Completion Score</label>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.p_c_score ?? "0"}%</label>
                        </div>
                    </div>
                    <div className='Sa-DashboardBlock' title="To be launched Soon!!" style={{background:'rgba(0,0,0,0.08)'}}>
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Leads Generated</label>
                            <div class="tooltip1 mr16" style={{ marginLeft: "5px" }}> <button class="filter "><img src={too} /></button>
                                <span class="tooltiptext1">Please Download and Login to Proctur Mobile App to view Lead
                                    Details (app store link)</span>
                            </div>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.leads_gen ?? "0"}</label>
                        </div>
                    </div>
                    <div className='Sa-DashboardBlock' title="To be launched Soon!!" style={{background:'rgba(0,0,0,0.08)'}}>
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Reviews Received</label>
                            <div class="tooltip1 mr16" style={{ marginLeft: "5px" }}> <button class="filter "><img src={too} title='To be launched soon' /></button>
                            </div>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.rev_rec ?? "0"}</label>
                        </div>
                    </div>
                </div>
                 <div className="Sa-dasboarddiv">
                    <div className='Sa-DashboardBlock'title="To be launched Soon!!" style={{background:'rgba(0,0,0,0.08)'}}>
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Profile Visits</label>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.p_visits ?? "0"}</label>
                        </div>
                    </div>
                    <div className='Sa-DashboardBlock'title="To be launched Soon!!" style={{background:'rgba(0,0,0,0.08)'}} >
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Profile Link Requests Sent</label>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.l_r_rec ?? "0"}</label>
                        </div>
                    </div>
                    <div className='Sa-DashboardBlock'title="To be launched Soon!!" style={{background:'rgba(0,0,0,0.08)'}}>
                        <div className="SA-dashdiv">
                            <img src={review} />
                            <label className="SA-DashboardLable">Profile Link Requests Accepted</label>
                        </div>
                        <div className="SA-Percent">
                            <label>{rdata?.l_r_acc ?? "0"}</label>
                        </div>
                    </div>
                </div>
                <div style={{height:"345px",overflow:"overlay"}}>
                 <div className="SA-statDiv" title="To be launched Soon!!">
                        <div className="SA-dashGraph">
                            <div className="SA-profilelable1">
                                <div>
                                    <div > Check Out Your Profile Performance</div>
                                  
                                </div>
                                <div >
                                    {/* <input type='month' className="dis-input"  style={{background:' #00000050'}}/> */}
                                </div>
                            </div>
                            <Bar data={graph_data1} height="90%" />
                        </div>
                        <div className="SA-partition">

                        </div>
                        <div className="SA-ProfileViews">
                            <div className="SA-profilelable">
                                Want to generate more Profile Views
                                and get more leads ?
                            </div>
                            <center><img src={group} style={{ marginTop: "5px" }} /></center>
                            <center><button className="SA-dashboardButton" onClick={() => window.alert("This will be launched soon!!")}>  Get In Touch with Our Expert</button></center>
                        </div>
                    </div> 

                     <div className="Sa-leadsDiv" title="To be launched Soon!!">
                        <div>
                            <div className="SA-dashGraph1">
                                <div className="SA-profilelable1">
                                    <div>
                                        <div > Check Out Your Profile Performance</div>
                                    </div>
                                    <div>
                                        {/* <input type='month' className="dis-input" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="SA-ProfileViews1" style={{ width: '25%', marginBottom: "10px" }}>
                                <Doughnut data={data} options={{ cutout: '60%', borderWidth: 0 }} />
                            </div>
                            <div style={{ width: "65%", marginTop: "30px", marginLeft: "60px" }}>
                                <center> <div className="Sa-totalLeads">
                                    <center><div className="SA-DashboardLable1">Total Leads Received</div></center>
                                    <center><div className="SA-Percent1">56
                                        {/* {rdata.leads_status?.total_leads_recieved ?? "0"} */}
                                        </div></center>
                                </div></center>

                                <div className="SA-LeadssDiv">
                                    <div className="Sa-totalLeads1">
                                        <center><div className="SA-DashboardLable1">Open</div></center>
                                        <center><div className="SA-Percent1">33
                                            {/* {rdata.leads_status?.open ?? "0"} */}
                                            </div></center>
                                    </div>
                                    <div className="Sa-totalLeads1">
                                        <center><div className="SA-DashboardLable1">Student Admitted</div></center>
                                        <center><div className="SA-Percent1">45
                                            {/* {rdata.leads_status?.admitted ?? "0"} */}
                                            </div></center>
                                    </div>
                                    <div className="Sa-totalLeads1">
                                        <center><div className="SA-DashboardLable1">In-Progress</div></center>
                                        <center><div className="SA-Percent1">44
                                            {/* {rdata.leads_status?.in_progress ?? "0"} */}
                                            </div></center>
                                    </div>
                                    <div className="Sa-totalLeads1">
                                        <center><div className="SA-DashboardLable1">Lost</div></center>
                                        <center><div className="SA-Percent1">13
                                            {/* {rdata.leads_status?.lost ?? "0"} */}
                                        </div></center>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div> 


                </div>

            </div>
            {/* { comingSoon==true &&
             <ComingSoon/>
            } */}
            {
                verified == true &&
                <VerifiedNow setVerifiles={setVerifiles} getCount={getCount} />
            }
            <ToastContainer/>
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(Dashboard)
