import React from "react";
import ReportPopup from "./reportPopup";


export default function Review(props) {


    return (
        <>

            <div class="box-section1 review">
                <div>
                    <input type="text" placeholder="Search" class="search" onChange={(e)=>props.setOperate({...props.operate,search_text:e.target.value})}/>
                </div>
                <div class="flex mob-v">
                    <input type="date" class="filter-box"   onChange={(e)=>props.setOperate({...props.operate,f_date:e.target.value})}/>
                    <input type="date" class="filter-box" onChange={(e)=>props.setOperate({...props.operate,t_date:e.target.value})}/>
                    <div class="select-wrapper filter-box">
                        <select class="expandale-input"  onChange={(e)=>props.setOperate({...props.operate,ratings:e.target.value})}>
                            <option hidden selected>Ratings Given</option>
                            <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                            <option value="4">&#9733; &#9733; &#9733; &#9733;</option>
                            <option value="3">&#9733; &#9733; &#9733;</option>
                            <option value="2">&#9733; &#9733;</option>
                            <option value="1">&#9733;</option>
                        </select>
                    </div>
                </div>
                <div className="sa-SeachButton">
                    <button className="Super-admin-save-button" onClick={props.Apply}>Go</button>
                </div>
            </div>
            <div class="grey-head1">
                <div>SNo.</div>
                <div> Review ID</div>
                <div>Reviewer Name</div>
                <div>Date/Time</div>
                <div>
                    <p>Over All Rating</p>
                    <p>(Out Of 5)</p>
                </div>
                <div>Actions</div>
            </div>
            <div class="table-v">
                {props.rdata.map((item, key) => {
                    return (
                        <div key={key} class="white-body1">
                            <div><label>SNo.</label>{key + 1}</div>
                            <div><label>Review ID</label>{item.display_id}</div>
                            <div><label>Reviewer Name</label>{item.stud_nm}</div>
                            <div><label>Date/Time</label>{item.u_date}</div>
                            <div><label>
                                <span>Over All Rating</span><br />
                                <span>(Out Of 5)</span>
                            </label>{item.ratings}</div>
                            <div> <label>Actions</label>
                                <button class="viewbutton" onClick={() => {
                                    props.getReviewbyId(item.id)
                                }}>View</button>
                                <button class="ml16 rejectbutton" onClick={() => { props.setpopup(true); props.setselected(item.id) }}>Report</button>
                            </div>
                        </div>
                    )
                })}
            </div>




        </>
    )
}