import React, { useState, useEffect } from 'react'
import LeadNavBar from '../leads/navbar'
import LeadSchool from "../leads/school/index";
import LeadCoaching from '../leads/coaching/index'
import LeadEducator from '../leads/Educator/index'
import api from '../../api/api'
import { errorMsg } from '../../utils';
import Operate from './operate';
import { ToastContainer, toast } from 'react-toastify';



function Leads(props) {

    const [selectedTab, setSelectedTab] = useState("School")
    const [fetchedData, setFetchedData] = useState([])
    const [pageno, setPageno] = useState(1)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)
    const [school, setSchool] = useState()
    const [lead, setLead] = useState()

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {

        setLoading(true)
        let request = {
            url: `/todos/1`,
            headers: {
                'Authorization': ""
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata([{ id: 1, title: "Competition", Leads: "25" }, { id: 2, title: "Government", Leads: "25" }])
            let id = rdata.id
            setSchool({ school: rdata.title, id })
            searchdata(id)
        }).catch((err) => {
            setLoading(false)
              if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })
    }

    const searchdata = (id) => {
        setLoading(true)
        let request = {
            url: `/todos/1`,
            headers: {
                'Authorization': ""
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            let lead = ["0 to 25", "26 to 99", "100 to 499", "500 Above"]


        }).catch((err) => {
            setLoading(false)
              if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
        })

    }

    return (
        <>

            <div className="sa-leadsss">

                <LeadNavBar
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
                <Operate school={school} setSchool={setSchool} lead={lead} setLead={setLead} setLoading={setLoading} id={rdata.id} value={search} onChange={setSearch} data={rdata.find(data => data.id == selected)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                />


                {selectedTab == "School" &&
                    <LeadSchool rdata={rdata} school={school} lead={lead} />
                }

                {selectedTab == "Coaching" &&
                    <LeadCoaching rdata={rdata}  school={school} lead={lead} />
                }

                {selectedTab == "Educator" &&
                    <LeadEducator rdata={rdata}  school={school} lead={lead} />
                }
               

            </div>
        </>
    )
}


export default Leads

