import {useState, useEffect} from 'react'
import Header from "./components/header/header.js";
import SideBar from "./components/sidebar/sidebar.js"
import MasterSidebar from "./components/sidebar/masterSidebar.js"
import './css/vAdmin/admin.css'
import './css/loader.css'
import './css/superAdmin/SuperAdmincss.css'
import Routes from './routes';
import Login from '../src/modules/login/login'
import LoginContainer from './modules/login/loginContainer.js';
import Index from './modules/login/signin/index.js';
import ProfileSidebar from './components/sidebar/profileMsidebar.js';
import { connect } from 'react-redux';
import { checkUserType } from './utils';



function App(props) {
  const type = checkUserType(props.auth)

  useEffect(() => {
    if(props.auth.userid == 0 && window.location.pathname != '/'){
      if(!!localStorage.getItem("superadmin")){
        localStorage.removeItem("superadmin")
        window.location = '/?type=superadmin'
      } else {
        window.location = '/'
      }
      
    }
  }, [props.auth.userid])


  
  return (
    <>

      {/* For super admin */}
      {
        props.auth.userid == 0 ? <LoginContainer /> :
      <>
        <Header dispatch={props.dispatch} />
        <div class="paneldd"></div>
        <div class="page-wrapper">
          <SideBar />
          <div class="page-content" >
            <div className="Sa-page">
              {
                window.location.pathname.indexOf('/superadmin/masters/') != -1 && <MasterSidebar />
              }
              {
                window.location.pathname.indexOf('/profilemanagement/') != -1 && <ProfileSidebar />
              }
              <Routes />
            </div>

          </div>
        
        </div>
      </>
      }

      

    </>
  );
}

const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(App)
