import React, { useState, useEffect } from 'react';
import eye from '../../assets/img/header/eye.svg'
import Moment from 'moment';
import searchh from '../../assets/home/search_icon.png'





export default function ClientReport(props) {
    const [FromDate, setFromDate] = useState("2022-07-01")
    const [ToDate, setToDate] = useState("2022-12-31")
    const [rdata, setRdata] = useState([])
    const [selectedTab, setSelectedTab] = useState("School")



    return (
        <>
            <div className="entire_reports">
                <div className="sa_reports_inner">
                    <div className="reports_heading_and_cards">
                        <div className="sa_reports_heading2">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <font
                                    onClick={() => { props.setShow("reports_initial_page") }}
                                    style={{
                                        cursor: "pointer",
                                        // border:"1px solid gray",
                                        borderRadius: "4px",
                                        color: "#0097F9",
                                        background: "rgb(236, 230, 230);",
                                    }}
                                >
                                    {" Reports > "}</font><div style={{ cursor: "pointer" }} onClick={() => { props.setInitialList("Initial") }}> User Log Reports</div >{">"} {props.initialList}</div>


                        </div>

                        <div className="sa-LeadsNav" style={{ marginLeft: '0px', marginBottom: '10px' }}>
                            <div className="sa-leadsHead2" style={{ marginTop: "10px", width: '25%' }}>

                                <span className={selectedTab == "School" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("School") }}>School</span>
                                <span className={selectedTab == "Coaching" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("Coaching") }}>Coaching</span>
                                <span className={selectedTab == "Educator" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { setSelectedTab("Educator") }}>Educator</span>

                            </div>
                        </div>


                        <div style={{ height: "50px", display: "flex", flexDirection: "row", justifyContent: "space-between", background: "#FFFFFF" }}>
                            <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ padding: "0", marginTop: "10px",marginLeft:'20px' }}>
                                <div className="sa-searchhh">
                                    <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                                    <input className="Super-admin-Search_Input" type="text"
                                        placeholder="Search by Name" style={{ outline: "0" }}  />
                                </div>
                            </div></div>
                                <div className="Sa-dropdown3">
                                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>From date:</div>
                                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" />
                                </div>
                                <div className="Sa-dropdown3">
                                    <div style={{ marginTop: "7px", fontSize: "12px", marginRight: "10px" }}>To date:</div>
                                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" style={{ fontSize: "12px" }} />
                                </div>


                                <div className="sa-SeachButton">
                                    <button className="Super-admin-save-button" >Apply</button>
                                    <button className="Super-admin-save-button"  >Reset</button>
                                </div>


                            </div>


                            <div>
                                <table id="super-admin">
                                    <tr>
                                        <th>Id</th>
                                        <th>User name</th>
                                        <th>Date/Time</th>
                                        <th>Module</th>
                                        <th>Sub Module</th>
                                        <th>Data Change</th>
                                        <th>Action</th>
                                    </tr>



                                    {
                                        props.clientData.map((item, key) => {
                                            return (<tr key={key} > <td>{item.user_id}</td>
                                                <td>{item.user_name}</td>
                                                <td>{Moment(item.act_dt).format('DD-MM-YYYY')}/{Moment(item.act_dt).format('HH:mm')}</td>
                                                <td>{item.module}</td>
                                                <td>{item.sub_module}</td>
                                                <td>
                                                    <img src={eye} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
                                                </td>
                                                <td>{item.action}</td>
                                            </tr>

                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )
}