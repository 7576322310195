import React, { useState, useEffect } from "react";
import unlink from '../../../assets/img/unlink.svg';
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import Loader from '../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';

function SettingMain(props) {
    const [tab, setTab] = useState("Notifications")
    const [rdata, setRdata] = useState([])
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState()
    const [letter, setLetter] = useState()
    const [setting, setSetting] = useState('')
    const [active, setActive] = useState()

    console.log(tab)

    useEffect(() => {
        getSettings();
    }, [])

    const getSettings = () => {
        let prof_id = localStorage.getItem("prof_id")
        if (props.p_type == 3 ? prof_id == 'null' : props.prof_id == null) {
            toast.error("Please add Basic details first")
        } else {
            setLoading(true)
            let request = {
                url: `setting/${props.p_type == 3 ? "Educator" : "Institute"}/${props.p_type == 3 ? prof_id : props.prof_id}`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
            }
            api.getCustom(request).then(data => {
                console.log(data.data.result, "result")
                setLoading(false)
                setLetter(data.data.result.enb_proc_news_letters)
                setNotification(data.data.result.enb_notification)
                setSetting(data.data.result.id)
                setActive(data.data.result.is_profile_active)
                setRdata(data.data.result)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    // props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }

    }

    const updateSettings = () => {
        let prof_id = localStorage.getItem("prof_id")
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "institute"}/setting/${setting}/update`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
            data: {
                // i_p_id: "d94de3e6-d5f7-4515-80fe-58c4a59331f3",
                enb_notification: notification,
                enb_proc_news_letters: letter
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Settings Updated sucessfully")
            getSettings()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const ShowonProfile = () => {
        let prof_id = localStorage.getItem("prof_id")
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "inst"}/${props.p_type == 3 ? prof_id : props.prof_id}/set-active/${!active}?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success(data.data.result)
            getSettings()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    const deleteInst = () => {
        let prof_id = localStorage.getItem("prof_id")
        setLoading(true)
        let request = {
            url: `${props.p_type == 3 ? "educator" : "inst"}/${props.p_type == 3 ? prof_id : props.prof_id}/delete?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.delete(request).then(data => {
            setLoading(false)
            toast.success("Deleted successfully")
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    return (
        <>
            {loading && <Loader />}
            <div class="box-educator1 mob-v">
                <div class="scroll-solve">
                    <div class="tab">
                        {/* <button class="tags-e" onClick={() => setTab("Change Password")}>Change Password</button> */}
                        <button class={tab == "Notifications" ? "tags-e selected-tag" : "tags-e"} onClick={() => setTab("Notifications")}>Notifications</button>
                        <button class={tab == "Delete" ? "tags-e selected-tag" : "tags-e"} onClick={() => setTab("Delete")}>Delete Your Account</button>
                        <button class={tab == "Logout" ? "tags-e selected-tag" : "tags-e"} onClick={() => setTab("Logout")}>Logout Your Account</button>
                    </div>
                </div>
            </div>

            {/* {
                tab == "Change Password" &&

                <div >
                    <div class="form-section">
                        <div>
                            <label>Current Password <span>*</span></label>
                            <p contenteditable="true" class="expandale-input w-450" placeholder="Enter"></p>
                        </div>
                        <div>


                            <label>New Password <span>*</span></label>
                            <p contenteditable="true" class="expandale-input w-450" placeholder="Enter"></p>
                        </div>
                        <div>


                            <label>Confirm New Password <span>*</span></label>
                            <p contenteditable="true" class="expandale-input w-450" placeholder="Enter"></p>
                        </div>
                        <div class="button-section">
                            <div></div>
                            <div>
                                <button class="white_button mr16">Cancel</button>
                                <button class="blue_button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            } */}

            {
                tab == "Notifications" &&
                <div >
                    <div class="form-section">
                        <div>
                            <label>Notifications</label>
                            <div class="display flex-a">
                                <p class="notif-info">All notifications you receive in the Web Panel for various activities
                                    and updates</p>

                                <label class="switch1">
                                    <input type="checkbox" checked={notification}
                                        onClick={() => setNotification(!notification)}
                                    />
                                    <span class="slider1 round"></span>
                                </label>

                            </div>
                        </div>
                        <div>
                            <label>Promotions and Newsletters from Proctur</label>
                            <div class="display flex-a">
                                <p class="notif-info">All Emails you receive for New Offers and Updates from Our side</p>

                                <label class="switch1">
                                    <input type="checkbox" checked={letter}
                                        onClick={() => setLetter(!letter)}
                                    />
                                    <span class="slider1 round"></span>
                                </label>

                            </div>
                        </div>
                        <div class="button-section">
                            <div></div>
                            <div>
                                {/* <button class="white_button mr16" onClick={()=>{setLetter(letter);setNotification(notification)}}>Cancel</button> */}
                                <button class="blue_button" onClick={updateSettings}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            }

            {
                tab == "Delete" &&
                <div >
                    <div class="form-section">
                        <div>
                            <embed src={unlink} class="unlink-svg" />
                            <label>Notifications</label>
                            <p class="notif-info">1. Deactivate your Account if you wish to hide your profile. You can
                                reactivate your profile when needed</p>
                            <p class="notif-info"> 2. After deleting your account all your data along with branches will be
                                deleted forever</p>
                            <p class="notif-info">3. Only Admin can delete the account</p>
                            <div class="flex mt-16">
                                <button class="deactivatebutton" onClick={ShowonProfile}> {active != true ? 'Activate Account' : 'Deactivate Account'}</button>
                                <button class="deletebutton2" data-target="#deleteModal" onClick={deleteInst}
                                    data-toggle="modal">Delete&nbsp;My&nbsp;Account </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                tab == "Logout" &&
                <div >
                    <div class="form-section">
                        <div>
                            <embed src={unlink} class="unlink-svg" />
                            <label>Logout</label>
                            <p class="notif-info">Please Click on logout button to logout.</p>
                            {/* <p class="notif-info"> 2. After deleting your account all your data along with branches will be
                                deleted forever</p>
                            <p class="notif-info">3. Only Admin can delete the account</p> */}
                            <div class="flex mt-16">
                                <button class="deletebutton2" data-target="#deleteModal" onClick={() => { props.dispatch({ type: 'LOGOUT' });localStorage.clear() }}
                                    data-toggle="modal">Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <ToastContainer />

        </>
    )

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(SettingMain)