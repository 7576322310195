import React, { useState, useEffect } from "react";
import Delete from '../../../../../components/delete'
import Loader from '../../../../../components/loader'
import api from '../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../utils";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';


function AcheivementsBlock(props) {
  const [deletepopup, setdelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setid] = useState("")



  const deleteAcheivemnet = () => {
    setdelete(false)
    props.setLoading(true)
    let request = {
      url: `achv/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      props.setLoading(false)
      toast.success("Achievement Deleted successfully")
      props.setRdata(props.rdata.filter((item3) => item3.id != id))
      setid("")
    }).catch((err) => {
      setid("")
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }
  return (
    <>
      {loading && <Loader />}
      <div>
        <div class="box-educator">
          {props.rdata.map((item, key) => {
            return (
              <div key={key}>
                <div class="display mb-8 mr-70">
                  <div class="name-div">
                    <p>{item.name} </p>
                    <span>{Moment(item.year).format('DD-MM-YYYY')}</span>
                  </div>
                </div>
                <div class="line-v-grey">
                </div>
                <div class="flex">

                  <div class="ml-a">
                    <button class="editbutton" onClick={() => { props.setselected(item.id); props.setMdata(item); props.setForm(true) }}> Edit</button>
                    <button class="viewbutton ml16" onClick={() => window.alert("Will be launching soon!!")} >View</button>
                    <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                  </div>
                </div>
              </div>
            )
          })
          }

        </div>
      </div>

      {deletepopup == true &&
        <Delete delete={deleteAcheivemnet} setdelete={setdelete} />
      }
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(AcheivementsBlock)