import React, { useState, useEffect } from 'react';
import Loader from '../../components/loader'
import api from '../../api/api'
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import { errorMsg } from "../../utils";
import { connect } from 'react-redux';
import eye from '../../assets/img/header/eye.svg'
import cross from '../../assets/home/cross.png'
import '../../css/superAdmin/sa_reports.css'
import filter_icon from '../../assets/home/filter_icon.png'
import ClientReport from './client_report';

function UserReport(props) {
    const [clientData,setClientData] = useState([])
    const [rdata, setRdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const [getdata, setGetData] = useState()
    const [FromDate, setFromDate] = useState("2022-07-01")
    const [ToDate, setToDate] = useState("2022-12-31")
    const [allData, setallData] = useState([])
    const [popup, setPopup] = useState(false)
    const [initialList, setInitialList] = useState("Initial")




    useEffect(() => {
        fetchDataStudde();
        getUser()
    }, [FromDate, ToDate])



    useEffect(() => {
        fetchDataClient();
    }, [FromDate, ToDate])


    const getUser = () => {
        setLoading(true)
        let request = {
            url: `user/all`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const fetchDataStudde = (value) => {
        setLoading(true)
        let request = {
            url: `activity/all?uid=${value==undefined?"":value}&f_date=${FromDate}&t_date=${ToDate}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setallData(data.data.result)
        }).catch((err) => {
            console.log(err, "errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const fetchDataClient = (value) => {
        setLoading(true)
        let request = {
            url: `activity/all?is_admin=true`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setClientData(data.data.result)
        }).catch((err) => {
            console.log(err, "errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const getData = (id) => {
        setLoading(true)
        let request = {
            url: `activity/${id}/get-data`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setGetData(data.data.result)
            setPopup(true)
        }).catch((err) => {
            console.log(err, "errr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    return (
        <>
            {loading && <Loader />}



            {
                initialList == "Initial" &&
                <div style={{ marginLeft: '30px' }} ><div style={{ display: "flex", flexDirection: "row" }}>
                    <font onClick={() => { props.setShow("reports_initial_page"); }}
                        style={{
                            cursor: "pointer",
                            // border:"1px solid gray",
                            borderRadius: "4px",
                            color: "#0097F9",
                            background: "rgb(236, 230, 230);",
                        }}
                    >
                        {" Reports > "}</font > <div style={{ cursor: "pointer" }} onClick={() => { setInitialList("Initial") }}>User Log Reports</div> </div>
                    <div className="sa_reports_cards" style={{ marginTop: '30px' }}>
                        <div className="sa_reports_cards_inner" style={{ justifyContent: 'unset' }}>

                            <div className="reports_cards" style={{ marginLeft: '120px' }} onClick={() => setInitialList("Studde Report")}>
                                <center>
                                    Studde Report
                                    <p>
                                        {'-'}
                                        {/* {this.state.rprt_data1[0]} */}
                                    </p>
                                </center>
                            </div>

                            <div className="reports_cards" style={{ marginLeft: '80px' }} onClick={() => setInitialList("Client Report")}>
                                <center>
                                    Client Report
                                    <p>
                                        {'-'}
                                        {/* {this.state.rprt_data1[2]} */}
                                    </p>
                                </center>
                            </div>
                        </div>
                    </div></div>
            }


            {initialList == "Studde Report" &&
                <div className="entire_reports">
                    <div className="sa_reports_inner">
                        <div className="reports_heading_and_cards">
                            <div className="sa_reports_heading2">
                                <div  style={{ display: "flex", flexDirection: "row" }}>
                                    <font onClick={() => { props.setShow("reports_initial_page") }}
                                        style={{
                                            cursor: "pointer",
                                            // border:"1px solid gray",
                                            borderRadius: "4px",
                                            color: "#0097F9",
                                            background: "rgb(236, 230, 230);",
                                        }}
                                    >
                                        {" Reports > "}</font><div style={{ cursor: "pointer" }} onClick={() => { setInitialList("Initial") }}> User Log Reports </div> {">"} {initialList}</div>

                                <div className="reports_heading_filters" >
                                    <img src={filter_icon} height="22px" />
                                    <div className="reports_from_date_filter_area">

                                        {/* <div className="reports_absolute_inputs">
                            <input  type="date"/>
                        </div> */}

                                        <div className="reports_non_absolute_inputs">
                                            {<input placeholder="From Date" id="input1"
                                                style={{ cursor: "pointer", outline: "0" }}
                                                type='date' value={FromDate}
                                                onChange={(e) => { setFromDate(e.target.value) }}
                                            />
                                            }
                                        </div>

                                    </div>
                                    {/* { this.document.getElementById("input1").click()} */}

                                    <div className="reports_from_date_filter_area">
                                        {/* <div className="reports_absolute_inputs">
                            <input  type="date"/>
                        </div> */}
                                        <div className="reports_non_absolute_inputs">
                                            {
                                                <input placeholder="To Date" id="input1"
                                                    style={{ cursor: "pointer", outline: "0" }}
                                                    type='date' value={ToDate}
                                                    onChange={(e) => { setToDate(e.target.value) }}
                                                />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div style={{ height: "50px", display: "flex", flexDirection: "row", justifyContent: "space-between", background: "#FFFFFF" }}>
                                <div style={{ marginLeft: "20px" }}>
                                    <div className="Sa-dropdown">
                                        <section className="sa-wrapper2">
                                            <select name="Master Category" id="Master Category" className="Sa-input" style={{ fontSize: "13px" }} onChange={(e)=>fetchDataStudde(e.target.value)}>
                                                <option value="">Select User</option>
                                                {rdata.map((item) => { return (<option value={item.id}>{item.name}</option>) })}
                                            </select>
                                        </section>

                                    </div>
                                </div>

                            </div>


                            <div>
                                <table id="super-admin">
                                    <tr>
                                        <th>Date / Time </th>
                                        <th>User name </th>
                                        <th>Module name </th>
                                        <th>Sub-module name </th>
                                        <th>Data Change </th>
                                        <th>Action</th>
                                    </tr>

                                    {
                                        allData.map((item, key) => {
                                            return (
                                                <tr key={key} >
                                                    <td>{Moment(item.act_dt).format('DD-MM-YYYY')}/{Moment(item.act_dt).format('HH:mm')}</td>
                                                    <td>{item.user_name}</td>
                                                    <td>{item.module}</td>
                                                    <td>{item.sub_module}</td>
                                                    <td>
                                                        <img src={eye} onClick={() => getData(item.act_id)} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
                                                    </td>
                                                    <td>{item.action}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {initialList == "Client Report" &&
                <ClientReport initialList={initialList} setShow={props.setShow} setInitialList={setInitialList} clientData={clientData}/>
            }


            {
                popup == true &&
                <div className="sa-popupEnquiry">
                    <div style={{ width: '50%', background: 'white', alignItems: 'stretch' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                            <div>View Details</div>
                            <img src={cross} style={{ width: '30px', height: '30px', marginTop: '5px', cursor: 'pointer' }} onClick={() => setPopup(false)} />
                        </div>
                        <div style={{ padding: '20px' }}>{getdata.current_data}</div>
                    </div>


                </div>
            }


        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(UserReport)

