const URL = {
    sit: {
        BASE_URL: 'https://discov-dev-api.proctur.com/proc-dsc/api/v1/',
        BASE: 'https://api.postalpincode.in'
    },
    testbed: {
        BASE_URL: 'https://discov-dev-api.proctur.com/proc-dsc/api/v1/',
        BASE: 'https://api.postalpincode.in'
    },
    prod: {
        BASE_URL: 'https://discprodapi.proctur.com/proc-dsc/api/v1/',
        BASE: 'https://api.postalpincode.in'
    },
    uat: {
        BASE_URL: 'https://discov-dev-api.proctur.com/proc-dsc/api/v1/',
        BASE: 'https://api.postalpincode.in'
    },
}

export default URL;