import React, { useState, useEffect } from 'react'
import searchh from '../../assets/home/search_icon.png'
import api from '../../api/api'
import { errorMsg } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';


function Operate(props) {
    const [search, setSearch] = useState("");
    const [change, setChange] = useState("")
    const [echange, seteChange] = useState("")
    const [date,setDate]=useState("")
    const [edate,seteDate]=useState("")
    const [enq,setEnq]=useState("")



//     const searchButton = () => {

//         props.setLoading(true)
//         let request = {
//             url: `/todos/1`,
//             headers: {
//                 'Authorization': ""
//             },
//             data: {
               
//                 enq:enq,
//                 date:date,
//                 edate:edate

//             }
//         }

//         api.postCustom(request).then(data => {

//             props.setLoading(false)
//             seteChange("");
//             setChange("");
//             setEnq("")
//             props.fetchData();


//         }).catch((err) => {
//             props.setLoading(false)
//               if(err.response.status == 401){
//         props.dispatch({ type: 'LOGOUT' })
//       }else{
//         toast.error(errorMsg(err))
//       } 
//         })

//      }

//   console.log(enq)
  

    return (
        <>
            <div className="sa-leadsSchool2">

                <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh">
                <div className="sa-searchhh">
                <img  src={searchh} style={{height:"18px",width:"18px",marginLeft:"8px",marginRight:"5px"}}/>
                <input className="Super-admin-Search_Input" type="text"
                onChange={(e)=>props.setOperate({...props.operate,search_text:e.target.value})} value={props.operate.search_text}
                placeholder="Search"  style={{outline:"0"}} />
                </div>
                </div>
                </div>
                {/* <div className="Sa-dropdown2">
                    <select name="Master Category" id="Master Category" className="Sa-input2" onChange={(e) => setChange(e.target.value)} style={{ fontSize: "13px" }} >
                        <option value="">Select Subject</option>
                        <option value="Assignment">Assignment</option>
                    </select>
                </div> */}
                <div className="Sa-dropdown3">
                <div style={{marginTop:"7px",fontSize:"12px",marginRight:"10px"}}>From date:</div>
                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" 
                    onChange={(e)=>props.setOperate({...props.operate,f_date:e.target.value})} value={props.operate.f_date}
                    style={{ fontSize: "12px" }} />
                </div>
                <div className="Sa-dropdown3">
                <div style={{marginTop:"7px",fontSize:"12px",marginRight:"10px"}}>To date:</div>
                    <input type="date" id="Start date" className="Sa-input3" name="Start Date" 
                    onChange={(e)=>props.setOperate({...props.operate,t_date:e.target.value})} value={props.operate.t_date}
                    style={{ fontSize: "12px" }} />
                </div>
                 
                   
                <div className="Sa-dropdown2">
                <section className="sa-wrapper">
                    <select name="Master Category" id="Master Category" className="Sa-input2" onChange={(e)=>props.setOperate({...props.operate,ratings:e.target.value})} value={props.operate.ratings} style={{ fontSize: "13px" }} >
                        <option value="">Rating Given</option>
                        <option hidden selected>Ratings Given</option>
                            <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                            <option value="4">&#9733; &#9733; &#9733; &#9733;</option>
                            <option value="3">&#9733; &#9733; &#9733;</option>
                            <option value="2">&#9733; &#9733;</option>
                            <option value="1">&#9733;</option>
                    </select>
                    </section >
                </div>


                <div className="Sa-dropdown2">
                <section className="sa-wrapper">
                    <select name="Master Category" id="Master Category" className="Sa-input2"  
                    onChange={(e)=>props.setOperate({...props.operate,reported:e.target.value})} value={props.operate.reported}
                    style={{ fontSize: "13px" }} >
                        <option value="">Reported</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    </section >
                </div>

                <div className="sa-SeachButton">
                    <button className="Super-admin-save-button" onClick={props.Apply} >Apply</button>
                </div>


            </div>
            <ToastContainer />
        </>
    )
}
export default Operate