const ProfileMang = {
    sit: [
        {
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Profile Type",
            "path": "/profilemanagement/profile/",
        }, {
            "entity_id": "3b6df447-d9af-4958-bc0a-224273cae73f",
            "name": "Sub Profile Type",
            "path": "/profilemanagement/subprofile/",

        }, {
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Master Category",
            "path": '/profilemanagement/mastercategory/',
        },{
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Department",
            "path": '/profilemanagement/department/',
        },{
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Stream",
            "path": '/profilemanagement/stream/',
        },
         {
            "entity_id": "3b6df447-d9af-4958-bc0a-224273cae73f",
            "name": "Category/Course",
            "path": "/profilemanagement/category/",

        }, {
            "entity_id": "55cff4e3-6a46-4ddb-ae85-a675b32e3b42",
            "name": "Standard",
            "path": "/profilemanagement/standard/",
        }, {
            "entity_id": "62eea39e-b473-49d3-b224-386822c0a998",
            "name": 'Subjects',
            "path": '/profilemanagement/subject/',
        },],
    prod: [
        {
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Profile Type",
            "path": "/profilemanagement/profile/",
        }, {
            "entity_id": "3b6df447-d9af-4958-bc0a-224273cae73f",
            "name": "Sub Profile Type",
            "path": "/profilemanagement/subprofile/",

        }, {
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Master Category",
            "path": '/profilemanagement/mastercategory/',
        },{
            "entity_id": "1367520a-3a39-4560-9b5e-5d50c8b7c8e4",
            "name": "Department",
            "path": '/profilemanagement/department/',
        },
         {
            "entity_id": "3b6df447-d9af-4958-bc0a-224273cae73f",
            "name": "Category/Course",
            "path": "/profilemanagement/category/",

        }, {
            "entity_id": "55cff4e3-6a46-4ddb-ae85-a675b32e3b42",
            "name": "Standard",
            "path": "/profilemanagement/standard/",
        }, {
            "entity_id": "62eea39e-b473-49d3-b224-386822c0a998",
            "name": 'Subjects',
            "path": '/profilemanagement/subject/',
        },],
}

export default ProfileMang;