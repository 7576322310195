import React from "react"
import { Component } from "react"

class MultiDown extends React.Component {
    constructor(props) {
        super(props)
        this.state =
        {
            count: 0,
            labels_array: this.props.labels.rows,
            labels_obj: {},
            selected_element_list: [],
            all: false,
            final: [],
            selected_elements:[],
        }
    }
    selected_element_list = []
    check = ""

    Obj(el) {
        if (this.props.labels.rows.length > 0) {
            var arr = this.props.labels.rows
            // console.log("arr::",arr)
            var objct = {}
            for (var r of arr) {
                if (el && el == true) {
                    objct[r.name] = true
                }
                else if (!el || el == false) {
                    objct[r.name] = false
                }
            }
            // console.log("objct:",objct)
            this.setState({ labels_obj: objct })
        }
    }

    Count() {
        if (this.props.labels.rows.length > 0) {
            var final = []
            var count = 0
            // console.log("labels_obj::",this.state.labels_obj)
            for (var r in this.state.labels_obj) {
                if (this.state.labels_obj[r] == true) {
                    count = count + 1
                    final.push(r)
                }
            }
            // console.log("final::",final)
            this.setState({ final: final })
            if (count == this.props.labels.rows.length) {
                this.setState({ all: true })
            }
            if (count < this.props.labels.rows.length) {
                this.setState({ all: false })
            }
            this.setState({ count: count })
        }
    }

    All() {
        if (this.state.all == false) {
            this.setState({ all: true })
            this.Obj(true)
        }
        else if (this.state.all == true) {
            this.setState({ all: false })
            this.Obj(false)
        }
    }

    HandleChekbox(name, ix,el) {
        // console.log("el::",el,typeof(el))
        var elem = {}
        if (this.state.labels_obj[name] == false) {
            elem[Object.keys(this.state.labels_obj)[ix]] = true
            var neew = Object.assign({ ...this.state.labels_obj }, elem)
            this.setState({ labels_obj: neew })
            
            var temp=[...this.state.selected_elements]
            temp.push(el.entity_id)
            this.setState({selected_elements:temp})
        }
        else if (this.state.labels_obj[name] == true) {
            elem[Object.keys(this.state.labels_obj)[ix]] = false
            var neew = Object.assign({ ...this.state.labels_obj }, elem)
            this.setState({ labels_obj: neew })
            this.setState({ all: false })
            this.state.selected_elements.map((e,i)=>{
                if(e==el.entity_id){
                    var temp=[...this.state.selected_elements]
                    temp.splice(i,1)
                    this.setState({selected_elements:temp})
                }
        })
        }
    }

    Go = () => {
        this.props.Fromparent(this.state.final, true,this.state.selected_elements)
    }

    componentDidMount() {
        this.Obj()
        this.Count()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.labels_obj !== this.state.labels_obj) {
            this.Count()
            // console.log("update final::", prevState)
        }
        if (prevState.final != this.state.final) {
            this.props.Fromparent(this.state.final)
        }

    }
    render() {
                // console.log("this.state.final:",this.state.final)
                // console.log("this.state.labels_obj::",this.state.labels_obj)
                
        console.log("afef:;",this.state.selected_elements)

        return (
            <>
                {
                    <div
                        style={{
                            padding: "2px", border: "0", borderRadius: "10px", width: "30%", height: "200px", maxHeight: "800px", zIndex: "2", position: "absolute",
                            overflowY: "hidden", background: "#fff"
                        }} >
                        <div style={{ borderRadius: "10px", border: "0", overflow: "scroll", height: "100%" }}>
                            <div>
                                <table style={{ margin: "0", width: "100%" }}>
                                    <tr style={{ background: "#0097F9", lineHeight: "30px" }}>
                                        <th style={{ width: "15%", padding: 0 }}>
                                            <div style={{}}>
                                                {
                                                    <input type="checkbox"
                                                        checked={this.state.all ? true : false}
                                                        onClick={() => {
                                                            this.All()
                                                        }}
                                                        style={{ width: "50%" }}>
                                                    </input>
                                                }
                                                &nbsp;&nbsp;
                                            </div>
                                        </th>
                                        <th style={{ width: "80%", color: "black", padding: "2px" }}>
                                            <div>{this.props.labels.heading}</div>

                                        </th>

                                    </tr>
                                    {
                                        this.props.labels.rows.map((el, ix) =>
                                            <tr style={{ height: "30px" }}>
                                                {
                                                    <td style={{ width: "15%" }}>
                                                        <input type="checkbox"
                                                            checked={this.state.labels_obj[el.name] ? true : false}
                                                            onClick={() => {this.HandleChekbox(el.name,ix,el);}}
                                                            style={{ width: "50%" }}></input>
                                                    </td>
                                                }
                                                <td>{el.name}</td>
                                            </tr>

                                        )

                                    }
                                </table>
                                <center>
                                    <div
                                        onClick={this.Go}
                                        style={{ marginTop: "2px", width: "40px", border: "1px solid gray", borderRadius: "5px", paddingLeft: "5px", paddingRight: "5px", cursor: "pointer" }} >
                                        Go</div></center>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
export default MultiDown