import React, { useState, useEffect } from 'react';
import '../../css/superAdmin/sa_inst_reports.css'
import filter_icon from '../../assets/home/filter_icon.png'
import api from '../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../utils'
import ReportsData from './data';
import Loader from '../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import KYCData from './kycData';
import ReportsInside from './reportsinside';



function InstReports(props) {

    const [rdata, setRdata] = useState()
    const [Show, setShow] = useState("reports_initial_page")
    const [loading, setLoading] = useState(false)
    const [FromDate, setFromDate] = useState("2022-07-01")
    const [ToDate, setToDate] = useState("2022-12-31")
    const [initialList, setInitialList] = useState("Initial")
    const [tag, setTag] = useState("")
    const [data, setData] = useState([])
    const [kycdata, setKycData] = useState([])
    const [url, setUrl] = useState(``)
    


    useEffect(() => {
        getTotalCountInst(FromDate, ToDate)
    }, [])



    const getTotalCountInst = (FromDate, ToDate) => {
        setLoading(true)
        let request = {
            url: `report/institute/count-all?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const Url = (type) => {
        if (type == " > Listed in last 15 days") {
            return `report/institute/last-15-days?uid=${props.up_id}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > KYC Verified") {
            return `inst/kyc/all/get?fromDate=${FromDate}&toDate=${ToDate}&pageOffset=1&pageSize=10&isAll=false&isStatusVarified=true&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > KYC Not Verified") {
            return `inst/kyc/all/get?fromDate=${FromDate}&toDate=${ToDate}&pageOffset=1&pageSize=10&isAll=false&isStatusVarified=false&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > ERP Purchased - discovery profile incomplete") {
            return `report/institute/erp-purchased/true?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Educators Requests Accepted") {
            return `report/institute/edu-req-accepted?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > State Wise") {
            return `report/institute/state-list?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > City Wise") {
            return `report/institute/city-list?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Category/course Wise profiles") {
            return `report/institute/mst-course-list?uid=${props.up_id}`
        } if (type == " > Leads Generated") {
            return `enquiry/lead/${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}/get-all?fromDate=${FromDate}&toDate=${ToDate}&isAll`
        } if (type == " > Top Rated (More than 4 stars)") {
            return `report/institute/ratings/Top?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Avg Rated (between 1 to 4 stars )") {
            return `report/institute/ratings/Average?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Least Rated (less than 1 stars )") {
            return `report/institute/ratings/Least?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Duplicate Names") {
            return `report/institute/duplicate-named?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Inactive Profiles") {
            return `report/institute/inactive-inst?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Incomplete Profiles") {
            return `report/institute/incomplete-prof?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Multiple Branches") {
            return `report/institute/multi-branches?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Direct Registration") {
            return `report/institute/direct-reg?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Profile Claim Reuqests") {
            return `report/institute/claimed-list?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Most Compared Profiles") {
            return `report/institute/compared?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Most Wishlisted Profiles") {
            return `report/institute/wishlisted?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        } if (type == " > Discovery profile Only") {
            return `report/institute/erp-purchased/false?uid=${props.up_id}&f_date=${FromDate}&t_date=${ToDate}&inst_type=${props.Show == 'school' ? 'SCHOOL' : 'COACHING'}`
        }if (type == " > Education Board") {
            return `report/institute/edu-board-list?uid=${props.up_id}`
        }
    }


    const getTableData = (type) => {
        setLoading(true)
        let request = {
            url: Url(type),
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(res => {
            console.log(res.data.result,"leads dat")
            setLoading(false)
            setData(Array.isArray(res.data.result) ? res.data.result : res.data.result.response)
            setKycData(res.data.result.response ?? [])
        }).catch((err) => {
            console.log(err,"errorrr")
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    console.log(data,'dattatattatatta')

    return (
        <>
            {loading && <Loader />}
            <div className="inst_reports">
                <div className="inst_reports_inner">
                    <div className="entire_reports_2">
                        <div className="sa_reports_inner" >
                            <div className="reports_heading_and_cards">
                                <div className="sa_reports_heading2" >
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <font
                                            onClick={() => { props.setShow("reports_initial_page") }}
                                            style={{
                                                cursor: "pointer",
                                                // border:"1px solid gray",
                                                borderRadius: "4px",
                                                color: "#0097F9",
                                                background: "rgb(236, 230, 230);",
                                            }}
                                        >
                                            {" Reports > "}</font>

                                        <div style={{ cursor: "pointer" }} onClick={() => { setInitialList("Initial"); setTag("");setData([]) }}>{props.Show == 'school' ? 'School' : 'Institute'}</div>
                                        <div>{tag}</div>
                                    </div>

                                    <div className="reports_heading_filters" >
                                        <img src={filter_icon} height="22px" />
                                        <div className="reports_from_date_filter_area">



                                            <div className="reports_non_absolute_inputs">
                                                {<input placeholder="From Date" id="input1" type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    value={FromDate}
                                                    style={{ cursor: "pointer", outline: "0" }}

                                                    onChange={(e) => {
                                                        setFromDate(e.target.value);
                                                        if (props.show == "reports_initial_page") {
                                                            getTotalCountInst(ToDate, e.target.value)
                                                        } else {
                                                            getTableData(tag)
                                                        }

                                                    }}
                                                />
                                                }
                                            </div>

                                        </div>


                                        <div className="reports_from_date_filter_area">

                                            <div className="reports_non_absolute_inputs">
                                                {
                                                    <input placeholder="To Date" id="input1" type="date"
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        value={ToDate}
                                                        style={{ cursor: "pointer", outline: "0" }}
                                                        onChange={(e) => {
                                                            setToDate(e.target.value)
                                                            if (props.show == "reports_initial_page") {
                                                                getTotalCountInst(FromDate, e.target.value)
                                                            } else {
                                                                getTableData(tag)
                                                            }

                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    initialList == "Last" &&
                                    <ReportsData data={data} />
                                }

                                {
                                    initialList == "Kyc" &&
                                    <KYCData data={kycdata} getTableData={getTableData} setTag={setTag} tag={tag} />
                                }
                                 
                                 {initialList == 'inside'&&
                                 <ReportsInside  data={data} setData={setData} setInitialList={setInitialList} setTag={setTag} tag={tag} Show={props.Show}/>

                                 }

                                {
                                    initialList == "Initial" &&

                                    <><div className="sa_reports_cards">
                                        <div className="sa_reports_cards_inner_2">
                                            <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Listed in last 15 days"); getTableData(" > Listed in last 15 days") }}>
                                                <center>
                                                    Listed in last 15 days
                                                    <p>
                                                        {rdata?.last_15_days ?? "-"}
                                                        {/* {this.state.rprt_data1[1]} */}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards" onClick={() => { setInitialList("Kyc"); setTag(" > KYC Verified"); getTableData(" > KYC Verified") }}>
                                                <center>
                                                    KYC Verified
                                                    <p>
                                                        {rdata?.kyc_verified ?? "-"}
                                                        {/* {this.state.rprt_data1[2]} */}
                                                    </p>
                                                </center>
                                            </div>

                                            <div className="reports_cards" onClick={() => { setInitialList("Kyc"); setTag(" > KYC Not Verified"); getTableData(" > KYC Not Verified") }}>
                                                <center>
                                                    KYC Not Verified
                                                    <p>
                                                        {rdata?.kyc_not_verified ?? "-"}
                                                        {/* {this.state.rprt_data1[2]} */}
                                                    </p>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("inside"); setTag(" > State Wise"); getTableData(" > State Wise") }}>
                                                    <center>
                                                        State Wise
                                                        <p>
                                                            {rdata?.state_wise ?? "-"}
                                                            {/* {this.state.rprt_data1[1]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("inside"); setTag(" > City Wise"); getTableData(" > City Wise") }}>
                                                    <center>
                                                        City Wise
                                                        <p>
                                                            {rdata?.city_wise ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("inside"); setTag(props.Show == 'school' ? ' > Education Board' : ' > Category/course Wise profiles'); getTableData(props.Show == 'school' ? ' > Education Board' : ' > Category/course Wise profiles') }}>
                                                    <center>
                                                    {props.Show == 'school' ? 'Education Board' : ' Category/course Wise profiles'}
                                                       
                                                        <p>

                                                            {
                                                               props.Show == 'school' ?
                                                               rdata?.education_boards ?? "-"
                                                               :
                                                               rdata?.category_profiles ?? "-"
                                                            }
                                                            
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Leads Generated"); getTableData(" > Leads Generated") }}>
                                                    <center>
                                                        Leads Generated
                                                        <p>
                                                            {rdata?.leads_generated ?? "-"}
                                                            {/* {this.state.rprt_data1[1]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Top Rated (More than 4 stars)"); getTableData(" > Top Rated (More than 4 stars)") }}>
                                                    <center>
                                                        Top Rated (More than 4 stars)
                                                        <p>
                                                            {rdata?.top_rated ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Avg Rated (between 1 to 4 stars )"); getTableData(" > Avg Rated (between 1 to 4 stars )") }}>
                                                    <center>
                                                        Avg Rated (between 1 to 4 stars )
                                                        <p>
                                                            {rdata?.avg_rated ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Least Rated (less than 1 stars )"); getTableData(" > Least Rated (less than 1 stars )") }}>
                                                    <center>
                                                        Least Rated (less than 1 stars )
                                                        <p>
                                                            {rdata?.least_rated ?? "-"}
                                                            {/* {this.state.rprt_data1[1]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Duplicate Names"); getTableData(" > Duplicate Names") }}>
                                                    <center>
                                                        Duplicate Names
                                                        <p>
                                                            {rdata?.duplicate_named ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Inactive Profiles"); getTableData(" > Inactive Profiles") }}>
                                                    <center>
                                                        Inactive Profiles
                                                        <p>
                                                            {rdata?.inactive_profiles ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Incomplete Profiles"); getTableData(" > Incomplete Profiles") }}>
                                                    <center>
                                                        Incomplete Profiles
                                                        <p>
                                                            {rdata?.incomplete_profiles ?? "-"}
                                                            {/* {this.state.rprt_data1[1]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Multiple Branches"); getTableData(" > Multiple Branches") }}>
                                                    <center>
                                                        Multiple Branches
                                                        <p>
                                                            {rdata?.multiple_branches ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Direct Registration"); getTableData(" > Direct Registration") }}>
                                                    <center>
                                                        Direct Registration
                                                        <p>
                                                            {rdata?.direct_registrations ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Profile Claim Reuqests"); getTableData(" > Profile Claim Reuqests") }}>
                                                    <center>
                                                        Profile Claim Requests
                                                        <p>
                                                            {rdata?.profile_claim_reqs ?? "-"}
                                                            {/* {this.state.rprt_data1[1]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Most Compared Profiles"); getTableData(" > Most Compared Profiles") }}>
                                                    <center>
                                                        Most Compared Profiles
                                                        <p>
                                                            {rdata?.compared_institutes ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Most Wishlisted Profiles"); getTableData(" > Most Wishlisted Profiles") }}>
                                                    <center>
                                                        Most Wishlisted Profiles
                                                        <p>
                                                            {rdata?.wishlisted_institutes ?? "-"}
                                                            {/* {this.state.rprt_data1[2]} */}
                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sa_reports_cards">
                                            <div className="sa_reports_cards_inner_2">
                                                {/* <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > ERP Purchased - discovery profile incomplete"); getTableData(" > ERP Purchased - discovery profile incomplete") }}>
                                                    <center>
                                                        ERP Purchased - discovery profile incomplete
                                                        <p>
                                                            {rdata?.erp_purchased ?? "-"}
                                                            
                                                        </p>
                                                    </center>
                                                </div>

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Discovery profile Only"); getTableData(" > Discovery profile Only") }}>
                                                    <center>
                                                        Discovery profile Only
                                                        <p>
                                                            {rdata?.discovery_only ?? "-"}
                                                            
                                                        </p>
                                                    </center>
                                                </div> */}

                                                <div className="reports_cards" onClick={() => { setInitialList("Last"); setTag(" > Educators Requests Accepted"); getTableData(" > Educators Requests Accepted") }}>
                                                    <center>
                                                        Educators Requests Accepted
                                                        <p>
                                                            {rdata?.edu_req_accepted ?? "-"}

                                                        </p>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                }


                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </>
    )

}





const mapStateToProps = state => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })
export default connect(mapStateToProps)(InstReports)



