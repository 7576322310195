import React, { useState } from "react"
import { useHistory } from 'react-router-dom';
import '../../css/superAdmin/sa_home.css'
import tata from "../../assets/home/tata.png"
import searchh from '../../assets/home/search_icon.png'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { errorMsg } from '../../utils';
import './home.css'
import api from '../../api/api'



function Home(props) {
    const [loading, setLoading] = useState(false)
    const [rdata, setRdata] = useState('');
    const [school, setSchool] = useState([])
    const [coaching, setCoaching] = useState([])
    const [educator, setEducator] = useState([])

    const setSearch = (value) => {
        setRdata(value)
        setLoading(true)
        let request = {
            url: `dashboard/search-by-name/${value}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            // setRdata(data.data.result.response)
            setSchool(data.data.result?.SCHOOL ?? [])
            setCoaching(data.data.result?.COACHING ?? [])
            setEducator(data.data.result?.EDUCATOR ?? [])
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    const history = useHistory()
    return (
        <>
            <div className="sa_home_container">

                <div className="sa_home_inner_container">

                    <div className="sa_home_upper_section">
                        <div className="home_logo_part">
                            {/* <div className="home_logo_frame">
                             <img src={proctur_logo} height="100%"/>
                          </div> */}
                            <div className="home_logo_text">

                            </div>
                        </div>

                        <div className="home_welcome_part">
                            <div className="welcome_inner_container">
                                <div className="welcome_and_three_button_container">
                                    <div className="welcome_text_and_an_image">
                                        <div className="welcome_text_and_an_image_inner">
                                            <div className="tata_imgae">
                                                <img src={tata} height="98%" />
                                            </div>
                                            <div className="home_welcome_text">
                                                Welcome !
                                            </div>

                                        </div>

                                    </div>


                                    <div className="home_three_buttons_and_text">
                                        <div className="three_buttons_upper_text">
                                            Start adding profile here...

                                        </div>

                                        <div className="home_three_buttons_outer">
                                            <div className="home_three_buttons_inner">
                                                <button class="blue_button" onClick={() => { history.push('/institute/profile/?type=institute') }} >Add Institute </button>
                                                <button class="blue_button" onClick={() => { history.push('/institute/profile/?type=school') }}>Add School </button>
                                                <button class="blue_button" onClick={() => history.push('/educator/profile/')} >Add Educator </button>
                                                {/* <button class="blue_button" onClick={() => { history.push('/reports/') }} >View Report </button> */}
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div  >
                        <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ marginLeft: "42%" }}>
                            <div className="sa-searchhh">
                                <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                                <input className="Super-admin-Search_Input" type="text" onChange={(e) => setSearch(e.target.value)}
                                    placeholder=" Search" style={{ outline: "0" }} />
                            </div>

                            {
                                rdata != "" &&
                                <div style={{ width: '50%', height: '200px', background: "white", borderRadius: '5px', overflowX: 'hidden', overflowY: 'auto',padding:'10px' }}>
                                    <div style={{ color: 'blue',fontSize:'18px',fontStyle:'italic' }}>Coaching</div>
                                    {coaching.map((item) => <div className="listdlist" style={{ cursor: 'pointer' }} onClick={() => {
                                        props.dispatch({ type: 'BASIC_DETAILS', prof_id: item.id });
                                        history.push(`/institute/profile/?type=institute?id=${item.id}`)
                                    }}>{item.name}</div>)}
                                    <div style={{ color: 'blue',fontSize:'18px',fontStyle:'italic'  }}>School</div>
                                    {school.map((item) => <div className="listdlist" style={{ cursor: 'pointer' }} onClick={() => {
                                        props.dispatch({ type: 'BASIC_DETAILS', prof_id: item.id });
                                        history.push(`/institute/profile/?type=school?id=${item.id}`)
                                    }}>{item.name}</div>)}
                                    <div style={{ color: 'blue',fontSize:'18px',fontStyle:'italic'  }}>Educator</div>
                                    {educator.map((item) => <div className="listdlist" style={{ cursor: 'pointer' }} onClick={() => {
                                        props.dispatch({ type: 'BASIC_DETAILS', prof_id: item.id });
                                        history.push(`/educator/profile/?id=${item.id}`)
                                    }}>{item.name}</div>)}
                                </div>
                            }



                        </div>

                        </div>

                    </div>



                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken })

export default connect(mapStateToProps)(Home)
