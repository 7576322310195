import React, { useState, useEffect } from "react";
import add from '../../../../assets/img/add.svg'
import close from '../../../../assets/img/close.svg'
import api from '../../../../api/api'
import Loader from '../../../../components/loader'
import { errorMsg } from "../../../../utils";
import pdf from '../../../../assets/img/pdf.png'
import xls from '../../../../assets/img/xls.png'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Admission(props) {
  const [value, setValue] = useState('');
  const [eligiblity, setEligiblity] = useState([])
  const [availability, setAvailability] = useState([])
  const [formPaymnet, setFormpaymnet] = useState([])
  const [loading, setLoading] = useState(false)
  const [image, setimage] = useState([])
  const [oimage, setOimage] = useState([])
  const [dataId, setDataId] = useState("")
  let standard = localStorage.getItem("stnadard")
  let educator = localStorage.getItem("educator")



  useEffect(() => {
    callGetApi();
  }, [])

  const callGetApi = () => {
    if (props.prof_id == "") {
      toast.error("Please Add Basic Details First.")
      setLoading(false)
    } else if (standard == 'false' && educator == 'false') {
      toast.error("Please Add Standard and Educator.")
    } else if (educator == 'false') {
      toast.error("Please Add Educator")
    } else {
      setLoading(true)
      let request = {
        url: `admsn-process/${props.prof_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        console.log(data.data.result, "rdata")
        let rdata = data.data.result;
        // setFiles(rdata)
        setDataId(rdata.id);
        setValue(rdata.admsn_det);
        setEligiblity(rdata.eligibility ?? []);
        setAvailability(rdata.f_availability ?? []);
        setFormpaymnet(rdata.f_payment ?? []);
        setOimage(rdata.files ?? [])
        setimage([])

      }).catch((err) => {
        console.log(err, "error")
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          // toast.error(errorMsg(err))
        }
      })
    }
  }




  const savedetails = () => {

    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        admsn_det: value,
        eligibility: eligiblity,
        f_availability: availability,
        f_payment: formPaymnet,
        i_p_id: props.prof_id,
        uid: props.up_id,
      }));

      for (let index = 0; index < image.length; index++) {
        fd.append('files', image[index]);
      }

      let request = {
        url: `admsn-process/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      if (dataId == "") {
        api.postCustom(request).then(data => {
          setLoading(false)
          toast.success("Admission process Added successfully.")
          console.warn(data, "idddjshjhs")
          setDataId(data.data.result)
          callGetApi()
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      } else {
        request.url = 'admsn-process/' + dataId + '/update/'
        api.putOther(request).then(data => {
          toast.success("Admission process updated successfully.")
          setLoading(false)
          callGetApi()
        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      }
    }

  }



  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }

    })

  }



  const setitem = (item) => {
    if (eligiblity.indexOf(item) == -1) {
      setEligiblity([...eligiblity, item])
    } else {
      setEligiblity(eligiblity.filter(data => data != item))
    }
  }

  const setitem2 = (item) => {
    if (availability.indexOf(item) == -1) {
      setAvailability([...availability, item])
    } else {
      setAvailability(availability.filter(data => data != item))
    }
  }

  const setitem3 = (item) => {
    if (formPaymnet.indexOf(item) == -1) {
      setFormpaymnet([...formPaymnet, item])
    } else {
      setFormpaymnet(formPaymnet.filter(data => data != item))
    }
  }

  const validateImage = (e) => {
    console.log(e.target.files[0].size, "kjngnghg")
    let img = e.target.files[0].size / 1024
    console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }


  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      // console.log([...oimage,...image].length,"proor")
      if ([...oimage, ...image].length > 4) {
        toast.error("You are only allowed to upload a maximum of 5 files")
      } else {
        if (e.target.files.length > 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          setimage([...image, ...imgs])
          // props.setSocial({ ...props.Social, gallary: imgs })
        }
      }
    }
  }


  const cancel = () => {
    setEligiblity([])
    setAvailability([])
    setFormpaymnet([])
    setimage([])
    setValue([])
    setOimage([])
  }



  const validate = () => {
    if (value === '') {
      toast.error("Please Enter Name")
      return false
    }
    if (eligiblity === '') {
      toast.error("Please Enter eligiblity")
      return false
    }
    if (availability === '') {
      toast.error("Please Enter availability")
      return false
    } if (formPaymnet === '') {
      toast.error("Please Enter formPaymnet")
      return false
    }
    return true
  }


  return (
    <>
      {loading && <Loader />}
      <div>
        <div class="form-section">
          <div>
            <label>Admission Process Details</label>
            <p class="data-info">Max 1500 chars</p>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </div>
          <div>
            <label>Eligibility </label>
            <div class="flex-a mob-res mt-16">
              {
                ["Entrance Test", "Student Interaction", "Parent Interaction"].map((item, key) => {
                  // console.log("INDEX OF " + item + ", " + eligiblity.indexOf(item))
                  return (
                    <div key={key} class="flex-inline">
                      <label class="checkbox">
                        <span class="checkbox__input">
                          <input type="checkbox" checked={eligiblity.indexOf(item) == -1 ? false : true} value={item}
                            onClick={() => setitem(item)} />
                          <span class="checkbox__control">
                            <svg viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                              <path fill='none' stroke='currentColor' d='M1.3 12.91l6.37 6.37L22.79 4.59' /></svg>
                          </span>
                        </span>
                      </label>
                      <span>{item}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div>
            <label>Form Availability </label>
            <div class="flex-a mob-res mt-16">
              {
                ["Online", "Offline"].map((item, key) => {
                  return (
                    <div key={key} class="flex-inline">
                      <label class="checkbox">
                        <span class="checkbox__input">
                          <input type="checkbox" checked={availability.indexOf(item) == -1 ? false : true} value={item}
                            onClick={() => setitem2(item)} />
                          <span class="checkbox__control">
                            <svg viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                              <path fill='none' stroke='currentColor' d='M1.3 12.91l6.37 6.37L22.79 4.59' /></svg>
                          </span>
                        </span>
                      </label>
                      <span>{item} </span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div>
            <label>Form Payment</label>
            <div class="flex-a mob-res mt-16">
              {
                ["Free", "Paid"].map((item, key) => {
                  return (
                    <div key={key} class="flex-inline">
                      <label class="checkbox">
                        <span class="checkbox__input">
                          <input type="checkbox" checked={formPaymnet.indexOf(item) == -1 ? false : true} value={item}
                            onClick={() => setitem3(item)} />
                          <span class="checkbox__control">
                            <svg viewBox='0 0 24 24' aria-hidden="true" focusable="false">
                              <path fill='none' stroke='currentColor' d='M1.3 12.91l6.37 6.37L22.79 4.59' /></svg>
                          </span>
                        </span>
                      </label>
                      <span>{item} </span>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div>
            <label>Upload File </label>
            <p class="line_purple"></p>

            <p class="data-info">You can upload files related to admission process or any other important process
            </p>
            <div class="flex-p1">
              <input class="expandale-input w-450 choose" type="file" id="myFile" accept="application/pdf,application/vnd.ms-excel,image/*" multiple onChange={select_MediaContent} placeholder="Choose File" /><a href="#"></a>
            </div>
            <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img, pdf, word</p>
            <div class="tags-img">
              {oimage.map((item, key) => {

                return (
                  <div key={key} >
                    {
                      item.f_disp.split(".")[1] == "pdf" ?
                        <img src={pdf} /> :
                        item.f_disp.split(".")[1] == "xls" ?
                          <img src={xls} /> :
                          <img src={item.f_url} />
                    }
                    <img src={close} onClick={() => { deleteImage(item.id); }} />
                    {/* Once deleted and response is 200 filter the oimage array and remove deleted image on the basis of ID  */}
                  </div>
                )
              })
              }
              {image.map((item, key) => {
                return (
                  <div key={key}>
                    {
                      item.name.split(".")[1] == "pdf" ?
                        <img src={pdf} /> :
                        item.name.split(".")[1] == "xls" ?
                          <img src={xls} /> :
                          <img src={URL.createObjectURL(item)} />

                    }
                    <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                  </div>
                )
              })
              }
            </div>
          </div>
          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => cancel()}>Cancel</button>
              <button class="blue_button" onClick={() => {
                if (props.prof_id == "") {
                  toast.error("Please Add Basic Details First.")
                }else if(standard=='false' && educator=='false'){
                  toast.error("Please Add Standard and Educator.")
                }else if(educator=='false'){
                  toast.error("Please Add Educator")
                } else {
                  savedetails()
                }
              }}>Save</button>
            </div>
          </div>
        </div>
        {/* <!--Admissions Form Starts --> */}


      </div>

    </>
  )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Admission)