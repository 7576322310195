import './cn_popup1.css'

import React from 'react';
import download_icon from '../../assets/home/download_icon.png'
import cross_icon from '../../assets/home/cross_icon.svg'

class Cnpopup1 extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            table_data:[
                ["Ravi Shukla","Delhi"],
                ["Rohit","Prayaag"],
                ["vipul","Varanasi"],
                ["Om","JhAnsi"],
                ["Tej","Lucknow"],
                ["Prakash","Ayodhya"],
                ["kisan","Varanasi"],
                ["Bhanu","JhAnsi"],
                ["Raju","Varanasi"],
               
                
               ],
        }
    
    }


  
    render()
    {
        return(
            <div>
            <div className="cn_pp1">
                <div className="cn_pp1_inner">
                     
                   <div className="cn_download_file">
                          Download File <font>
                              <img src={cross_icon}
                          onClick={this.props.FromChildCnPopUp1} style={{cursor:"pointer"}}
                          /></font>
                   </div>

                   <div  className="cn_file_img">
                       {this.props.data?.p_url??'photo'} <img src={download_icon} height="18px" />
                   </div>

                   <div  className="cn_heading_named_text">
                      Text
                   </div>
                        
                    <div  className="cn_textarea_1">
                        <textarea  value={this.props.data.msg}/>
                    </div>

                    <div className="cn_heading_named_recipients">
                    Recipients
                    </div>

                    <div  className="cn_textarea_2">
                    {/* <textarea /> */}
                    <table id="super-admin"  style={{margin:"0"}}>
                                    <tr>
                                        <th style={{width:"50%"}}>Name </th>
                                        <th  style={{width:"50%"}}>Location</th>
                                        {/* <th>Icon</th>
                                        <th>Action</th> */}
                                    </tr>

                                    
                                {  
                                    this.props.data?.name_li??[].map((el)=>

                                        <tr>
                                            <td>{el[0]}</td>
                                            <td>{el[1]}</td>
                                            {/* <td>{el[2]}</td> */}
                                        
                                        </tr>

                                    ) 
                                }  


                     </table>
                    </div>

                </div>
                  
            </div>
            </div>
        )
    }
}
export default Cnpopup1
