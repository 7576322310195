import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { errorMsg } from "../../../utils";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import RoleSetting from "./roleSettings";




function RolesForm(props) {
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([])
    const [ename, seteName] = useState("")
    const [settings, setSettings] = useState([])
    const [value, setValue] = useState("")
    const [modules, setModules] = useState({
        home: "",
        dashboard: "",
        profile: "",
        review: "",
        settings: "",
        rolesnuser: "",
        branches: ""
    })

    useEffect(() => {
        getfeature()
        if (props.selected != 0) {
            setLoading(true)
            let request = {
                url: `role/${props.mdata.id}/SUPERADMIN`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
            }
            api.getCustom(request).then(data => {
                setLoading(false)
                let rdata = data.data.result;
                setName(rdata.name)
                setValue(rdata.desc)
                setRdata(rdata.c_f_det)
                setSettings(rdata.f_li)

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }, [props.selected])




    const savedetails = () => {
        if (validate()) {
            setLoading(true)
            let request = {
                url: `role/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
                data: {
                    name: name,
                    desc: value,
                    u_id: props.up_id,
                    f_li: settings
                }
            }
            api.postCustom(request).then(data => {
                setLoading(false)
                toast.success("Roles added successfully.")
                setTimeout(() => {
                    props.setselected(0)
                    props.setForm(false)
                    props.fetchData()
                }, [3000]);
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }



    const getfeature = () => {
        setLoading(true)
        let request = {
            url: `role/allFeatures/SUPERADMIN`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)
            //   setRdata([{id:1,name:"Manager",time:"11-9-2021 04:40 PM "},{id:2,name:"Teacher",time:"11-9-2021 04:40 PM "}])
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const validate = () => {
        if (name == "") {
            toast.error("Please enter Role Name.")
            return false
        }
        if (settings.length === 0) {
            toast.error("Please select Roles.")
            return false
        }
        else return true
    }


    const updateValue = () => {
        if (validate()) {
            setLoading(true)
            let request = {
                url: `role/${props.mdata.id}/update`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`
                },
                data: {
                    name: name,
                    desc: value,
                    u_id: props.up_id,
                    f_li: settings
                }
            }
            api.putOther(request).then(data => {
                setLoading(false)
                toast.success("Roles updated successfully.")
                setTimeout(() => {
                    props.setselected(0)
                    props.setForm(false)
                    props.fetchData()
                }, [3000]);

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }


    const setitem = (item) => {
        if (settings.indexOf(item.c_u_id) == -1) {
            setSettings([...settings, item.c_u_id, ...item.f_li.map((item1) => item1.f_u_id)])
        } else {
            let ids_remove = item.f_li.map((el) => el.f_u_id);
            ids_remove.push(item.c_u_id)
            setSettings(settings.filter(item => !ids_remove.includes(item)))
        }
    }



    console.log(rdata, "rdata")



    return (
        <>
            {loading && <Loader />}
            <div class="form-section roles">
                <div>
                    {props.selected ?
                        <p class="heading">Edit Role</p> : <p class="heading">Add Role</p>}
                    <p class="line_purple"></p>
                    <label>Role Name<span>*</span></label>
                    <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div>
                    <div>
                        <label>Description </label>
                        <ReactQuill theme="snow" value={value} onChange={setValue} />
                    </div>
                </div>
                <div>
                    <p class="heading">Select Modules and Access</p>
                    <p class="line_purple"></p>
                    <p class="data-info">Define the Actions that can be taken as per Module</p>

                    {
                        // rdata.length > 0 &&
                        rdata?.map((item, key) =>
                        (
                            <>
                                <div class="flex-a mt-36" key={key}>
                                    <label class="switch1">
                                        <input type="checkbox" checked={settings.indexOf(item.c_u_id) == -1 ? false : true} onClick={() => setitem(item)} />
                                        <span class="slider1 round"></span>
                                    </label>
                                    <label class="mb-0 ml16">{item.c_nm}</label>
                                </div>
                                <RoleSetting item={item} setSettings={setSettings} settings={settings} />

                            </>
                        )
                        )
                    }


                </div>


                <div class="button-section">
                    <div></div>
                    <div>
                        <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
                        {
                            props.selected ?
                                <button class="blue_button" onClick={updateValue}>Update</button> :
                                <button class="blue_button" onClick={savedetails}>Save</button>
                        }
                    </div>

                </div>
            </div>
            <ToastContainer />
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })

export default connect(mapStateToProps)(RolesForm)