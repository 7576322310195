const initialState = {
    userid: 0,
    usertype: 0,
    loginToken:'',
    up_id:'',
    user:'',
    password:"",
    error: false,
    phone:'',
    datalogin:'',
    nav:'',
    error_msg:'',
    prof_id:"",
    edu_id:'',
    c_id:'',
    p_type:""


};



function reducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS': {
            console.log(action,"action")
            return {
                ...state,
                userid: action.userid,
                usertype: action.usertype,
                loginToken:action.loginToken,
                up_id:action.up_id,
                user:action.user,
                password:action.password,
                phone:action.phone,
                datalogin:action.datalogin,
                error_msg:"",
                error: false,
                c_id:action.c_id,
                p_type:action.p_type

                
            };
        }
        case 'LOGIN_LOADING': {
            return {
                ...state,
            };
        }
        case 'BASIC_DETAILS': {
            return {
                ...state,
                prof_id:action.prof_id

            };
        }
        case 'EDUCATOR': {
            return {
                ...state,
                edu_id:action.edu_id

            };
        }
        case 'LOGIN_ERROR': {
            return {
                ...state,
                error_msg: action.error_msg ? action.error_msg : 'Invailid Login Details',
                error: action.error,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                userid: 0,
                usertype: 0,
                prof_id:"",
                up_id:"",
                loginToken:"",
                c_id:'',
                p_type:''


            }
        }
        default: {
            return { ...state };
        }
    }
}

export default reducer;