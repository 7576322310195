import React, { useRef, useState } from 'react'
import arrow from '../../../assets/login/signin/arrow.png'
import loader2 from '../../../assets/login/signin/loader/loader2.gif'
import api from '../../../api/api'
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'
import style from '../../../css/style.css'
import website from '../../../css/website.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Signin(props) {
    const ldata = JSON.parse(localStorage.getItem('login'));
    let refs = [useRef(null), useRef(null), useRef(null), useRef(null)]
    let [val, setVal] = useState(["", "", "", ""])
    let [loading, setLoading] = useState(false)

    let changeFocus = (value, ind) => {
        if (isNaN(value * 1)) {
            refs[ind].current.value = "";
        } else {
            if (value.length > 1) {
                value = (value % 10) + "";
            }
            let newVal = [...val]
            newVal[ind] = value
            setVal(newVal);
            if (value.length == 0) {
                if (ind != 0) {
                    refs[ind - 1].current.focus();
                }
            } else {
                if (ind != 3) {
                    refs[ind + 1].current.focus();
                }
            }
        }

    }

    console.log(ldata, "data")
    const onSubmit = () => {
        if (!val.every(data => data.length == 1)) {
            toast.error("Please provide valid OTP.");
        } else {
            setLoading(true);
            // props.setSelected('SigninNext')
            api.post({ url: 'user/otp/validate', data: { "ent_id": ldata.ent_id, "otp": val.join(""), "id": ldata.otp_id, "is_multi_login": ldata.is_multi_login, "phone": ldata.number, "user_type": ldata.user_type } }).then((data) => {
                if (ldata.is_multi_login == true) {
                    console.log(data.data.result,"result")
                    localStorage.setItem("multiuserData", JSON.stringify(data.data.result))
                    props.setSelected('MultipleUser')
                } else {
                    props.dispatch({
                        type: 'LOGIN_SUCCESS',
                        datalogin: data.data.result,
                        usertype: data.data.result.p_type,
                        userid: data.data.result.p_type,
                        loginToken: data.data.result.token,
                        up_id: data.data.result.up_id,
                        p_type: data.data.result.p_type
                    });
                    props.dispatch({ type: 'BASIC_DETAILS', prof_id: data.data.result.c_id });
                    localStorage.setItem("prof_id", data.data.result.c_id);
                    localStorage.setItem("name", (data.data.result.name));
                    localStorage.setItem("email", (data.data.result.email));
                    localStorage.setItem("phone", (data.data.result.phone));
                    setTimeout(() => { window.location = '/coaching/home/' }, 500);
                    if (ldata.user_type == 4 || ldata.is_new_user == false) {
                        localStorage.removeItem("login")
                        localStorage.setItem("userdata", JSON.stringify(data.data.result));
                        localStorage.setItem("token", (data.data.result.token));
                        localStorage.setItem("id", (data.data.result.up_id));
                        localStorage.setItem("p_type", (data.data.result.p_type));
                        localStorage.setItem("prof_id", data.data.result.c_id);
                        toast.success("Login successful");
                        setTimeout(() => { window.location = '/coaching/home/' }, 500);
                        // props.setSelected('SigninNext')
                        // setTimeout(() => {props.close();}, 1000)
                    } else if (ldata.is_new_user) {
                        localStorage.setItem("newuserdata", JSON.stringify(data.data.result));
                        localStorage.setItem("prof_id", data.data.result.c_id);
                        setTimeout(() => { window.location = '/coaching/home/' }, 500);
                    }
                }
            }).catch((err) => {
                setLoading(false);
                if (err.response.data.error[0].error_code == 650) {
                    props.setSelected('SigninNext')
                } else {
                    if (err.response.status == 401) {
                        props.dispatch({ type: 'LOGOUT' })
                    } else {
                        toast.error(errorMsg(err))
                    };
                }


            })
        }

    }

    const onResend = () => {
        setLoading(true);
        api.post({ url: 'user/login', data: { "phone": ldata.number, "user_type": ldata.user_type } }).then((data) => {
            localStorage.setItem("login", JSON.stringify(data.data.result))
            props.setSelected('Otp')
            toast.success("Otp send sucessfully");
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })
    }

    const changeNum = () => {
        localStorage.removeItem("login");
        if (ldata.user_type == 4) {
            props.setSelected('Login')
        } else {
            props.setSelected('Signin')
        }

    }

    return (
        <div className="signin">
            <h2>Sign In</h2>
            <h4>to your account</h4>
            <h4 style={{ marginTop: '10px' }}>Please enter OTP sent to your </h4>
            <h4 style={{ marginBottom: '10px' }}>mobile
                {/* {ldata.number} */}
            </h4>
            <div className="flex-row otpinput">
                <div><input type="text" ref={refs[0]} value={val[0]} onChange={e => changeFocus(e.target.value, 0)} /></div>
                <div><input type="text" ref={refs[1]} value={val[1]} onChange={e => changeFocus(e.target.value, 1)} /></div>
                <div><input type="text" ref={refs[2]} value={val[2]} onChange={e => changeFocus(e.target.value, 2)} /></div>
                <div><input type="text" ref={refs[3]} value={val[3]} onChange={e => changeFocus(e.target.value, 3)} /></div>
            </div>
            <p className="resend" onClick={onResend} >RESEND OTP</p>
            {
                loading ?
                    <div className="loginProgress"><img src={loader2} /></div>
                    :
                    <button className="flex-row" onClick={onSubmit} >Confirm <img src={arrow} /></button>
            }
            <button className="flex-row" style={{ background: '#F00' }} onClick={changeNum} >Change Number</button>

            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}

const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(Signin)
