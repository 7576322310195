import React from "react";
import { useHistory } from 'react-router-dom';
import home from "../../assets/home/homephoto.png"
import { connect } from 'react-redux';

function MainHome(props) {
    const history = useHistory()

    const redirectCoahing = () => {
        let prof_id = localStorage.getItem("prof_id")
        if (props.p_type == 1 && props.prof_id != null) {
            history.push(`/coaching/profile/?type=institute?id=${props.prof_id}`)
        } else {
            history.push(`/coaching/profile/?type=institute`)
        }
    }

    const redirectSchool = () => {
        let prof_id = localStorage.getItem("prof_id")
        if (props.p_type == 2 && props.prof_id != null) {
            history.push(`/coaching/profile/?type=school?id=${props.prof_id}`)
        } else {
            history.push(`/coaching/profile/?type=school`)
        }
    }

    const redirectEducator = () => {
        let prof_id = localStorage.getItem("prof_id")
        console.log(prof_id,"prof_id")
        if (props.p_type == 3 && prof_id != "null") {
            history.push(`/educator/profile/?id=${prof_id}`)
        } else {
            history.push(`/educator/profile/`)
        }

    }

    const redirect = () => {
        if (props.p_type == 1) {
            redirectCoahing()
        } else if (props.p_type == 2) {
            redirectSchool()
        }else if(props.p_type == 3){
            redirectEducator()
        }
    }

    return (
        <>
            <div className="Sa-HomemainDiv">
                <div className="SA-homeMAin">
                    <lable className="Sa-Welcome">Welcome!</lable>
                    <lable className="Sa-Manage">Start Managing your Profile with Proctur</lable>
                    <button className="Sa-HomeButton" onClick={() => redirect()}>Take Me To Profile </button>
                    {/* <label className="Sa-lable">First Time Here,</label>
                    <p className="Sa-lable2">Watch the <a href="#"> Short Tutorial To Begin! </a></p> */}
                </div >
                <img src={home} className="SA-homePagePhoto" />
            </div>


        </>

    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(MainHome)