import React, { useState, useEffect } from 'react'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'


function Operate(props) {
    const [title, setTitle] = useState("")
    const [etitle, seteTitle] = useState("")
    const [position, setPosition] = useState("")
    const [eposition, setePosition] = useState("")

    useEffect(() => {
        if (props.selected != 0) {
            seteTitle(props.data.name)
            setePosition(props.data.position)
        }
    }, [props.selected])

    const updateValue = () => {
        props.setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({ name: etitle, pos: eposition * 1 }));
        let request = {
            url: `master/${props.entity_id}/sub-master/update/${props.data.entity_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: fd
        }
        api.putOther(request).then(data => {
            props.setLoading(false)
            toast.success("Medium of teaching updated Successfully  ")
            setTitle("");
            seteTitle("");
            setPosition("")
            props.setSelected(0);
            props.fetchData();

        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const addValue = () => {
        if (validate()) {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({ name: title, pos: position * 1 }));
            let request = {
                url: `master/${props.entity_id}/sub-master/add`,
                headers: { 'Authorization': `Bearer ${props.loginToken}` },
                data: fd
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Medium of teaching added Successfully  ")
                setTitle("");
                seteTitle("");
                setPosition("")
                props.fetchData();
            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const isNumber = (num) => {
        return /^[0-9\b]+$/.test(num)
    }

    const validate = () => {
        if (title === '') {
            toast.error("Please enter Medium of Teaching.")
            return false
        }
        return true
    }



    return (
        <>

            <div className="Sa-AddProfile">
                {
                    props.selected ? <p style={{ fontSize: "15px" }}>Edit Medium of Instructions</p> : <p style={{ fontSize: "15px" }}>Add Medium of Instructions</p>
                }
                <div className="Super-admin-line_purple"></div>


                <div className="Sa-Inputbox">
                    <div>
                        {
                            props.selected ?
                                <input value={etitle} onChange={(e) => seteTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Medium of Teaching " style={{ fontSize: "13px" }} /> :
                                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="title" className="Sa-input" name="fname" placeholder="Enter Medium of Teaching " style={{ fontSize: "13px" }} />
                        }

                    </div>
                </div>
                {
                    props.selected ?
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { props.setSelected(0); setTitle(""); setPosition("") }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={updateValue}>Update</button>
                        </div>
                        :
                        <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={() => { setTitle(""); setPosition("") }}>Cancel</button>
                            <button className="Super-admin-save-button" onClick={addValue}>Save</button>
                        </div>
                }

                <ToastContainer />
            </div>

        </>
    );
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Operate)

