import React, { useEffect, useState } from "react";
import close from '../../../assets/img/close.svg'
import globe from '../../../assets/img/basic/globe.png'
import api from '../../../api/api'
import Loader from '../../../components/loader'
import pdf from '../../../assets/img/pdf.png'
import xls from '../../../assets/img/xls.png'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import { connect } from "react-redux";
import 'react-quill/dist/quill.snow.css';



function NewsForm(props) {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('')
  const [link, setLink] = useState("")
  const [image, setimage] = useState([])
  const [oimage, setOimage] = useState([])



  useEffect(() => {
    if (props.selected != 0) {
      setTitle(props.mdata.title)
      setLink(props.mdata.links)
      setValue(props.mdata.desc)
      setOimage(props.mdata.file_li ?? [])
      setimage([])

    }
    return title
  }, [props.selected])


  const savedetails = () => {
    if (props.prof_id == null) {
      toast.error('Please add Basic Detail first.')
    } else {
      if (title == "") {
        toast.error("Please add Title.")
      } else {
        setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({
          title: title,
          links: [link],
          desc: value,
          i_p_id: props.prof_id
        }));

        for (let index = 0; index < image.length; index++) {
          fd.append('files', image[index]);
        }
        let request = {
          url: `news/add`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`
          },
          data: fd
        }
        api.postCustom(request).then(data => {
          setLoading(false)
          toast.success("News added successfully")
          setTimeout(() => {
            props.setForm(false)
            props.fetchData()
          }, [3000]);

        }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      }
    }
  }


  const updateValue = () => {
    if (title == "") {
      toast.error("Please add Title.")
    } else {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        title: title,
        link: link,
        desc: value,
        i_p_id: props.prof_id
      }));

      for (let index = 0; index < image.length; index++) {
        fd.append('image', image[index]);
      }
      let request = {
        url: `news/${props.mdata.id}/update`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`
        },
        data: fd
      }
      api.putOther(request).then(data => {
        setLoading(false)
        toast.success("News updated successfully")
        setTimeout(() => {
          props.setForm(false)
          props.fetchData()
        }, [3000]);

      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const select_MediaContent = (e) => {
    if (validateImage(e)) {
      if ([...oimage, ...image].length > 1) {
        toast.error("You are only allowed to upload a maximum of 2 files")
      } else {
        if (e.target.files.length > 0) {
          let imgs = [];
          for (const [key, value] of Object.entries(e.target.files)) {
            imgs.push(value)
          }
          setimage([...image, ...imgs])
        }
      }
    }
  }

  const validateImage = (e) => {
    let img = e.target.files[0].size / 1024
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }


  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }

    })

  }




  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <div class="form-section news">
        <div>
          {props.selected ?
            <p class="heading">Edit News</p> : <p class="heading">Add News</p>}
          <p class="line_purple"></p>
          <label>Title <span>*</span></label>
          <input class="expandale-input w-450" placeholder="Enter" value={title} onChange={(e) => setTitle(e.target.value)}></input>
          <p class="data-info">You can add link for files. Students will be redirected to those links from
            your profile</p>
          <label class="mt-16">Add Link</label>
          <div class="flex-p1 m-24">
            <embed src={globe} />
            <input class="expandale-input w-450 ml-8" placeholder="Enter Your Link" value={link} onChange={(e) => setLink(e.target.value)} />
          </div>
        </div>
        <div>

          <label>Upload File</label>

          <div class="flex-p1">
            <input class="expandale-input w-450 choose" type="file" multiple onChange={select_MediaContent} disabled={image.length === 5} placeholder="Choose File" />
          </div>
          <p class="data-info">Format jpeg, jpg, img, pdf, word Max 2 Files can be uploaded .Max file size
            500kb per upload.</p>
          <div class="tags-img">
            {oimage.map((item, key) => {
              return (
                <div key={key}>
                  {
                    item.f_disp.split(".")[1] == "pdf" ?
                      <img src={pdf} /> :
                      item.f_disp.split(".")[1] == "xls" ?
                        <img src={xls} /> :
                        <img src={item.f_url} />
                  }
                  <img src={close} onClick={() => { deleteImage(item.id); }} />
                </div>
              )
            })
            }
            {image.map((item,key) => {
              return (
                <div key={key}>
                  {
                    item.name.split(".")[1] == "pdf" ?
                      <img src={pdf} /> :
                      item.name.split(".")[1] == "xls" ?
                        <img src={xls} /> :
                        <img src={URL.createObjectURL(item)} />

                  }
                  <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                </div>
              )
            })
            }
          </div>

        </div>
        <div>
          <label>More Details</label>
          <ReactQuill theme="snow" value={value} onChange={setValue} />
        </div>
        <div class="button-section">
          <div></div>
          <div>
            <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
            {
              props.selected ?
                <button class="blue_button" onClick={updateValue}>Update</button> :
                <button class="blue_button" onClick={savedetails}>Save</button>
            }
          </div>

        </div>
      </div>

    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(NewsForm)