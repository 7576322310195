import React, { useEffect } from 'react'


import Slider1 from '../../../assets/login/signin/slider1.jpg'
import Slider2 from '../../../assets/login/signin/slider2.jpg'
import Slider3 from '../../../assets/login/signin/slider3.jpg'
import Slider4 from '../../../assets/login/signin/slider4.jpg'



export default function Slider() {
    var margin = 0;
    useEffect(() => {
        var timer = setInterval(() => {
            let nItemm = document.querySelector("#signin-slider-dots div:nth-child(" + (margin+1) + ")");
            nItemm.classList.remove("active");
            margin = ((margin+1)%4);
            let signdiv= document.getElementById('signin-slider-div3');
            signdiv.style.marginLeft = '-'+(margin*100)+'%';
            let nItem = document.querySelector("#signin-slider-dots div:nth-child(" + (margin+1) + ")");
            nItem.classList.add("active");
            //Add class 'active' to nth child
        }, 2500)
        return () => {
            clearInterval(timer)
        }
    }, [])     
    return (
        <>
            <div id="signin-slider-div3" className="signin-slider-div" style={{marginLeft: '-100%'}}>
                <div className="signin-slider">
                    <img src={Slider1} />
                    <div className="sigin-slider-info">
                        <h3>Search</h3>
                        <p>Find the best Schools, Coaching Institutes, Educators 
that will help you to elevate your knowledge</p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={Slider2} />
                    <div className="sigin-slider-info">
                        <h3>Connect</h3>
                        <p>Send your interest to School, Coaching Institute, Educator and 
connect with them if they are the right fit for you</p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={Slider3} />
                    <div className="sigin-slider-info">
                        <h3>Learn</h3>
                        <p>Buy customized courses from Top Coaching Institutes as per your interests and start learning from anywhere with Proctur Study </p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={Slider4} />
                    <div className="sigin-slider-info">
                        <h3>Discover Your Interest</h3>
                        <p>Find out where your interest is with Our Interest Discovery Tool and take a step towards your bright future</p>
                    </div>
                </div>
            </div>
            <div className="signin-slider-dots" id="signin-slider-dots">
                <div></div>
                <div></div>
                <div className="active"></div>
                <div></div>
            </div>
        </>
    )
}
