import React, { useState } from "react";
import Admission from "./admission";
import ScholarshipBlock from "./scholarship/";
import EventsBlock from "./events/";
import AcheivementsBlock from "./achivements/"
import PlacementsBlock from "./placements/";
import FAQBlock from "./faq/";



export default function Content(props) {
    let contents = [{ tab: 'Addmission', component: Admission }, { tab: 'Scholarship', component: ScholarshipBlock },
    { tab: 'Events', component: EventsBlock }, { tab: 'Achievements', component: AcheivementsBlock },
    { tab: 'Placements', component: PlacementsBlock }, { tab: 'FAQ', component: FAQBlock },]
    return <>
        {
            contents.map(data => props.tab == data.tab && <data.component {...props} />)
        }
    </>
}