import React, { useState ,useEffect} from "react";
import RolesForm from "./roles_form";
import RolesBlock from "./roles_blocks";
import { errorMsg } from "../../../utils";
import { connect } from 'react-redux';
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { ToastContainer, toast } from 'react-toastify';



 function Roles(props) {
  const [form ,setForm]=useState(false)
  const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([]);
    let [mdata, setMdata] = useState({})
    const [selected, setselected]=useState(0)
    const [branches ,setBranches]=useState([{ id: "1", value: "Pune Branch 1" }, { id: "2", value: "Pune Branch 2" }, { id: "3", value: "Pune Branch 3" }, { id: "4", value: "Pune Branch 4" }])

   
  useEffect(() => {
    fetchData();
     setselected(0);
  }, [])



  const fetchData = () => {

    setLoading(true)
    let request = {
        url: `role/all?uid=${props.up_id}`,
        headers: {
           'Authorization': `Bearer ${props.loginToken}`
        },
     }
     api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
     }).catch((err) => {
        setLoading(false)
         if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
     })
}



    return (
        <> 
        {loading && <Loader />}
        {
            form==false ?
            <RolesBlock rdata={rdata}  setRdata={setRdata} fetchData={fetchData} setselected={setselected} setMdata={setMdata}  setForm={setForm}/>:
            <RolesForm selected={selected}  mdata={mdata} fetchData={fetchData} setselected={setselected} setForm={setForm} branches={branches}/>
              
        }
         </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken ,up_id: state.auth.up_id})

export default connect(mapStateToProps)(Roles)