import React, { useState } from "react";
import '../../css/superAdmin/SuperAdmincss.css'
import Data from "./data";

function ReviewPopup(props) {

console.log(props.setReviewData,"setReviewData")

    return (

        <>
            <div className="sa-popupEnquiry">

                <div className="sa-popup" style={{ width: '45%' }}>

                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub">
                            <p className="">Reviewer Details</p></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>Reviewer Name : {props.setReviewData.stud_nm} </div>
                        <div style={{ marginLeft: '50px' }}>Review On :  {props.setReviewData.u_date}  </div>
                    </div>

                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub">
                            <p className="">Review Details</p></div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span>Overall</span>
                                <span style={{marginLeft:'10px'}}>
                                {Array.from(Array(props.setReviewData.overall).keys()).map((data)=><i class="fa fa-star"></i>)}
                                 {/* {props.setReviewData.overall} */}
                    
                                </span>

                            </div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span>Faculty</span>
                                <span >
                                {Array.from(Array(props.setReviewData.faculty).keys()).map((data)=><i class="fa fa-star"></i>)}
                                </span></div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span>Academics</span>
                                <span >
                                {Array.from(Array(props.setReviewData.academics).keys()).map((data)=><i class="fa fa-star"></i>)}
                                </span></div>
                        </div>
                        <div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span>Infrastructure</span>
                                <span >
                                {Array.from(Array(props.setReviewData.infra).keys()).map((data)=><i class="fa fa-star"></i>)}
                                </span></div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span>Placements</span>
                                <span>
                                {Array.from(Array(props.setReviewData.placements).keys()).map((data)=><i class="fa fa-star"></i>)}
                                </span></div>
                            <div style={{display:'flex',justifyContent: 'space-between'}}>
                                <span> Study Material </span>
                                <span >
                                {Array.from(Array(props.setReviewData.std_mat).keys()).map((data)=><i class="fa fa-star"></i>)}
                                </span></div>
                        </div>
                    </div>


                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub">
                            <p className="">Review Description</p></div>
                    </div>

                    <textarea id="w3review" name="w3review" rows="2" cols="40" value={props.setReviewData.desc}>

                    </textarea>






                    <div className="Super-admin-buttons">
                        <button className="Super-admin-cancel-button" onClick={() => props.setPopup(false)} >Cancel</button>
                        <button className="Super-admin-save-button" onClick={()=>{props.Delete(props.setReviewData.id)}}>Delete Rating</button>
                    </div>

                </div>
            </div>
        </>
    )

}

export default ReviewPopup
