import React, { useState } from "react";
import './admission.css'
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';


function AcceptRejectPopup(props) {
  const [check,setCheck] = useState(false)
  const [message,setMessage] = useState("")
  const [loading, setLoading] = useState(false)


  const SendNotification = () => {
   
      props.setLoading(true)
      
      let request = {
        url: `website/student/applications/${props.addId}/application-status/${props.AccRej=="A"?"4":"2"}?uid=${props.up_id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          message:message
        }
      }
      api.putOther(request).then(data => {
        props.setLoading(false)
        props.setAccept(false)
        toast.success(data.data.result)
       
      }).catch((err) => {
        props.setLoading(false)
        if(err.response.status == 401){
          props.dispatch({ type: 'LOGOUT' })
        }else{
          toast.error(errorMsg(err))
          props.setAccept(false)

        } 
      })
  }


  return (
    <div className="backdrop_at_all_transparent" >
    <div  id="changeModal">
          <div class="modal-dialog" >
            <div class="modal-content">

            <div style={{padding:'10px'}}>
              <div class="display-v">
                <h5 class="modal-title">Confirm Your Action</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>props.setAccept(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="p-16-1">
                <p class="bold_settings">

                  Are you sure you want to {props.AccRej=="A"?"Accept":"Reject"} the application ?</p>
                <p class="black_settings-v">Application status can be changed at any point of time in future</p>
                <div class="flex-v mt-16">
                  <div><label class="checkbox-v"><span class="checkbox__input"><input type="checkbox" name="checked"
                    checked={check}  onClick={()=>setCheck(!check)}/><span class="checkbox__control"><svg viewBox="0 0 24 24" aria-hidden="true"
                      focusable="false">
                      <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                    </svg></span></span></label>
                  </div>
                  <div>
                    <p class="bold_settings">Message to Student </p>
                    <p class="black_settings-v">
                      Students will be notified with below message</p>
                  </div>

                </div>
                {check==true && <textarea class="admission-input-v" placeholder="Enter Message" onChange={(e)=>setMessage(e.target.value)}></textarea>}
                
              </div>

              <div class="display-v end">

                <button type="button" class="white-button-v" data-dismiss="modal" onClick={()=>props.setAccept(false)}>Close</button>
                <button type="button" class="ml-16-v blue-button-v" onClick={()=>SendNotification()}>Confirm</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(AcceptRejectPopup)