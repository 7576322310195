import React, { useEffect, useState } from "react";
import Delete from '../../../../components/delete'
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../../../utils'
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'moment';
import AluminiForm from "./alumini_form";
import ProfileHeader from "../profile_header";


export function AluminiBlock(props) {
  const [deletepopup, setdelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [id, setid] = useState("")
  let standard = localStorage.getItem("stnadard")
  let educator = localStorage.getItem("educator")

  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = () => {
    if (props.prof_id == "") {
      toast.error("Please Add Basic Details First.")
      setLoading(false)
    }else if(standard=='false' && educator=='false'){
      toast.error("Please Add Standard and Educator.")
    }else if(educator=='false'){
      toast.error("Please Add Educator")
    }else {
      setLoading(true)
      let request = {
        url: `alumni/all?inst_id=${props.prof_id}&pageOffset=1&pageSize=10&isAll=false `,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${props.loginToken}`
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const deleteAlumni = () => {
    setdelete(false)
    let request = {
      url: `alumni/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Alumni Deleted successfully")
      setRdata(rdata.filter((item3) => item3.id != id))
      setid("")
    }).catch((err) => {
      setid("")
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  const ShowonProfile = (id, status) => {
    setLoading(true)
    let request = {
      url: `inst/setSop`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
      data: {
        id: id,
        module: "alumni",
        "is_sop": status == true ? false : true
      }
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success("Show On Profile Updated successfully")
      fetchData()
    }).catch((err) => {
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })

  }


  return (
    <>
      {loading && <Loader />}
      <ProfileHeader count={props.count} setCount={props.setCount}
        setButton={props.setButton}
        setEcount={props.setEcount}
        setEdit={props.setEdit}
        showform={showform}
      />

      {showform == false && <>
        <div class="box-section1">
          <div>Our Glorious Alumnus</div>
          <div><button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add Alumni</button></div>
        </div>

        <div class="box-educator">

          {rdata.map((item,key) => {
            return (
              <div key={key}>
                <div class="display mb-8">
                  <div class="flex">

                    <img src={item.p_ph} class="profile_pic" />
                    <div class="name-div">
                      <p>{item.name}</p>
                      <span>{Moment(item.c_date).format('DD-MM-YYYY')}</span>
                    </div>
                  </div>
                </div>
                <div class="line-v-grey">
                </div>
                <div class="flex">
                  <div class="flex-a">
                    <span>Show on Profile</span>
                    <label class="switch1">
                      <input type="checkbox" checked={item.s_on_prof == true ? true : false}
                        onClick={() => ShowonProfile(item.id, item.s_on_prof)}
                      />
                      <span class="slider1 round"></span>

                    </label>
                  </div>
                  <div class="ml-a">
                    <button class="editbutton" onClick={() => { setSelected(item.id); setshowform(true); }}> Edit</button>
                    <button class="viewbutton ml16" onClick={() => window.alert("Will be launching soon!!")}>View</button>
                    <button class="deletebutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                  </div>
                </div>
              </div>

            )
          })
          }
        </div>
      </>
      }

      {deletepopup == true &&
        <Delete delete={deleteAlumni} setdelete={setdelete} />
      }


      {
        showform == true &&
        <AluminiForm setSelected={setSelected} selected={selected} data={rdata.find(data => data.id == selected)}
          showform={showform} setshowform={setshowform}
          fetchData={fetchData} standard={standard} educator={educator}
        />
      }


    </>
  )

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(AluminiBlock)