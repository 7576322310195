import React, { useState } from 'react'
import deleteb from '../../assets/img/header/delete.svg'
import eye from '../../assets/img/header/eye.svg'
import ReviewPopup from './popupView'



function Data(props) {
    


    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr>
                        <th>S.No</th>
                        <th>Rid</th>
                        <th>Reviewer Name</th>
                        <th>School Name</th>
                        <th>Date/Time</th>
                        <th>Overall Rating</th>
                        <th>Reported</th>
                        <th>Actions</th>
                    </tr>

                  
                    {
                        props.rdata.map((data, key) => 
                        <tr >
                        <td>{key+1}</td>
                        <td>{data.display_id}</td>
                        <td>{data.stud_nm}</td>
                        <td>{data.i_p_nm}</td>
                        <td>{data.u_date}</td>
                        <td>{data.overall}</td>
                        <td>{data.reported==false?"NO":"YES"}</td>
                        <td>
                            <img src={eye} onClick={()=>
                               { props.getReviewbyId(data.id);
                                }} style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
                            <img src={deleteb} onClick={()=>props.Delete(data.id)}
                            style={{ width: "20px", height: "20px", marginRight: "5px", cursor: "pointer" }} />
                        </td>

                    </tr>
                        
                        )
                    }
                </table>
            </div>

            {props.popup &&
                <ReviewPopup  setPopup={props.setPopup}
                setReviewData={props.setReviewData} Delete={props.Delete}
                />
            }


        </>
    );
}

export default Data;