import React, { useState, useEffect } from "react";
import Delete from '../../../../../../components/delete'
import dropdown from '../../../../../../assets/img/blue_dropdown.svg'
import api from '../../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../../utils";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../../../../components/loader'
import unlink from '../../../../../../assets/img/unlink.svg'

function FAQBlock(props) {
  const [deletepopup, setdelete] = useState(false)
  const [loading, setLoading] = useState(true)
  const [id,setid]=useState("")
  const [idd,setidd]=useState("")
  const [show,setShow]=useState(false)
    

  const deletefaq=()=>{
    setdelete(false)
    props.setLoading(true)
    let request = {
      url: `faq/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
       toast.success("FAQ Deleted successfully")
      //  props.setsetfaq(props.rdata.filter((item3) => item3.id != id))
      setdelete(false)
      // props.setRdata(props.rdata.filter((item3) => item3.id != id))
      setid("")
      props.fetchDataFaq()
    }).catch((err) => {
      props.setLoading(false)
      setid("")
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    
    })

  }

  const deletefaqcategory=()=>{
    setdelete(false)
    props.setLoading(true)
    let request = {
      url: `faq/catg/${idd}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      props.setLoading(false)
       toast.success("Category Deleted successfully")
       props.setRdata(props.rdata.filter((item3) => item3.id != id))
       setidd("")
       props.fetchData()
    }).catch((err) => {
      props.setLoading(false)
      setid("")
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    
    })

  }

  console.log( deletepopup,"deletepopup")

  
  return (
    <>
      <div class="height-adjust">
        <div class="box-educator h-a">
          {props.rdata.map((item,key) => {
            return (
              <div  key={key}>
                <div class="display mb-8 mr-70">

                  <div class="name-div">
                    <p>{item.name} </p>
                    <div class="flex-a added-by">
                      <p>Position :</p>
                      <p class="position_number">{item.position}</p>
                    </div>
                  </div>
                </div>
                <div class="line-v-grey">
                </div>
                <div class="flex">
                  <div class="ml-a">
                    <button class="editbutton" onClick={() => { props.setselected(item.id); props.setMdata(item); props.setForm(true) }}> Edit</button>
                    <button class="viewbutton ml16" onClick={()=>window.alert("Will be launching soon!!")}>View</button>
                    <button class="deletebutton ml16" onClick={() =>{ setdelete(true);setidd(item.id);props.setType("Category")}}>Delete</button>
                  </div>
                </div>
              </div>
            )
          })
          }

        </div>
        <div class="view-all">
          <div class="view-line"></div>
          <p>View All Categories <img src={dropdown} /></p>
          <div class="view-line"></div>
        </div>
        <div class="box-section1">
          <div>FAQ</div>
          <div><button class="blue_button" onClick={() => { props.setType("Faq"); props.setselected(0); props.setForm(true) }} >Add FAQ</button></div>
        </div>
        <div class="box-educator h-a w-b-100">
 
  
        {props.faq.map((item,key)=>{
              return(
                <div  key={key}>
                <div class="display mb-8 mr-70">
      
                  <div class="name-div">
                    <p>{item.question} </p>
                  </div>
      
                </div>
                <div class="blue_up" onClick={()=>setShow(!show)}>
                  Answer <img src={dropdown} />
                </div>
                    {
                      show==true &&
                      <div class="answer-text">
                      {item.ans}
                    </div>
                    }
              
      
                <div class="line-v-grey">
                </div>
                <div class="flex">
      
                  <div class="ml-a">
                    <button class="editbutton" onClick={()=>{ props.setType("Faq"); props.setselected(item.id); props.setForm(true);props.setMdata(item)}}> Edit</button>
      
                    <button class="deletebutton ml16"  onClick={() => {setdelete(true);setid(item.id);props.setType("Faq")}}>Delete</button>
                  </div>
                </div>
              </div>
              )
        })
         }
        </div>
      </div>
      {deletepopup == true &&
       (  props.type=="Faq"?
        <Delete delete={deletefaq} setdelete={setdelete}  />
        :
        <Delete delete={deletefaqcategory} setdelete={setdelete}  />)
      }
    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(FAQBlock)