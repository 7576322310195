import React, { useState, useEffect } from "react";
import LinkForm from "./link_form";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import Educatorheader from "../../addEducators/educator_header";



export function LinkBlock(props) {
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    fetchData();
    setSelected(0);
  }, [])



  const fetchData = () => {
    if (props.eduId == "") {
      toast.error("Please add Basic Details first.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/link/all?e_id=${props.eduId}&pageOffset=1&pageSize=10&isAll=false`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }






  return (<>
    {loading && <Loader />}

    <Educatorheader
      count={props.count} setCount={props.setCount}
      setButton={props.setButton}
      setEcount={props.setEcount}
      showform={showform}
    />

    {showform == false && <div>
      <div class="box-view3">
        <div class="flex-a display">
          <div class=" main-shadow">
            <ul class="nav ">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#one">Your Requests</a>
              </li>
            </ul>

          </div>
          <button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Link Your Profile</button>
        </div>

        <div class="tab-content">
          <div id="one" class="tab-pane active">
            <div class="grey-head">
              <div>Name of Institute/School</div>
              <div>Submitted on</div>
              <div>Status</div>
            </div>
            {rdata.map((item, key) => {
              return (
                <div key = {key} class="white-body">
                  <div>{item.i_p_name}</div>
                  <div>{Moment(item.c_date).format('DD-MM-YYYY')}</div>
                  <div>{item.status}</div>
                </div>
              )
            })

            }
          </div>

          <div id="two" class="tab-pane">
            <div class="grey-head">
              <div>Name of Institute/School</div>
              <div>Submitted on</div>
              <div>Status</div>
              <div>
                Action
              </div>
            </div>
            <div class="white-body">
              <div>Long Name of Institute/School Pvt Ltd</div>
              <div>11 Aug 2021 4.44pm</div>
              <div>Pending</div>
              <div>
                <button class="acceptbutton">Accept</button>
                <button class="rejectbutton ml16">Reject</button>
              </div>
            </div>
            <div class="white-body">
              <div>Long Name of Institute/School Pvt Ltd</div>
              <div>11 Aug 2021 4.44pm</div>
              <div>
                Accepted
              </div>
              <div>
                <button class="unlinkbutton">Remove</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    }
    {
      showform == true &&
      <LinkForm setSelected={setSelected} selected={selected} data={rdata.find(data => data.id == selected)}
        showform={showform} setshowform={setshowform} eduId={props.eduId} fetchData={fetchData}

      />
    }
  </>)
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken })

export default connect(mapStateToProps)(LinkBlock)