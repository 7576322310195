import React, { useState } from 'react'



function Data(props) {


    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Uid</th>
                        <th>Name</th>
                        <th>Added By</th>
                        <th>Location</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                        <th>Added On</th>
                        <th>Status</th>
                        <th>KYC</th>
                        <th>Last Updated </th>
                        <th>Last Updated By</th>
                    </tr>


                    {
                        props.rdata.map((data, key) => <tr key={key} >
                            <td >{data.disp_id}</td>
                            <td>{data.nm}</td>
                            <td>{data.c_by}</td>
                            <td>{data?.addr ?? "-"}</td>
                            <td>{data.em_id}</td>
                            <td>{data.m_no}</td>
                            <td>{data.c_date.split(" ", 1)}</td>
                            <td>{data.status ?? "-"}</td>
                            <td>{data.k_status}</td>
                            <td>{data.u_date.split(" ", 1)}</td>
                            <td>{data.u_by}</td>
                        </tr>)
                    }
                </table>


            </div>



        </>
    );
}

export default Data;