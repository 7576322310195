import React, { useState } from 'react'
import edit from '../../assets/img/header/edit.svg'
import deleteb from '../../assets/img/header/delete.svg'


function Data(props) {


    return (
        <>
            <div className="sa-tableLeads">
                <table id="super-admin">
                    <tr >
                        <th >Title</th>
                        <th>Description</th>
                        <th>Link</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th>Action</th>
                    </tr>

                    {
                        props.rdata.map((data,key) => <tr key={key} >
                            <td >{data.title}</td>
                            <td>{data.desc}</td>
                            <td>{data?.links && data.links.join(', ')}</td>
                            <td>{data?.addr ?? "-"}</td>
                            <td>{data?.addr ?? "-"}</td>
                            <td>
                            <img src={edit} style={{ width: "20px", height: "20px",marginRight:"5px" ,cursor:"pointer"}}  onClick={() => {props.setSelected(data.id); window.scrollTo(0, 0)}}/>
                            <img src={deleteb} onClick={()=>{props.setdelete(true); props.setId(data.id)
                            props.setTitle("")
                            props.setGetvideolink([])
                            props.setvideolink([])
                            props.setdesc("")
                            }} style={{ width: "20px", height: "20px" ,marginRight:"5px",cursor:"pointer" }}/>
                            <label class="switch1" >
                                <input type="checkbox"   checked={data.is_active == true ? true : false}
                                 onChange={()=>{props.ShowonProfile(data.id,data.is_active)}}/>
                                <span class="slider1 round"></span>
                            </label>
                            </td> 

                        </tr>)
                    }
                </table>


            </div>



        </>
    );
}

export default Data;