import React, { useEffect, useState } from "react";
import '../../css/superAdmin/mastersidebar.css'
import { baseurl } from '../../utils'
import List from './list'
import api from '../../api/api'
import config from '../../config'
import masters from '../../masters'


const mdata = masters[config.env];


function MasterSidebar() {
  const [result, setResult] = useState([])

  useEffect(() => {
    setResult(mdata)
  }, [])


  return (
    <>
      <div className="sa-mastersidebar">
        <ul>
          {result.map((objLink, i) => <List key={i} name={objLink.name} url={objLink.path} />)}
        </ul>
      </div>

    </>
  )
}

export default MasterSidebar;