import React, { useState, useEffect } from 'react'
import LeadNavBar from '../enquires/navbar'
import EnquiresCoaching from '../enquires/coaching/index'
import EnquiresEducator from '../enquires/educator/index'
import EnquiresSchool from '../enquires/coaching/index'
import Loader from '../../components/loader'
import Popup from './enquire_popup'
import { errorMsg } from '../../utils';
import api from '../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import Operate from './operate'


function Enquires(props) {
    const [selectedTab, setSelectedTab] = useState("School")
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const [rdata, setRdata] = useState([]);
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        // fetchData();
    }, [])

    const fetchData = () => {

        setLoading(true)
        let request = {
            url: `/todos/1`,
            headers: {
                'Authorization': ""
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            // setRdata(data.data.result)
            // setRdata([{ id: 1, title: "Rohan Rai", Email: "Rohan@rai.com", Mobile: "545444", Leads: "25", EnquiryType: "Ad Related", Generated0n: "25Dec", Status: "25", lub: "User Name", lut: "2:50pm", },
            // { id: 2, title: "Mohan Rai", Email: "Mohan@rai.com", Mobile: "545444", Leads: "25", EnquiryType: "Ad Related", Generated0n: "25Dec", Status: "25", lub: "User Name", lut: "2:50pm", }])
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                // props.dispatch({ type: 'LOGOUT' })
            } else {
                // toast.error(errorMsg(err))
            }
        })
    }


    return (
        <>
            {loading && <Loader />}
            <div className="sa-leadsss">

                <LeadNavBar
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />

                <Operate fetchData={fetchData} setLoading={setLoading} selected={selected} setSelected={setSelected} value={search} onChange={setSearch} data={rdata.find(data => data.id == selected)} />

                {selectedTab == "School" &&
                    <EnquiresSchool rdata={rdata} />
                }

                {selectedTab == "Coaching" &&
                    <EnquiresCoaching rdata={rdata} />
                }

                {selectedTab == "Educator" &&
                    <EnquiresEducator rdata={rdata} />
                }


            </div>
        </>
    )
}
export default Enquires