import React, { useState } from "react";
import too from '../../../../assets/img/tootip.svg'
import UnlinkPopup from "../../profile/popups/unlink";
import FilterPopup from "../../profile/popups/filter"
import { errorMsg } from "../../../../utils";
import { connect } from "react-redux";
import api from "../../../../api/api";
import { toast } from "react-toastify";
import Moment from 'moment';
import Loader from "../../../../components/loader";


export function EducatorBlock(props) {

    const [tab, settab] = useState("Educator")
    const [unlink, setUnlink] = useState(false)
    const [filter, setFilter] = useState(false)
    const [id, setid] = useState("")
    const [sop, setSOP] = useState(false)
    const [status, setStatus] = useState("")
    const [search, setSearch] = useState("")


    const EducatorUnlink = () => {
        props.setLoading(true)
        let request = {
            url: `educator/link/updateStatus `,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                e_id: id,
                i_p_id: props.prof_id,
                status: "Unlink"

            }
        }
        api.patch(request).then(data => {
            props.setLoading(false)
            toast.success("Educator Delete successfully")
            setUnlink(false)
            props.setRdata(props.rdata.filter((item3) => item3.id != id))
            setid("")
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    const ShowonProfile = (id, status) => {
        setSOP(sop => (!sop));
        props.setLoading(true)
        let request = {
            url: `inst/setSop`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                id: id,
                module: "educator",
                is_sop: status == true ? false : true
            }
        }
        api.putOther(request).then(data => {
            props.setLoading(false)
            toast.success("Show On Profile Updated successfully")
            props.fetchData()
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    const EducatorStatus = (id, statuss) => {
        props.setLoading(true)
        let request = {
            url: `educator/link/updateStatus`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: {
                e_id: id,
                i_p_id: props.prof_id,
                status: statuss
            }
        }
        api.patch(request).then(data => {
            props.setLoading(false)
            toast.success(" Profile Updated successfully")
            props.fetchData()
            props.fetchrequest()
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    return (
        <><div class="box-educator1 mob-v">
            <div><button class={tab == "Educator" ? "tags-e selected-tag" : "tags-e"} onClick={() => { settab("Educator") }}>Our Educators</button>
                <div class="tooltip1 mr16"> <button class="filter "><img src={too} /></button>
                    <span class="tooltiptext1">Educators that are working in your institute.</span>
                </div>
                <button class={tab == "Request" ? "tags-e selected-tag" : "tags-e"} onClick={() => { settab("Request") }}>Requests</button>
                <div class="tooltip1 "> <button class="filter "><img src={too} /></button>
                    <span class="tooltiptext1 large">Educators that are Interested in joining your institute. Once the
                        request is accepted, same educator will be reflected under Educators tab on Proctur’s Discovery
                        .</span>
                </div>
            </div>
            <div>
                {/* <button class="filter mr16" id="show-sort"> <img src={sort} /></button>
                <div class="sort-div" id="div-sort">
                    <div>
                        <a href="#">Alphabetical </a>
                    </div>
                    <div>
                        <a href="#">Newest </a>
                    </div>
                    <div>
                        <a href="#">Oldest</a>
                    </div>
                </div>

                <button class="filter mr16" onClick={() => setFilter(true)}> <img
                    src={filters} /></button> */}
                <input type="text" placeholder="Search" class="search mr16" value={search} onChange={(e) => setSearch(e.target.value)} />
                <button class="blue_button" onClick={() => props.setform(true)}>Add Educator</button>
            </div>
        </div><div class="box-educator">

                {tab == "Educator" && props.rdata.map((item, key) => <> 
                    {(item.e_name.toLowerCase().indexOf(search.toLowerCase()) != -1
                    ) && <>
                            <div key = {key}>
                                <div class="display mb-8">
                                    <div class="flex">
                                        <img src={item.ph_url} class="profile_pic" />
                                        <div class="name-div">
                                            <p>{item.e_name}</p>
                                            <span>{Moment(item.c_date).format('DD-MM-YYYY')}</span>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <p class="proctur-tag">Proctur</p>
                                    </div> */}
                                </div>
                                <div class="display added-by">
                                    <p>Added By :</p>
                                    <p>Proctur</p>
                                </div>
                                <div class="line-v-grey">
                                </div>
                                <div class="flex">
                                    <div class="flex-a">
                                        <span>Show on Profile</span>
                                        <label class="switch1">
                                            <input type="checkbox" checked={item.s_on_prof == true ? true : false}
                                                onClick={() => ShowonProfile(item.id, item.s_on_prof)}
                                            />
                                            <span class="slider1 round"></span>

                                        </label>
                                    </div>
                                    <div class="ml-a">
                                        <button class="editbutton" onClick={() => { props.setSelected(item.id); props.setform(true); }}> Edit</button>
                                        <button class="viewbutton ml16" onClick={() => window.alert("Will be launching soon!!")}>View</button>
                                        <button class="unlinkbutton ml16" onClick={() => { setUnlink(true); setid(item.id) }}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>)}

                {tab == "Request" &&
                    props.Mdata.map((item, key) => <> {(item.ed_name.toLowerCase().indexOf(search.toLowerCase()) != -1
                    ) && <>
                            <div >
                                <div class="display mb-8">
                                    <div class="flex">
                                        <img src={item.ed_url} class="profile_pic" />
                                        <div class="name-div">
                                            <p>{item.ed_name}</p>
                                            <span>{Moment(item.c_date).format('DD-MM-YYYY')}</span>
                                        </div>
                                    </div>
                                    .
                                </div>
                                <div class="display added-by">
                                    <p>Added By :</p>
                                    <p>{item.ed_name}</p>
                                </div>

                                <div class="line-v-grey">
                                </div>
                                <div class="flex">
                                    <div class="ml-a">
                                        <button class="viewbutton ml16" onClick={() => window.alert("Will be launching soon!!")}>View</button>
                                        <button class="acceptbutton ml16" onClick={() => { setStatus("Accepted"); EducatorStatus(item.e_id, "Accepted") }}>Accept</button>
                                        <button class="rejectbutton ml16" onClick={() => { setStatus("Rejected"); EducatorStatus(item.e_id, "Rejected") }}>Reject</button>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    </>)}
            </div>

            {
                unlink == true &&
                <UnlinkPopup setUnlink={setUnlink} Unlinkk={EducatorUnlink} />
            }


            {filter == true &&
                <FilterPopup setFilter={setFilter} />
            }
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(EducatorBlock)