import React, { useState } from "react";
import '../../css/superAdmin/SuperAdmincss.css'

function Popup(props) {
    

    return (

        <>
            <div className="sa-popupEnquiry">

                <div className="sa-popup">

                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub">
                            <p className="">Enquiry Description</p></div>
                    </div>
                    <textarea id="w3review" name="w3review" rows="2" cols="40">

                    </textarea>

                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub">
                            <p className="">Enter Call Discussion Detail</p></div>
                    </div>

                    <textarea id="w3review" name="w3review" rows="2" cols="40">

                    </textarea>

                    <div className="sa-Standard-Subjects">
                        <div className="sa-sub2">
                            <p className="">Status For Enquiry</p></div>
                    </div>

                        
                <div >
                <section className="sa-wrapper3">
                    <select name="Master Category" id="Master Category" className="Sa-input3"  style={{ fontSize: "13px" }} >
                        <option value="">Select Status</option>
                        <option value="Open">Open</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Closed">Closed</option>
                    </select>
                    </section>
                </div>

                <div className="Super-admin-buttons">
                            <button className="Super-admin-cancel-button" onClick={()=>props.setShowPopup(false)} >Cancel</button>
                            <button className="Super-admin-save-button" >Save</button>
                        </div>

                </div>
            </div>
        </>
    )

}

export default Popup
