import React, { useState, useEffect } from "react";
import UserBlock from "./user_block";
import UserForm from "./user_form";
import { errorMsg } from "../../../../utils";
import { connect } from 'react-redux';
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';

function User(props) {
    const [form, setForm] = useState(false)
    const [loading, setLoading] = useState(true)
    const [rdata, setRdata] = useState([]);
    let [mdata, setMdata] = useState({})
    const [selected, setselected] = useState(0)
    const [roles, setRoles] = useState([])
    const [branches, setBranches] = useState([{ id: "1", value: "Pune Branch 1" }, { id: "2", value: "Pune Branch 2" }, { id: "3", value: "Pune Branch 3" }, { id: "4", value: "Pune Branch 4" }])



    useEffect(() => {
        fetchData();
        getRole();
        setselected(0);
    }, [])


    const getRole = () => {

        setLoading(true)
        let request = {
            url: `role/all?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRoles(data.data.result.response)
            //   setRdata([{id:1,name:"Manager",time:"11-9-2021 04:40 PM "},{id:2,name:"Teacher",time:"11-9-2021 04:40 PM "}])
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `user/all?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result.response)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }



    return (

        <>
            {loading && <Loader />}
            {
                form == false ?
                    <UserBlock setForm={setForm} rdata={rdata} setRdata={setRdata} fetchData={fetchData} setselected={setselected} setMdata={setMdata} /> :
                    <UserForm setForm={setForm} roles={roles} branches={branches} fetchData={fetchData} selected={selected} mdata={mdata} setselected={setselected} />
            }


        </>
    )
}
const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(User)