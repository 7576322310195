import React,{useState} from "react";
import cross_icon from '../../../assets/home/cross_icon.svg'
import searchh from '../../../assets/home/search_icon.png'

function LeadsData(props) {
    const [check,setCheck]=useState("")
    

    return (<>
        <div>

            {/* <table className="cn_table1" style={{ margin: "0" }}>
    <tr>
        <th  style={{ width: "15%" }}><div style={{}}>
        <input type="checkbox"  style={{ width: "15%" }}
            checked={this.state.check_box_selected_all && "on"}
            onClick={() => { this.setState({ check_box_selected_all: true }) }}
        ></input> &nbsp;&nbsp;Select All</div> </th>
        <th style={{ width: "10%" }}>Sr. No.</th>
        <th style={{ width: "25%" }}>Name </th>
        <th style={{ width: "25%" }}>Location</th>
       
    </tr>
    {
        this.state.table_data2.map((el,index) =>

            <tr>
                {this.state.check_box_selected_all ? <td>
                    <input type="checkbox" checked="on"  style={{ width: "15%" }}></input></td>
                    : <td ><input type="checkbox"   style={{ width: "15%" }}></input></td>
                }
                <td>{index+1}</td>
                <td>{el[0]}</td>
                <td>{el[1]}</td>
 

            </tr>

        )
    }


</table> */}

            <div className="sa-popupEnquiry">
            <div className="sa-popup">
                <div style={{display:"flex" ,justifyContent:"space-between"}}>
                <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{padding:"0",marginLeft:"15px"}}>
                <div className="sa-searchhh">
                    <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                    <input className="Super-admin-Search_Input" type="text"
                        placeholder="Search" style={{ outline: "0" }} />
                </div>
            </div>
            </div>
            <div>
            <img src={cross_icon} onClick={()=>props.setClicked(false)} style={{cursor:"pointer"}}/>
            </div>

                </div>
                <div className="sa-tableLeads">
                    <table id="super-admin">
                        <tr>
                            <th>
                            <input type="checkbox"  style={{marginRight:"10px"}}
                            checked={check  && "on"}
                            onClick={()=>setCheck(!check)}
                            />
                                
                             Select All</th>
                            <th>SR No</th>
                            <th>Name</th>
                            <th>Phone No</th>
                            <th>Location</th>
                        </tr>
                        <tr>
                           
                                <td>  
                                    {check==true?
                                    <input type="checkbox" checked="on" />
                                    : <input type="checkbox"  />
}</td>
                                <td>105</td>
                                <td >Raj</td>
                                <td >123654789</td>
                                <td >Delhi</td>

                                {/* <td><button onClick={() => props.setSelected(data.id)}>EDIT</button></td> */}
                            </tr>
                        



                    </table>

</div>
                </div>

            </div>
        </div>
    </>)
}

export default LeadsData