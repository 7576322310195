
import React, { useState, useEffect } from "react";
import Loader from '../../../../components/loader'
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import config from '../../../../config'
import { errorMsg } from '../../../../utils'





function StandardPopup(props) {
    let type = window.location.href.split("?")[1].split("=")[1]
    const [loading, setLoading] = useState(true)
    const [stan, setStan] = useState([])
    const [mastercourse, setMastercourse] = useState([])
    const[masterId,setMasterId] = useState("")
    const [courseName,setCourseName] = useState("")

    useEffect(() => {
        if (type == "school") {
            getStandard();
        } else {
            course();
        }
      }, [])
    
      const saveStandard = () => {
        if(masterId==''){
           toast.error("Please Select Standard.")
        }else{
          setLoading(true)
          let request = {
              url: `inst-standard/clone-standard?existingStandardId=${props.selected}&masterStandardId=${masterId}&uid=${props.up_id}`,
              headers: {
                  'Authorization': `Bearer ${props.loginToken}`
              },
          }
          api.postCustom(request).then(data => {
            setLoading(false)
            toast.success("Standard clone successfully")
            props.setpopup(false)
            props.fetchData()
          }).catch((err) => {
              setLoading(false)
              if (err.response.status == 401) {
                  props.dispatch({ type: 'LOGOUT' })
              } else {
                  toast.error(errorMsg(err))
              }
          })
        }
           
        
    }


    const saveCourse = () => {
      if(masterId==''){
        toast.error("Please Select Category.")
     }else if(courseName==''){
      toast.error("Please enter course name.")
     }else{
      setLoading(true)
      let request = {
          url: `inst-course/clone-course?existingCourseId=${props.selected}&masterCourseId=${masterId}&courseName=${courseName}&uid=${props.up_id}`,
          headers: {
              'Authorization': `Bearer ${props.loginToken}`
          },
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Course clone successfully")
        props.setpopup(false)
        props.fetchCources()
      }).catch((err) => {
          setLoading(false)
          if (err.response.status == 401) {
              props.dispatch({ type: 'LOGOUT' })
          } else {
              toast.error(errorMsg(err))
          }
      })
    }
}

    const course = () => {
        setLoading(true)
        let request = {
          url: `course/all`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.getCustom(request).then(data => {
          setLoading(false)
          setMastercourse(data.data.result)
        }).catch((err) => {
          // console.log("hsjhjh")
          setLoading(false)
          toast.error(errorMsg(err));
        })
      }
    
      const getStandard = () => {
        setLoading(true)
        let request = {
          url: `master-stand/all`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
        }
        api.getCustom(request).then(data => {
          setLoading(false)
          setStan(data.data.result)
        }).catch((err) => {
          setLoading(false)
        })
      }


  return (
    <>
     {loading && <Loader />}
      <div class="modal-backdrop show">
      </div>
      <div class="modal" id="filterModal" style={{ paddingRight: '4px', display: 'block' }}>
        <div class="modal-dialog">
          <div class="modal-content">

            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <div>
                <h4 class="modal-title">Select {type == "school"?'Standard':'Category Course'}</h4>
                <p class="line_purple"></p>
              </div>
              <button type="button" class="close"onClick={()=>props.setpopup(false)}>&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div class="form-section">
                <div>
                  <div class="select-wrapper">
                    <select class="expandale-input" onChange={(e)=>setMasterId(e.target.value)}>
                      <option>Select</option>{
                        type == "school"?
                        stan.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) }):
                        mastercourse.map((item) => { return (<option value={item.entity_id}>{item.name}</option>) })
                      }
                    </select>
                  </div>
                </div>
                {
                  type != "school" &&
                  <div>
                  <label>Add Course Name</label>
                  <input class="expandale-input" contenteditable="true" placeholder="Enter" onChange={(e)=>setCourseName(e.target.value)}  />
                </div>
                }
               
              </div>
             

            </div>

            {/* <!-- Modal footer --> */}
            <div class="modal-footer">
              <button type="button" class="white_button mr16" data-dismiss="modal" onClick={()=>props.setpopup(false)}>Close</button>
              <button type="button" class="blue_button" onClick={()=>
                {if(type == 'school'){
                  saveStandard()
                }else{
                  saveCourse()
                }}}
                >Confirm</button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(StandardPopup)