import React, { useState, useEffect } from "react";
import close from '../../../assets/img/close.svg'


export default function ClassDetails(props) {
  const [standard, setStandard] = useState([])
  const [category, setCategory] = useState([])
  const [subject, setSubject] = useState([])
  const [subjectcat, setSubjectCat] = useState([])



  const setitem = (item) => {
    if (props.classdetails.modeofTution.indexOf(item) == -1) {
      props.setClassDetails({ ...props.classdetails, modeofTution: [...props.classdetails.modeofTution, item] })
    } else {
      props.setClassDetails({ ...props.classdetails, modeofTution: props.classdetails.modeofTution.filter(data => data != item) })
    }
  }

   

  let addSubject = (cat_id, sub_id) => {
    let ind = props.detail.findIndex(data => data.std_id == cat_id);
    if (ind == -1) {
      props.setDetail([...props.detail, { std_id: cat_id, sub_det: [sub_id] }])
    } else {
      let element = props.detail[ind].sub_det;
      if (element.indexOf(sub_id) == -1) {
        element.push(sub_id);
        props.detail[ind].sub_det = element;
        props.setDetail([...props.detail])
      }

    }
  }

  let removeSubject = (cat_id, sub_id) => {
    let ind = props.detail.findIndex(data => data.std_id == cat_id);
    let element = props.detail[ind]?.sub_det ?? []
    if (element.length == 1) {
      props.setDetail(props.detail.filter(data => data.std_id != cat_id))

    } else {
      element = element.filter(data => data != sub_id);
      props.detail[ind].sub_det = element;
      props.setDetail([...props.detail])
    }
  }


  let addSubjectCourses = (cat_id, sub_id) => {
    let ind = props.courseDeatil.findIndex(data => data.crs_id == cat_id);
    if (ind == -1) {
      props.setcourseDetail([...props.courseDeatil, { crs_id: cat_id, sub_det: [sub_id] }])
    } else {
      let element = props.courseDeatil[ind].sub_det;
      if (element.indexOf(sub_id) == -1) {
        element.push(sub_id);
        props.courseDeatil[ind].sub_det = element;
        props.setcourseDetail([...props.courseDeatil])
      }

    }
  }

  let removeSubjectcourses = (cat_id, sub_id) => {
    let ind = props.courseDeatil.findIndex(data => data.crs_id == cat_id);
    let element = props.courseDeatil[ind]?.sub_det ?? []
    if (element.length == 1) {
      props.setcourseDetail(props.courseDeatil.filter(data => data.crs_id != cat_id))

    } else {
      element = element.filter(data => data != sub_id);
      props.courseDeatil[ind].sub_det = element;
      props.setcourseDetail([...props.courseDeatil])
    }
  }



  return (
    <>
      <div>
        <label>Class Details</label>
        <p class="line_purple"></p>
        <div class="flex w-40 mt-16">
          <div>
            <label>Standard<span>*</span></label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" onChange={(e) => { setStandard(e.target.value) }}>
                <option style={{ fontWeight: 'bold' }}>Standard</option>
                {props.Master.standard.map((item,key) =>  <option value={item.entity_id}>{item.name}</option>)}
              </select>
            </div>
            <div class="tags">
              {props.detail.map((item,key) => <><button> {[...props.Master.standard, ...props.Master.category].find(data => data.entity_id == item.std_id)?.name ?? ''} - {item.sub_det.map((data) => <>{[...props.Master.courseSub, ...props.Master.subjects].find(data2 => data == data2.entity_id)?.name ?? ''}<img src={close} onClick={() => removeSubject(item.std_id, data)} /></>)}</button></>)}
            </div>
          </div>
          <div class="mob-top">
            <label>Subjects <span>*</span></label>
            <p class="data-info mt10"></p>
            <div class="select-wrapper">
              <select class="expandale-input w-200" onChange={(e) => { setSubject(e.target.value); addSubject(standard, e.target.value) }}>
                <option>Select</option>
                {props.Master.subjects.map((item,key) => item.master_standard_id == standard && <option value={item.entity_id}>{item.name}</option>)}
              </select>
            </div>
          </div>
        </div>


        <div class="flex mob-v" style={{ marginTop: "40px" }}>
          <div>
            <label>Category <span>*</span></label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" onChange={(e) => { setCategory(e.target.value) }}>
                <option style={{ fontWeight: 'bold' }}>Category</option>
                {props.Master.category.map((item,key) => <option value={item.entity_id}>{item.name}</option>)}
              </select>
            </div>
            <div class="tags">
              {props.courseDeatil.map((item,key) => <><button> {[...props.Master.category].find(data => data.entity_id == item.crs_id)?.name ?? ''} - {item.sub_det.map((data) => <>{[...props.Master.courseSub, ...props.Master.subjects].find(data2 => data == data2.entity_id)?.name ?? ''}<img src={close} onClick={() => removeSubjectcourses(item.crs_id, data)} /></>)}</button></>)}
            </div>
          </div>
          <div class="mob-top">
            <label>Subjects <span>*</span></label>
            <p class="data-info mt10"></p>
            <div class="select-wrapper">
              <select class="expandale-input w-200" onChange={(e) => { setSubjectCat(e.target.value); addSubjectCourses(category, e.target.value) }}>
                <option>Select</option>
                {props.Master.courseSub.map((item,key) => item.mast_course_id == category && <option value={item.entity_id}>{item.name}</option>)}
              </select>
            </div>
          </div>

        </div>

        <div class="flex-a mt-16 space">
          <label>Mode Of Tuitions</label>
          {["Online", "Offline"].map((item,key) => {
             {key=key}
            return (
              <div class="flex-inline ml20">
                <label class="checkbox">
                  <span class="checkbox__input">
                    <input type="checkbox" checked={props.classdetails.modeofTution.indexOf(item) == -1 ? false : true} value={item}
                      onClick={() => setitem(item)} />
                    <span class="checkbox__control">
                      <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                        <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                      </svg>
                    </span>
                  </span>
                </label>
                <span>{item}</span>
              </div>

            )
          })
          }
        </div>

        
        <div class="flex-inline dynamic-ml">
        {["Student Home", "Own Premise"].map((item,key) => {
           {key=key}
            return (
              <div class="flex-a ">
                <label class="checkbox">
                  <span class="checkbox__input">
                    <input type="checkbox" checked={props.classdetails.modeofTution.indexOf(item) == -1 ? false : true} value={item}
                      onClick={() => setitem(item)} />
                    <span class="checkbox__control">
                      <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                        <path fill="none" stroke="currentColor" d="M1.3 12.91l6.37 6.37L22.79 4.59"></path>
                      </svg>
                    </span>
                  </span>
                </label>
                <span>{item}</span>
              </div>
            )
          })
          }
           </div>
          

        <div class="flex-a mt-16">
          <label>Do you give Private Tuitions?</label>
          <div class="flex-a ml20">
            {[{ type: "Yes", id: 1 }, { type: "No", id: 2 }].map((choice, index, selected) => {
              return (<div>
                <input type="radio" class="form-radio" style={{ marginLeft: "10px" }}
                  name="vote"
                  checked={props.classdetails.tution==choice.type}
                  value={choice.type}
                  key={index}
                  onClick={(e) =>  props.setClassDetails({ ...props.classdetails, tution: e.target.value })} />
                <span>{choice.type}</span>
              </div>
              )
            })
            }
          </div>

        </div>
        <div class="mt-16">
          <label>Fee/hr</label>
          <div class="flex">
            <p class="expandale-input fees"></p>
            <input contenteditable="true" class="expandale-input w-200 " placeholder="Enter in ₹" type="number" min={0}  value={props.classdetails.fee} onChange={(e) => props.setClassDetails({ ...props.classdetails, fee: e.target.value })} />
          </div>
        </div>

      </div>


    </>
  )
}