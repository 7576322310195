import React, { useState, useEffect } from "react";
import close from '../../../../../assets/img/close.svg'
import add from '../../../../../assets/img/add.svg'
import api from '../../../../../api/api'
import { connect } from "react-redux";
import pdf from '../../../../../assets/img/pdf.png'
import xls from '../../../../../assets/img/xls.png'
import { errorMsg } from "../../../../../utils";
import Loader from '../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function AcheivementsForms(props) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [year, setYear] = useState("")
  const [conducted, setConducted] = useState("")
  const [value, setvalue] = useState('')
  const [image, setimage] = useState([])
  const [etitle, seteTitle] = useState("")
  const [oimage, setOimage] = useState([])



  useEffect(() => {
    if (props.selected != 0) {
      let id = props.mdata.id
      setLoading(true)
      let request = {
        url: `achv/${id}`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        let mdata = data.data.result;
        console.log(mdata,"props.mdata")
        seteTitle(mdata.name)
        setYear(mdata.year)
        setConducted(mdata.conducted_by)
        setOimage(mdata.files ?? [])
        setvalue(mdata.desc)
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })}
    
    
  }, [props.selected])



  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      var fd = new FormData();
      fd.append("u_data", JSON.stringify({
        name: name,
        desc: value,
        awarded_by: conducted,
        year: year,
        uid: props.up_id,
        i_p_id: props.prof_id
      }));
      // fd.append('file', image);
      for (let index = 0; index < image.length; index++) {
        fd.append('file', image[index]);
      }
      let request = {
        url: `achv/add`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: fd
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Achievement added successfully.")
        props.setselected(0); props.setForm(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const updateValue = () => {
    props.setLoading(true)
    var fd = new FormData();
    fd.append("u_data", JSON.stringify({
      name: etitle,
      desc: value,
      awarded_by: conducted,
      year: year,
      i_p_id:props.prof_id
    }));
    // fd.append('file', image);
    for (let index = 0; index < image.length; index++) {
      fd.append('file', image[index]);
    }

    let request = {
      url: `achv/${props.mdata.id}/update`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: fd
    }
    api.putOther(request).then(data => {
      props.setLoading(false)
      toast.success("Achievement details updated successfully.")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }


 


  const validate = () => {
    if (name === '') {
      toast.error("Please Enter Name")
      return false
    }
     if (year === '') {
      toast.error("Please Enter Year")
      return false
    }
    // if (conducted === '') {
    //   toast.error("Please Enter Conducted By")
    //   return false
    // } 
    // if (image === '') {
    //   toast.error("Please Upload Image")
    //   return false
    // } if (value === '') {
    //   toast.error("Please Enter Details")
    //   return false
    // }
    return true
  }

  const validateImage = (e) => {
    console.log(e.target.files[0].size , "kjngnghg")
    let img = e.target.files[0].size/ 1024
     console.log(img, "size")
    if (img > 500) {
      toast.error("Please upload image of size 500kb only")
      return false
    }
    else return true
  }


  const select_MediaContent = (e) => {
    if(validateImage(e)){
      if([...oimage,...image].length > 4 ){
        toast.error("You are only allowed to upload a maximum of 5 files.")
      }else{
      if (e.target.files.length > 0) {
        let imgs = [];
        for (const [key, value] of Object.entries(e.target.files)) {
          imgs.push(value)
        }
        setimage([...image,...imgs])
        // props.setSocial({ ...props.Social, gallary: imgs })
      }}
    }
    
  }


  const deleteImage = (id) => {
    setLoading(true)
    let request = {
      url: `inst/deleteUploadedFile/${id}`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("Image Deleted successfully")
      setOimage(oimage.filter((item3) => item3.id != id))
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 

    })

  }

  return (

    <>

      {loading && <Loader />}
      <div>
        <div class="form-section">
          <div>
            {props.selected ?
              <p class="heading">Edit Achievement</p> : <p class="heading">Add Achievement</p>}
            <p class="line_purple"></p>
            <p class="data-info mt10">
              You can display your school past recognition, awards or any accreditations to be displayed on your
              profile. .
            </p>
            <label>Name <span>*</span></label>
            {/* <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)} /> */}
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
              <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
            }


          </div>
          <div>
            <div class="display f-wrap">

              <div>
                <label>Date <span>*</span> </label>
                <input type="date" class="expandale-input" onKeyDown={(e) => e.preventDefault()} value={year} onChange={(e) => setYear(e.target.value)} />
              </div>

              <div>
                <label> Awarded By</label>
                <input class="expandale-input" contenteditable="true" placeholder="Enter" value={conducted} onChange={(e) => setConducted(e.target.value)} />
              </div>
              <div>

              </div>
            </div>
          </div>

          <div>

            <div>
              <label>Upload Image </label>
              <p class="line_purple"></p>

              <p class="data-info">Submit your achievement related picture such as trophy, certificate etc.
              </p>
              <div class="flex-p1">
                <input class="expandale-input w-450 choose" type="file" id="myFile"  accept="application/pdf,application/vnd.ms-excel,image/*" multiple onChange={select_MediaContent} disabled={image.length === 5} placeholder="Choose File" /><a href="#"></a>
              </div>
              <p class="data-info">Max size 500kb. Format accepted: jpg, jpeg, img, pdf, word</p>

              <div class="tags-img">
              {oimage.map((item,key) => {
                return (
                  <div  key={key}>
                     {
                      item.f_disp.split(".")[1]=="pdf"?
                      <img src={pdf} />:
                      item.f_disp.split(".")[1]=="xls"?
                      <img src={xls} />:
                      <img src={item.f_url} />
                    }
                    {/* <img src={item.f_url} /> */}
                    <img src={close} onClick={() => { deleteImage(item.id); }} />
                    {/* Once deleted and response is 200 filter the oimage array and remove deleted image on the basis of ID  */}
                  </div>
                )
              })
              }
                {image.map((item,key) => {
                  return (
                    <div  key={key} >
                       {
                      item.name.split(".")[1]=="pdf"?
                      <img src={pdf} />:
                      item.name.split(".")[1]=="xls"?
                      <img src={xls} />:
                      <img src={URL.createObjectURL(item)} />
                    }
                      {/* <img src={URL.createObjectURL(item)} /> */}
                      <img src={close} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} />
                    </div>
                  )
                })
                }
              </div>
            </div>

          </div>

          <div>
            <label>Achievement Details</label>
            {/* <input contenteditable="true" class="expandale-input w-450" placeholder="Enter" value={details} onChange={(e) => setDetails(e.target.value)} /> */}
            <ReactQuill theme="snow" value={value} onChange={setvalue} />
          </div>
          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
              {
                props.selected ?
                  <button class="blue_button" onClick={updateValue}>Update</button> :
                  <button class="blue_button" onClick={()=>{
                    if(props.prof_id==""){
                      toast.error("Please Add Basic Details First.")
                    }else{
                      savedetails()
                    }
                  }}>Save</button>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(AcheivementsForms)