import React, { useState, useEffect } from "react";
import api from '../../../../../api/api'
import { connect } from "react-redux";
import { errorMsg } from "../../../../../utils";
import Loader from '../../../../../components/loader'
import { ToastContainer, toast } from 'react-toastify';

function EventsForms(props) {
  const [name, setName] = useState("")
  const [date, setdate] = useState("")
  const [edate, setedate] = useState("")
  const [mode, setMode] = useState("")
  const [emode, seteMode] = useState("")
  const [loading, setLoading] = useState(false)
  const [conducted, setConducted] = useState("")
  const [econducted, seteConducted] = useState("")
  const [description, setDescription] = useState("")
  const [edescription, seteDescription] = useState("")
  const [etitle, seteTitle] = useState("")



  useEffect(() => {
    if (props.selected != 0) {
      console.log(props.mdata,"props")
      seteTitle(props.mdata.name)
      seteDescription(props.mdata.desc)
      setMode(props.mdata.mode)
      seteConducted(props.mdata.c_by)
      setedate(props.mdata.date)
    }
    return name
  }, [props.selected])




  const savedetails = () => {
    if (validate()) {
      setLoading(true)
      let request = {
        url: `event/add `,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
        data: {
          name: name,
          desc: description,
          date: date,
          mode: mode,
          uid: props.up_id,
          c_by: conducted,
          i_p_id:props.prof_id
        }
      }
      api.postCustom(request).then(data => {
        setLoading(false)
        toast.success("Event Added successfully")
        props.setselected(0); props.setForm(false);
        props.fetchData()
      }).catch((err) => {
        setLoading(false)
          if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
      })
    }
  }

  const updateValue = () => {
    props.setLoading(true)
    let request = {
      url: `event/${props.mdata.id}/update`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: {
        name: etitle,
        desc: edescription,
        date: edate,
        mode: mode,
        c_by: econducted,
        uid: props.up_id,
        i_p_id:props.prof_id
      }
    }
    api.putOther(request).then(data => {
      props.setLoading(false)
      toast.success("Event Updated successfully")
      props.setselected(0); props.setForm(false);
      props.fetchData()
    }).catch((err) => {
      props.setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } 
    })
  }


  const validate = () => {
    if (name === '') {
      toast.error("Please Enter Name")
      return false
    } if (date === '') {
      toast.error("Please Select Date")
      return false
    }
    if (description === '') {
      toast.error("Please Enter Description")
      return false
    }
    return true
  }


  return (

    <>
      {loading && <Loader />}
      <div>
        <div class="form-section">
          <div>
            {props.selected ?
              <p class="heading">Edit Event</p> : <p class="heading">Add Event</p>}
            <p class="line_purple"></p>
            <p class="data-info mt10">
              You can display your schools past events such as annual gatherings, exhibition, sports day etc to be
              displayed on your profile. .
            </p>
            <label>Name <span>*</span></label>
            {/* <input class="expandale-input w-450" placeholder="Enter" onChange={(e) => setName(e.target.value)}></input> */}
            {props.selected ?
              <input class="expandale-input w-450" placeholder="Enter" value={etitle} onChange={(e) => seteTitle(e.target.value)}></input> :
              <input class="expandale-input w-450" placeholder="Enter" value={name} onChange={(e) => setName(e.target.value)}></input>
            }
          </div>
          <div>
            <div class="display f-wrap">

              <div>
                <label>Date <span>*</span> </label>
                {props.selected ?
              <input type="date" class="expandale-input" value={edate} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setedate(e.target.value)} /> :
              <input type="date" class="expandale-input" value={date} onKeyDown={(e) => e.preventDefault()} onChange={(e) => setdate(e.target.value)} />
            }
               
              </div>
              <div>
                <label>Mode</label>
                <div class="select-wrapper">
                  <select class="expandale-input" value={mode} onChange={(e) => setMode(e.target.value)}>
                    <option>Select</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>

              </div>
              <div>
                <label>Conducted By</label>
                {props.selected ?
               <input class="expandale-input" contenteditable="true" placeholder="Enter" value={econducted} onChange={(e) => seteConducted(e.target.value)} /> :
              <input class="expandale-input" contenteditable="true" placeholder="Enter" value={conducted} onChange={(e) => setConducted(e.target.value)} />
            }
                
              
              </div>
            </div>
          </div>

          <div>
            <label>Description <span>*</span></label>
            {props.selected ?
               <input class="expandale-input w-450" placeholder="Enter" value={edescription} onChange={(e) => seteDescription(e.target.value)}></input> :
              <input class="expandale-input w-450" placeholder="Enter" onChange={(e) => setDescription(e.target.value)}></input>
            }
          
          </div>

          <div class="button-section">
            <div></div>
            <div>
              <button class="white_button mr16" onClick={() => { props.setselected(0); props.setForm(false); }} >Cancel</button>
              {
                props.selected ?
                  <button class="blue_button" onClick={updateValue} >Update</button> :
                  <button class="blue_button" onClick={()=>{
                    if(props.prof_id==""){
                      toast.error("Please Add Basic Details First.")
                    }else{
                      savedetails()
                    }
                  }}>Save</button>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(EventsForms)