import React, { useState, useEffect } from "react";
import dwld from '../../assets/home/upload.svg'
import searchh from '../../assets/home/search_icon.png'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { errorMsg } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/loader'
import api from '../../api/api'


function InstituteNavBar(props) {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [uploadFile, setUploadFile] = useState({})
    const [bulkTab, setbulkTab] = useState("SCHOOL")



    const Bulkupload = (temp) => {
        props.setLoading(true)
        var fd = new FormData();
        fd.append("u_data", JSON.stringify({ uid: props.up_id, upld_for: bulkTab }));
        fd.append('file', temp);
        let request = {
            url: `inst/bulk-upload`,
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${props.loginToken}`,
            },
            data: fd
        }
        api.postCustom(request).then(data => {
            props.setLoading(false)
            toast.success("File Uploaded Successfully")
            setUploadFile({})

        }).catch((err) => {
            props.setLoading(false)
            setUploadFile({})
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }




    return (<>
        {/* {loading && <Loader />} */}
        <ToastContainer />
        <div className="sa-LeadsNav">
            <div className="sa-leadsHead2" style={{ marginTop: "10px", width: '25%' }}>

                <span className={props.selectedTab == "School" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { props.setSelectedTab("School"); setbulkTab("SCHOOL") }}>School</span>
                <span className={props.selectedTab == "Coaching" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { props.setSelectedTab("Coaching"); setbulkTab("COACHING") }}>Coaching</span>
                <span className={props.selectedTab == "College" ? "sa-leadsSchool-active" : "sa-leadsSchool"} onClick={() => { props.setSelectedTab("College"); setbulkTab("COLLEGE") }}>College</span>


            </div>

            {props.selectedTab == "Coaching" &&
                <div className="Sa-dropdown2" style={{ marginLeft: "30%" }}>
                    <section className="sa-wrapper" style={{ width: "7rem" }}>
                        <select name="Master Category" id="Master Category" className="Sa-input2" style={{ fontSize: "13px", width: "100px" }} value={props.master} onChange={(e) => { props.setmaster(e.target.value); props.fetchCoachingCategory(props.active2, e.target.value) }}>
                            <option value="0">Category</option>
                            {props.masterCat.map((item) => { return <option value={item.entity_id}>{item.name}</option> })}
                        </select>
                    </section >
                </div>
            }

            <div className="sa-leads">

                <div className="Sa-dropdown2">   <div className="Super-admin-searchhhhhhhhhhh" style={{ padding: "0", marginRight: "10px" }}>
                    <div className="sa-searchhh">
                        <img src={searchh} style={{ height: "18px", width: "18px", marginLeft: "8px", marginRight: "5px" }} />
                        <input className="Super-admin-Search_Input" type="text"
                            placeholder="Search by Name" style={{ outline: "0" }} onChange={(e) => props.setSearch(e.target.value)} />
                    </div>
                </div>
                </div>
                {props.selectedTab == "Coaching" &&
                    <div className="sa-add">
                        <button class="blue_button" onClick={() => {
                            history.push('/institute/profile/?type=institute')
                            props.dispatch({ type: 'BASIC_DETAILS', prof_id: "" })
                        }}  >Add Institute </button>
                    </div>
                }

                {props.selectedTab == "School" &&
                    <div className="sa-add">
                        <button class="blue_button" onClick={() => {
                            history.push('/institute/profile/?type=school')
                            props.dispatch({ type: 'BASIC_DETAILS', prof_id: "" })
                        }}  >Add School </button>
                    </div>
                }


                {props.selectedTab == "College" &&
                    <div className="sa-add">
                        <button class="blue_button" onClick={() => {
                            history.push('/institute/profile/?type=college')
                            props.dispatch({ type: 'BASIC_DETAILS', prof_id: "" })
                        }}  >Add College </button>
                    </div>
                }

                <label htmlFor="photo-upload" >
                    <div >
                        <img src={dwld} style={{ height: "20px", width: "20px", marginRight: "5px" }} />
                    </div>
                    <input id="photo-upload" type="file" accept="application/vnd.ms-excel" style={{ display: "none", visibility: "hidden" }}
                        onChange={(e) => {
                            let temp = e.target.files[0];
                            Bulkupload(temp)
                        }} ></input>
                </label>
                <span >Bulk Upload</span>
            </div>
        </div>


    </>)

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(InstituteNavBar)

