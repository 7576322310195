import React, { useState, useEffect } from "react";
import EditForm from "./formEdit";
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

function CustomiseForm(props) {
  const [formedit, setEdit] = useState(false)
  const [formdata, setfromData] = useState(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(props.formDataSet, "props.formDataSet")
    if (props.formDataSet == true) {
      getFromDetails();
    }
  }, [])

  console.log(formdata, "djhdhshdhj")

  const getFromDetails = () => {
    setLoading(true)
    let request = {
      url: `website/institute/admission-setting/application-procedure-all/${props.settingid}/get`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`
      },
    }
    api.getCustom(request).then(data => {
      setLoading(false)
      setfromData(data.data.result)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      };
    })
  }


  return (
    <>
      {
        formedit == false ? <><section class="middle-top-v bulk-header-v display-v">
          <div>
            <h1 className="Heading-v">Send Admission Process Details</h1>
            <div class="block-v">
              <p class="black_settings-v">You can edit this form as per your preference and send it to Applicants. This will be
                sent on Mobile App as well as on Applicants Email ID.<br />Use our customised admission procedure guide to keep
                your Applicants Updated.</p>
            </div>
          </div>

          <button class="blue-button-v" onClick={() => setEdit(true)}>Edit</button>
        </section><div class="p-16-3">{
          !formdata ?
            <p class="black_settings-v">
              Dear "Student Name", We are pleased to let you know that your application has been accepted.
              In Order to process your application further, you have complete the below our admission process.
              Please go through the details below and feel free to connect with us in case of any queries or doubts.</p> :
            <p class="black_settings-v">{formdata.procedure_message}
            </p>
        }

            <div class="scroll-solve-v">
              <div class="progress_bar-v">
                <div class="selected-v">

                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                  <span>{!formdata ? "Registration" : formdata.procedure_label[0]}</span>
                </div>
                <div>

                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                  <span>{!formdata ? "Personal Details" : formdata?.procedure_label[1]}</span>
                </div>
                <div>

                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                  <span>{!formdata ? "Educational Details" : formdata?.procedure_label[2]}</span>
                </div>
                <div>

                  <div>
                    <button>

                    </button>
                    <div></div>
                  </div>
                  <span>{!formdata ? "Identification" : formdata?.procedure_label[3]}</span>
                </div>
                <div>

                  <div>
                    <button>

                    </button>

                  </div>
                  <span>{!formdata ? "Payment  Details" : formdata?.procedure_label[4]}</span>

                </div>
              </div>
            </div>
            <p class="bold-v">Admission Procedure Details
            </p>
            <div class="table_head1 w-40-v">
              <div>Sr No.</div>
              <div>Action </div>
              <div>Date </div>
              <div> Last Date</div>

            </div>

            {!formdata ? <>
              <div class="scrool1-1">
                <div class="table_body1 w-40-v">
                  <div>12 </div>
                  <div>Reporting to School for Document verification </div>
                  <div>11 Nov 2021 </div>
                  <div>25 Nov 2021 </div>
                </div>
              </div>
            </> : <>
              {formdata.action_detail.map((item,key) => {
                return (
                  <div class="scrool1-1" key={key}>
                    <div class="table_body1 w-40-v">
                      <div>{key+1}</div>
                      <div>{item.action} </div>
                      <div>{item.start_date}</div>
                      <div>{item.end_date}</div>
                    </div>
                  </div>
                )
              })

              }
            </>}



            <p class="bold-v">Documents Required
            </p>

            
            <div class="table_head1 w-80-v">
              <div>Sr No.</div>
              <div>Document name </div>
            </div>

            {!formdata ? <>
              <div class="scrool1-1" >
              <div class="table_body1 w-80-v">
                <div>Sr No.</div>
                <div>Document name </div>
              
                </div>
            </div>
            </> : <>
              {formdata.document_detail.map((item, key) => {
                return (
                  <div class="scrool1-1"  key={key}>
                  <div class="table_body1 w-80-v">
                    <div>{key + 1}</div>
                    <div>{item.document_name}</div>
                 
              </div>
            </div>
                )
              })

              }
            </>}

            {/* <div class="scrool1-1">
              <div class="table_body1 w-80-v">
                <div>12 </div>
                <div>PAN Card Random Long Name </div>

              </div>
            </div> */}
            {/* <div className="backdrop_at_all_transparent" >
    <div  id="AddModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">

          <div class="modal-body">
            <div class="display-v">
              <h5 class="modal-title">Admission Procedure</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="p-16-1">
              <label>Action Name</label>
              <input class="admission-input-v" placeholder="Enter Message" />
              <label>Date</label>
              <input class="admission-input-v" type="date" />
              <label>Last Date</label>
              <input class="admission-input-v" type="date" />
            </div>
            <div class="display-v end">
              <button type="button" class="white-button-v" data-dismiss="modal">Close</button>
              <button type="button" class="ml-16-v blue-button-v">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div> */}

          </div>
          <div class="bottom-button-v">
            <div class="display-v end">
              <button class="white_button-v" onClick={() => props.setForm(false)}>Back</button>
              {/* <button class="blue_button-v ml-16-v">Send</button> */}
            </div>
          </div></> : <EditForm setEdit={setEdit} settingid={props.settingid} setfromData={setfromData} formDataSet={props.formDataSet} />

      }


    </>
  )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, prof_id: state.auth.prof_id, up_id: state.auth.up_id })

export default connect(mapStateToProps)(CustomiseForm)