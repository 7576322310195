import React, { useState ,useRef} from "react";
import '../../../../css/vAdmin/admin.css'
import api from '../../../../api/api'
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import Loader from '../../../../components/loader'
import config from '../../../../config'
import { errorMsg } from '../../../../utils'



function ChangePopup(props) {

  const [Email, setEmail] = useState("")
  const [phone,setPhone] =useState("")
  const [otp,setOtp] =useState("")
  const [loading, setLoading] = useState(false)
  const [result,setresult] =useState({})
  let refs = [useRef(null), useRef(null), useRef(null), useRef(null)]
  let [val, setVal] = useState(["", "", "", ""])


  const validateEmail = (email) => {
    var reg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!reg.test(email))
      toast.error("Please enter a valid email address");
  }

  const SubmitEmail = () => {
    //  setLoading(true)
    let request = {
      url: `inst/${props.prof_id}/phone-email-change-otp`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: {
        phone: phone,    //add if change phone no
        email_id: Email,   //add if change email
        uid:  props.up_id
      }
    }
    api.postCustom(request).then(data => {
      setLoading(false)
      console.log(data.data.result,"mmfm")
      setresult(data.data.result)
      if(props.changeMob=="Email"){
        toast.success("OTP Sent to your Mail ID.")
      }else{
        toast.success("OTP Sent to your Phone Number.")
      }
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
    })
  }


 const GetOtp =(result) =>{
  // setLoading(true)
  console.log(result,"rresfg")
    let request = {
      url: `inst/${props.prof_id}/validate-phone-email-change-otp`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
      data: {
        id:result.otp_id,
        otp:result.otp_code,
        phone:result.phone_no,
        email_id:result.email_id,
        uid: props.up_id
      }
    }
    api.putOther(request).then(data => {
      setLoading(false)
      toast.success(data.data.result)
    }).catch((err) => {
      setLoading(false)
        if(err.response.status == 401){
        props.dispatch({ type: 'LOGOUT' })
      }else{
        toast.error(errorMsg(err))
      } ;
    })
  }

  let changeFocus = (value, ind) => {
    if(isNaN(value*1)){
        refs[ind].current.value = "";
    } else {
        if(value.length > 1){
            value = (value%10)+"";
        }
        let newVal = [...val]
        newVal[ind] = value
        setVal(newVal);
        if(value.length == 0){
            if(ind != 0){
                refs[ind-1].current.focus();
            }
        } else {
            if(ind != 3){
                refs[ind+1].current.focus();
            }
        }
    }
    
}


  return (
    <>
     {loading && <Loader />}
      <div>
        <div class="modal" id="changeModal">
          <div class="modal-dialog">
            <div class="modal-content">

              {/* <!-- Modal Header --> */}
              <div class="modal-header">
                <div>
                  <h4 class="modal-title">{props.changeMob=="Email"?"Change Email Id":"Change Phone Number"}</h4>
                  <p class="line_purple"></p>
                </div>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                <div class="form-section">
                  <div>
                    <label>{props.changeMob=="Email"?"Enter New Email Id":"Enter New Phone Number"}</label>
                    {
                      props.changeMob=="Email"?
                      <input contenteditable="true" class="expandale-input" placeholder="Enter" onBlur={(e) => validateEmail(e.target.value)} value={Email} onChange={(e) => setEmail(e.target.value)} />
                      : 
                      <input contenteditable="true" class="expandale-input" placeholder="Enter" maxLength={10} value={phone} 
                      onChange={(e) => {
                        let regex = /^\d+$/;
                        if (((e.target.value === '' || regex.test(e.target.value)))) {
                          setPhone(e.target.value)
                        }}}/> 
                    }
                   
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="blue_button" onClick={()=>{props.prof_id==""? toast.error("Please Add Basic Details"):SubmitEmail()}}>Submit</button>
                  </div>
                </div>
                <label class="mt-16 center-align">An OTP has been sent to your registered<br /> email id / mobile number, please
                  verify it below.</label>
                  <div style={{width:"100%",height:'60px' ,display:'flex' ,flexDirection:'row',justifyContent:'space-between' }}>
                   <input style={{width:'15%',height:'60px',fontSize:'50px',textAlign:'center'}}   ref={refs[0]} value={val[0]}  onChange={e => changeFocus(e.target.value, 0)}></input>
                   <input style={{width:'15%',height:'60px',fontSize:'50px',textAlign:'center'}}  ref={refs[1]} value={val[1]}  onChange={e => changeFocus(e.target.value, 1)}></input>
                   <input style={{width:'15%',height:'60px',fontSize:'50px',textAlign:'center'}} ref={refs[2]} value={val[2]}  onChange={e => changeFocus(e.target.value, 2)}></input>
                   <input style={{width:'15%',height:'60px',fontSize:'50px',textAlign:'center'}} ref={refs[3]} value={val[3]}  onChange={e => changeFocus(e.target.value, 3)}></input>
                   {/* <input></input>
                   <input></input>
                   <input></input> */}
                  </div>

                {/* <div class="otp-box" value={otp}  onChange={(e)=>setOtp(e.target.value)}>
                  <div contenteditable="true"/>
                  <div contenteditable="true"/>
                  <div contenteditable="true"/>
                  <div contenteditable="true"/>
                </div> */}
                <label class="mt-16 w-100-a">Didn't Receive OTP? <a href="#" onClick={()=>{props.prof_id==""? toast.error("Please Add Basic Details"):SubmitEmail()}}>Resend OTP</a></label>
              </div>

              {/* <!-- Modal footer --> */}
              <div class="modal-footer">
                <button type="button" class="white_button mr16" data-dismiss="modal" onClick={() => props.setChange(false)}>Close</button>
                <button type="button" class="blue_button" onClick={()=>{props.prof_id==""? toast.error("Please Add Basic Details"):GetOtp(result)}}>Verify</button>
              </div>

            </div>
          </div>
        </div>

      </div>


    </>
  )
}


const mapStateToProps = (state) => ({loginToken: state.auth.loginToken, up_id: state.auth.up_id ,prof_id:state.auth.prof_id })

export default connect(mapStateToProps)(ChangePopup)

