import React, { useState, useEffect } from 'react';
import '../../css/superAdmin/sa_reports.css'
import ReportsD from './reports_default';
import InstReports from './inst_reports';
import { ToastContainer, toast } from 'react-toastify';
import EduReports from './educators_reports';
import StuReports from './stu_reports';
import UserReport from './user_report';
import DailyReport from './dailyReport';
import api from '../../api/api'
import { connect } from "react-redux";
import { errorMsg } from '../../utils'
import Loader from '../../components/loader'




function Report(props) {

    const [rdata, setRdata] = useState()
    const [Show, setShow] = useState("reports_initial_page")
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        getTotalCount()
    }, [])


    const getTotalCount = () => {
        setLoading(true)
        let request = {
            url: `report/count-all?uid=${props.up_id}`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)

        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            };
        })

    }



    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div className="entire_reports">
                {
                    Show == "reports_initial_page" &&
                    <><div className="entire_reports">
                        <div className="sa_reports_inner">
                            <div className="reports_heading_and_cards">
                                <div className="sa_reports_heading">
                                    Reports
                                </div>
                                <div className="sa_reports_cards">

                                    <div className="sa_reports_cards_inner">

                                        <div className="reports_cards" onClick={() => { setShow("inst") }}>
                                            <center>
                                                Institutes
                                                <p>
                                                    {rdata?.institutes ?? '-'}
                                                    {/* {this.state.rprt_data1[0]} */}
                                                </p>
                                            </center>
                                        </div>

                                        <div className="reports_cards" onClick={() => { setShow("school") }}>
                                            <center>
                                                School
                                                <p>
                                                    {rdata?.schools ?? '-'}
                                                    {/* {this.state.rprt_data1[0]} */}
                                                </p>
                                            </center>
                                        </div>

                                        <div className="reports_cards" onClick={() => { setShow("edu") }}>
                                            <center>
                                                Educators
                                                <p>
                                                    {rdata?.educators ?? "-"}
                                                    {/* {this.state.rprt_data1[1]} */}
                                                </p>
                                            </center>
                                        </div>

                                        {/* <div className="reports_cards" onClick={() => { setShow("White") }} >
                                            <center>
                                                White Labeled App
                                                <p>
                                                    {rdata?.white_labelled_app ?? "-"}
                                                
                                                </p>
                                            </center>
                                        </div> */}
                                    </div>

                                </div>

                                <div className="sa_reports_cards">

                                    <div className="sa_reports_cards_inner">

                                        <div className="reports_cards" onClick={() => { setShow("stu") }}>
                                            <center>
                                                Students Reports
                                                <p>
                                                    {rdata?.students ?? "-"}
                                                    {/* {this.state.rprt_data1[0]} */}
                                                </p>
                                            </center>
                                        </div>

                                        <div className="reports_cards" onClick={() => { setShow("activity") }}>
                                            <center>
                                                Daily Activity Reports
                                                <p>
                                                    {'-'}
                                                    {/* {this.state.rprt_data1[2]} */}
                                                </p>
                                            </center>
                                        </div>


                                        <div className="reports_cards" onClick={() => { setShow("user") }}>
                                            <center>
                                                User Log Reports
                                                <p>
                                                    {"-"}
                                                    {/* {this.state.rprt_data1[2]} */}
                                                </p>
                                            </center>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>


                    </>
                }



                {
                    Show == "inst" &&
                    <InstReports setShow={setShow} Show={Show} />
                }
                {
                    Show == "school" &&
                    <InstReports setShow={setShow} Show={Show} />
                }

                {
                    Show == "edu" &&
                    <EduReports FromEachReportsChild={Show} setShow={setShow} Show={Show} />
                }
                {
                    Show == "stu" &&
                    <StuReports FromEachReportsChild={Show} setShow={setShow} Show={Show} />
                }

                {Show == "White" &&
                    < ReportsD FromEachReportsChild={Show} setShow={setShow} Show={Show} />
                }

                {Show == "user" &&
                    <UserReport FromEachReportsChild={Show} setShow={setShow} Show={Show} />
                }
                {Show == "activity" &&
                    <DailyReport FromEachReportsChild={Show} setShow={setShow} Show={Show} />
                }


            </div>
        </>
    )
}





const mapStateToProps = state => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id })
export default connect(mapStateToProps)(Report)
