export const checkUserType = (data) => {
  if (data.usertype == 6) {
    return "Superadmin"
  } else if (data.usertype == 1) {
    return "Coaching"
  } else if (data.usertype == 2) {
    return "School"
  } else if (data.usertype == 3) {
    return "Educator"
  }else if (data.usertype == 5) {
    return "Staff"
  }
}


export const baseurl = () => {
  let path = ''
  if (window.location.host == 'localhost:3000') {
    path = `http://localhost:3000`;
  } else {
    path = `https://` + window.location.host;
  }  
  return path;
}

export const errorMsg = (err) => {
  if (err.response.status ==500) {
    return "Some error occured while executing your request."
  } else if (err.response.status == 400) {
    return err.response.data.error[0]?.error_message
  } else if(err.response.status){
    getErrorMessage(err.response.status)
  } else {
    if(!err.response.data?.error){
      return "Some error occured while executing your request."
    } else if(!err.response.data.error.length) {
      return "Some error occured while executing your request.";
    } else if (!err.response.data.error[0]?.error_message){
      return "Some error occured while executing your request."
    } else {
      return err.response.data.error[0]?.error_message
    }
  }
}

const StatusCode = {
  400: "Bad Request",
  401: "Unauthorized",
  404: "Data not found",
  500: "Internal server error",
  503: "Service unavailable"
}

const getErrorMessage = (statusCode) => {
  switch (statusCode) {
      case 400:
          return StatusCode[400];
      case 401:
          return StatusCode[401];
      case 404:
          return StatusCode[404];
      case 500:
          return StatusCode[500];
      case 503:
          return StatusCode[503];
      default:
          return "Something went wrong";
  }
}