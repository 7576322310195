import React, { useRef, useState } from "react";
import '../../css/website.css'
import '../../css/vAdmin/admin.css'
import add from '../../assets/img/add.svg'
import cross from '../../assets/home/cross.png'
import pdf from '../../assets/img/pdf.png'
import xls from '../../assets/img/xls.png'
import verified from '../../assets/home/verified.png'
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api/api'
import Loader from '../../components/loader'
import { errorMsg } from "../../utils";
import { connect } from "react-redux";


function VerifiedNow(props) {
    const hiddenFileInput = React.useRef(null);
    const [selectId, setselectId] = useState("")
    const [loading, setLoading] = useState(false)
    const [image, setimage] = useState([])
    const [oimage, setOimage] = useState([])

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const validateImage = (e) => {
        let img = e.target.files[0].size / 1024
        if (img > 500) {
            toast.error("Please upload image of size 500kb only")
            return false
        }
        else return true
    }


    const select_MediaContent = (e) => {
        if (validateImage(e)) {
            if ([...oimage, ...image].length > 2) {
                toast.error("You are only allowed to upload a maximum of 3 files")
            } else {
                if (e.target.files.length > 0) {
                    let imgs = [];
                    for (const [key, value] of Object.entries(e.target.files)) {
                        imgs.push(value)
                    }
                    setimage([...image, ...imgs])
                    // props.setSocial({ ...props.Social, gallary: imgs })
                }
            }
        }
    }

    const saveInstitute = () => {
        if (validate()) {
            setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({
                i_p_id: props.prof_id,
                uid: props.up_id,
                d_type: selectId
            }));
            for (let index = 0; index < image.length; index++) {
                fd.append('file', image[index]);

            } let request = {
                url: `inst/kyc/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                setLoading(false)
                toast.success("KYC Added successfully")
                setTimeout(() => {
                    props.setVerifiles(false)
                    props.getCount()
                }, 5000);

            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const saveEducator = () => {
        if (validate()) {
            let prof_id = localStorage.getItem("prof_id")
            setLoading(true)
            var fd = new FormData();
            fd.append("u_data", JSON.stringify({
                // i_p_id: props.prof_id,
                uid: props.up_id,
                d_type: selectId
            }));
            for (let index = 0; index < image.length; index++) {
                fd.append('files', image[index]);

            } let request = {
                url: `educator/${prof_id}/kyc/add`,
                headers: {
                    'Authorization': `Bearer ${props.loginToken}`,
                },
                data: fd
            }
            api.postCustom(request).then(data => {
                setLoading(false)
                toast.success("KYC Added successfully")
                setTimeout(() => {
                    props.setVerifiles(false)
                    props.getCount()
                }, 5000);


            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    const validate = () => {
        if (selectId === '') {
            toast.error("Please select Document Type")
            return false
        }
        if (image.length === 0) {
            toast.error("Please upload Document")
            return false
        }
        return true
    }




    return (
        <>
            {loading && <Loader />}
            <div className="sa-popupEnquiry" >
                <div className="signin-div" style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ borderRight: '1px solid #00000061', padding: "15px", marginTop: '20px', marginBottom: '20px' }}>
                        <div >
                            <img src={verified} style={{ marginLeft: '10px', marginBottom: '30px' }} />
                        </div>
                        <div className="getVerifed">
                            Why To Get Profile Verified ?
                        </div>
                        <div className="headingVerified" style={{ padding: "30px" }}>
                            <div style={{ padding: '5px' }}>1. You will be given a verified by Proctur Badge on your profile</div>
                            <div style={{ padding: '5px' }}> 2. Verified Profiles usually gets 5X more views and inquiries as compared to Non-Verified Profile</div>
                            <div style={{ padding: '5px' }}>  3. Verified Profiles are Ranked Higher as per Our Proctur AI Algorithm</div>
                        </div>

                    </div>
                    <div style={{ padding: "20px" }}>
                        <div className="VreifiedHeading" style={{ marginBottom: '10px', marginTop: '10px' }}>
                            Upload KYC Documents
                        </div>
                        <div className="VreifiedHeadingSmall" style={{ marginBottom: '20px' }}>
                            Please Upload Document that Verifies Your Ownership Of Institute
                        </div>
                        {
                            props.p_type == 3 ?
                                <div className="VerfiedInputTag" style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                                    <label>Document Type<span style={{ color: 'red' }}>*</span></label>
                                    <select className="dropdownVeri" onChange={(e) => setselectId(e.target.value)}>
                                        <option className="ValueVerified">Select</option>
                                        <option value='GOVERNMENT'>Government Issued ID Proof</option>
                                        <option value='IDCARD'>ID card of School / Institute</option>
                                        <option value='OTHER'>Other</option>

                                    </select>
                                </div> :
                                <div className="VerfiedInputTag" style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                                    <label>Document Type<span style={{ color: 'red' }}>*</span></label>
                                    <select className="dropdownVeri" onChange={(e) => setselectId(e.target.value)}>
                                        <option className="ValueVerified">Select</option>
                                        <option value='GOVERNMENT'>Government Issued ID Proof</option>
                                        <option value='OWNERSHIP'>Ownership Proof of School / Institute</option>//notfor educator
                                        <option value='IDCARD'>ID card of School / Institute</option>
                                        <option value='DELEGATION'>Delegation Certification of School / Institute</option>//not for educator
                                        <option value='OTHER'>Other</option>

                                    </select>
                                </div>
                        }
                        <div className="VerfiedInputTag" style={{ display: 'flex', flexDirection: 'column', }}>
                            <label>Upload Document </label>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                                <div className="dropdownVeri" style={{ width: '100%' }}>Choose File</div>
                                <button onClick={handleClick} style={{ background: 'none' }}><img src={add} style={{ cursor: "pointer", width: '20px', height: '20px' }} /></button>
                                <input type='file' ref={hiddenFileInput} onChange={select_MediaContent} style={{ display: 'none' }}></input>

                            </div>
                            <p className="VreifiedHeadingSmall">Format accepted: jpg, jpeg, img, pdf, word / Max size 500kb.</p>
                        </div>
                        <div style={{ height: '140px' }}>

                            {image.map((item,key) => {
                                return (
                                    <div key={key}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
                                            {
                                                item.name.split(".")[1] == "pdf" ?
                                                    <img src={pdf} style={{ width: '40px', height: '40px' }} /> :
                                                    item.name.split(".")[1] == "xls" ?
                                                        <img src={xls} style={{ width: '40px', height: '40px' }} /> :
                                                        <img src={URL.createObjectURL(item)} style={{ width: '40px', height: '40px' }} />
                                            }

                                            <div style={{ width: '80%', marginTop: '6px' }}>{item.name}</div>
                                            <img src={cross} onClick={(index) => setimage(image.filter(item3 => item3.name + item3.lastModified != item.name + item.lastModified))} style={{ width: '30px', height: '30px', marginTop: '5px' }} />
                                        </div>
                                    </div>
                                )
                            })
                            }


                        </div>



                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="CancelButton" onClick={() => props.setVerifiles(false)}>Cancel</div>
                                <div className="SubmitButton" onClick={() => {
                                    if (props.p_type == 3) {
                                        saveEducator()
                                    } else {
                                        saveInstitute()
                                    }
                                }}>Submit</div>
                            </div>
                        </div>

                    </div>

                </div></div>
            <ToastContainer />
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id, p_type: state.auth.p_type })

export default connect(mapStateToProps)(VerifiedNow)