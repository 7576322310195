import React, { useState } from "react";
import api from '../../../api/api'
import Loader from '../../../components/loader'
import { connect } from 'react-redux'
import { errorMsg } from '../../../utils'
import { ToastContainer, toast } from 'react-toastify';


 function Message(props) {

    const [check,setCheck] = useState(false)
    const [message,setMessage] = useState("")
    const [loading, setLoading] = useState(false)
  
  
    const SendNotification = () => {
     
        props.setLoading(true)
        
        let request = {
          url: `website/student/applications/${props.addId}/send-notification`,
          headers: {
            'Authorization': `Bearer ${props.loginToken}`,
          },
          data: {
            message:message
          }
        }
        api.putOther(request).then(data => {
          props.setLoading(false)
          toast.success(data.data.result)
          props.setMessage(false)
        }).catch((err) => {
          props.setLoading(false)
          if(err.response.status == 401){
            props.dispatch({ type: 'LOGOUT' })
          }else{
            props.setMessage(false)
            toast.error(errorMsg(err))
  
          } 
        })
    }

    return (
        <div className="backdrop_at_all_transparent" >
            <div id="changeModal">
            <div class="modal-dialog" >
                <div class="modal-content">

                    <div >
                        <div class="modal-body">
                            <div class="display-v">
                                <h5 class="modal-title" >Message to Student
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>props.setMessage(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="p-16-1">

                                <p class="black_settings-v">You can send message to Students for any more details required regarding the admission application.</p>

                                <textarea class="admission-input-v" placeholder="Enter Message" onChange={(e)=>setMessage(e.target.value)}></textarea>
                            </div>

                            <div class="display-v end">

                                <button type="button" class="white-button-v" data-dismiss="modal" onClick={()=>props.setMessage(false)}>Close</button>
                                <button type="button" class="ml-16-v blue-button-v" onClick={()=>SendNotification()}>Send</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
        
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(Message)