import React, { useState, useEffect } from "react";
import download from '../../../assets/img/download.svg'
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import AcceptRejectPopup from "./popupAccept";
import Message from "./message";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';


function ApplicationRecieved(props) {
    const [rdata, setRdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const [addId, setAddId] = useState("")
    const [search, setSearch] = useState("")
    const [AccRej,setAccRej] = useState("") 
    const [accept, setAccept] = useState(false)
    const [message, setMessage] = useState(false)
    const [Appstatus,setAppStatus] = useState("")
    const [order,setOrdet] = useState("")
    const [Formstatus,setFormStatus] = useState("")







    useEffect(() => {
        fetchData();
    }, [search,Appstatus,order,Formstatus])


    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `website/institute/${props.prof_id}/application-received/get?search_text=${search}&app_status=${Appstatus}&sort_order=${order}&form_status=${Formstatus}&pageNumber&pageSize`,
            headers: {
                'Authorization': `Bearer ${props.loginToken}`,
            },
        }
        api.getCustom(request).then(data => {
            setLoading(false)
            setRdata(data.data.result)
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }


    return (
        <>
        {loading && <Loader />}
            <div id="two" class="tab-pane ">
                <div class="display-v mt-16">
                    <input class="search admission-input-v" type="text" placeholder="Search" onChange={(e)=>setSearch(e.target.value)}/>
                    <div class="flex-v">
                        <select class="admission-input-v" value={Appstatus} onChange={(e)=>setAppStatus(e.target.value)}>
                            <option value="">Select Status</option>
                            <option value="true">Complete</option>
                            <option value="faslse">Incomplete</option>
                        </select>
                        <select class="admission-input-v ml-16-v" value={order} onChange={(e)=>setOrdet(e.target.value)}>
                            <option value="">Application Status</option>
                            <option value="1">Pending</option>
                            <option value="4">Accepted</option>
                            <option value="2">Rejected</option>
                        </select>
                        {/* <select class="admission-input-v ml-16-v">
                                                <option disabled hidden selected-v>Academic Year</option>
                                                <option disabled hidden selected-v>2021-22</option>
                                            </select> */}
                        <select class="admission-input-v ml-16-v" value={Formstatus} onChange={(e)=>setFormStatus(e.target.value)}>
                            <option value="">Sort By</option>
                            <option value="desc">Latest</option>
                            <option value="asc">Oldest</option>

                        </select>
                    </div>
                </div>
                <div class="table_head">

                    <div>Student Name </div>
                    <div class="md-div-v">Mobile number </div>
                    <div> Email </div>
                    <div class="md-div-v"> Application </div>
                    <div class="sm-div-v"> Form Fee </div>
                    <div class="sm-div-v"> Status </div>
                    <div class="sm-div-v"> Applied on </div>
                    <div class="md-div-v"> Last Updated By </div>
                    <div class="md-div-v"> Last Updated On </div>
                    <div class="lg-div-v"> Action </div>

                </div>
                <div class="scrool1">
                    {
                        rdata?.map((item, key) => {
                            return (
                                <div class="table_body" key={key}>

                                    <div><label>Student Name</label> {item.student_name} </div>
                                    <div class="md-div-v"><label>Mobile number</label> {item.mobile_no}</div>
                                    <div class="email-addjust"><label>Email</label><span>{item.email}</span></div>
                                    <div class="md-div-v"><label>Application</label>{item.application_status}</div>
                                    <div class="sm-div-v"><label>Form Fee</label>{item.is_form_fee_paid == true ? "Paid" : "Pending"} <a><img src={download} /></a></div>
                                    <div class="sm-div-v"><label> Status</label> {item.form_status == true ? "Complete" : "Incomplete"} </div>
                                    <div class="sm-div-v"><label>Applied on</label> {item.applied_on} </div>
                                    <div class="md-div-v"><label>Last Updated By</label>{item.updated_by} </div>
                                    <div class="md-div-v"><label>Last Updated On</label>{item.updated_date}</div>
                                    <div class="lg-div-v"><label>Action</label> <button class="acceptbutton" onClick={() => {setAddId(item.application_id);setAccRej("A"); setAccept(true) }}>Accept</button> <button data-toggle="modal" data-target="#exampleModal" class="rejectbutton ml-8" onClick={() => { setAccept(true); setAccRej("R"); setAddId(item.application_id) }}>Reject</button> <button data-toggle="modal" class="viewbutton ml-8" data-target="#exampleModal1" onClick={() => {setAddId(item.application_id);setMessage(true)}}>Message</button> </div>

                                </div>
                            )
                        })
                    }

                </div>
            </div>

            {
                accept &&
                <AcceptRejectPopup setAccept={setAccept} setAccRej={setAccRej} AccRej={AccRej} addId={addId} setLoading={setLoading}/>
            }

            {message &&
                <Message setMessage={setMessage} addId={addId} setLoading={setLoading}/>

            }
        </>
    )
}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(ApplicationRecieved)