import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { coachingRoutes } from "../routes/coachingRoutes.js";
import { superAdminRoutes } from "../routes/superAdminRoutes.js";
import { connect } from 'react-redux';
import { checkUserType } from '../utils';

function Routes(props) {
  const type = checkUserType(props.auth)

  function checkValid(usertype){
    return true;
    if(window.location.pathname == '/'){
      return true;
    }else if(usertype=='Superadmin'){
      return type == 'Superadmin';
    } else if(usertype == 'Institute'){
      return (type=='Coaching' || type=='Educator');
    } else {
      return type=='Educator';
    }
  }
 
  function PrivateRoute({ children, usertype, ...rest }) {
    console.log(usertype+"SDLJFLDKJLDFF")
    console.log(checkValid(usertype))
    return (
      <Route {...rest} render={({ location }) =>
        checkValid(usertype) ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
      />
    );
  }


  return (
    <Router>
        <PrivateRoute path="/" usertype="Superadmin">
          <Switch>
            {
              superAdminRoutes.map((routes, i) => {
                return (
                  <Route key={i}
                    path={routes.path}
                    exact={routes.exact}
                    strict={routes.strict}
                    children={<routes.component />}
                  />
                )
              })
            }
            </Switch>
        </PrivateRoute>
        <PrivateRoute path="/" usertype="Institute">
          <Switch>
            {
              coachingRoutes.map((routes, i) => {
                return (
                  <Route key={i}
                    path={routes.path}
                    exact={routes.exact}
                    strict={routes.strict}
                    
                    children={<routes.component />}
                  />
                )

              })

            }
            </Switch>
        </PrivateRoute>
          
     
    </Router>
  )
}



const mapStateToProps = state => ({ auth: state.auth })
export default connect(mapStateToProps)(Routes)
