import React, { useState, useEffect } from "react";
import Delete from '../../../components/delete'
import KYCForm from "./kyc_form";
import Loader from '../../../components/loader'
import api from '../../../api/api'
import { errorMsg } from '../../../utils'
import { connect } from "react-redux";
import Moment from 'moment'; 
import { ToastContainer, toast } from 'react-toastify';
import Educatorheader from "../../addEducators/educator_header";



export function KYCBlock(props) {
  const [selected, setSelected] = useState(0)
  const [showform, setshowform] = useState(false)
  const [rdata, setRdata] = useState([]);
  const [loading, setLoading] = useState(false)
  const [deletepopup, setdelete] = useState(false)
  const [id, setid] = useState("")



  useEffect(() => {
    fetchData();
    setSelected(0);
  }, [])


  const fetchData = () => {
    if (props.eduId == "") {
      toast.error("Please add Basic Details first.")
    } else {
      setLoading(true)
      let request = {
        url: `educator/kyc/all?educator_id=${props.eduId}&pageOffset=1&pageSize=10&isAll=false`,
        headers: {
          'Authorization': `Bearer ${props.loginToken}`,
        },
      }
      api.getCustom(request).then(data => {
        setLoading(false)
        setRdata(data.data.result.response)
      }).catch((err) => {
        setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const deletekyc = () => {
    setdelete(false)
    let request = {
      url: `educator/kyc/${id}/delete`,
      headers: {
        'Authorization': `Bearer ${props.loginToken}`,
      },
    }
    api.delete(request).then(data => {
      setLoading(false)
      toast.success("KYC Deleted successfully")
      setRdata(rdata.filter((item3) => item3.id != id))
      setid("")
    }).catch((err) => {
      setid("")
      setLoading(false)
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        toast.error(errorMsg(err))
      }
    })
  }

  return (<>
    {loading && <Loader />}

    <Educatorheader
      count={props.count} setCount={props.setCount}
      setButton={props.setButton}
      setEcount={props.setEcount}
      showform={showform}
    />

    {showform == false && <div>
      <div class="box-section1">
        <div>KYC </div>
        <div><button class="blue_button" onClick={() => { setshowform(true); setSelected(0) }}>Add KYC </button></div>
      </div>
      <div class="box-educator">
        {rdata.map((item,key) => {
           
          return (
            <div key={key}>

              <div class="display added-by">
                <p>Document Name</p>
                <p> {item.name}</p>
              </div>
              <div class="display added-by">
                <p>File Name</p>
                {/* <p>{item.d_url.split("/").pop()}</p> */}
              </div>
              <div class="display added-by">
                <p>Added On</p>
                <p>{Moment(item.c_date).format('DD-MM-YYYY')}</p>
              </div>
              <div class="line-v-grey">
              </div>
              <div class="flex">
                <div class="flex-a">

                </div>
                <div class="ml-a">
                  <button class="editbutton" onClick={() => { setshowform(true); setSelected(item.id) }}> Edit</button>

                  <button class="unlinkbutton ml16" onClick={() => { setdelete(true); setid(item.id) }}>Delete</button>
                </div>
              </div>
            </div>
          )
        })

        }
      </div>
    </div>
    }


    {deletepopup == true &&
      <Delete delete={deletekyc} setdelete={setdelete} />
    }

    {
      showform == true &&
      <KYCForm setSelected={setSelected} selected={selected} data={rdata.find(data => data.id == selected)}
        showform={showform} setshowform={setshowform}
        fetchData={fetchData} eduId={props.eduId}
      />
    }
    <ToastContainer />
  </>)

}

const mapStateToProps = (state) => ({ loginToken: state.auth.loginToken, up_id: state.auth.up_id, prof_id: state.auth.prof_id })

export default connect(mapStateToProps)(KYCBlock)