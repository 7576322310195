import React from "react";
import close from '../../../../assets/img/close.svg'



function MoreDetails(props) {
  let type = window.location.href.split("?")[1].split("=")[1]



  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <label>Education Level<span>*</span></label>
          <p class="line_purple"></p>
          <p class="data-info">Please select Education Level that you offer.</p>
          <div class="select-wrapper">

            <select class="expandale-input w-200"
              onChange={(e) => props.setSocial({ ...props.Social, educationLevel: [...props.Social.educationLevel, e.target.value] })}
            >
              <option value="0">Select</option>
              {props.Master.educationLevel.map((item, key) => props.Social.educationLevel.indexOf(item.entity_id) == -1 && <option value={item.entity_id}>{item.name}</option>)}
            </select>
          </div>
          <div class="tags">
            {props.Master.educationLevel.map((item, key) => props.Social.educationLevel.indexOf(item.entity_id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, educationLevel: props.Social.educationLevel.filter((data) => data != item.entity_id) })} /></button>)}
          </div>
        </div>

        {
          type == 'institute' &&
          <div>
            <label>Profile Type<span>*</span></label>
            <p class="line_purple"></p>
            <p class="data-info">Please select Profile Type that you offer.</p>
            <div class="select-wrapper">

              <select class="expandale-input w-200"
                onChange={(e) => props.setSocial({ ...props.Social, ProfileType: [...props.Social.ProfileType, e.target.value] })}
              >
                <option value="0">Select</option>
                {props.Master.ProfileType.map((item, key) => props.Social.ProfileType.indexOf(item.entity_id) == -1 && <option value={item.entity_id}>{item.name}</option>)}
              </select>
            </div>
            <div class="tags">
              {props.Master.ProfileType.map((item, key) => props.Social.ProfileType.indexOf(item.entity_id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, ProfileType: props.Social.ProfileType.filter((data) => data != item.entity_id) })} /></button>)}
            </div>
          </div>
        }

      </div>





      <div>
        <label>More Details</label>
        <p class="line_purple"></p>
        <div class="display f-wrap">
          {
            type == "school" &&
            <><div>
              <label>Ownership Type<span>*</span></label>

              <div class="select-wrapper">
                <select class="expandale-input w-200" value={props.Details.ownershipTyp} onChange={(e) => props.setDetails({ ...props.Details, ownershipTyp: e.target.value })}>
                  <option value="" disabled selected hidden>Select </option>
                  {props.Master.ownershipTyp.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>); })}
                </select>
              </div>
            </div><div>
                <label>Education Board<span>*</span></label>

                <div class="select-wrapper">
                  <select class="expandale-input w-200" value={props.Details.eduBoard}
                  onChange={(e) => props.setDetails({ ...props.Details, eduBoard:[...props.Details.eduBoard, e.target.value] })}>
                    <option>Select</option>
                    {props.Master.eduBoard.map((item, key) => props.Details.eduBoard.indexOf(item.entity_id) == -1 && <option value={item.entity_id}>{item.name}</option>)}
                    {/* {props.Master.eduBoard.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>); })} */}
                  </select>
                </div>
                <div class="tags">
                  {props.Master.eduBoard.map((item, key) => props.Details.eduBoard.indexOf(item.entity_id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setDetails({ ...props.Details, eduBoard: props.Details.eduBoard.filter((data) => data != item.entity_id) })} /></button>)}
                </div>
              </div></>
          }

          {
            type == "college" &&
            <><div>
              <label>University<span>*</span></label>
              <div class="select-wrapper">
                <select class="expandale-input w-200" value={props.Details.university} onChange={(e) => props.setDetails({ ...props.Details, university: e.target.value })}>
                  <option>Select</option>
                  {props.Master.university.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>); })}
                </select>
              </div>
            </div>

              <div>
                <label>Department<span>*</span></label>
                <div class="select-wrapper">

                  <select class="expandale-input w-200"
                    onChange={(e) => {props.getStream(e.target.value);props.setSocial({ ...props.Social, Department: [...props.Social.Department, e.target.value] })}}
                  >
                    <option value="0">Select</option>
                    {props.Master.Department.map((item, key) => props.Social.Department.indexOf(item.id) == -1 && <option value={item.id}>{item.name}</option>)}
                  </select>
                </div>
                <div class="tags">
                  {props.Master.Department.map((item, key) => props.Social.Department.indexOf(item.id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, Department: props.Social.Department.filter((data) => data != item.id) })} /></button>)}
                </div>
              </div>

              <div>
                <label>Stream<span>*</span></label>
                <div class="select-wrapper">

                  <select class="expandale-input w-200" 
                    onChange={(e) => props.setSocial({ ...props.Social, Stream: [...props.Social.Stream, e.target.value] })}
                  >
                    <option value="0">Select</option>
                    {props.Master.Stream.map((item, key) => props.Social.Stream.indexOf(item.id) == -1 && <option value={item.id}>{item.name}</option>)}
                  </select>
                </div>
                <div class="tags">
                {props.Master.Department.reduce((pValue,cValue)=>[...pValue,...cValue?.sub_mst_li??[]],[]).map((item, key) => props.Social.Stream.indexOf(item.id) !== -1 && <button>{item.name}<img src={close} onClick={() => props.setSocial({ ...props.Social, Stream: props.Social.Stream.filter((data) => data != item.id) })} /></button>)}
                </div>
              </div>

            </>
          }

          <div>
            <label>Established In </label>
            {
              (type == 'institute' || type == 'college') &&
              <p class="data-info">&nbsp;</p>
            }
            <input type="date" class="expandale-input" value={props.Details.established} onKeyDown={(e) => e.preventDefault()} onChange={(e) => props.setDetails({ ...props.Details, established: e.target.value })} />
          </div>
          <div>
            <label>Number of Students</label>
            <p class="data-info">Enter your student strength </p>
            <input contenteditable="true" class="expandale-input w-200" type="number" min={0} placeholder="Enter" onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} value={props.Details.studentNo} onChange={(e) => props.setDetails({ ...props.Details, studentNo: e.target.value })}></input>
          </div>
          <div>
            <label>Number of Faculties</label>
            <p class="data-info">Enter your faculty strength </p>
            <input contenteditable="true" class="expandale-input w-200" type="number" min={0} placeholder="Enter" onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} value={props.Details.facultiesNo} onChange={(e) => props.setDetails({ ...props.Details, facultiesNo: e.target.value })}></input>
          </div>
          <div>
            <label>Student to Faculty Ratio</label>
            <p class="data-info ">Enter number of students assigned to one faculty</p>
            <input contenteditable="true" class="expandale-input w-200" type="number" min={0} placeholder="Enter" onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} value={props.Details.ratio}
              onChange={(e) => {
                props.setDetails({ ...props.Details, ratio: e.target.value })
              }}
            ></input>
          </div>
          {
            type == "school" &&
            <div>
              <label>School Type </label>

              <div class="select-wrapper">
                <select class="expandale-input w-200" value={props.Details.schooltype} onChange={(e) => props.setDetails({ ...props.Details, schooltype: e.target.value })}>
                  <option value="">Select</option>
                  {props.Master.schooltype.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
                </select>
              </div>
            </div>
          }

          {
            type == "college" &&
            <div>
              <label>College Type <span>*</span></label>
              {
                type == 'college' &&
                <p class="data-info">&nbsp;</p>
              }
              <div class="select-wrapper">
                <select class="expandale-input w-200" value={props.Details.collegeType} onChange={(e) => props.setDetails({ ...props.Details, collegeType: e.target.value })}>
                  <option value="">Select</option>
                  {props.Master.collegeType.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
                </select>
              </div>
            </div>
          }

          <div>
            <label>Campus Size</label>
            {
              (type == 'institute' || type == 'college') &&
              <p class="data-info">&nbsp;</p>
            }
            <div class="display flex">
              <input contenteditable="true" class="expandale-input" type="number" min={0} placeholder="Enter" value={props.Details.campusSize} onChange={(e) => props.setDetails({ ...props.Details, campusSize: e.target.value })}></input>
              <div class="select-wrapper">
                <select class="expandale-input w-20">
                  <option>Acres</option>
                  {props.Master.campusSize.map((item, key) => { return (<option key={key} value={item.id}>{item.value}</option>) })}
                </select>
              </div>
            </div>

          </div>
          <div>
            <label>Campus Type</label>
            {
              type == 'institute' &&
              <p class="data-info">&nbsp;</p>
            }
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={props.Details.campusType} onChange={(e) => props.setDetails({ ...props.Details, campusType: e.target.value })}>
                <option value="">Select</option>
                {props.Master.campusType.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>

          <div>
            <label>Mode Of Teaching<span>*</span></label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={props.Details.modeofTeaching} onChange={(e) => props.setDetails({ ...props.Details, modeofTeaching: e.target.value })}>
                <option value="">Select</option>
                {props.Master.modeofTeaching.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>
          <div>
            <label>Education Type</label>
            <div class="select-wrapper">
              <select class="expandale-input w-200" value={props.Details.eductionType} onChange={(e) => props.setDetails({ ...props.Details, eductionType: e.target.value })}>
                <option value="">Select</option>
                {props.Master.eductionType.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>
          <div>
            <label>Medium of Instruction</label>

            <div class="select-wrapper">
              <select class="expandale-input w-200" value={props.Details.mediumofinst} onChange={(e) => props.setDetails({ ...props.Details, mediumofinst: e.target.value })}>
                <option value="">Select</option>
                {props.Master.mediumofinst.map((item, key) => { return (<option key={key} value={item.entity_id}>{item.name}</option>) })}
              </select>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default MoreDetails