import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom'
import {rootstore, persistor} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(
    <Provider store={rootstore}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    , document.getElementById('root')
)